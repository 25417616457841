import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { justFetchDataV2, fetchDataV2, updateDataV2, updateDataV3 } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import createButtonSm from "../../../images/createbuttonsm.svg";
import "../auditplan/auditplan.css";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import Button from "../../user/Button";

const Auditplan = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();
  const [loading, setLoading] = useState(false);


  const location = useLocation();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const successMessageColor = location.state?.successMessageColor;
  const [successMessage,setSuccessMessage] = useState(location.state?.successMessage);
  const [successMessageTask,setSuccessMessageTask]=useState(false)
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [showFielworkPopup, setShowFieldworkPopup] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  useEffect(() => {
      const timeoutId = setTimeout(() => {
        navigate(".", { replace: true });
        setSuccessMessage(false);
      }, 5000);
      return () => clearTimeout(timeoutId);
    }, [successMessage]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/plan/?audit_id=${id}`,
      setterFunction: (data) => { setAPIData(data) },
      setErrors: (error) => { setErrors(error) },
      setLoading: setLoading,
    }

    justFetchDataV2(parameter);
  }, [id]);


  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAuditData,
      setErrors: setErrors
    }
    fetchDataV2(parameter);
  }, [id]);



  useEffect(() => {
    setAreAllTasksDone(APIData.every(task => task?.task?.status === "Done"));

  }, [APIData]);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const auditEntityName = [...new Set(APIData.map(item => item.audit.audit_entity?.name))];



  const renderIcons = () => {
    return auditExecution.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
        <NavLink
          to={`${route.path.replace(":id", id)}`}
          key={index}
          className="navlink"
          activeClassName="active1"
          end
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const Viewuser = (data) => {
    const planId = data;
    navigate(`/audit-execution/${id}/planning/${planId}/`);
  };
  const sortOrder = [
    "Prepare and Send Audit Memo",
    "Schedule Kick-off Meeting",
    "Send initial list of requirements",
    "Fill RCM Template",
  ];

  const sortedData = APIData.sort((a, b) => {
    const typeA = a.data?.type?.trim().toLowerCase();
    const typeB = b.data?.type?.trim().toLowerCase();

    return sortOrder.findIndex(item => item.toLowerCase() === typeA) -
      sortOrder.findIndex(item => item.toLowerCase() === typeB);
  });





  const renderAuditPlanStatus = (status) => {
    switch (status) {
      case "Done":
        return (
          <span
            className="status_complete"
          >
            Done
          </span>
        );
      case "todo":
      default:
        return (
          <span
            className="status_rejected"
          >
            Pending
          </span>
        );
    }
  };

  const handleCheckboxChange = (taskId, status, task) => {


    if (task.type !== "Fill RCM Template" && (task.scheduled_start_date === null || task.scheduled_end_date === null)) {
      setErrors("Please fill the Start Date And End Date to Complete the Task");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
      setTaskDetails(task);
      setSelectedTaskId(taskId); 
    if (status === 'Done') {
      setShowPopup(false);
    }
    else {
      setShowPopup(true);
    }

  };

  const handlePopupClose = (task) => {
    const Parameter = {
      url: `/api/audit_engagement/plan/${task.id}`,
      payload: {
        objective: task?.objective,
        preparer_id: task.preparer?.id,
        reviewer_id: task.reviewer?.id,
        assign_to_id: task.assign_to?.id,
        type: task.type,
        audit_id: id,
        task_data: "Done",
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageTask,
        items: [{ fetchurl: `/api/audit_engagement/plan/?audit_id=${id}`, dataset: setAPIData }],
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/audit-execution/${id}/planning`
    }

    updateDataV3(Parameter);

    // setAPIData((prevAPIData) =>
    //   prevAPIData.map((task) => {
    //     if (task.id === selectedTaskId) {
    //       return {
    //         ...task,
    //         task: {
    //           ...task.task,
    //           status: "Done",
    //         },
    //       };
    //     }
    //     return task;
    //   })
    // );
    setShowPopup(false);
  };


  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };
  const handlePopupFiledworkCloseNo = () => {
    setShowFieldworkPopup(false);
  };

  const updateWithPhase = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];

    const Parameter = {
      url: `/api/audit_engagement/audit/date/${id}`,
      payload: {
        date_of: "fieldwork_date",
        start_date: currentDate,
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/audit-execution/${id}/Fieldwork/`,
    }

    updateDataV2(Parameter);
  };


  const phaseTrue = {
    phase: auditData.phase
  }
  const name = auditData.name;


  const createTask = () => {
    navigate(`/audit-execution/${id}/Planning/GenericTask`)
  }





  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageTask && (<p className="successmessage">Task Done Successfully</p>)}
      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}
      {showFielworkPopup && (
        <div className="popupATP" style={{width:'600px'}}>
          <div className="subpopupATP" >
            <p style={{wordWrap:'wrap',textAlign:'center'}}>Planning phase will be completed, and Fieldwork phase will be started. Please 
            confirm?</p>
            <div className="subpopupBtnDiv">
              <button onClick={(e) => updateWithPhase(e)}>Yes</button>
              <button onClick={handlePopupFiledworkCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow parentName={name} name={name} Create={'Create'} link={auditData.phase === 'Planning' ? `/audit-execution/${id}/planning/generic-task` : null} trueback={true} phaseTrue={phaseTrue} backToExecution={`/audit-execution`} />

        <div className="planTableContainer"
          style={{
            width: tableSize ? "100%" : "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <table
            className="task_list"
          >
            <thead >
              <tr className="tablehead" >
                <th><p style={{paddingLeft:"27px",fontFamily:"intersemibold"}}>Type</p></th>
                <th>Status</th>
                <th>Prepared By</th>
                <th>Actions</th>
              </tr>
            </thead>


            {loading ? (
              <div className="spinner-bounce-loader">
                <BounceLoader color="#a6fafa" />
              </div>
            ) : APIData.length === 0 && auditData.phase !== "Open" ? (
              <p className="data-error-text">No data found</p>
            ) : auditData.phase === "Open" ? (
              <p className="data-error-empty-text">Task must be generated by clicking "Start Planning" button</p>
            ) : (
              sortedData.map((task) => (
                <React.Fragment key={task.id}>
                  <tbody>
                    <tr className="task">
                      <td >
                        <p onClick={() => Viewuser(task.id)}>
                          {task.type ? task.type : ""}
                        </p>
                      </td>
                      <td>{renderAuditPlanStatus(task.task ? task?.task?.status : "")}</td>
                      <td>
                        <p>{task.preparer ? task.preparer.name : ""}</p>
                      </td>
                      <td>
                        <button
                          style={{ backgroundColor:task?.task?.status === "Done"?'#7A7A7A': "#5757df" }}
                          className="cmn_btn"
                          onClick={() =>
                            handleCheckboxChange(task?.id, task?.task?.status, task)
                          }
                          disabled={task?.task?.status === "Done"}
                        >
                          Complete Task
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </React.Fragment>
              ))
            )}

            <div className="button_container">
              {auditData.phase === "Planning" && (
                <button onClick={() => setShowFieldworkPopup(true)}
                  className="cmn_btn"
                  style={{ backgroundColor: !areAllTasksDone ? '#7A7A7A' : "#5757df" }}
                  disabled={!areAllTasksDone}
                >Start Fieldwork</button>)}
            </div>
          </table>


          {/* {auditData.phase ==="Planning Complete" &&( <div ><span> Planning Completed</span></div>)}  */}


        </div>

        <div className="cardscontainer">
          {
            APIData.map((task) => (
              <React.Fragment key={task.id}>

                <div className="deptcard" onClick={() => Viewuser(task.id)}>
                  <div className="bunstkhlder">
                    <h2>
                      {task.type ? task.type : (<p className="buplacehldr"> Type </p>)}
                    </h2>
                  </div>

                  <div className="deptnauthnbtn">

                    <h5>
                      {task?.preparer?.name ? task?.preparer?.name : (<p className="buplacehldr"> Preparer </p>)}
                    </h5>

                    <h6>
                      {task.id ? task.id : (<p className="buplacehldr" >N/A</p>)}
                    </h6>

                  </div>
                  {/* <img src={openarrow} alt="Open Arrow" /> */}
                </div>

              </React.Fragment>
            ))
          }

        </div>

      </section>
    </>
  );
};

export default Auditplan;
