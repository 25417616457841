import React, { useState } from "react";

const CommonInput = ({
  labelName,
  error,
  type,
  placeHolder,
  setText,
  setTextError,
  value,
  editMode,
  commanDivClass,
  tabIndex=0
}) => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
  
    if (type === "number") {
      // Allow only numbers
      if (/^\d*$/.test(inputValue)) {
        setText(inputValue);
  
        // Check if input is empty
        if (inputValue === "") {
          setTextError(false); // No error for empty input
        } else if (inputValue.length >= 10 && inputValue.length <= 15) {
          setTextError(false); // Valid length
        } else {
          setTextError(true); // Invalid length
        }
      }
    } else {
      // For other input types
      setText(inputValue);
      setTextError(false);
    }
  };

  return (
    <div className={commanDivClass}>
      <label>{labelName}</label>
      <div style={{ position: "relative" }}>
        <input
          style={{
            border: error ? "1px solid #FF4646" : "",
            backgroundColor: editMode ? "#e6e6e6" : "#EEF1FC",
          }}
          required
          type={showPassword && type === "password" ? "text" : type}
          placeholder={placeHolder}
          value={value}
          className={editMode ? "editable-input" : "non-editable-input"}
          readOnly={editMode}
          onChange={handleInputChange}
          tabIndex={tabIndex}
        />

        {type === "password" && (
          <span
            onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          >
            {showPassword ? "Hide" : "Show"}
          </span>
        )}
      </div>
    </div>
  );
};

export default CommonInput;
