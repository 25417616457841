import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../css/viewuser.css";
import { fetchDataV2, updateDataV2, fetchDataObjectV2, updateDataV3 } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import "../auditplan/auditplan.css";
import "./auditreporting.css";
import "./auditreport.css";
import heatmap from "../../../images/heatmapSVG.svg";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
const GenerateReportMoreDetails = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const { id, obejctionId } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [introduction, setIntroduction] = useState();
  const [introductionerror, setIntroductionError] = useState();
  const [audit_background, setBackground] = useState();
  const [audit_backgrounderror, setBackgroundError] = useState();
  const [final_opinion, setFinalOpinion] = useState();
  const [final_opinionerror, setFinalOpinionError] = useState();
  const [reportData, SetReportData] = useState([]);
  const [editMode, setEditMode] = useState(false);
const [successMessageUpdate,setSuccessMessageUpdate] =useState(false);

  const handleEditClick = (e) => {
    e.preventDefault();
    setEditMode(true);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  console.log(obejctionId);
  // useEffect(() => {
  //   const parameter ={
  //     url :`/api/audit_engagement/observation/${obejctionId}`,
  //     setterFunction : setAPIData,
  //     setErrors : setErrors,
  //     setLoading : setLoading
  //    }
  //   fetchDataObjectV2(parameter);

  // }, []);

  // useEffect(() => {
  //   const parameter ={
  //     url : `/api/audit_engagement/report/?audit_id=${id}`,
  //     setterFunction :SetReportData,
  //     setErrors : setErrors
  //    }
  //   fetchDataV2(parameter,
  //     (data) => {
  //       console.log("Fetched files:", data.files); // Check data structure
  //       SetReportData(data.files || []);
  //     }
  //   );
  // }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/observation/?audit_id=${id}`,
      setterFunction: setAPIData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAuditData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    setIntroduction(auditData?.introduction || "");
    setBackground(auditData?.audit_background || "");
    setFinalOpinion(auditData?.final_opinion || "");
  }, [auditData]);
  

  const updateMoreDetails = (e) => {
    // if (!introduction || !audit_background || !final_opinion) {
    //   setIntroductionError(!auditData?.introduction ? true : false);
    //   setBackgroundError(!auditData?.audit_background ? true : false);
    //   setFinalOpinionError(!auditData?.final_opinion ? true : false);
    //   setErrors("Please fill in all required fields.");
    //   setTimeout(() => {
    //     setErrors(null);
    //   }, 5000);
    //   return;
    // }

    e.preventDefault();
    const Parameter = {
      url: `/api/audit_engagement/audit/${id}`,
      payload: {
        introduction:introduction,
        audit_background:audit_background,
        final_opinion:final_opinion,
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageUpdate,
        items: [{ fetchurl: `/api/audit_engagement/audit/${id}/`, dataset: setAuditData }],
      },
      setErrors: setErrors,
    };

    updateDataV3(Parameter);
    setEditMode(false);
  };

  const handlePopupClose = (task) => {
    const Parameter = {
      url: `/api/system/task/${task?.task?.id}`,
      payload: {
        title: task.objective,
        status: "done",
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/audit-execution/${id}/reporting`,
    };

    updateDataV2(Parameter);

    setAPIData((prevAPIData) =>
      prevAPIData.map((task) => {
        if (task.id === selectedTaskId) {
          return {
            ...task,
            task: {
              ...task.task,
              status: "Done",
            },
          };
        }
        return task;
      })
    );

    setShowPopup(false);
  };

  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };

  const moreDetails = () => {
    navigate(`/audit-execution/${id}/reporting/generatereportlist`);
  };

  const phaseTrue = {
    phase: auditData.phase,
  };
  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessageUpdate && (
        <p className="successmessage">Updated Successfully</p>
      )}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}
      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow
          name={"More Details"}
          trueReport={() => moreDetails()}
          moreReport={true}
          parentName={APIaudit?.name}
        />

        <div
          className="moreDetailContainer"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
           <div className="backToReport">
              <button
                className="cmn_btn"
                style={{ backgroundColor:editMode? "#65CC5C":"#7A7A7A" }}
                disabled={!editMode}
                onClick={(e) => updateMoreDetails(e)}
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Save
              </button>

              {editMode && (
                <button
                  className="cmn_btn"
                  style={{ backgroundColor: "#d6d6d6", color: "#000" }}
                  onClick={() => handleCancelClick()}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L10.9989 10.9989"
                      stroke="#292D32"
                      stroke-width="1.76758"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.00107 10.9989L11 1"
                      stroke="#292D32"
                      stroke-width="1.76758"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel Edit
                </button>
              )}

              <button
                className="cmn_btn"
                style={{ backgroundColor: "#5571DF" }}
                onClick={handleEditClick}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Edit
              </button>
            </div>
          <form>

            <div className="lbl_txa">
              <label htmlFor="">Introduction</label>
              <textarea
                style={{
                  border: introductionerror ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                value={introduction}
                onChange={(e) => {
                  setIntroduction(e.target.value);
                  setIntroductionError(false);
                }}
                readOnly={!editMode}
                placeholder="Introduction"
              ></textarea>
            </div>
            <div className="lbl_txa">
              <label htmlFor="">Background</label>
              <textarea
                style={{
                  border: audit_backgrounderror ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                value={audit_background}
                onChange={(e) => {
                  setBackground(e.target.value);
                  setBackgroundError(false);
                }}
                readOnly={!editMode}
                placeholder="Background"
              ></textarea>
            </div>
            <div className="lbl_txa">
              <label htmlFor="">Final Opinion</label>
              <textarea
                style={{
                  border: final_opinionerror ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                value={final_opinion}
                onChange={(e) => {
                  setFinalOpinion(e.target.value);
                  setFinalOpinionError(false);
                }}
                readOnly={!editMode}
                placeholder="Final Opinion"
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="heat_map_div">
                <img src={heatmap} />
              </div>
            </div>
           
          </form>
        </div>
      </section>
    </>
  );
};

export default GenerateReportMoreDetails;
