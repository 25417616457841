import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import LoginForm from "./components/LoginForm";
import Top from "./components/Top";
import SideBar from "./components/SideBar";
import Users from "./components/user/Users";
import CreateUser from "./components/user/CreateUser";
import Viewuser from "./components/user/ViewUser";
import ForgotPassword from "./components/user/ForgotPassword";
import Department from "./components/department/Departments";
import Createdepartment from "./components/department/CreateDepartment";
import Viewdepartment from "./components/department/ViewDepartment";
import Businessunit from "./components/businessunit/Businessunit";
import Createbu from "./components/businessunit/CreateBu";
import Viewbusinessunit from "./components/businessunit/ViewBusinessunit";
import Teams from "./components/Teams/Teams";
import ViewTeams from "./components/Teams/ViewTeams";
import CorporateStructure from "./components/corporateStructure/CorporateStructure";
import Auditentity from "./components/auditUniverse/auditEntities/AuditEntity";
import Createentity from "./components/auditUniverse/auditEntities/CreateEntity";
import Viewaudit from "./components/auditUniverse/auditEntities/ViewAudit";
import Userhome from "./components/auditUniverse/userHome/Userhome";
import Riskassessment from "./components/auditUniverse/riskAssesment/Riskassessment";
import RiskassessmentTable from "./components/auditUniverse/riskAssesment/RiskassesmentTable";
import CreateQuestionnaire from "./components/auditUniverse/riskAssesment/createQuestionnaire/CreateQuestionnaire";
import CreateRiskAssessment from "./components/auditUniverse/riskAssesment/createRiskAssessment/CreateRiskAssessment";
import AnswerQuestionnaire from "./components/auditUniverse/riskAssesment/answerQuestionnaire/AnswerQuestionnaire";
import AnswerQA from "./components/auditExecution/auditWrapUp/AnswerQA/AnswerQA";
import CalenderViewPage from "./components/auditUniverse/auditPlan/CalenderViewPage";
import AuditPlanTable from "./components/auditUniverse/auditPlan/AuditPlanTable";
import GraphViewRa from "./components/auditUniverse/riskAssesment/graphViewRa/GraphViewRa";
import AuditExecution from "./components/auditExecution/AuditExecution";
import RcmTemplate from "./components/auditExecution/RcmTemplate";
import QaCheckTemplate from "./components/auditExecution/QaCheckTemplate";
import QaSurveyTemplate from "./components/auditExecution/QaSurveyTemplate";
import Createaudit from "./components/auditExecution/createaudit/Createaudit";
import ViewAudits from "./components/auditExecution/createaudit/Viewaudit";
import Auditplan from "./components/auditExecution/auditplan/Auditplan";
import Schedulemeeting from "./components/auditExecution/auditplan/schedulemeeting/Schedulemeeting";
import Createauditmemo from "./components/auditExecution/auditplan/auditmemo/Createauditmemo";
import CreateInitialReqList from "./components/auditExecution/auditplan/initialreqlist/CreateInitialReqList";
import GenericTask from "./components/auditExecution/auditplan/GenericTask";
import RiskControlMatrix from "./components/auditExecution/auditplan/RiskControlMatrix";
import AuditFieldwork from "./components/auditExecution/auditfieldwork/AuditFieldwork";
import ViewAuditFieldwork from "./components/auditExecution/auditfieldwork/ViewAuditFieldwork";
import AuditReporting from "./components/auditExecution/auditReporting/AuditReporting";
import Issuetracking from "./components/issueTracking/Issuetracking";
import AuditIssue from "./components/issueTracking/AuditIssue";
import Issues from "./components/issueTracking/issuespage/Issues";
import CreateIssue from "./components/issueTracking/issuespage/CreateIssue";
import AuditWrapUp from "./components/auditExecution/auditWrapUp/AuditWrapUp";
import CreateObservation from "./components/auditExecution/auditReporting/createObservation/CreateObservation";
import CreateTask from "./components/auditExecution/auditplan/createtask/CreateTask";
import ViewTask from "./components/auditExecution/auditplan/viewTask/ViewTask";
import ListOfRcapPage from "./components/auditUniverse/rcm/ListOfRcapPage";
import ListOfAllRcapPage from "./components/auditUniverse/rcm/ListOfAllRcapPage";
import ViewObservation from "./components/auditExecution/auditReporting/createObservation/viewObservation";
import Initial from "./components/auditExecution/auditReporting/Observations/Initial";
import QAInitaiApproval from "./components/auditExecution/auditReporting/Observations/QAInitaiApproval";
import BusinessInitial from "./components/auditExecution/auditReporting/Observations/BusinessInitial";
import CreateRCAP from "./components/auditUniverse/rcm/createRcm/CreateRCAP";
import RcmTask from "./components/auditUniverse/rcm/RcmTask";
import ViewRcmTask from "./components/auditUniverse/rcm/RcmTask";
import ViewRCAP from "./components/auditUniverse/rcm/createRcm/ViewRCAP";
import PDFPage from "./components/auditExecution/pdfpage/PDFPage";
import CreateWorkpaper from "./components/auditExecution/auditfieldwork/CreateAuditFieldwork";
import DepartmentRCMPage from "./components/auditUniverse/rcm/departmentViewPage/DepartmentRCMPage";
import ReportingTasksPage from "./components/auditExecution/auditReporting/ReportingTasksPage/ReportingTasksPage";
import GenerateReportList from "./components/auditExecution/auditReporting/GenerateReportList";
import GenerateReport from "./components/auditExecution/auditReporting/Observations/GenerateReport";
import QAChecklist from "./components/auditExecution/auditWrapUp/QAChecklistFolder/QAChecklist";
import FileManager from "./components/auditExecution/fillemanager/FileManager";
import Issuedetailpage from "./components/issueTracking/issuedetailpage/Issuedetailpage";
import AuditReport from "./components/auditExecution/auditReporting/AuditReport";
import ScheduleMeetingSendInitial from "./components/auditExecution/auditReporting/ScheduleMeetingSendInitial";
import GenerateReportMoreDetails from "./components/auditExecution/auditReporting/GenerateReportMoreDetails";
import PublishReport from "./components/auditExecution/auditReporting/PublishReport";
// import CreateQasurvey from "./components/auditExecution/auditWrapUp/QASurveyFolder/CreateQasurvey";
import AnswerQASurvey from "./components/auditExecution/auditWrapUp/AnswerQA/AnswerQA";
import FinalizeDraft from "./components/auditExecution/auditReporting/Observations/FinalizeDraft";
import QAFinalApproval from "./components/auditExecution/auditReporting/Observations/QAFinalApproval";
import AuditDirectorApproval from "./components/auditExecution/auditReporting/Observations/AuditDirectorApproval";
import AuditAnalytics from "./components/issueTracking/AuditAnalytics/AuditAnalytics";
import DetailRcapPwa from "./components/auditUniverse/rcm/DetailRcapPWA/DetailRcapPwa";
import UserSetting from "./components/user/UserSetting";
import ErrorPage from "./ErrorPage";

const AuditTeam = ({tableSize}) => {
  return (
    <>
     <Route path="*" element={<ErrorPage errorCode={404} />} />
     <Route path="/server-error" element={<ErrorPage errorCode={500} />} />
    <Route
      path="/Usersettings"
      element={<UserSetting tableSize={tableSize} />}
    />

    <Route path="/" element={<Userhome tableSize={tableSize} />} />
    <Route
      path="/audit-universe"
      element={
        <Navigate replace to="/audit-universe/corporate-structure" />
      }
    />
    <Route
      path="/audit-universe/corporate-structure"
      element={<CorporateStructure tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/audit-entities"
      element={<Auditentity tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/audit-entities/create"
      element={<Createentity tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/audit-entities/:id"
      element={<Viewaudit tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/audit-entities/:id/riskcontrolmatrix"
      element={<ListOfRcapPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/audit-entities/:id/riskcontrolmatrix/:rid"
      element={<ViewRCAP tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/rcm/:departmentId/:rid/rcapdetailpwa"
      element={<DetailRcapPwa tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/audit-entities/:id/riskcontrolmatrix/creatercap"
      element={<CreateRCAP tableSize={tableSize} />}
    />
      <Route
      path="/audit-universe/audit-entities/:id/:quid"
      element={<CreateQuestionnaire tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/risk-assessments"
      element={<RiskassessmentTable tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/risk-assessments/:raid"
      element={<Riskassessment tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/risk-assessments/:raid/graphview"
      element={<GraphViewRa tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/risk-assessments/create"
      element={<CreateRiskAssessment tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/risk-assessments/create-questionnaire"
      element={<CreateQuestionnaire tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/risk-assessments/:raid/answer-questionnaire"
      element={<AnswerQuestionnaire tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/risk-assessments/answer-questionnaire"
      element={<AnswerQuestionnaire tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/risk-assessments/answer-questionnairepwa"
      element={<AnswerQuestionnaire tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/audit-planning/:auid"
      element={<CalenderViewPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/audit-planning"
      element={<AuditPlanTable tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/rcm/allrcm/"
      element={<ListOfAllRcapPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/rcm"
      element={<DepartmentRCMPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-universe/rcm/:departmentId"
      element={<ListOfAllRcapPage tableSize={tableSize} />}
    />

    <Route
      path="/audit-execution"
      element={<Navigate replace to="/audit-execution/Audits" />}
    />
    <Route
      path="/audit-execution/"
      element={<AuditExecution tableSize={tableSize} />}
    />

    <Route
      path="/templates/"
      element={<Navigate replace to="/templates/rcmtemp" />}
    />

    <Route
      path="/templates/rcmtemp"
      element={<RcmTemplate tableSize={tableSize} />}
    />
    <Route
      path="/templates/qachecklist"
      element={<QaCheckTemplate tableSize={tableSize} />}
    />
    <Route
      path="/templates/qaSurvey"
      element={<QaSurveyTemplate tableSize={tableSize} />}
    />

    <Route
      path="/audit-execution/create"
      element={<Createaudit tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/"
      element={<ViewAudits tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/detail"
      element={<ViewAudits tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/file-manager"
      element={<FileManager tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning"
      element={<Auditplan tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/Createtask"
      element={<CreateTask tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/RcmTask"
      element={<RcmTask tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/:planid"
      element={<ViewTask tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/:planid/riskcontrolmatrix/"
      element={<ListOfAllRcapPage tableSize={tableSize} />}
    />
     <Route
      path="/audit-execution/:id/planning/:planid/rcm/riskcontrolmatrix/"
      element={<ListOfAllRcapPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/:planid/riskcontrolmatrix/:rid/"
      element={<ViewRCAP tableSize={tableSize} />}
    />
    {/* <Route path="/audit-execution/planning/RiskControlMatrix/RcapDetailPWA" element={<DetailRcapPwa tableSize={tableSize} />} /> */}
    <Route
      path="/audit-execution/:id/planning/:planid/riskcontrolmatrix/creatercap"
      element={<CreateRCAP tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/generic-task"
      element={<GenericTask tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/planning/riskcontrolmatrix"
      element={<RiskControlMatrix tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/ScheduleMeeting"
      element={<Schedulemeeting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/Createauditmemo"
      element={<Createauditmemo tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/pdfpage"
      element={<PDFPage tableSize={tableSize} />}
    />
     <Route
      path="/user-settings"
      element={<UserSetting tableSize={tableSize} />}
    />
     <Route
      path="/user-settings/:id/forgot-password"
      element={<ForgotPassword tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/CreateInitialReqList"
      element={<CreateInitialReqList tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/fieldwork"
      element={<AuditFieldwork tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/fieldwork/create-workpaper/"
      element={<CreateWorkpaper tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/fieldwork/:workId/"
      element={<ViewAuditFieldwork tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/reporting/reportingTask"
      element={<ReportingTasksPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/:reportId/"
      element={<AuditReporting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/createobservation"
      element={<CreateObservation tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/wrapup-qa"
      element={<AuditWrapUp tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/wrapup-qa/qachecklist"
      element={<QAChecklist tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/wrapup-qa/qasurvey"
      element={<AnswerQA tableSize={tableSize} />}
    />
    {/* <Route path="/audit-execution/:id/WrapupQA/Qasurvey/CreateQasurvey" element={<CreateQasurvey tableSize={tableSize} />} /> */}
    <Route
      path="/audit-execution/planning/riskcontrolmatrix"
      element={<RiskControlMatrix tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/ScheduleMeeting"
      element={<Schedulemeeting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/Createauditmemo"
      element={<Createauditmemo tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/pdfpage"
      element={<PDFPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/planning/CreateInitialReqList"
      element={<CreateInitialReqList tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/Fieldwork"
      element={<AuditFieldwork tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/Fieldwork/CreateWorkpaper/"
      element={<CreateWorkpaper tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/Fieldwork/:workId/"
      element={<ViewAuditFieldwork tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/reporting/reportingTask"
      element={<ReportingTasksPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/"
      element={<ReportingTasksPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/initialobservation"
      element={<AuditReporting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/qaapprovalinitialobservation"
      element={<AuditReporting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/scheduleclosingmeeting/"
      element={<ScheduleMeetingSendInitial tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/businessownerapproval"
      element={<AuditReporting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/finalizeobservation"
      element={<AuditReporting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/generatereportlist"
      element={<GenerateReportList tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/generatereportmoredetails"
      element={<GenerateReportMoreDetails tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/finalizeobservation"
      element={<AuditReporting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/auditdirectorapproval"
      element={<AuditReporting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/publishreport"
      element={<PublishReport tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/"
      element={<ReportingTasksPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingObservationCreation/:obejctionId/"
      element={<ViewObservation tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/initialobservation/:obejctionId/"
      element={<Initial tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/qaapprovalinitialobservation/:obejctionId/"
      element={<QAInitaiApproval tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/scheduleclosingmeeting/:obejctionId/"
      element={<ViewObservation tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/businessownerapproval/:obejctionId/"
      element={<BusinessInitial tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/finalizeobservation/:obejctionId/"
      element={<FinalizeDraft tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/generatereportlist/:obejctionId/"
      element={<GenerateReport tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/generatereportmoredetails/:obejctionId/"
      element={<ViewObservation tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/qaapprovalfinalizeobservation/:obejctionId/"
      element={<QAFinalApproval tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/auditdirectorapproval/:obejctionId/"
      element={<AuditDirectorApproval tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/publishreport/:obejctionId/"
      element={<ViewObservation tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingObservationUpdate/"
      element={<ReportingTasksPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingReportCreation/"
      element={<ReportingTasksPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingReportCreation/"
      element={<ReportingTasksPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingObservationCreation/"
      element={<ReportingTasksPage tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingObservationCreation/:reportId/"
      element={<AuditReporting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingObservationUpdate/:reportId/"
      element={<AuditReporting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingReportCreation/:reportId/"
      element={<AuditReporting tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/Report"
      element={<AuditReport tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/wrapup-qa"
      element={<AuditWrapUp tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingObservationCreation/:reportId/CreateObservation/"
      element={<CreateObservation tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingObservationUpdate/:reportId/:obejctionId/"
      element={<ViewObservation tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingReportCreation/:reportId/:obejctionId/"
      element={<ViewObservation tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/reportingObservationCreation/:reportId/:obejctionId/"
      element={<ViewObservation tableSize={tableSize} />}
    />
    <Route
      path="/audit-execution/:id/reporting/Observation/:obejctionId"
      element={<ViewObservation tableSize={tableSize} />}
    />

<Route
      path="/issue-tracking/"
      element={<Navigate replace to="/issue-tracking/analytics" />}
    />
    <Route
      path="/issue-tracking/analytics"
      element={<Issuetracking tableSize={tableSize} />}
    />
    <Route
      path="/issue-tracking/analytics/audit-issue"
      
      element={ <Navigate  replace to ="/issue-tracking/analytics" tableSize={tableSize} />}
    />
    <Route
      path="/issue-tracking/analytics/audit-issue/:auid"
      element={<AuditIssue tableSize={tableSize} />}
    />
    <Route
      path="/issue-tracking/issues"
      element={<Issues tableSize={tableSize} />}
    />
    <Route
      path="/issue-tracking/issues/create"
      element={<CreateIssue tableSize={tableSize} />}
    />
    <Route
      path="/issue-tracking/issues/:issueid"
      element={<Issuedetailpage tableSize={tableSize} />}
    />
    <Route
      path="/issue-tracking/analytics/audit-issue/:auid/:issueid"
      element={<Issuedetailpage tableSize={tableSize} />}
    />
    <Route
      path="/issue-tracking/analytics/:auid"
      element={<AuditAnalytics tableSize={tableSize} />}
    />

    <Route path="*" element={<> not found </>} />
  </>
  )
}

export default AuditTeam
