import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import LoginForm from "./components/LoginForm";
import Top from "./components/Top";
import SideBar from "./components/SideBar";
import Users from "./components/user/Users";
import CreateUser from "./components/user/CreateUser";
import Viewuser from "./components/user/ViewUser";
import ForgotPassword from "./components/user/ForgotPassword";
import Department from "./components/department/Departments";
import Createdepartment from "./components/department/CreateDepartment";
import Viewdepartment from "./components/department/ViewDepartment";
import Businessunit from "./components/businessunit/Businessunit";
import Createbu from "./components/businessunit/CreateBu";
import Viewbusinessunit from "./components/businessunit/ViewBusinessunit";
import Teams from "./components/Teams/Teams";
import ViewTeams from "./components/Teams/ViewTeams";
import CorporateStructure from "./components/corporateStructure/CorporateStructure";
import Auditentity from "./components/auditUniverse/auditEntities/AuditEntity";
import Createentity from "./components/auditUniverse/auditEntities/CreateEntity";
import Viewaudit from "./components/auditUniverse/auditEntities/ViewAudit";
import Userhome from "./components/auditUniverse/userHome/Userhome";
import Riskassessment from "../src/components/auditUniverse/riskAssesment/Riskassessment";
import RiskassessmentTable from "../src/components/auditUniverse/riskAssesment/RiskassesmentTable";
import CreateQuestionnaire from "./components/auditUniverse/riskAssesment/createQuestionnaire/CreateQuestionnaire";
import CreateRiskAssessment from "./components/auditUniverse/riskAssesment/createRiskAssessment/CreateRiskAssessment";
import AnswerQuestionnaire from "./components/auditUniverse/riskAssesment/answerQuestionnaire/AnswerQuestionnaire";
import AnswerQA from "./components/auditExecution/auditWrapUp/AnswerQA/AnswerQA";
import CalenderViewPage from "./components/auditUniverse/auditPlan/CalenderViewPage";
import AuditPlanTable from "./components/auditUniverse/auditPlan/AuditPlanTable";
import GraphViewRa from "./components/auditUniverse/riskAssesment/graphViewRa/GraphViewRa";
import AuditExecution from "./components/auditExecution/AuditExecution";
import RcmTemplate from "./components/auditExecution/RcmTemplate";
import QaCheckTemplate from "./components/auditExecution/QaCheckTemplate";
import QaSurveyTemplate from "./components/auditExecution/QaSurveyTemplate";
import Createaudit from "./components/auditExecution/createaudit/Createaudit";
import ViewAudits from "./components/auditExecution/createaudit/Viewaudit";
import Auditplan from "./components/auditExecution/auditplan/Auditplan";
import Schedulemeeting from "./components/auditExecution/auditplan/schedulemeeting/Schedulemeeting";
import Createauditmemo from "./components/auditExecution/auditplan/auditmemo/Createauditmemo";
import CreateInitialReqList from "./components/auditExecution/auditplan/initialreqlist/CreateInitialReqList";
import GenericTask from "./components/auditExecution/auditplan/GenericTask";
import RiskControlMatrix from "./components/auditExecution/auditplan/RiskControlMatrix";
import AuditFieldwork from "./components/auditExecution/auditfieldwork/AuditFieldwork";
import ViewAuditFieldwork from "./components/auditExecution/auditfieldwork/ViewAuditFieldwork";
import AuditReporting from "./components/auditExecution/auditReporting/AuditReporting";
import Issuetracking from "./components/issueTracking/Issuetracking";
import AuditIssue from "./components/issueTracking/AuditIssue";
import Issues from "./components/issueTracking/issuespage/Issues";
import CreateIssue from "./components/issueTracking/issuespage/CreateIssue";
import AuditWrapUp from "./components/auditExecution/auditWrapUp/AuditWrapUp";
import CreateObservation from "./components/auditExecution/auditReporting/createObservation/CreateObservation";
import CreateTask from "./components/auditExecution/auditplan/createtask/CreateTask";
import ViewTask from "./components/auditExecution/auditplan/viewTask/ViewTask";
import ListOfRcapPage from "./components/auditUniverse/rcm/ListOfRcapPage";
import ListOfAllRcapPage from "./components/auditUniverse/rcm/ListOfAllRcapPage";
import ViewObservation from "./components/auditExecution/auditReporting/createObservation/viewObservation";
import Initial from "./components/auditExecution/auditReporting/Observations/Initial";
import QAInitaiApproval from "./components/auditExecution/auditReporting/Observations/QAInitaiApproval";
import BusinessInitial from "./components/auditExecution/auditReporting/Observations/BusinessInitial";
import CreateRCAP from "./components/auditUniverse/rcm/createRcm/CreateRCAP";
import RcmTask from "./components/auditUniverse/rcm/RcmTask";
import ViewRcmTask from "./components/auditUniverse/rcm/RcmTask";
import ViewRCAP from "./components/auditUniverse/rcm/createRcm/ViewRCAP";
import PDFPage from "./components/auditExecution/pdfpage/PDFPage";
import CreateWorkpaper from "./components/auditExecution/auditfieldwork/CreateAuditFieldwork";
import DepartmentRCMPage from "./components/auditUniverse/rcm/departmentViewPage/DepartmentRCMPage";
import ReportingTasksPage from "./components/auditExecution/auditReporting/ReportingTasksPage/ReportingTasksPage";
import GenerateReportList from "./components/auditExecution/auditReporting/GenerateReportList";
import GenerateReport from "./components/auditExecution/auditReporting/Observations/GenerateReport";
import QAChecklist from "./components/auditExecution/auditWrapUp/QAChecklistFolder/QAChecklist";
import FileManager from "./components/auditExecution/fillemanager/FileManager";
import Issuedetailpage from "./components/issueTracking/issuedetailpage/Issuedetailpage";
import AuditReport from "./components/auditExecution/auditReporting/AuditReport";
import ScheduleMeetingSendInitial from "./components/auditExecution/auditReporting/ScheduleMeetingSendInitial";
import GenerateReportMoreDetails from "./components/auditExecution/auditReporting/GenerateReportMoreDetails";
import PublishReport from "./components/auditExecution/auditReporting/PublishReport";
// import CreateQasurvey from "./components/auditExecution/auditWrapUp/QASurveyFolder/CreateQasurvey";
import AnswerQASurvey from "./components/auditExecution/auditWrapUp/AnswerQA/AnswerQA";
import FinalizeDraft from "./components/auditExecution/auditReporting/Observations/FinalizeDraft";
import QAFinalApproval from "./components/auditExecution/auditReporting/Observations/QAFinalApproval";
import AuditDirectorApproval from "./components/auditExecution/auditReporting/Observations/AuditDirectorApproval";
import AuditAnalytics from "./components/issueTracking/AuditAnalytics/AuditAnalytics";
import DetailRcapPwa from "./components/auditUniverse/rcm/DetailRcapPWA/DetailRcapPwa";
import UserSetting from "./components/user/UserSetting";
import AuditAdminRoutes from "./AuditTeamAdminRoutes";
import AuditTeam from "./AuditTeamRoutes";
import BusinnessRoutes from "./BusinnessRoutes";
import QaRoutes from "./QaRoutes";
import AuditCommisionRoutes from "./AuditCommisionRoutes";
import ErrorPage from "./ErrorPage";


function ProtectedRoute({ children }) {
  const isLoggedIn = !!localStorage.getItem("token");

  return isLoggedIn ? children : <Navigate replace to="/login" />;
}
export default function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [tableSize, setTableSize] = useState(true);
  const handleChange = () => {
    setTableSize(!tableSize);
  };

  useEffect(() => {
    const storedAuthState = localStorage.getItem("isLoggedIn");
    if (storedAuthState) {
      setLoggedIn(JSON.parse(storedAuthState));
    }
  }, []);

  var userRoles = "";
  var is_Admin;
  const token = localStorage.getItem("token");
  if (token) {
    const decoded = jwtDecode(token);
    console.log("decoded : ", decoded);
    const userName = decoded.first_name ? decoded.first_name : "";
    const userLastName = decoded.first_name ? decoded.last_name : "";
    const userId = decoded.first_name ? decoded.user_id : "";
    userRoles = decoded.roles ? decoded.roles : "";

    console.log("userRoles12 : " + JSON.stringify(userRoles));

    const is_Admin = Object.keys(userRoles).some(
      (key) => key.includes("Audit Team") && userRoles[key] === "Admin"
    );

    localStorage.setItem("userName", userName ? userName : "");
    localStorage.setItem("user_id", userId ? userId : "");
    localStorage.setItem("userLastName", userLastName ? userLastName : "");
    localStorage.setItem(
      "userRoles",
      JSON.stringify(userRoles ? userRoles : {})
    );
    localStorage.setItem("is_Admin", is_Admin ? is_Admin : "");
  }
  const handleLogin = () => {
    setLoggedIn(true);
    localStorage.setItem("isLoggedIn", JSON.stringify(true));
  };

  const renderRoutes = () => {
    if (
      Object.keys(userRoles).some(
        (key) => key.includes("Audit Team") && userRoles[key] === "Admin"
      )
    ) {

      return (
        <Route>
          {AuditAdminRoutes(tableSize)}
        </Route>
      );
    }
    if (Object.keys(userRoles).some((key) => key.includes("Audit Team"))) {
      console.log("Rendering routes for Audit Team");

      return (
        <Route>
          {AuditTeam(tableSize)}
        </Route>
      );
    }
    if (Object.keys(userRoles).some((key) => key.includes("Business"))) {
      return (
        <Route>
          {BusinnessRoutes(tableSize)}
        </Route>
      );
    }

    if (
      Object.keys(userRoles).some((key) => key.includes("Quality Assurance"))
    ) {
      return (
        <Route>
          {QaRoutes(tableSize)}
        </Route>
      );
    }

    if (Object.keys(userRoles).some((key) => key.includes("Audit Commsion"))) {
      return (
        <Route>
          {AuditCommisionRoutes(tableSize)}
        </Route>
      );
    }
  };

  return (
    <BrowserRouter>
      <Routes>

        {/* Public Routes */}
        {!isLoggedIn && (
          <>
            <Route
              path="/login"
              element={<LoginForm onLogin={handleLogin} />}
            />
            <Route
              path="*"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <SideBar handleChange={handleChange}>
                    <div style={{ display: "block" }}>
                      <Routes>{renderRoutes()}</Routes>
                    </div>
                  </SideBar>
                </ProtectedRoute>
              }
            />
          </>
        )}

        {/* Protected Routes */}
        {isLoggedIn && (
          <Route
            path="*"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <SideBar handleChange={handleChange}>
                  <div style={{ display: "block" }}>
                    <Routes>{renderRoutes()}
                    <Route path="*" element={<ErrorPage errorCode={404} />} />
                    <Route path="/server-error" element={<ErrorPage errorCode={500} />} />

                    </Routes>
                   
                  </div>
                </SideBar>
              </ProtectedRoute>
            }
          />
        )}
      </Routes>
    </BrowserRouter>
  );
}
