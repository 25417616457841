import React, { useState, useEffect, useRef } from "react";
import { fetchDataV2 } from "./apiUtils";
import cut from "../images/cut.svg";
import "../css/department.css";

const SingleOptionSelectorRole = ({
  setIData,
  typeData,
  apiUrl,
  setSelectorId,
  setSelectorError,
  selectorError,
  Name,
  title,
  initialId,
  initialName,
  editMode,
  selectedOption,
  tabIndex=0
}) => {

  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (apiUrl && selectedOption) {
      const parameter ={
        url :apiUrl, 
        setterFunction :setData,
        setErrors : setSelectorError
       }
      fetchDataV2(parameter);
    }
    if (typeData) {
      setData(typeData);
    }
  }, [apiUrl, setSelectorError, typeData, selectedOption]);

  useEffect(() => {
    if (initialId && initialName) {
      setSelected([{ id: initialId, Name: initialName }]);
      setSelectedId([initialId]);
      setSelectorId(initialId);
      setSelectorError(false);
    }
  }, [initialId, initialName, setSelectorError, setSelectorId]);

  useEffect(() => {
    if (expanded) {
      inputRef.current.focus();
    }
  }, [expanded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChangeDepts = (event, departmentId, departmentName, unit) => {
    const selectedUnitId = departmentId;
    const selectedUnitName = departmentName;

    if (event.target.checked && selectedUnitId) {
      setSelected([{ id: selectedUnitId, Name: selectedUnitName }]);
      setSelectedId([selectedUnitId]);
      setSelectorId(selectedUnitId);
      setSelectorError(false);
    } else {
      setSelectedId([]);
      setSelected([]);
      setSelectorId(null);
      setSelectorError(true);
    }

    if (typeof setIData === "function") {
      setIData(unit);
    }
  };

  const handleDivClick = (event, departmentId, departmentName, unit) => {
    if (event.target &&event.target.type !== "checkbox") {
      const isChecked = selectedId.includes(departmentId);
      handleCheckboxChangeDepts(
        { target: { checked: !isChecked } },
        departmentId,
        departmentName,
        unit
      );
    }
  };

  const handleSearchChangeDepts = (event) => {
    setSearchTerm(event.target.value);
    setExpanded(true);
  };

  const toggleExpandDept = () => {
    setExpanded(!expanded);
  };

  const handleRemoveSelectedDepts = (unitToRemove) => {
    setSelected((prev) => prev.filter((dept) => dept.id !== unitToRemove.id));
    setSelectedId([]);
    setSelectorId(null);
    setSelectorError(false);
  };

  // Filter data based on search term and selected option
  const filteredData = data
    .filter((unit) =>
      unit.groups.some((group) =>
        group.name.toLowerCase().includes(selectedOption?.toLowerCase())
      )
    )
    .filter((unit) =>
      `${unit.first_name} ${unit.last_name}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

  return (
    <div className="lbl_selector">
      <label>{title}</label>

      <div
        ref={dropdownRef}
        className="ref_div"
        style={{
          border: selectorError ? "1px solid #FF4646" : "",
          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
        }}
        tabIndex={tabIndex}
        onKeyDown={(e) => {
          if (e.key === "Enter" && editMode) {
            toggleExpandDept();
          } else if (e.key === "Tab") {
            setExpanded(false);
          }
        }}
      >
        <div
          className="sub_ref_div"
          onClick={() => {
            if (editMode) {
              toggleExpandDept();
            }
          }}
        >
          {selected.length === 0 ? (
            <p className="slct_plchldr">{title}</p>
          ) : (
            selected.map((dept) => (
              <span
                key={dept.id}
                className="slct_tag"
                style={{
                  backgroundColor: editMode ? "#5771DF" : "#5f5f5f",
                }}
              >
                <span>{dept.Name}</span>

                <img
                  src={cut}
                  alt="Dashboard Icon"
                  onClick={() => {
                    if (editMode) {
                      handleRemoveSelectedDepts(dept);
                    }
                  }}
                />
              </span>
            ))
          )}
        </div>

        <div
          className="opt_drp_dwn"
          style={{
            visibility: expanded ? "visible" : "hidden",
            height: expanded ? "auto" : "0vh",
          }}
        >
          <input
            ref={inputRef}
            type="text"
            placeholder={`Search ${title}`}
            value={searchTerm}
            onChange={handleSearchChangeDepts}
          />

          <div>
            {filteredData.length === 0 ? (
              <div className="noDataFound">No data found</div>
            ) : (
              filteredData.filter((unit) =>
                unit[Name]
                  ? unit[Name].toLowerCase().includes(searchTerm.toLowerCase())
                  : true
              ).map((unit) => (
                <div
                  key={unit.id}
                  className="opt_drp_dwn_optns"
                  onClick={(event) =>
                    handleDivClick(
                      event,
                      unit.id,
                      `${unit.first_name} ${unit.last_name}`,
                      unit
                    )
                  }
                >
                  <label> {`${unit.first_name} ${unit.last_name}`}</label>

                  <input
                    type="checkbox"
                    id={unit.id}
                    value={unit.id}
                    checked={selectedId.includes(unit.id)}
                    onChange={(event) =>
                      handleCheckboxChangeDepts(
                        event,
                        unit.id,
                        `${unit.first_name} ${unit.last_name}`,
                        unit
                      )
                    }
                  />
                </div>
              ))
            )}
          </div>

          <div className="closebutton" onClick={toggleExpandDept}>
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleOptionSelectorRole;
