import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect,useRef } from "react";
import "../../css/createbu.css";
import { fetchDataV2, postDataV2 } from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import SingleOptionSelectorRole from "../SingleOptionSelectorRole";
import RenderIcons from "../RenderIcons";
import { homeRoutes } from "../subroutes";
import CommonInput from "../CommonInput";
import NavRow from "../NavRow";
import BusinessSingleOptionSelector from "../BusinessSingleOptionSelector";
const CreateBu = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [parentDept, setParentDept] = useState("");
  const location = useLocation();
  const [name, setname] = useState("");
  const [nameError, setnameError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const navigate = useNavigate();
  const [toggleunit, setToggleunit] = useState("");
  const [toggledept, setToggledept] = useState("");
  const [toggleunitname, setToggleunitname] = useState("");
  const [department_id, setDepartment] = useState("");
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [parentToggle, setParentToggle] = useState(true);
  const [departmentIdError, setDepartmentIdError] = useState("");
  const [searchTermDept, setSearchTermDept] = useState("");
  const [selectorError, setSelectorError] = useState(false);
  const [unit_head_id, setSelectorId] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [parent_bu_id, setParentBu] = useState("");
  const [parent_bu_idError, setParentBuError] = useState("");
  const [activeToggle, setActiveToggle] = useState("");
  const [selectedOption, setSelectedOption] = useState("Business");
  const [APIDataBU, setAPIDataBU] = useState([]);
  const [initialDepratment, setInitialDepartment] = useState();
  const [maketrue, setTrue] = useState(false);

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  const postApi = (e) => {
    e.preventDefault();

    if (!name || !description || !department_id) {
      setErrorMessage(true);
      setErrors("Please fill in all required fields.");
      setnameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      setDepartmentIdError(!department_id ? true : false);
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const buunithead = parseInt(unit_head_id, 10);

    const papameter = {
      url: `/api/system/business-units/`,
      payload: {
        name,
        description,
        department_id,
        parent_bu_id: parseInt(parent_bu_id, 10),
        unit_head_id: buunithead,
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/administration/business-unit`,
    };

    postDataV2(papameter);
  };

  const toggleDepartment = (e) => {
    setParentToggle(true);
    setParentDept("");
    setToggleunitname("Department");
    setToggledept(!toggledept);
    setToggleunit(false);
    setSelectedDepts([]);
    setSearchTermDept("");
    setActiveToggle("Department");
  };

  useEffect(() => {
    toggleDepartment();
  }, []);

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  function getDepartmentById(id) {
    const result = APIDataBU?.find((item) => item.id === id);
    if (result) {
      return {
        departmentId: result.department.id,
        departmentName: result.department.name,
      };
    } else {
      return `No data found for ID ${id}`;
    }
  }
  useEffect(() => {
    const parameter={
      url:`/api/system/business-units/`,
      setterFunction: setAPIDataBU,
      setErrors:setErrors,
    }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    setInitialDepartment(getDepartmentById(parent_bu_id));
  }, [parent_bu_id]);

  useEffect(() => {
    if (department_id && maketrue) {
      setParentBu("");
    }
  }, [department_id]);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={homeRoutes} />
        <NavRow name={"Create Business Unit"} idtrue={true} />

        <div
          className="createbucontainer"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <form>
            <CommonInput
              labelName="Business Unit Name"
              error={nameError}
              type="text"
              placeHolder="Business Unit Name"
              setText={setname}
              setTextError={setnameError}
              commanDivClass="lbl_inpt"
              tabIndex={1}
            />

            <SingleOptionSelectorRole
              apiUrl="/api/system/users/"
              setSelectorId={setSelectorId}
              setSelectorError={setSelectorError}
              selectorError={selectorError}
              Name="first_name"
              title="Business Unit Head"
              editMode={editMode}
              selectedOption={selectedOption}
              tabIndex={2}
            />

            <div className="lbl_slct">
              <label htmlFor="dropdown">Business Unit Head Role</label>
              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
                tabIndex={3}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="Business">Business</option>
                <option value="Audit Team">Audit</option>
              </select>
            </div>

            <SingleOptionSelector
              apiUrl="/api/system/departments/"
              setSelectorId={setDepartment}
              setSelectorError={setDepartmentIdError}
              selectorError={departmentIdError}
              Name="name"
              title="Parent Department"
              editMode={editMode}
              initialId={initialDepratment?.departmentId}
              initialName={initialDepratment?.departmentName}
              setTrue={setTrue}
              maketrue={maketrue}
              tabIndex={4}
            />

            <BusinessSingleOptionSelector
              apiUrl="/api/system/business-units/"
              setSelectorId={setParentBu}
              setSelectorError={setParentBuError}
              selectorError={parent_bu_idError}
              Name="name"
              title="Parent Business Unit"
              editMode={editMode}
              makeTrue={maketrue}
              setTrue={setTrue}
              tabIndex={5}
            />

            <div className="lbl_txa">
              <label htmlFor="departmentId">Description</label>
              <textarea
                id="departmentId"
                value={description}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
                className={descriptionError ? "input-error" : ""}
                tabIndex={6}
              />
            </div>
          </form>

          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={postApi}
              tabIndex={7}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create Business Unit
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateBu;
