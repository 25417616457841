import React from "react";
import MultiSelect from "./MultiSelect";
import MultiSelectView from "./MultiSelectView";

import { useState, useEffect, useRef } from "react";
import { auditExecution } from "../../subroutes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  fetchDataV2,
  fetchDataObjectV2,
  updateDataV2,
  deleteDataV2,
  justPostDataV2,
  justUpdateDataV2,
  postDataWithFetchV2,
  updatewithFetchDataV2,
  deleteDataWithFetchV2,
  convertDate,
  updateDataV3,
  convertDateTime,
} from "../../apiUtils";
import IconButton from "../../iconbutton/IconButton";
import SingleOptionSelector from "../../SingleOptionSelector";
import WindowComponent from "../../WindowComponent";
import RenderIcons from "../../RenderIcons";
import EditRow from "../../../images/edit_pencil.svg";
import closeBtn from "../../../images/closeicon.svg";
import cutbutton from "../../../images/cutbutton.svg";
import create_plus from "../../../images/createbutton.svg";
import SaveRow from "../../../images/saveBtn.svg";
import deleteBtn from "../../../images/deleteBtn.svg";
import sendComment from "../../../images/send.svg";
import SuperAvatar from "../../SuperAvatar";
import SaveBtn from "../../../images/saveBtn.svg";
import BounceLoader from "react-spinners/BounceLoader";
import NavRow from "../../NavRow";
import SingleOptionSelectorAuditBU from "../../SingleOptionSelectorAuditBU";
import SingleOptionSelectorAuditPR from "../../SingleOptionSelectorAuditPR";
const avaUserName = localStorage.getItem("userName");
const lastName = localStorage.getItem("userLastName");

const ViewAuditFieldwork = ({ tableSize }) => {
  const [options, setOptions] = useState([]);
  const [successMessageQuestion, setSuccessMessage] = useState(false);
  const [successMessageFile, setSuccessMessageFile] = useState(false);
  const [successMessageQuestionUpdate, setSuccessMessageQuesUpdate] = useState(false);
  const [successMessageQuestionDelete, setSuccessMessageDelete] = useState(false);
  const [successMessageTask, setSuccessMessageTask] = useState(false);
  const [successcommentMessage,setSuccessCommentMessage]=useState(false)
  const [errors, setErrors] = useState("");
  const [objective, setObjective] = useState("");
  const [title, setTitle] = useState("");
  const [APITestData, setAPITestData] = useState([]);
  const [APIEvidenceData, setAPIEvidenceData] = useState([]);
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [risk_id, setRiskId] = useState("");
  const [entityId, setEntityId] = useState("");

  const [risk_category, setRiskCategory] = useState("");
  const [risk_description, setDescribeRisk] = useState("");
  const [risk_title, setTitleRisk] = useState("");

  const [inherent_assessment_likelihood, setLikelikhood] = useState("");
  const [inherent_assessment_impact, setImpact] = useState("");
  const [inherent_risk_evaluation, setIRE] = useState("");
  const [inherent_risk_score, setIRS] = useState("");

  const [control_existing, setExistingControl] = useState("");
  const [control_desired, setDesiredControl] = useState("");
  const [control_evaluation, setEvaluation] = useState("");
  const [control_design, setControlDesign] = useState("");
  const [control_type, setcontrolType] = useState("");
  const [control_frequency, setcontrolFrequency] = useState("");
  const [control_gap, setControlGap] = useState("");
  const [residual_assessment_likelihood, setResLikelihood] = useState("");
  const [residual_assessment_impact, setResImpact] = useState("");
  const [residual_risk_score, setRRS] = useState("");
  const [residual_risk_evaluation, setRRE] = useState("");
  const [APIQData, setAPIQData] = useState("");
  const [taskData, setTaskData] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);

  const [test_case, setTestCase] = useState("");
  const [test_case1, setTestCase1] = useState("");

  const [sample_rationaleError, setSampleRationaleError] = useState(false);

  const [record_work_done, setRecordWorkDone] = useState("");

  const [sample_size, setSampleSize] = useState("");
  const [sample_size1, setSampleSize1] = useState("");

  const [sample_sizeError, setSampleSizeError] = useState(false);

  const [note, setNote] = useState("");
  const [note1, setNote1] = useState("");

  const [workpaper_referenceError, setWorkpaperReferenceError] =
    useState(false);

  const [preliminaryError, setPreliminaryError] = useState(false);

  const [testing_status, setTestingStatus] = useState("");

  const [step, setStep] = useState("");
  const [step1, setStep1] = useState("");
  const [step1error, setStep1Error] = useState(false);

  const [audit_procedure_data, setDataRequired] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTestCreateOpen, setIsTestCreateOpen] = useState(false);
  const [activitylog, setActivityLog] = useState([]);

  const [statusValues, setStatusValues] = useState(
    APITestData.map((data) => {
      return data.status || "";
    })
  );

  const [statusVal, setStatusVal] = useState("");

  const [editMode, setEditMode] = useState(false);

  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [assign_to_id, setAssign_to_id] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_idError, setAssign_to_idError] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [data, setData] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const { id, planid, tempId, workId } = useParams();

  const segments = location.pathname.split("/");
  const [isComments, setIsComments] = useState(true);

  const [APIaudit, setAudit] = useState([]);
  const [successMessageUpdate, setSuccessMessageUpdate] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/system/activity/?action_on=FieldWork&action_on_id=${workId}`,
      setterFunction: setActivityLog,
      setErrors: setErrors

    }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {

    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
      setLoading: setLoading
    }
    fetchDataObjectV2(parameter);
  }, [id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (errors) {
      const timer = setTimeout(() => {
        setErrors("");
      }, 5000); // Hides the error after 10 seconds (10000ms)

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when errors change
    }
  }, [errors]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/workpaper/${workId}/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
      setLoading: setLoading
    }
    fetchDataObjectV2(parameter);

  }, []);

  const [taskErrors, setTaskErrors] = useState("");

  useEffect(() => {
    if (APIData.task) {
      const parameter = {
        url: `/api/system/comments/?task_id=${APIData.task?.id}`,
        setterFunction: setTaskData,
        setErrors: setTaskErrors
      }
      fetchDataV2(parameter);
    }
  }, [APIData]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/workpapertestcase/?workpaper_id=${workId}`,
      setterFunction: setAPITestData,
      setErrors: setErrors
    }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/auditfile/?audit_id=${id}`,
      setterFunction: setAPIEvidenceData,
      setErrors: setErrors
    }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    if (APIEvidenceData.length > 0) {
      const formattedOptions = APIEvidenceData.map((item) => ({
        id: item.id, // assuming APIEvidenceData contains an 'id' field
        name: item.filename, // using 'title' as the 'name' for display
      }));
      setOptions(formattedOptions);
    }
  }, [APIEvidenceData]);

  const [titlePage, setTitlePage] = useState("");

  useEffect(() => {
    setObjective(APIData.objective);
    setTitle(APIData.title);
    setTitlePage(APIData?.objective);
    setAPIQData(APIData.task?.id);
    setRiskId(APIData.risk_id);
    setRiskCategory(APIData.risk_category);
    setDescribeRisk(APIData.risk_description);
    setTitleRisk(APIData.risk_title);
    setLikelikhood(APIData.inherent_assessment_likelihood);
    setImpact(APIData.inherent_assessment_impact);
    setIRE(APIData.risk_inherant_evaluation);
    setIRS(APIData.risk_inherant_evaluation_score);
    setExistingControl(APIData.control_existing);
    setDesiredControl(APIData.control_desired);
    setEvaluation(APIData.control_evaluation);
    setControlDesign(APIData.control_design);
    setcontrolType(APIData.control_type);
    setcontrolFrequency(APIData.control_frequency);
    setControlGap(APIData.control_gap);
    setResLikelihood(APIData.residual_assessment_likelihood);
    setResImpact(APIData.residual_assessment_impact);
    setRRS();
    setRRE();
    setTestCase();
    setRecordWorkDone();
    setSampleSize();
    setNote();
    setTestingStatus();
    setStep();
    setDataRequired();
  }, [APIData]);

  function impactmapping(value) {
    switch (value) {
      case 1:
        return "Low (1)";
      case 2:
        return "Minor (2)";
      case 3:
        return "Moderate (3)";
      case 4:
        return "Major (4)";
      case 5:
        return "Severe (5)";
      default:
        return "";
    }
  }
  function likemapping(value) {
    switch (value) {
      case 1:
        return "Remote (1)";
      case 2:
        return "Unlikely (2)";
      case 3:
        return "Possible (3)";
      case 4:
        return "Likely (4)";
      case 5:
        return "Almost Certain (5)";
      default:
        return "";
    }
  }

  const [urlNav, setUrlNav] = useState("");

  useEffect(() => {
    if (entityId) {
      setUrlNav(`/audit-execution/${id}/planning/${planid}/${tempId}/`);
    } else {
      setUrlNav(`/audit-universe/audit-entities/${id}/${tempId}/`);
    }
  }, [entityId]);

  const handleTestCreate = () => {
    setIsTestCreateOpen(true);
  };

  const handleTestClose = () => {
    setIsTestCreateOpen(false);
  };
  const handleTestDelete = (Id) => {

    const parameter = {
      url: `/api/audit_engagement/workpapertestcase/${Id}/`,
      navigate: navigate,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/workpapertestcase/?workpaper_id=${workId}`,
            dataset: setAPITestData,
          },
        ],
        setSuccessMessage: setSuccessMessageDelete,
        setErrors: setErrors,
      },
    };

    deleteDataWithFetchV2(parameter);

  };

  const onDelete = (e) => {
    e.preventDefault();
    const parameter = {
      url: `/api/audit_engagement/workpaper/${workId}`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/fieldwork`,
    };

    deleteDataV2(parameter);
  };

  const postTest = () => {
    if (statusVal === "Failed" && !note1) {
      setWorkpaperReferenceError(true);
      setErrors("Please Fill Preliminary Observation ");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (!step1) {
      setStep1Error(!step1 ? true : false);
      setErrors("Please Fill The Step");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const parameter = {
      url: `/api/audit_engagement/workpapertestcase/`,
      payload: {
        workpaper_id: workId,
        step: step1,
        test_case: test_case1,
        sample_size: sample_size1,
        note: note1,
        status: statusVal,
        data: {
          files: selectedOptions,
        },
      },
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/workpapertestcase/?workpaper_id=${workId}`,
            dataset: setAPITestData,
          },
        ],
        setSuccessMessage: setSuccessMessage,
        setErrors: setErrors,
      },
    };

    postDataWithFetchV2(parameter);
    setIsTestCreateOpen(false);
    setStep1("");
    setSampleSize1("");
    setNote1("");
    setStatusVal("");
  };

  const updateapi = (e) => {
    e.preventDefault();
    if (!preparer_id || !reviewer_id || !assign_to_id) {
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssign_to_idError(!assign_to_id ? true : false);
      setErrors("Please fill in all required fields.");

      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const parameter = {
      url: `/api/audit_engagement/workpaper/${workId}`,
      payload: {
        audit_id: id,
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        title,
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageUpdate,
        items: [{ fetchurl: `/api/audit_engagement/workpaper/${workId}/`, dataset: setAPIData }],
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/audit-execution/${id}/fieldwork/${workId}`,
    };
    updateDataV3(parameter);
    setEditMode(false)
  };
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [editingRow, setEditingRow] = useState(null);
  const [editValues, setEditValues] = useState({});

  const handleEditSave = () => {
    setEditMode(true);
  };

  const handleEditClick = (index, data) => {
    setEditingRow(index);
    setEditValues({
      step: data?.step || "",
      sample_size: data?.sample_size || "",
      note: data?.note || "",
      status: data?.pass,
      workpaper_id: workId,
    });
  };

  const handleInputChange = (e, field) => {
    setEditValues({
      ...editValues,
      [field]: e.target.value,
    });
  };
  const handleSaveClick = (testId) => {
    setEditValues({
      ...editValues,
      workpaper_id: workId,
    });

    const payload = editValues;

    const parameter = {
      url: `/api/audit_engagement/workpapertestcase/${testId}`,
      payload: {
        workpaper_id: payload.workpaper_id,
        step: payload.step,
        test_case: payload.test_case,
        sample_size: payload.sample_size,
        note: payload.note,
        status: payload.pass,
        data: {
          files: selectedOptions,
        },
      },
      setErrors:setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/workpapertestcase/?workpaper_id=${workId}`,
            dataset: setAPITestData,
          },
        ],
        setSuccessMessage:setSuccessMessageQuesUpdate,
        setErrors: setErrors,
      },
    };

    updatewithFetchDataV2(parameter);

    setEditValues("");
    setEditingRow("");
  };

  const handleSaveClick1 = (data) => {
    const parameter = {
      url: `/api/audit_engagement/workpapertestcase/${data.id}`,
      payload: {
        workpaper_id: data.workpaper_id,
        step: data.step,
        test_case: data.test_case,
        sample_size: data.sample_size,
        note: data.note,
        status: data.pass,
        data: {
          files: selectedOptions,
        },
      },
      setErrors:setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/workpapertestcase/?workpaper_id=${workId}`,
            dataset: setAPITestData,
          },
        ],
        setSuccessMessage:setSuccessMessageFile,
        setErrors: setErrors,
      },
    };

    updatewithFetchDataV2(parameter);

    setEditValues("");
    setEditingRow("");
  };

  const handleCancelClickField = () => {
    setEditMode(false);
    window.location.reload();
  };

  const handleCancelClick = () => {
    setEditingRow(null);
    setEditValues({});
  };

  // useEffect(() => {
  //   // Function to handle clicks outside the editing row
  //   const handleClickOutside = (event) => {
  //     if (editingRow !== null) {
  //       // Check if click is outside the editing row
  //       const rowElement = document.querySelector(
  //         `tr[data-index="${editingRow}"]`
  //       );
  //       if (rowElement && !rowElement.contains(event.target)) {
  //         handleCancelClick(); // Call your function to close edit
  //       }
  //     }
  //   };

  //   // Add event listener to the document
  //   document.addEventListener("mousedown", handleClickOutside);

  //   // Clean up the event listener when component unmounts or editing row changes
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [editingRow]); // Dependency array includes editingRowIndex

  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      const parameter = {
        url: `/api/system/comments/`,
        payload: {
          user_id: localStorage.getItem("user_id"),
          task_id: APIData.task?.id,
          text: inputValue,
        },
        setErrors: setErrors,
        tofetch: {
        items: [
          {
            fetchurl: `/api/system/comments/?task_id=${APIData.task?.id}`,
            dataset: setTaskData,
          },
        ],
        setSuccessMessage:setSuccessMessageFile,
        setErrors: setErrors,
      },
        
      };
      postDataWithFetchV2(parameter);
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };
  const handleInputChange1 = (event) => {
    setInputValue(event.target.value);
  };

  // Handler for dropdown change
  const handleChange = (index, event, testId, noteData,sampleSize,stepdetail) => {
    if (event.target.value === "Failed" && !noteData) {
      console.log("editValues : ", noteData);
      setErrors("Please Fill Preliminary Observation ");
      event.target.value = "";
      return false;
      // setPreliminaryError(true);
    }

    console.log("editValues : ", noteData);

    const newStatusValues = [...statusValues];
    newStatusValues[testId] = event.target.value;
    setStatusValues(newStatusValues);

    const parameter = {
      url: `/api/audit_engagement/workpapertestcase/${testId}`,
      payload: {
        workpaper_id: workId,
        note: noteData,
        step:stepdetail,
        sample_size:sampleSize,
        status: newStatusValues[testId],
      },
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/workpapertestcase/?workpaper_id=${workId}`,
            dataset: setAPITestData,
          },
        ],
        setSuccessMessage: setSuccessMessageQuesUpdate,
        setErrors: setErrors,
      },
    };

    updatewithFetchDataV2(parameter);

    setStatusValues("");
    setEditValues("");
    setEditingRow("");
  };


  const [isPopupVisible, setPopupVisible] = useState(false);

  const [openIndex, setOpenIndex] = useState(null);

  const handleDivClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    console.log(selectedOptions);
  }, [selectedOptions]);

  const handleSelectionChange = (newSelectedOptions) => {
    // Update state with the new selected options
    setSelectedOptions(newSelectedOptions);
  };

  const markAsDone = () => {
    if (!preparer_id || !reviewer_id || !assign_to_id) {
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssign_to_idError(!assign_to_id ? true : false);
      setErrors("Please fill in all required fields.");

      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (APIData.task?.id) {
      console.log("cllick");

      const Parameter = {
        url: `/api/audit_engagement/workpaper/${workId}`,
        payload: {
          audit_id: id,
          objective: objective ? objective : "no objective",
          preparer_id,
          reviewer_id,
          status: "Completed",
          assign_to_id,
          title: title ? title : risk_title,
        },
        setErrors: setErrors,
        tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/workpapertestcase/?workpaper_id=${workId}`,
            dataset: setAPITestData,
          },
          {
            fetchurl: `/api/audit_engagement/workpaper/${workId}/`,
            dataset: setAPIData,
          },
        ],
        setSuccessMessage:setSuccessMessageFile,
        setErrors: setErrors,
      },

      };
      updateDataV3(Parameter);

      const Parameter2 = {
        url: `/api/system/task/${APIData.task?.id}`,
        payload: {
          title: objective ? objective : "no objective",
          status: "done",
        },
        setErrors: setErrors,
        tofetch: {
          items: [
            {
              fetchurl: `/api/audit_engagement/workpaper/${workId}/`,
              dataset: setAPIData,
            },
          ],
          setSuccessMessage:setSuccessMessageFile,
          setErrors: setErrors,
        },
      };

      updateDataV3(Parameter2);
    }
  };

  const fieldStatus = {
    fieldstatus: APIData?.status,
  };

  useEffect(() => {
    if (editMode) {
      if (!preparer_id) {
        setPreparerError(!preparer_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null)
        }, 5000);
        return;
      }

      const parameter = {
        url: `/api/audit_engagement/workpaper/preparer/${workId}/`,
        payload: {
          preparer_id: preparer_id,
        },
        setErrors: setErrors,
      };

      justUpdateDataV2(parameter);
    }
  }, [preparer_id]);

  useEffect(() => {
    if (editMode) {
      if (!reviewer_id) {
        setReviewerError(!reviewer_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null)
        }, 5000);
        return;
      }

      const parameter = {
        url: `/api/audit_engagement/workpaper/reviewer/${workId}/`,
        payload: {
          reviewer_id: reviewer_id,
        },
        setErrors: setErrors,
      };

      justUpdateDataV2(parameter);
    }
  }, [reviewer_id]);

  useEffect(() => {
    if (editMode) {
      if (!assign_to_id) {
        setAssign_to_idError(!assign_to_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null)
        }, 5000);
        return;
      }

      const parameter = {
        url: `/api/audit_engagement/workpaper/assign/${workId}/`,
        payload: {
          assign_to_id: assign_to_id,
        },
        setErrors: setErrors,
      };

      justUpdateDataV2(parameter);
    }
  }, [assign_to_id]);

  const [maketrue, setTrue] = useState(false);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageUpdate && (
        <p className="successmessage">Updated Successfully</p>
      )}

      {successMessageQuestion && (
        <p className="successmessage">Test Added Successfully</p>
      )}

      {successMessageQuestionUpdate && (
        <p className="successmessage">Test Updated  SuccessFully</p>
      )}
      {successMessageQuestionDelete && (
        <p className="successmessage">Test Deleted Successfully</p>
      )}
      {successMessageQuestionDelete && (
        <p className="successmessage">Comment Added Successfully</p>
      )}
      {successMessageTask && (
        <p className="successmessage">Workpaper completed Successfully</p>
      )}
      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow
          name={editMode ? "Edit Fieldwork" : "View Fieldwork"}
          parentName={APIaudit?.name}
          fieldStatus={fieldStatus}
          backToExecution={`/audit-execution/${id}/fieldwork`}
        />

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="view_workpaper_con"
          style={{ width: tableSize ? "100%" : "100%" }}
          >
            <div className="view_workpap_prepearer_reviewer">
              <form
                onDoubleClick={(e) => {
                  if (APIData.status === "Completed") {
                    e.preventDefault();
                    return;
                  }
                  handleEditSave(e);
                }}
              >
                <div className="lng_lbl_inpt">
                  <label>Title</label>
                  <input
                    type="text"
                    placeholder="name of fieldwork"
                    value={title ? title : risk_title}
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                    onChange={(e) => setTitle(e.target.value)}
                    readOnly={!editMode}
                    tabIndex={1}
                  />
                </div>
                <div className="lng_lbl_inpt">
                  <label>Objective</label>
                  <input
                    type="text"
                    placeholder="name of fieldwork"
                    value={objective}
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                    onChange={(e) => setObjective(e.target.value)}
                    readOnly={!editMode}
                    tabIndex={2}
                  />
                </div>

                <SingleOptionSelectorAuditPR
                  id={id}
                  setSelectorId={setPreparerId}
                  setSelectorError={setPreparerError}
                  selectorError={PreparerError}
                  Name="name"
                  title="Preparer"
                  initialId={APIData.preparer ? APIData.preparer.id : ""}
                  initialName={APIData.preparer ? APIData.preparer.name : ""}
                  editMode={editMode}
                  tabIndex={3}
                  maketrue={maketrue}
                  setTrue={setTrue}
                />

                <SingleOptionSelectorAuditPR
                  id={id}
                  setSelectorId={setReviewerId}
                  setSelectorError={setReviewerError}
                  selectorError={reviewerError}
                  Name="name"
                  title="Reviewer"
                  initialId={APIData.reviewer ? APIData.reviewer.id : ""}
                  initialName={APIData.reviewer ? APIData.reviewer.name : ""}
                  editMode={editMode}
                  tabIndex={4}
                  maketrue={maketrue}
                  setTrue={setTrue}
                />

                <SingleOptionSelectorAuditBU
                  id={id}
                  setSelectorId={setAssign_to_id}
                  setSelectorError={setAssign_to_idError}
                  selectorError={assign_to_idError}
                  Name="name"
                  title="Assign To"
                  initialId={APIData.assign_to ? APIData.assign_to.id : ""}
                  initialName={APIData.assign_to ? APIData.assign_to.name : ""}
                  editMode={editMode}
                  tabIndex={5}
                  maketrue={maketrue}
                  setTrue={setTrue}
                />
              </form>

              <div className="button_container">
                <button
                  className="cmn_btn"
                  style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
                  disabled={!editMode}
                  onClick={updateapi}
                  tabIndex={6}
                >
                  <svg
                    width="12"
                    height="10"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.72621L3.18182 6.90803L9 1.08984"
                      stroke="white"
                      stroke-width="1.45455"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Save
                </button>

                {editMode && (
                  <button
                    onClick={handleCancelClickField}
                    className="cmn_btn"
                    style={{ backgroundColor: "#d6d6d6", color: "#000" }}
                    tabIndex={7}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L10.9989 10.9989"
                        stroke="#292D32"
                        stroke-width="1.76758"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.00107 10.9989L11 1"
                        stroke="#292D32"
                        stroke-width="1.76758"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Cancel Edit
                  </button>
                )}

                <WindowComponent tabIndex={8} disabled={APIData.status==="Completed"} onDelete={onDelete} text="Delete Fieldwork" />
                {APIData.status === "Open" && APITestData.length > 0 && (
                  <button
                    className="cmn_btn"
                    style={{
                      backgroundColor: !APITestData.every(
                        (test) =>
                          test.status === "Passed" || test.status === "Failed"
                      )
                        ? "#7A7A7A"
                        : "#283cf4ea",
                    }}
                    disabled={
                      !APITestData.every(
                        (test) =>
                          test.status === "Passed" || test.status === "Failed"
                      )
                    }
                    onClick={() => markAsDone()}
                    tabIndex={9}
                  >
                    <svg
                      width="12"
                      height="10"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 4.72621L3.18182 6.90803L9 1.08984"
                        stroke="white"
                        stroke-width="1.45455"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Mark As Done
                  </button>
                )}
              </div>
            </div>

            <div className="button_container">
              <button
                onClick={(e) => {
                  if (APIData.status === "Completed") {
                    e.preventDefault();
                    return;
                  }
                  handleTestCreate(e);
                }}
                disabled={
                  APIData.status ==="Completed"
                }
                tabIndex={10}
                className="cmn_btn"
                style={{ backgroundColor: "#5771DF" }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="16" height="16" rx="8" fill="white" />
                  <path
                    d="M5 8H11"
                    stroke="#5771DF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 11V5"
                    stroke="#5771DF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Create Test
              </button>
            </div>



            <div className="fieldtable_con">
              <table className="fieldtable">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Sr No.</th>
                    <th>Test</th>
                    <th>Sample Size</th>
                    <th>Preliminary Observation</th>
                    <th>Result</th>
                    <th>Evidence</th>
                  </tr>
                </thead>

                <tbody>
                  {APITestData.sort((u, v) => u.id - v.id).map(
                    (data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          key={data.id}
                          // data-index={index}
                          onDoubleClick={(e) => {
                            if (APIData.status === "Completed") {
                              e.preventDefault();
                              return;
                            }
                            handleEditClick(index, data);
                          }}
                        >
                          {editingRow === index ? (
                            <>
                              <td>
                                <button
                                  style={{
                                    marginRight: "1vw",
                                    border: "none",
                                    background: "transparent",
                                  }}
                                  onClick={(e) => handleSaveClick(data.id)}
                                >
                                  <img src={SaveRow} style={{ width: "2vw" }} />
                                </button>
                                <button
                                  style={{
                                    marginRight: "1vw",
                                    border: "none",
                                    background: "transparent",
                                  }}
                                  onClick={handleCancelClick}
                                >
                                  <img
                                    src={closeBtn}
                                    style={{ width: "2vw" }}
                                  />
                                </button>
                              </td>

                              <td>{index + 1}</td>

                              <td style={{ textAlign: "left" }}>
                                <textarea
                                  type="text"
                                  style={{ width: "400px" }}
                                  value={editValues?.step || ""}
                                  onChange={(e) => handleInputChange(e, "step")}
                                />
                              </td>

                              <td>
                                <textarea
                                  type="text"
                                  style={{
                                    width:"200px",
                                     border: "1px solid lightblue" }}
                                  value={editValues?.sample_size || ""}
                                  onChange={(e) =>
                                    handleInputChange(e, "sample_size")
                                  }
                                />
                              </td>

                              <td>
                                <textarea
                                  type="text"
                                  style={{
                                    width:"400px",
                                    border: "1px solid lightblue",
                                  }}
                                  value={editValues.note || ""}
                                  onChange={(e) => handleInputChange(e, "note")}
                                />
                              </td>

                              <td>
                                <div>
                                  <select
                                    id="status"
                                    className="rslt_selector"
                                    value={data.status}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        e,
                                        data.id,
                                        editValues.note,
                                        editValues.sample_size,
                                        editValues.step
                                      )
                                    }
                                  >
                                    <option value="">--Select--</option>
                                    <option value="Passed">Passed</option>
                                    <option value="Failed">Failed</option>
                                  </select>
                                </div>
                              </td>

                              <td>
                                <MultiSelectView
                                  options={options}
                                  selectedOptions={selectedOptions}
                                  onChange={handleSelectionChange}
                                />
                              </td>
                            </>
                          ) : (
                            <>
                              <td
                                style={{
                                  gap: "1vw",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                  }}
                                  onClick={(e) => {
                                    if (APIData.status === "Completed") {
                                      e.preventDefault();
                                      return;
                                    }
                                    handleEditClick(index, data);
                                  }}
                                  disabled={APIData.status === "Completed"}
                                >
                                  <img src={EditRow} style={{ width: "2vw" }} />
                                </button>
                                <button
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                  }}
                                  disabled={APIData.status === "Completed"}
                                  onClick={(e) => {
                                    if (APIData.status === "Completed") {
                                      e.preventDefault();
                                      return;
                                    }
                                    handleTestDelete(data.id);
                                  }}
                                >
                                  <img
                                    src={deleteBtn}
                                    style={{ width: "2vw" }}
                                  />
                                </button>
                              </td>
                              <td>{index + 1}</td>
                              <td
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                <span>{data?.step || "--"}</span>
                              </td>
                              <td>{data?.sample_size || "--"}</td>
                              <td style={{ textAlign: data?.note !== "" ? "center" : "center" }}><span>{data?.note || "--"}</span></td>
                              <td>
                                <div>
                                  <select
                                    id="status"
                                    value={data?.status}
                                    disabled={APIData.status === "Completed"}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        e,
                                        data.id,
                                        data?.note
                                      )
                                    }
                                    className="rslt_selector"
                                  >
                                    <option value="">--Select--</option>
                                    <option value="Passed">Passed</option>
                                    <option value="Failed">Failed</option>
                                  </select>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div onClick={() => handleDivClick(index)}>
                                    {data?.data?.files?.length > 0 ? (
                                      <span>View Files</span>
                                    ) : (
                                      <span>No files available</span>
                                    )}
                                  </div>
                                  <div
                                    className="file-select-popup"
                                    style={{
                                      display:
                                        openIndex === index ? "block" : "none",
                                    }}
                                  >
                                    <div className="sub-file-select-popup">
                                      <div className="file-select-popup-top">
                                        <h4>Uploaded Files</h4>
                                        <div className="btn-div-popup">
                                          <button
                                            onClick={() =>
                                              handleSaveClick1(data)
                                            }
                                          >
                                            <img src={SaveBtn} />
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleDivClick(index)
                                            }
                                          >
                                            <img src={cutbutton} alt="Close" />
                                          </button>
                                        </div>
                                      </div>
                                      <h6>
                                        You can select/deselect files for this
                                        test case.
                                      </h6>
                                      <div className="file-select-input">
                                        <MultiSelectView
                                          options={options}
                                          selectedOptions={
                                            data?.data?.files || []
                                          }
                                          onChange={handleSelectionChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      </React.Fragment>
                    )
                  )}

                  {isTestCreateOpen && (
                    <tr>
                      <td>
                        <div>
                          <button
                            onClick={postTest}
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <img src={SaveRow} style={{ width: "2vw" }} />
                          </button>
                          <button
                            style={{
                              marginRight: "1vw",
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={handleTestClose}
                          >
                            <img src={closeBtn} style={{ width: "2vw" }} />
                          </button>
                        </div>
                      </td>
                      <td>
                        <h2></h2>
                      </td>

                      <td>
                        <div className="viewworkpap_labelandinputs">
                          <textarea
                            type="text"
                            placeholder="--"
                            style={{
                              width: "400px",
                              border: step1error ? "1px solid #FF4646" : "",
                            }}
                            value={step1}
                            onChange={(e) => {
                              setStep1(e.target.value);
                              setStep1Error(false);
                            }}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="viewworkpap_labelandinputs">
                          <textarea
                            type="text"
                            placeholder="--"
                            style={{
                              width:"200px",
                              border: sample_sizeError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                            value={sample_size1}
                            onChange={(e) => {
                              setSampleSize1(e.target.value);
                              setSampleSizeError(false);
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="viewworkpap_labelandinputs">
                          <textarea
                            type="text"
                            placeholder=""
                            style={{
                              width: "400px",
                              border: preliminaryError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                            value={note1}
                            onChange={(e) => {
                              setNote1(e.target.value);
                              setPreliminaryError(false);
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="viewworkpap_labelandinputs">
                          <div>
                            <select
                              id="status"
                              className="rslt_selector"
                              value={statusVal}
                              onChange={(e) => setStatusVal(e.target.value)}
                            >
                              <option value="">--Select--</option>
                              <option value="">None</option>
                              <option value="Passed">Passed</option>
                              <option value="Failed">Failed</option>
                            </select>
                          </div>
                        </div>
                      </td>

                      <td>
                        <MultiSelectView
                          options={options}
                          selectedOptions={data?.data?.files || []}
                          onChange={handleSelectionChange}
                        />
                      </td>

                      <td>
                        <div className="viewworkpap_labelandinputs"></div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="fw_grid_container">
              <div className="previous_rcm_data fw_grid_item ">
                <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>
                  Risk Control Matrix Audit Procedure
                </h2>

                <div className="read_rcm_section">
                  <h2
                    style={{
                      fontSize: "14px",
                      fontFamily: "interbold",
                      marginBottom: "16px",
                    }}
                  >
                    Introductory
                  </h2>

                  <div className="read_rcm_content">
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Functions</label>
                      <p>
                        {APIData
                          ? APIData.business_unit?.name
                            ? APIData.business_unit?.name
                            : "All Functions"
                          : "All Functions"}
                      </p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Process</label>
                      <p>{APIData.process ? APIData.process : "--"}</p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Sub-Process</label>
                      <p>{APIData.sub_process ? APIData.sub_process : "--"}</p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Objective Description</label>
                      <p>{APIData.objective ? APIData.objective : "--"}</p>
                    </div>
                  </div>
                </div>

                <div className="read_rcm_section">
                  <h2
                    style={{
                      fontSize: "14px",
                      fontFamily: "interbold",
                      marginBottom: "16px",
                    }}
                  >
                    Risk Description
                  </h2>

                  <div className="read_rcm_content">
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Risk ID</label>
                      <p>{APIData.risk_id ? APIData.risk_id : ""}</p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Risk Category</label>
                      <p>
                        {APIData.risk_category ? APIData.risk_category : ""}
                      </p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Risk Title</label>
                      <p>{APIData.risk_title ? APIData.risk_title : "--"}</p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Risk Statement</label>
                      <p>
                        {APIData.risk_description
                          ? APIData.risk_description
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="read_rcm_section">
                  <h2
                    style={{
                      fontSize: "14px",
                      fontFamily: "interbold",
                      marginBottom: "16px",
                    }}
                  >
                    Inherent Assessment
                  </h2>

                  <div className="read_rcm_content">
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Inh. Likelihood</label>
                      <p>
                        {likemapping(
                          APIData.inherent_assessment_likelihood
                            ? APIData.inherent_assessment_likelihood
                            : "--"
                        )}
                      </p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Inh. Impact</label>
                      <p>
                        {impactmapping(
                          APIData.inherent_assessment_impact
                            ? APIData.inherent_assessment_impact
                            : "--"
                        )}
                      </p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Inherent Risk Evaluation Score</label>
                      <p>
                        {APIData.risk_inherent_evaluation_score
                          ? APIData.risk_inherent_evaluation_score
                          : "--"}
                      </p>
                    </div>
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Inherent Risk Evaluation</label>
                      <p>
                        {APIData.risk_inherent_evaluation
                          ? APIData.risk_inherent_evaluation
                          : "--"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="read_rcm_section">
                  <h2
                    style={{
                      fontSize: "14px",
                      fontFamily: "interbold",
                      marginBottom: "16px",
                    }}
                  >
                    Control
                  </h2>

                  <div className="read_rcm_content">
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Control Description</label>
                      <pre>
                        {APIData.control_description
                          ? APIData.control_description
                          : "--"}
                      </pre>
                    </div>
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Best Practices for Reference</label>
                      <p>
                        {APIData.control_desired
                          ? APIData.control_desired
                          : "--"}
                      </p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Control Design</label>
                      <p>
                        {APIData.control_design ? APIData.control_design : "--"}
                      </p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Control Type</label>
                      <p>
                        {APIData.control_type ? APIData.control_type : "--"}
                      </p>
                    </div>
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Control Frequency</label>
                      <p>
                        {APIData.frequency ? APIData.frequency : "--"}
                      </p>
                    </div>
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Control Design Effectiveness</label>
                      <p>
                        {APIData.control_design_effectiveness ? APIData.control_design_effectiveness : "--"}
                      </p>
                    </div>
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Control Operating  Effectiveness</label>
                      <p>
                        {APIData.control_operating_effectiveness ? APIData.control_operating_effectiveness : "--"}
                      </p>
                    </div>
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Control Score</label>
                      <p>
                        {APIData.control_score ? APIData.control_score : "--"}
                      </p>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Control Evaluation</label>
                      <p>
                        {APIData.control_evaluation
                          ? APIData.control_evaluation
                          : "--"}
                      </p>
                    </div>

                  </div>
                </div>

                <div className="read_rcm_section">
                  <h2
                    style={{
                      fontSize: "14px",
                      fontFamily: "interbold",
                      marginBottom: "16px",
                    }}
                  >
                    Residual Assessment
                  </h2>

                  <div className="read_rcm_content">
                    {/* <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Residual Risk Evaluation Score</label>
                      <p>
                        {APIData.risk_residual_evaluation_score
                          ? APIData.risk_residual_evaluation_score
                          : "--"}
                      </p>
                    </div> */}
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Residual Risk Evaluation </label>
                      <p>
                        {APIData.evaluate_risk
                          ? APIData.evaluate_risk
                          : "--"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="read_rcm_section">
                  <h2
                    style={{
                      fontSize: "14px",
                      fontFamily: "interbold",
                      marginBottom: "16px",
                    }}
                  >
                    Audit Procedure
                  </h2>

                  <div className="read_rcm_content">
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Sample Size</label>
                      <pre>
                        {APIData.sample_size
                          ? APIData.sample_size
                          : "--"}
                      </pre>
                    </div>
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Sample Rationale </label>
                      <pre>
                        {APIData.sample_rationale
                          ? APIData.sample_rationale
                          : "--"}
                      </pre>
                    </div>
                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Audit Step Details</label>
                      <pre>
                        {APIData.audit_procedure_step
                          ? APIData.audit_procedure_step
                          : "--"}
                      </pre>
                    </div>

                    <div className="viewworkpap_labelandinputs">
                      <label htmlFor="">Data Required</label>
                      <p>
                        {APIData.audit_procedure_data
                          ? APIData.audit_procedure_data
                          : "--"}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: !showTest ? "none" : "flex",
                    gap: "2vh",
                  }}
                >
                  <div className="viewworkpap_labelandinputs">
                    <label htmlFor="">Test Case</label>
                    <textarea
                      type="text"
                      placeholder="--"
                      style={{
                        width: tableSize ? "12vw" : "15vw",
                        border: sample_rationaleError
                          ? "1px solid #FF4646"
                          : "",
                      }}
                      value={test_case}
                      onChange={(e) => {
                        setTestCase(e.target.value);
                        setSampleRationaleError(false);
                      }}
                      readOnly={!editMode}
                    />
                  </div>

                  <div className="viewworkpap_labelandinputs">
                    <label htmlFor="">Sample Size</label>
                    <input
                      type="text"
                      placeholder="--"
                      style={{
                        width: tableSize ? "12vw" : "15vw",
                        border: sample_sizeError ? "1px solid #FF4646" : "",
                      }}
                      value={sample_size ? sample_size : "--"}
                      onChange={(e) => {
                        setSampleSize(e.target.value);
                        setSampleSizeError(false);
                      }}
                      readOnly={!editMode}
                    />
                  </div>

                  <div className="viewworkpap_labelandinputs">
                    <label htmlFor="">Preliminary Observation</label>
                    <input
                      type="text"
                      placeholder="--"
                      style={{
                        width: tableSize ? "12vw" : "15vw",
                        border: preliminaryError ? "1px solid #FF4646" : "",
                      }}
                      value={note1}
                      onChange={(e) => {
                        setNote1(e.target.value);
                        setPreliminaryError(false);
                      }}
                      readOnly={!editMode}
                    />
                  </div>

                  <div className="fieldwork_cr_btn" onClick={postTest}>
                    {" "}
                    Create{" "}
                  </div>
                </div>
              </div>

              <div className="comment_section fw_grid_item">
                <div className="heading_toggle_aq">
                  <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>
                    Comments & Activity
                  </h2>

                  <div className="togglecon">
                    <div
                      className="toggle-option"
                      onClick={() => setIsComments(true)}
                      style={{
                        backgroundColor: isComments ? "#fff" : "",
                        borderRadius: "999px",
                      }}
                    >
                      Comments
                    </div>

                    <div
                      className="toggle-option"
                      onClick={() => setIsComments(false)}
                      style={{
                        backgroundColor: !isComments ? "#fff" : "",
                        borderRadius: "999px",
                      }}
                    >
                      Activity
                    </div>
                  </div>
                </div>

                {isComments ? (
                  <>
                    <div className="initial_comment">
                      <input
                        type="text"
                        placeholder="Enter a comment..."
                        value={inputValue}
                        onChange={handleInputChange1}
                        onKeyDown={handleKeyDown}
                        style={{ width: "90%" }}
                      />
                      <div className="sendComm">
                        <img
                          onClick={handleAddComment}
                          src={sendComment}
                          alt="Send Comment"
                        />
                      </div>
                    </div>

                    <div className="all_comments">
                      {taskData
                        .slice()
                        .reverse()
                        .map((comment, index) => {
                          const { text, date, time } = comment;
                          return (
                            <div className="comment_wrapper_" key={index}>
                              <div className="comment_head">
                                <div className="avatar_name_AQ">
                                  <SuperAvatar
                                    firstName={
                                      comment.user
                                        ? comment.user.name
                                        : `${avaUserName} ${lastName}`
                                    }
                                    classOfAvatar="Avtaraudit"
                                  />
                                  <h5>
                                    {comment.user
                                      ? comment.user.name
                                      : `${avaUserName} ${lastName}`}
                                  </h5>
                                </div>
                                <div className="time_date_AQ">
                                  <span>
                                    <sub>{convertDateTime(comment.created_at)}</sub>
                                  </span>
                                </div>
                              </div>
                              <div className="comment_AQ">
                                <p>{text}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <div className="all_comments">
                    {activitylog
                      .sort(
                        (a, b) =>
                          new Date(b.created_at) - new Date(a.created_at)
                      )
                      .map((activity, index) => (
                        <div className="activity_wrapper_com">
                          <div className="activity_head">
                            <span className="status_complete">
                              {activity.action}
                            </span>
                            <div className="time_date_AQ">
                              <span>
                                <sub>{convertDateTime(activity.created_at)}</sub>
                              </span>
                            </div>
                          </div>

                          <div className="activity_AQ">
                            <div className="activity_transaction">
                              <div className="avatar_name_AQ">
                                <SuperAvatar
                                  firstName={activity.action_by_id.name}
                                  classOfAvatar="Avtaraudit"
                                />
                                <h5>{activity.action_by_id.name}</h5>
                              </div>

                              <h4 title={activity.log} style={{ wordWrap: "break-word" }}>
                                {activity.log.length > 30
                                  ? `${activity.log.substring(0, 30)}...`
                                  : activity.log}
                              </h4>

                              {activity.action !== "CREATE" &&
                                activity.action !== "UPDATE" && (
                                  <div className="avatar_name_AQ">
                                    <SuperAvatar
                                      firstName={"Affan Shaikh"}
                                      classOfAvatar="Avtaraudit"
                                    />
                                    <h5> Affan Shaikh </h5>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

          </div>
        )}
      </section>
    </>
  );
};

export default ViewAuditFieldwork;
