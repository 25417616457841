import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../css/viewuser.css";
import "../rcm/listofrcap.css";
import OptionDropDown from "../../OptionDropDown";
import {
  postDataWithFile,
  fetchDataV2,
  justFetchDataV2,
  postDataWithFetchV2,
  updatewithFetchDataV2,
  deleteDataV2,
  deleteDataWithFetchV2,
} from "../../apiUtils";
import { auditRoutesRCM,auditExecution,auditRoutes } from "../../subroutes";
import WindowComponent from "../../WindowComponent";
import deleteBtn from "../../../images/deleteBtn.svg";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIconsRCM from "../../renderIconRCM";
import Button from "../../user/Button";
import NavRow from "../../NavRow";
import NavRowRCM from "../../NavRowRCM";
import RenderIcons from "../../RenderIcons";

const ListOfAllRcapPage = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [showDone, setShowDone] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [successMessageQuestion, setSuccessMessage] = useState(false);
  const [DepartmetData, setDepartmentData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [badRequest, setBadRequest] = useState("");
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { id, planid, tempId, departmentId } = useParams();
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editedRowData, setEditedRowData] = useState({});
  const [entityId, setEntityId] = useState("");
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [control_description, setExistingControl] = useState("");
  const [control_desired, setDesiredControl] = useState("");
  const [control_design, setControlDesign] = useState("");
  const [audit_procedure_data, setDataRequired] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [likelikhoodError, setLikelikhoodError] = useState(false);
  const [impactError, setImpactError] = useState(false);
  const [existingControlError, setExistingControlError] = useState(false);
  const [desiredControlError, setDesiredControlError] = useState(false);
  const [evaluationError, setEvaluationError] = useState(false);
  const [controlDesignError, setControlDesignError] = useState(false);
  const [controlDesignEffectiveError, setControlDesignEffectiveError] =
    useState(false);
  const [controlOperatingError, setControlOperatingError] = useState(false);
  const [controlTypeError, setcontrolTypeError] = useState(false);
  const [resLikelihoodError, setResLikelihoodError] = useState(false);
  const [resImpactError, setResImpactError] = useState(false);
  const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
  const [samplerationaleError, setsamplerationaleError] = useState(false);
  const [samplesizeError, setsamplsizeError] = useState(false);
  const [dataRequiredError, setDataRequiredError] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [objectiveError, setObjectiveError] = useState(false);
  const [riskIdError, setRiskIdError] = useState(false);
  const [riskCategoryError, setRiskCategoryError] = useState(false);
  const [describeRiskError, setDescribeRiskError] = useState(false);
  const [titleRiskError, setTitleRiskError] = useState(false);
  const segments = location.pathname.split("/");
  const AuditExecution = segments.find(
    (segment) => segment === "audit-execution"
  );
  const [showNewRow, setShowNewRow] = useState(false);
  const [deptObject, setDeptObject] = useState({});
  const [businessUnitsId, setBusinessUnitsId] = useState([]);
  const [departmentNames, setDepartmentNames] = useState([]);
  const [departmentIds, setDepartmentIds] = useState({});
  const [planData, setPlanData] = useState([]);
  const [showApproves, setShowApproves] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const [showSelectFileButton, setShowSelectFileButton] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [successMessageFileUpload, setSuccessMessageFileUpload] =
    useState(false);
  const [successMessageQuestionUpdate, setSuccessMessageUopdate] =
    useState(false);
  const [successMessageQuestionDelete, setSuccessMessageDelete] =
    useState(false);

  const url = location.pathname;
  const [auditRoute, setAuditRoute] = useState([]);

  useEffect(() => {
    if (url) {
      const isAuditExecution = location.pathname.includes("audit-execution");

      // Update the path based on the boolean value
      if (!isAuditExecution) {
        // If true, make the path "/auditExecution/Rcm"
        setAuditRoute(
          auditRoutes
        );
      } else {
        // If false, make the path "/auditUniverse/Rcm"
        setAuditRoute(
         auditExecution
        );
      }
    }
  }, [url]);

  useEffect(() => {
    console.log("auditRoute : ", auditRoute);
  }, [auditRoute]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileUpload]);

  function impactmapping(value) {
    switch (value) {
      case 1:
        return "Low (1)";
      case 2:
        return "Minor (2)";
      case 3:
        return "Moderate (3)";
      case 4:
        return "Major (4)";
      case 5:
        return "Severe (5)";
      default:
        return "";
    }
  }
  function likemapping(value) {
    switch (value) {
      case 1:
        return "Remote (1)";
      case 2:
        return "Unlikely (2)";
      case 3:
        return "Possible (3)";
      case 4:
        return "Likely (4)";
      case 5:
        return "Almost Certain (5)";
      default:
        return "";
    }
  }
  function controldesignmapping(value) {
    switch (value) {
      case 1:
        return "Poor (1)";
      case 2:
        return "Unsatisfactory (2)";
      case 3:
        return "Satisfactory (3)";
      case 4:
        return "Effective (4)";
      case 5:
        return "Highly Effective (5)";
      default:
        return "";
    }
  }

  const Viewuser = (data) => {
    const raid = data.id;

    navigate(`/audit-universe/rcm/${departmentId}/${raid}/rcapdetailpwa`);
    console.log(raid);
    console.log(data);
  };

  const closeEdit = (id) => {
    handleEditClick(false);
  };
  const closeCreate = () => {
    window.location.reload();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (APIData.length > 0) {
      setShowDone(true);
    }
  }, [APIData]);

  const handleDelete = (rcapid) => {
    const parameter = {
      url: AuditExecution
        ? `/api/audit_engagement/workpaper/${rcapid}/`
        : `/api/audit_universe/rcap/${rcapid}/`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: AuditExecution
        ? `/audit-execution/${id}/planning/${planid}/riskcontrolmatrix/`
        : `/audit-universe/rcm/${departmentId}`,
      tofetch: {
        items: [
          {
            fetchurl: AuditExecution
              ? `/api/audit_engagement/workpaper/?audit_id=${id}`
              : `/api/audit_universe/rcap/?department_ids=${departmentId}`,
            dataset: AuditExecution ? setAPIData : setAPIData,
          },
        ],
        setSuccessMessage: AuditExecution
          ? setSuccessMessageDelete
          : setSuccessMessageDelete,
        setErrors: setErrors,
      },
    };
    deleteDataWithFetchV2(parameter);
    setShowWindow(false);
  };

  const handleInputChange = (e, key) => {
    setEditedRowData({ ...editedRowData, [key]: e.target.value });
  };

  const handleDoneClick = (wid) => {
    if (
      !editedRowData.objective ||
      !editedRowData.risk_id ||
      !editedRowData.risk_title ||
      !editedRowData.inherent_assessment_likelihood ||
      !editedRowData.inherent_assessment_impact ||
      !editedRowData.control_design_effectiveness ||
      !editedRowData.control_operating_effectiveness ||
      !editedRowData.audit_procedure_step
    ) {
      setObjectiveError(!editedRowData.objective ? true : false);
      setRiskIdError(!editedRowData.risk_id ? true : false);
      setTitleRiskError(!editedRowData.risk_title ? true : false);
      setLikelikhoodError(
        !editedRowData.inherent_assessment_likelihood ? true : false
      );
      setImpactError(!editedRowData.inherent_assessment_impact ? true : false);
      setControlDesignEffectiveError(
        !editedRowData.control_design_effectiveness ? true : false
      );
      setControlOperatingError(
        !editedRowData.control_operating_effectiveness ? true : false
      );
      setAuditStepDetailsError(
        !editedRowData.audit_procedure_step ? true : false
      );
      // setBusinessUnitIdError(!newRow.business_unit ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const payload = AuditExecution
      ? {
          ...editedRowData,
          audit_id: id,
          rcm_flag: isChecked,
          business_unit_id: selectedBusiness
            ? selectedBusiness
            : editedRowData?.business_unit?.id,
        }
      : {
          ...editedRowData,
          audit_id: id,
          business_unit_id: selectedBusiness
            ? selectedBusiness
            : editedRowData?.business_unit?.id,
        };

    // Update the APIData with the edited row data
    const updatedAPIData = APIData.map((data, index) => {
      if (index === editingRowIndex) {
        return payload;
      }
      return data;
    });
    setAPIData(updatedAPIData);

    // Determine the API endpoint based on AuditExecution
    const apiEndpoint = AuditExecution
      ? `/api/audit_engagement/workpaper/${payload.id}`
      : `/api/audit_universe/rcap/${payload.id}`;

    // Make an API call to update the data on the server

    const parameter = {
      url: apiEndpoint,
      payload,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: AuditExecution
              ? `/api/audit_engagement/workpaper/?audit_id=${id}`
              : `/api/audit_universe/rcap/?department_ids=${departmentId}`,
            dataset: AuditExecution ? setAPIData : setAPIData,
          },
        ],
        setSuccessMessage: AuditExecution
          ? setSuccessMessageUopdate
          : setSuccessMessageUopdate,
        setErrors: setErrors,
      },
    };

    updatewithFetchDataV2(parameter);

    setEditingRowIndex(null);
    setEditedRowData({});
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles((prevSelectedFiles) => {
        if (!Array.isArray(prevSelectedFiles)) {
          console.error("selectedFiles is not an array", prevSelectedFiles);
          return [file];
        }
        return [...prevSelectedFiles, file];
      });
      setShowSelectFileButton(false);
    }
  };

  const getBackgroundColor = (value) => {
    switch (value) {
      case "Low":
        return "YellowGreen";
      case "Medium Low":
        return "Gold";
      case "Medium High":
        return "Orange";
      case "High":
        return "DarkRed";
      case "Very High":
        return "red";
      default:
        return "white"; // Default background color
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();

    if (selectedFiles.length === 0) {
      setErrors("Please select a file to upload.");
      return;
    }

    const parameter = {
      url: "/api/audit_universe/rcap-upload/",
      files: selectedFiles,
      data: {
        department: departmentId,
      },
      setErrors: setErrors,
      setSuccessMessage: setSuccessMessage,
    };

    postDataWithFile(parameter);
    setUploadStatus("success");
    setSelectedFiles(null); // Reset properly
    setShowSelectFileButton(true);
  };

  const handleEditClick = (index, data) => {
    setEditingRowIndex(index);
    setEditedRowData({ ...data });
  };

  const handleFilterChange = (filterKey, e) => {
    if (filterKey === "business_unit") {
      setFilters({
        ...filters,
        business_unit: { name: e.target.value },
      });
    } else {
      setFilters({ ...filters, [filterKey]: e.target.value });
    }
  };

  const [buData, setBuData] = useState({});
  useEffect(() => {
    if (DepartmetData) {
      setBusinessUnitOptions(
        DepartmetData.business_units ? DepartmetData.business_units : []
      );
      setBuData();
    }
  }, [DepartmetData]);

  const [audit, setAudit] = useState([]);

  useEffect(() => {
    if (id) {
      const parameter = {
        url: `/api/audit_engagement/audit/${id}/`,
        setterFunction: setAudit,
        setErrors: setErrors,
      };
      fetchDataV2(parameter);
    }
  }, [id]);

  const [auditEntity, setAuditEntity] = useState([]);
  useEffect(() => {
    if (AuditExecution) {
      const parameter = {
        url: `/api/audit_engagement/workpaper/?audit_id=${id}`,
        setterFunction: setAPIData,
        setErrors: setErrors,
        setLoading: setLoading,
      };
      justFetchDataV2(parameter);
    }
  }, [AuditExecution, id, setAPIData, setErrors]);

  useEffect(() => {
    if (audit.audit_entity) {
      const parameter = {
        url: `/api/audit_universe/audit-entities/${audit?.audit_entity?.id}/`,
        setterFunction: setAuditEntity,
        setErrors: setErrors,
        
      };
      fetchDataV2(parameter);
    }
  }, [audit]);

  useEffect(() => {
    if (auditEntity.audit_unit) {
      const unitsId = auditEntity.audit_unit.map(
        (unit) => unit.business_units_id
      );
      const deptNames = auditEntity.audit_unit.map(
        (unit) => unit.department_name
      );
      setBusinessUnitsId(unitsId);
      setDepartmentNames(deptNames);
    }
  }, [auditEntity]);

  useEffect(() => {
    const parameter = {
      url: `/api/system/departments/`,
      setterFunction: (allDepartments) => {
        var _deptObject = {};
        allDepartments.map((u) => {
          _deptObject[u.name] = u.id;
        });
        setDeptObject(_deptObject);
      },
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    console.log(deptObject);
    console.log(businessUnitsId);
    console.log(departmentNames);
    setDepartmentIds(
      departmentNames.map((department) => deptObject[department])
    );
  }, [deptObject]);

  useEffect(() => {
    setDepartmentIds(
      departmentNames.map((department) => deptObject[department])
    );
  }, [departmentNames]);

  const removeDuplicates = (arr) => {
    return Array.isArray(arr) ? [...new Set(arr)] : [];
  };
  useEffect(() => {
    const departmentsString = departmentIds
      ? removeDuplicates(departmentIds).toString()
      : "";
    const businessUnitsString = businessUnitsId
      ? businessUnitsId.toString()
      : "";
    if (departmentId) {
      const parameter2 = {
        url: `/api/audit_universe/rcap/?department_ids=${departmentId}`,
        setterFunction: (data) => {
          setAPIData(data);
        },
        setErrors: (error) => {
          setErrors(error);
        },
        setLoading: setLoading,
      };
      justFetchDataV2(parameter2);

      const parameter = {
        url: `/api/system/departments/${departmentId}/`,
        setterFunction: setDepartmentData,
        setErrors: setErrors,
      };
      fetchDataV2(parameter);
    }
    // console.log(DepartmetData);
    // console.log(APIData);

    if (
      !departmentId &&
      !businessUnitsString &&
      !departmentsString &&
      !AuditExecution
    ) {
      const parameter3 = {
        url: `/api/audit_universe/rcap/`,
        setterFunction: (data) => {
          setAPIData(data);
        },
        setErrors: (error) => {
          setErrors(error);
        },
        setLoading: setLoading,
      };
      justFetchDataV2(parameter3);
    }
  }, [departmentIds, businessUnitsId, departmentId, AuditExecution]);

  useEffect(() => {
    if (planData.reviewer) {
      const reviewerId = planData.reviewer ? planData.reviewer.user_id : "";
      if (reviewerId === user_id) {
        setShowApproves(true);

        console.log(showApproves, "showApproves");
      }
    }
  }, [planData]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/plan/${planid}/`,
      setterFunction: setPlanData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [planid]);

  const [filters, setFilters] = useState({
    business_unit: "",
    process: "",
    sub_process: "",
    risk_id: "",
    objective: "",
    risk_title: "",
    risk_category: "",
    risk_description: "",
    inherent_assessment_likelihood: "",
    inherent_assessment_impact: "",
    risk_residual_evaluation:"",
    control_description: "",
    control_desired: "",
    control_evaluation: "",
    control_design: "",
    control_type: "",
    frequency: "",
    control_design_effectiveness: "",
    control_operating_effectiveness: "",
    control_score: "",
    control_evaluation: "",
    residual_assessment_likelihood: "",
    residual_assessment_impact: "",
  });

  const filteredData = APIData.filter((data) => {
    return Object.keys(filters).every((key) => {
      if (!filters[key]) return true;
      if (key === "business_unit") {
        return data.business_unit?.name
          .toLowerCase()
          .includes(filters.business_unit.name.toLowerCase());
      }
      return data[key]
        ?.toString()
        .toLowerCase()
        .includes(filters[key].toLowerCase());
    });
  });
  // console.log(filteredData);

  const [newRow, setNewRow] = useState({
    process: "",
    sub_process: "",
    risk_id: "",
    objective: "",
    risk_title: "",
    risk_category: "",
    risk_description: "",
    inherent_assessment_likelihood: "",
    inherent_assessment_impact: "",
    control_description: "",
    control_desired: "",
    control_design: "",
    control_type: "",
    frequency: "",
    control_design_effectiveness: "",
    control_operating_effectiveness: "",
    residual_assessment_likelihood: "",
    residual_assessment_impact: "",
    audit_procedure_step: "",
    audit_procedure_data: "",
  });

  const handleCreateRow = () => {
    setShowNewRow(true);
    setNewRow({
      process: "",
      sub_process: "",
      risk_id: "",
      objective: "",
      risk_title: "",
      risk_category: "",
      risk_description: "",
      inherent_assessment_likelihood: "",
      inherent_assessment_impact: "",
      control_description: "",
      control_desired: "",
      control_design: "",
      control_type: "",
      frequency: "",
      control_design_effectiveness: "",
      control_operating_effectiveness: "",
      audit_procedure_step: "",
      sample_size: "",
      sample_rationale: "",
      audit_procedure_data: "",
    });
  };

  const addRCap = (e) => {
    if (
      !newRow.objective ||
      !newRow.risk_id ||
      !newRow.risk_title ||
      !newRow.inherent_assessment_likelihood ||
      !newRow.inherent_assessment_impact ||
      !newRow.control_design_effectiveness ||
      !newRow.control_operating_effectiveness ||
      !newRow.audit_procedure_step
    ) {
      setObjectiveError(!newRow.objective ? true : false);
      setRiskIdError(!newRow.risk_id ? true : false);
      setTitleRiskError(!newRow.risk_title ? true : false);
      setLikelikhoodError(
        !newRow.inherent_assessment_likelihood ? true : false
      );
      setImpactError(!newRow.inherent_assessment_impact ? true : false);
      setControlDesignEffectiveError(
        !newRow.control_design_effectiveness ? true : false
      );
      setControlOperatingError(
        !newRow.control_operating_effectiveness ? true : false
      );
      setAuditStepDetailsError(!newRow.audit_procedure_step ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
  
    // Construct payload
    const payload = {
      business_unit_id: selectedBusiness,
      department_id: departmentId,
      audit_id: id,
      process: newRow.process,
      sub_process: newRow.sub_process,
      objective: newRow.objective,
      risk_id: newRow.risk_id,
      risk_category: newRow.risk_category,
      risk_description: newRow.risk_description,
      risk_title: newRow.risk_title,
      inherent_assessment_likelihood: parseInt(
        newRow.inherent_assessment_likelihood
      ),
      inherent_assessment_impact: parseInt(newRow.inherent_assessment_impact),
      control_description: newRow.control_description,
      control_desired: newRow.control_desired,
      control_evaluation: newRow.control_evaluation,
      control_design: newRow.control_design,
      control_type: newRow.control_type,
      frequency: newRow.frequency,
      control_design_effectiveness: parseInt(
        newRow.control_design_effectiveness
      ),
      control_operating_effectiveness: parseInt(
        newRow.control_operating_effectiveness
      ),
      audit_procedure_step: newRow.audit_procedure_step,
      sample_size: newRow.sample_size?newRow.sample_size:null,
      sample_rationale: newRow.sample_rationale,
      audit_procedure_data: newRow.audit_procedure_data,
      audit_entity: entityId,
      rcm_template_id: tempId,
    };
  
    // Log payload and other critical variables
    console.log("Payload:", payload);
    console.log("Selected Business Unit ID:", selectedBusiness);
    console.log("Department ID:", departmentId);
  
    const parameter = {
      url: AuditExecution
        ? "/api/audit_engagement/workpaper/"
        : "/api/audit_universe/rcap/",
      payload,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: AuditExecution
              ? `/api/audit_engagement/workpaper/?audit_id=${id}`
              : `/api/audit_universe/rcap/?department_ids=${departmentId}`,
            dataset: setAPIData,
          },
        ],
        setSuccessMessage: setSuccessMessage,
        setErrors: setErrors,
      },
    };
  
    postDataWithFetchV2(parameter);
    setNewRow("");
    setShowNewRow(false);
    setSelectedBusiness(null)
  };
  

  const Category = [
    { label: " Financial", value: "Financial" },
    { label: "Operational", value: "Operational" },
    { label: "Strategic", value: "Strategic" },
    { label: "Compliance", value: "Compliance" },
    { label: "Reputational", value: "Reputational" },
  ];
  const inherentAssessmentImpactOptions = [
    { label: "Insignificant (1)", value: 1 },
    { label: "Minor (2)", value: 2 },
    { label: "Moderate (3)", value: 3 },
    { label: "Major (4)", value: 4 },
    { label: "Severe (5)", value: 5 },
  ];

  const inherentAssessmentLikeOptions = [
    { label: "Remote (1)", value: 1 },
    { label: "Not Likely (2)", value: 2 },
    { label: "Possible (3)", value: 3 },
    { label: "Expected (4)", value: 4 },
    { label: "Highly Likely (5)", value: 5 },
  ];

  const controldesigneffectiveOptions = [
    { label: "Poor (1)", value: 1 },
    { label: "Unsatisfactory (2)", value: 2 },
    { label: "Satisfactory (3)", value: 3 },
    { label: "Effective (4)", value: 4 },
    { label: "Highly Effective (5)", value: 5 },
  ];

  const controloperatingOptions = [
    { label: "Poor (1)", value: 1 },
    { label: "Unsatisfactory (2)", value: 2 },
    { label: "Satisfactory (3)", value: 3 },
    { label: "Effective (4)", value: 4 },
    { label: "Highly Effective (5)", value: 5 },
  ];

  const controlFrequencyOptions = [
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Yearly", value: "Yearly" },
  ];
  const controlDesignOptions = [
    { label: "Manual", value: "Manual" },
    { label: "Semi-Automated", value: "Semi-Automated" },
    { label: "Automated", value: "Automated" },
  ];
  const controlTypeOptions = [
    { label: "Preventive", value: "Preventive" },
    { label: "Detective", value: "Detective" },
    { label: "Corrective", value: "Corrective" },
  ];

  const handleChangea = (event) => {
    if (AuditExecution) {
      const user = businessUnitOptions.find(
        (user) => user?.business_units_id === Number(event.target.value)
      );
      setSelectedBusiness(user?.business_units_id);
    } else {
      const user = businessUnitOptions.find(
        (user) => user?.id === Number(event.target.value)
      );
      setSelectedBusiness(user?.id);
    }
  };
  console.log(selectedBusiness);

  useEffect(() => {
    if (filteredData) {
      const buIds = filteredData.map((data) =>
        data.business_unit ? data.business_unit.id : ""
      );
      setBuData(buIds);
    }
  }, []);

  const rcapUpload = {
    handleFileUpload: handleFileUpload,
    setAPIData: setAPIData,
    setUploadStatus: setUploadStatus,
    setUploadProgress: setUploadProgress,
    setFile: setFile,
    departmentId: departmentId,
    AuditExecution: AuditExecution,
    selectedFiles: selectedFiles,
    setSelectedFiles: setSelectedFiles,
    showSelectFileButton: showSelectFileButton,
    setShowSelectFileButton: setShowSelectFileButton,
    handleFileSelect: handleFileSelect,
    successMessageFileUpload: successMessageFileUpload,
    postDataWithFile: postDataWithFile,
  };

  useEffect(() => {
    if (auditEntity?.audit_unit) {
      setBusinessUnitOptions(auditEntity?.audit_unit);
    }
  }, [auditEntity]);

  const firstInputRef = useRef(null);
  const lastInputRef = useRef(null);

  useEffect(() => {
    const handleTabKey = (event) => {
      if (
        event.key === "Tab" &&
        document.activeElement === lastInputRef.current
      ) {
        event.preventDefault();
        firstInputRef.current.focus();
      }
    };
    window.addEventListener("keydown", handleTabKey);

    return () => {
      window.removeEventListener("keydown", handleTabKey);
    };
  }, []);

    const [showWindow, setShowWindow] = useState(false);
    const toggleWindow = () => {
      setShowWindow(!showWindow);
    };
    const handleClose = () => {
      setShowWindow(false);
    };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageQuestion && (
        <p className="successmessage">RCM Added Successfully</p>
      )}
      {successMessageQuestionUpdate && (
        <p className="successmessage">RCM Updated Successfully</p>
      )}
      {successMessageQuestionDelete && (
        <p className="successmessage">RCM Deleted Successfully</p>
      )}
      <section className="navroutes" style={{ width: "98.5%" }}>
        <RenderIcons
          homeRoutes={AuditExecution ? auditExecution : auditRoutes}
          id={id}
        />
        {!location.pathname.includes("audit-execution") ? (
          <NavRow
            name={DepartmetData?.name ? DepartmetData?.name : "ALL"}
            parentName={audit?.name}
            Create={"Create"}
            link={`/administration/user/createUser`}
            trueback={true}
            RcmCreate={() => handleCreateRow()}
            rcapUpload={rcapUpload}
            secondName={"Planning"}
          />
        ) : (
          <NavRowRCM
            name={
              DepartmetData?.name
                ? DepartmetData?.name
                : `Audit Program - ${audit.name ? audit.name : ""}`
            }
            parentName={audit?.name}
            Create={"Create"}
            link={`/administration/user/createUser`}
            trueback={true}
            RcmCreate={() => handleCreateRow()}
            rcapUpload={rcapUpload}
            secondName={"Planning"}
          />
        )}

        <div className="rcmt_table" style={{ width: "100%" }}>
          <table>
            <thead style={{ position: "sticky", top: "0", zIndex: 0 }}>
              <tr>
                {editingRowIndex !== null && AuditExecution && (
                  <th colSpan={1} style={{ textAlign: "center" }}>
                    <h4 className="thhead"> </h4>
                  </th>
                )}

                <th colSpan={1} style={{ textAlign: "center" }}>
                  <h4 className="thhead"> </h4>
                </th>

                <th
                  colSpan={3}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_bluelight)",
                  }}
                >
                  <h4 className="thhead"> Business Control </h4>
                </th>

                <th
                  colSpan={5}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_greendark)",
                  }}
                >
                  <h4 className="thhead"> Risk Description </h4>
                </th>

                <th
                  colSpan={4}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_red)",
                  }}
                >
                  <h4 className="thhead"> Inherent Assessment </h4>
                </th>

                <th
                  colSpan={9}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_greenlight)",
                  }}
                >
                  <h4 className="thhead"> Control Statement </h4>
                </th>

                <th
                  colSpan={1}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_yellow)",
                  }}
                >
                  <h4 className="thhead"> Residual Assessment </h4>
                </th>

                <th
                  colSpan={4}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_bluedark)",
                  }}
                >
                  <h4 className="thhead"> Audit Procedure </h4>
                </th>
              </tr>
            </thead>

            <thead style={{ position: "sticky", top: "0", zIndex: 50 }}>
              <tr>
                <th>
                  <h4>
                    <h4 className="thhead">Action</h4>
                  </h4>
                </th>
                {editingRowIndex !== null && AuditExecution && (
                  <th>
                    <h4 className="thhead">Also Update in RCM</h4>
                    <input type="text" placeholder="--" className="thfilter" />
                  </th>
                )}
                <th style={{ backgroundColor: "var(--rcm_bluelight)" }}>
                  <h4 className="thhead">Business Unit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("business_unit", e)}
                    value={filters.business_unit.name}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluelight)" }}>
                  <h4 className="thhead">Process</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("process", e)}
                    value={filters.process}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluelight)" }}>
                  <h4 className="thhead">Sub Process</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("sub_process", e)}
                    value={filters.sub_process}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">ID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_id", e)}
                    value={filters.risk_id}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Objective</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("objective", e)}
                    value={filters.objective}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Title</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_title", e)}
                    value={filters.risk_title}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Category</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_category", e)}
                    value={filters.risk_category}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Statement</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_description", e)}
                    value={filters.risk_description}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Likelihood</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("inherent_assessment_likelihood", e)
                    }
                    value={filters.inherent_assessment_likelihood}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Impact</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("inherent_assessment_impact", e)
                    }
                    value={filters.inherent_assessment_impact}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Risk Evaluation Score</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_inherant_evaluation_score", e)
                    }
                    value={filters.risk_inherant_evaluation_score}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Risk Evaluation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_inherant_evaluation", e)
                    }
                    value={filters.risk_inherant_evaluation}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Control Statement</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("control_description", e)
                    }
                    value={filters.control_description}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">
                    Best Practices for Reference (Desired Control){" "}
                  </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_desired", e)}
                    value={filters.control_desired}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Design</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_design", e)}
                    value={filters.control_design}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Type</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_type", e)}
                    value={filters.control_type}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Frequency</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("frequency", e)}
                    value={filters.frequency}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Control Design Effectiveness</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("control_design_effectiveness", e)
                    }
                    value={filters.control_design_effectiveness}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Control Operating Effectiveness</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("control_operating_effectiveness", e)
                    }
                    value={filters.control_operating_effectiveness}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Control Score</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_score", e)}
                    value={filters.control_score}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Control Evalution</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("control_evaluation", e)
                    }
                    value={filters.control_evaluation}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_yellow)" }}>
                  <h4 className="thhead">Residual Evaluation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_residual_evaluation", e)
                    }
                    value={filters.risk_residual_evaluation}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluedark)" }}>
                  <h4 className="thhead">Step Details</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("audit_procedure_step", e)
                    }
                    value={filters.audit_procedure_step}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluedark)" }}>
                  <h4 className="thhead">Sample Size</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("audit_procedure_step", e)
                    }
                    value={filters.sample_size}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluedark)" }}>
                  <h4 className="thhead">Sample Rationale</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("sample_rationale", e)}
                    value={filters.sample_rationale}
                  />
                </th>

                <th style={{ backgroundColor: "var(--rcm_bluedark)" }}>
                  <h4 className="thhead"> Data Required</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("audit_procedure_data", e)
                    }
                    value={filters.audit_procedure_data}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr style={{ display: showNewRow ? "" : "none" }}>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                    }}
                  >
                    <Button
                      buttonName="Save"
                      buttonColor="#4CAF50"
                      onClick={() => addRCap()}
                    />

                    <Button
                      buttonName="Close"
                      buttonColor="#4CAF50"
                      onClick={closeCreate}
                    />
                  </div>
                </td>

                <td>
                  <div>
                    {console.log(businessUnitOptions)}
                    <select
                      id="user-dropdown"
                      style={{ width: "auto" }}
                      onChange={handleChangea}
                    >
                      <option value="">--Select Business Unit--</option>
                      {businessUnitOptions.map((user) => (
                        <option
                          key={user.id}
                          value={
                            AuditExecution ? user.business_units_id : user.id
                          }
                        >
                          {user.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Process"
                    value={newRow.process}
                    onChange={(e) => {
                      setNewRow({ ...newRow, process: e.target.value });
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Sub Process"
                    style={{ width: "300px" }}
                    value={newRow.sub_process}
                    onChange={(e) => {
                      setNewRow({ ...newRow, sub_process: e.target.value });
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Risk Id"
                    style={{
                      border: riskIdError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.risk_id}
                    onChange={(e) => {
                      setNewRow({ ...newRow, risk_id: e.target.value });
                      setRiskIdError(false);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Unauthorized Access to Financial Data"
                    style={{
                      width: "400px",
                      border: objectiveError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.objective}
                    onChange={(e) => {
                      setNewRow({ ...newRow, objective: e.target.value });
                      setObjectiveError(false);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Unauthorized Access to Financial Data"
                    style={{
                      width: "400px",
                      border: titleRiskError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.risk_title}
                    onChange={(e) => {
                      setNewRow({ ...newRow, risk_title: e.target.value });
                      setTitleRiskError(false);
                    }}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="Select Category"
                    inputStyle={{
                      width: "200px",
                      border: riskCategoryError ? "1px solid #FF4646" : "",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    inputValue={newRow.risk_category}
                    onInputChange={(e) => {
                      setNewRow({ ...newRow, risk_category: e.target.value });
                      setRiskCategoryError(false);
                    }}
                    options={Category}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Describe Risk"
                    style={{
                      width: "400px",
                      border: describeRiskError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.risk_description}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        risk_description: e.target.value,
                      });
                    }}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="Select Likelihood"
                    inputStyle={{
                      width: "150px",
                      border: likelikhoodError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.inherent_assessment_likelihood}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        inherent_assessment_likelihood: e.target.value,
                      });
                      setLikelikhoodError(false);
                    }}
                    options={inherentAssessmentLikeOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="Select Impact"
                    inputStyle={{
                      width: "150px",
                      border: impactError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.inherent_assessment_impact}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        inherent_assessment_impact: e.target.value,
                      });
                      setImpactError(false);
                    }}
                    options={inherentAssessmentImpactOptions}
                  />
                </td>
                <td></td>
                <td></td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Control Description"
                    style={{
                      width: "600px",
                      border: existingControlError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.control_description}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        control_description: e.target.value,
                      });
                      setExistingControl(e.target.value);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Control Desired"
                    style={{
                      width: "600px",
                      border: desiredControlError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.control_desired}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        control_desired: e.target.value,
                      });
                      setDesiredControl(e.target.value);
                    }}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="Select Design"
                    inputStyle={{
                      width: "150px",
                      border: controlDesignError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.control_design}
                    onInputChange={(e) => {
                      setNewRow({ ...newRow, control_design: e.target.value });
                    }}
                    options={controlDesignOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="Select Type"
                    inputStyle={{
                      width: "150px",
                      border: controlTypeError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.control_type}
                    onInputChange={(e) => {
                      setNewRow({ ...newRow, control_type: e.target.value });
                    }}
                    options={controlTypeOptions}
                  />
                </td>
                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder=" Select Frequency"
                    inputStyle={{
                      width: "150px",
                      border: evaluationError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.frequency}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        frequency: e.target.value,
                      });
                    }}
                    options={controlFrequencyOptions}
                  />
                </td>
                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="Select Design Effectiveness"
                    inputStyle={{
                      width: "150px",
                      border: controlDesignEffectiveError
                        ? "1px solid #FF4646"
                        : "",
                    }}
                    inputValue={newRow.control_design_effectiveness}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        control_design_effectiveness: e.target.value,
                      });
                      setControlDesignEffectiveError(false);
                    }}
                    options={controldesigneffectiveOptions}
                  />
                </td>
                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="Select Operating Effectiveness"
                    inputStyle={{
                      width: "150px",
                      border: controlOperatingError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.control_operating_effectiveness}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        control_operating_effectiveness: e.target.value,
                      });
                      setControlOperatingError(false);
                    }}
                    options={controloperatingOptions}
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <textarea
                    type="text"
                    placeholder="Audit Step Details"
                    style={{
                      width: "600px",
                      border: auditStepDetailsError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.audit_procedure_step}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        audit_procedure_step: e.target.value,
                      });
                      setAuditStepDetailsError(false);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Sample Size"
                    style={{
                      width: "200px",
                      padding: "22px",
                      // border: auditStepDetailsError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.sample_size}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        sample_size: e.target.value,
                      });
                      // setAuditStepDetailsError(false);
                    }}
                  />
                </td>
                <td>
                  <textarea
                    type="text"
                    placeholder="Sample Rationale"
                    style={{
                      width: "600px",
                      // border: auditStepDetailsError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.sample_rationale}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        sample_rationale: e.target.value,
                      });
                      // setAuditStepDetailsError(false);
                    }}
                  />
                </td>
                <td>
                  <textarea
                    type="text"
                    placeholder="Documentation and recordkeeping procedures documentation"
                    style={{
                      width: "600px",
                      border: dataRequiredError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.audit_procedure_data}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        audit_procedure_data: e.target.value,
                      });
                      setDataRequired(e.target.value);
                    }}
                  />
                </td>
              </tr>

              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>
              ) : filteredData.length === 0 && !showNewRow ? (
                <p className="data-error-text">No data found</p>
              ) : (
                filteredData
                  .sort((a, b) => {
                    const riskIdA = a.risk_id || "";
                    const riskIdB = b.risk_id || "";
                    return riskIdA.localeCompare(riskIdB);
                  })
                  .map((data, index) => (
                    <tr
                      key={data.id}
                      onDoubleClick={() => handleEditClick(index, data)}
                    >
                      {editingRowIndex === index ? (
                        <>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "1vw",
                              }}
                            >
                              <Button
                                buttonName="Save"
                                buttonColor="#4CAF50"
                                onClick={() => handleDoneClick(data.id)}
                                tabIndex={1}
                                ref={firstInputRef}
                              />

                              <Button
                                buttonName="Close"
                                buttonColor="#4CAF50"
                                onClick={closeEdit}
                                tabIndex={2}
                              />
                            </div>
                          </td>
                          {AuditExecution && (
                            <td>
                              <input
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "32px",
                                }}
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                tabIndex={3}
                              />
                            </td>
                          )}
                          <td>
                            <div>
                              <select
                                id="user-dropdown"
                                onChange={handleChangea}
                                tabIndex={4}
                                style={{ width: "auto" }}
                              >
                                <option value="">
                                  {AuditExecution
                                    ? data?.business_unit?.name ||
                                      "--Select Business Unit--"
                                    : data.business_unit?.name ||
                                      "--Select Business Unit--"}
                                </option>

                                {AuditExecution
                                  ? businessUnitOptions
                                      .filter(
                                        (user) =>
                                          user?.business_units_id !==
                                          data?.business_unit?.id
                                      )
                                      .map((user) => (
                                        <option
                                          key={user.business_units_id}
                                          value={user.business_units_id}
                                        >
                                          {user.name}
                                        </option>
                                      ))
                                  : businessUnitOptions
                                      .filter(
                                        (user) =>
                                          user?.id !== data?.business_unit?.id
                                      )
                                      .map((user) => (
                                        <option key={user.id} value={user.id}>
                                          {user.name}
                                        </option>
                                      ))}
                              </select>
                            </div>
                          </td>
                          <td>
                            <textarea
                              type="text"
                              value={editedRowData.process}
                              onChange={(e) => handleInputChange(e, "process")}
                              tabIndex={5}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              value={editedRowData.sub_process}
                              onChange={(e) =>
                                handleInputChange(e, "sub_process")
                              }
                              style={{ width: "300px" }}
                              tabIndex={6}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                border: riskIdError ? "1px solid #FF4646" : "",
                              }}
                              value={editedRowData.risk_id}
                              onChange={(e) => {
                                handleInputChange(e, "risk_id");
                                setRiskIdError(false);
                              }}
                              tabIndex={7}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: "400px",
                                border: objectiveError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.objective}
                              onChange={(e) => {
                                handleInputChange(e, "objective");
                                setObjectiveError(false);
                              }}
                              tabIndex={8}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: "400px",
                                border: titleRiskError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.risk_title}
                              onChange={(e) => {
                                handleInputChange(e, "risk_title");
                                setTitleRiskError(false);
                              }}
                              tabIndex={9}
                            />
                          </td>

                          <td>
                            <OptionDropDown
                              inputValue={editedRowData.risk_category}
                              onInputChange={(e) =>
                                handleInputChange(e, "risk_category")
                              }
                              options={Category}
                              inputStyle={{
                                width: "200px",
                                border: riskCategoryError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              tabIndex={10}
                            />
                          </td>

                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: "400px",
                                border: describeRiskError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.risk_description}
                              onChange={(e) =>
                                handleInputChange(e, "risk_description")
                              }
                              tabIndex={11}
                            />
                          </td>
                          <td>
                            <OptionDropDown
                              inputValue={
                                editedRowData.inherent_assessment_likelihood
                              }
                              onInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  "inherent_assessment_likelihood"
                                )
                              }
                              options={inherentAssessmentLikeOptions}
                              inputStyle={{
                                width: "150px",
                                border: likelikhoodError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              tabIndex={12}
                            />
                          </td>

                          <td>
                            <OptionDropDown
                              inputValue={
                                editedRowData.inherent_assessment_impact
                              }
                              onInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  "inherent_assessment_impact"
                                )
                              }
                              options={inherentAssessmentLikeOptions}
                              inputStyle={{
                                width: "150px",
                                border: impactError ? "1px solid #FF4646" : "",
                              }}
                              tabIndex={13}
                            />
                          </td>
                          <td></td>
                          <td></td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: "600px",
                                border: existingControlError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.control_description}
                              onChange={(e) =>
                                handleInputChange(e, "control_description")
                              }
                              tabIndex={14}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: "600px",
                                border: desiredControlError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.control_desired}
                              onChange={(e) =>
                                handleInputChange(e, "control_desired")
                              }
                              tabIndex={15}
                            />
                          </td>

                          <td>
                            <OptionDropDown
                              inputValue={editedRowData.control_design}
                              onInputChange={(e) =>
                                handleInputChange(e, "control_design")
                              }
                              options={controlDesignOptions}
                              inputStyle={{
                                width: "150px",
                                border: controlDesignError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              tabIndex={16}
                            />
                          </td>
                          <td>
                            <OptionDropDown
                              inputValue={editedRowData.control_type}
                              onInputChange={(e) =>
                                handleInputChange(e, "control_type")
                              }
                              options={controlTypeOptions}
                              inputStyle={{
                                width: "150px",
                                border: controlTypeError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              tabIndex={17}
                            />
                          </td>
                          <td>
                            <OptionDropDown
                              inputValue={editedRowData.frequency}
                              onInputChange={(e) =>
                                handleInputChange(e, "frequency")
                              }
                              options={controlFrequencyOptions}
                              inputStyle={{
                                width: "150px",
                                border: evaluationError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              tabIndex={18}
                            />
                          </td>

                          <td>
                            <OptionDropDown
                              inputValue={
                                editedRowData.control_design_effectiveness
                              }
                              onInputChange={(e) => {
                                handleInputChange(
                                  e,
                                  "control_design_effectiveness"
                                );
                                setControlDesignEffectiveError(false);
                              }}
                              options={controldesigneffectiveOptions}
                              inputStyle={{
                                width: "150px",
                                border: controlDesignEffectiveError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              tabIndex={19}
                            />
                          </td>
                          <td>
                            <OptionDropDown
                              // label="Risk Category"
                              inputValue={
                                editedRowData.control_operating_effectiveness
                              }
                              onInputChange={(e) => {
                                handleInputChange(
                                  e,
                                  "control_operating_effectiveness"
                                );
                                setControlOperatingError(false);
                              }}
                              options={controloperatingOptions}
                              inputStyle={{
                                width: "150px",
                                border: controlOperatingError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              tabIndex={20}
                            />
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: "600px",
                                border: auditStepDetailsError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.audit_procedure_step}
                              onChange={(e) => {
                                handleInputChange(e, "audit_procedure_step");
                                setAuditStepDetailsError(false);
                              }}
                              tabIndex={21}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={editedRowData.sample_size}
                              onChange={(e) =>
                                handleInputChange(e, "sample_size")
                              }
                              style={{
                                width: "200px",
                                padding: "22px",
                              }}
                              tabIndex={22}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: "600px",
                                border: dataRequiredError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.sample_rationale}
                              onChange={(e) =>
                                handleInputChange(e, "sample_rationale")
                              }
                              tabIndex={23}
                            />
                          </td>
                          <td>
                            <textarea
                              type="type"
                              style={{
                                width: "600px",
                                border: dataRequiredError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.audit_procedure_data}
                              onChange={(e) =>
                                handleInputChange(e, "audit_procedure_data")
                              }
                              tabIndex={24}
                              ref={lastInputRef}
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            {" "}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "1vw",
                                position: "sticky",
                                left: "0",
                                zIndex: 0,
                              }}
                            >
                              <button
                                style={{
                                  background: "transparent",
                                  border: "none",
                                }}
                                onClick={toggleWindow}
                              >
                                <img
                                  src={deleteBtn}
                                  style={{ width: "24px" }}
                                />
                              </button>

                              <Button
                                buttonName="Edit"
                                buttonColor="#4CAF50"
                                onClick={() => handleEditClick(index, data)}
                              />
                            </div>
                          </td>
                          {editingRowIndex !== null && AuditExecution && (
                            <td>
                              <p style={{ maxWidth: "20px" }}> </p>
                            </td>
                          )}

                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {AuditExecution
                                ? data?.business_unit
                                  ? data?.business_unit?.name
                                  : "All Functions"
                                : data?.business_unit
                                ? data?.business_unit?.name
                                : "All Functions"}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>{data.process}</p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "300px" }}>
                              {data.sub_process}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "100px" }}>{data.risk_id}</p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "400px" }}>
                              {data.objective}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "400px" }}>
                              {data.risk_title}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {data.risk_category}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "400px" }}>
                              {data.risk_description}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "150px" }}>
                              {likemapping(data.inherent_assessment_likelihood)}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "150px" }}>
                              {impactmapping(data.inherent_assessment_impact)}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "150px" }}>
                              {data.risk_inherent_evaluation_score}
                            </p>
                          </td>
                          <td>
                            <p
                              style={{
                                maxWidth: "200px",
                                backgroundColor: getBackgroundColor(
                                  data.risk_inherent_evaluation
                                ),
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "10px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                textWrap: "nowrap",
                                fontWeight: "bold",
                              }}
                            >
                              {data.risk_inherent_evaluation}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "600px" }}>
                              {data.control_description}
                            </p>
                          </td>
                          <td>
                            <pre style={{ maxWidth: "600px" }}>
                              {data.control_desired}
                            </pre>
                          </td>
                          <td>
                            <p style={{ maxWidth: "150px" }}>
                              {data.control_design}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "150px" }}>
                              {data.control_type}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "150px" }}>
                              {data.frequency}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "150px" }}>
                              {controldesignmapping(
                                data.control_design_effectiveness
                              )}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "150px" }}>
                              {controldesignmapping(
                                data.control_operating_effectiveness
                              )}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "150px" }}>
                              {data.control_score}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "150px" }}>
                              {data.control_evaluation}
                            </p>
                          </td>
                          <td>
                            <p
                              style={{
                                maxWidth: "200px",
                                backgroundColor: getBackgroundColor(
                                  data.risk_inherent_evaluation
                                ),
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "10px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                textWrap: "nowrap",
                                fontWeight: "bold",
                              }}
                            >
                              {data.evaluate_risk}
                            </p>
                          </td>
                          <td>
                            <pre style={{ maxWidth: "600px" }}>
                              {data.audit_procedure_step}
                            </pre>
                          </td>
                          <td>
                            <pre
                              style={{ maxWidth: "200px", textAlign: "center" }}
                            >
                              {data.sample_size}
                            </pre>
                          </td>
                          <td>
                            <pre style={{ maxWidth: "600px" }}>
                              {data.sample_rationale}
                            </pre>
                          </td>
                          <td>
                            <pre style={{ maxWidth: "600px" }}>
                              {data.audit_procedure_data}
                            </pre>
                          </td>
                        </>
                      )}
                      {showWindow && (
                        <div className="window">
                          Are you sure you want to delete?
                          <div className="btn-option">
                            <div
                              className="cmn_btn"
                              style={{ backgroundColor: "#ff4646" }}
                              onClick={() => handleDelete(data.id)}
                            >
                              Delete
                            </div>

                            <div
                              className="cmn_btn"
                              style={{ backgroundColor: "#ff4646" }}
                              onClick={handleClose}
                            >
                              Close
                            </div>
                          </div>
                        </div>
                      )}
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>

        <div className="cardscontainer">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Risk ID
                </th>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Business Unit
                </th>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Process
                </th>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Sub Process
                </th>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Objective
                </th>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Risk Title
                </th>
              </tr>
            </thead>

            {/* Table Body (rows) */}
            <tbody>
              {filteredData
                .sort((u, v) => u.id - v.id)
                .map((data) => (
                  <tr
                    onClick={() => Viewuser(data)}
                    key={data.id}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <td
                      style={{
                        padding: "12px 15px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.risk_id}
                    </td>
                    <td
                      style={{
                        padding: "12px 15px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.rcap?.business_unit
                        ? data?.rcap?.business_unit?.name
                        : "All Functions"}
                    </td>
                    <td
                      style={{
                        padding: "12px 15px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.process}
                    </td>
                    <td
                      style={{
                        padding: "12px 15px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.sub_process}
                    </td>
                    <td
                      style={{
                        padding: "12px 15px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      <p
                        style={{
                          width: "200px",
                          whiteSpace: "normal",
                        }}
                      >
                        {data?.objective}
                      </p>
                    </td>
                    <td
                      style={{
                        padding: "12px 15px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.risk_title}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default ListOfAllRcapPage;
