import React, { useEffect } from 'react';
import AllRoutes from './AllRoutes';
import './App.css';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error });
    console.error('Error captured in ErrorBoundary:', error);
  }

  render() {
    if (this.state.hasError) {
      const styles = {
        container: {
          textAlign: "center", 
          padding: "50px", 
          fontFamily: "Arial, sans-serif", 
          width: "100vw", 
          position: "absolute",
          left: 0, 
          height: "100vh", 
          backgroundColor: "white",
          display: "flex", 
          alignItems: "center", 
          justifyContent: "center", 
          flexDirection: "column", 
        },
        content: {
          textAlign: "center",
          padding: "40px",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        },
        header: {
          fontSize: "56px",
          color: "#333",
          marginBottom: "16px",
          color : "red"
        },
        message: {
          fontSize: "22px",
          color: "#555",
          marginBottom: "24px",
        },
        buttons: {
          display: "flex",
          justifyContent: "center",
          gap: "16px",
        },
        button: {
          padding: "12px 24px",
          fontSize: "16px",
          cursor: "pointer",
          border: "none",
          borderRadius: "4px",
          backgroundColor: "#007bff",
          color: "#fff",
          transition: "background-color 0.3s",
        },
      };

      const handleHomeClick = () => {
        window.location.href = '/'; 
      };

      const handleGoBackClick = () => {
  window.history.back(); 
  setTimeout(() => {
    window.location.reload(); 
  }, 100);
};


      return (
        <div style={styles.container}>
          <div style={styles.content}>
            <h1 style={styles.header}>Oops! Something Went Wrong</h1>
            <p style={styles.message}>
              We encountered an unexpected issue. Please try again later.
            </p>
            <div style={styles.buttons}>
              <button
                className="cmn_btn"
                style={{ backgroundColor: "#65cc5c" }}
                onClick={handleHomeClick}  // Add click handler for Home
              >
                Home
              </button>
              <button
                className="cmn_btn"
                style={{ backgroundColor: "#5771df" }}
                onClick={handleGoBackClick}  // Add click handler for Go Back
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

function App() {
  useEffect(() => {
    const interval = setInterval(async () => {
      const storedDateTime = localStorage.getItem('loginTime');
      const currentDate = new Date();
      const storedDateObject = new Date(storedDateTime);
      const timeDifference = currentDate - storedDateObject;
      const expTime = 48 * 60 * 60 * 1000; // 48 hours

      if (timeDifference > expTime) {
        console.log('Session expired. Logging out...');
        localStorage.clear();
        window.location.href = '/login';
      } else {
        const storedRefreshToken = localStorage.getItem('refresh');
        if (storedRefreshToken) {
          try {
            const refreshResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/api/token/refresh/`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh: storedRefreshToken }),
              }
            );

            if (!refreshResponse.ok) {
              throw new Error('Failed to refresh token');
            }

            const refreshData = await refreshResponse.json();
            const { access: newAccessToken } = refreshData;
            localStorage.setItem('token', newAccessToken);
          } catch (error) {
            console.error('Error refreshing token:', error.message);
            localStorage.clear();
            window.location.href = '/login';
          }
        } else {
          console.log('No refresh token found in localStorage. Unable to refresh token.');
        }
      }
    }, 24 * 60 * 60 * 1000); // 24 hours

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <ErrorBoundary>
      <AllRoutes />
    </ErrorBoundary>
  );
}

export default App;
