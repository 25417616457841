import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const IssueRatingPieChart = ({ data }) => {
  // Destructure the data prop to get rating counts
  const { low, medium, high, veryHigh, critical } = data;

  // Prepare the pie chart data based on the passed data
  const pieData = [
    { name: 'Low', value: low },
    { name: 'Medium', value: medium },
    { name: 'High', value: high },
    { name: 'Very High', value: veryHigh },
    { name: 'Critical', value: critical },
  ];

  // Check if all values are zero
  const isAllZero = pieData.every(entry => entry.value === 0);
  if (isAllZero) {
    return <h3 style={{ textAlign: 'center' }}>There is no chart to show</h3>;
  }

  // Colors for the Pie segments
  const COLORS = ['#82ca9d', '#8884d8', '#ff8042', '#ffbb28', '#ff6361'];

  return (
    <ResponsiveContainer width="80%" height="80%">
      <PieChart>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="name"
          outerRadius={80}
          fill="#8884d8"
          label
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default IssueRatingPieChart;
