import React, { useState, useRef, useEffect } from "react";
import "./CustomSelect.css";
import arrowDown from "../../../images/toggle_side_down.svg";

const CustomSelect = ({
  options,
  placeholder = "Select an option",
  onChange,
  value,
  isClearable = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    if (onChange) onChange(option);
    setIsOpen(false);
  };

  const handleClear = (event) => {
    event.stopPropagation(); // Prevent dropdown from toggling
    if (onChange) onChange(null); // Pass `null` to clear the value
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const selectedLabel = value ? value.label : "";

  return (
    <div className="custom-select" ref={dropdownRef}>
      <div className="custom-select__control" style={{padding:selectedLabel?"3px 12px" :"8px 12px" }} onClick={toggleDropdown}>
      {selectedLabel ? (
          <div className="custom_tag">
            {selectedLabel}
            <button
              onClick={handleClear}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L10.9989 10.9989"
                  stroke="#fff"
                  strokeWidth="1.76758"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00107 10.9989L11 1"
                  stroke="#fff"
                  strokeWidth="1.76758"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        ) : (
          <span>--</span>
        )}
        <span className={`custom-select__arrow ${isOpen ? "open" : ""}`}>
          <img src={arrowDown} />
        </span>
      </div>

      {isOpen && (
        <div className="custom-select__dropdown">
          {options.map((option) => (
            <div
              key={option.value}
              className={`custom-select__option ${
                value?.value === option.value ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
