import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchDataV2, justFetchDataV2, updateDataV2, convertDate } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "../auditplan/auditplan.css";
import ProgressBar from "../progressbar/ProgressBar";
import "../auditfieldwork/auditfieldwork.css";

import pencil from "../../../images/editpencil_wbg.svg";
import deleteicon from "../../../images/delete_wbg.svg";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import ReactPaginate from "react-paginate";

const AuditFieldwork = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const [showFielworkPopup, setShowFieldworkPopup] = useState(false);
  const originalUrl = location.state?.from;
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 11; 

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAuditData,
      setErrors: setErrors
    }
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/workpaper/?audit_id=${id}`,
      setterFunction: (data) => { setAPIData(data) },
      setErrors: (error) => { setErrors(error) },
      setLoading: setLoading,
    }

    justFetchDataV2(parameter);
  }, []);


  useEffect(() => {
    setAreAllTasksDone(APIData.every(task => task.status === "Completed"));

  }, [APIData]);


  const Viewuser = (data) => {
    const workId = data;
    navigate(`/audit-execution/${id}/fieldwork/${workId}`);
  };

  const handlePopupFiledworkCloseNo = () => {
    setShowFieldworkPopup(false);
  };

  const [filters, setFilters] = useState({
    status: "",
    workpaper_id: "",
    title: "",
    preparer: "",
    reviewer: "",
    created_at: "",
    risk_title:'',
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const filteredData = APIData.filter((data) => {
    const { status, task, workpaper_id, objective, preparer, reviewer, created_at,risk_title } =
      data;

    const matchesStatus = filters.status
      ? status &&
      status.toLowerCase().includes(filters.status.toLowerCase())
      : true;

    const matchesId = filters.workpaper_id
      ? (workpaper_id || "").toLowerCase().includes(filters.workpaper_id)
      : true;

    const matchesPreparer = filters?.preparer
      ? preparer &&
      preparer?.name?.toLowerCase().includes(filters?.preparer?.toLowerCase())
      : true;

    const matchesReviewer = filters?.reviewer
      ? reviewer &&
      reviewer?.name?.toLowerCase().includes(filters?.reviewer?.toLowerCase())
      : true;

    const matchesObjective = filters.objective
      ? (objective || "")
        .toLowerCase()
        .includes(filters.objective.toLowerCase())
      : true;
    const matchesTitle = filters.risk_title
      ? (risk_title || "")
        .toLowerCase()
        .includes(filters.risk_title.toLowerCase())
      : true;

    const matchesCreatedAt = filters.created_at
      ? (created_at || "").toString().includes(filters.created_at)
      : true;

    return (
      matchesId &&
      matchesStatus &&
      matchesPreparer &&
      matchesReviewer &&
      matchesObjective&&
      matchesTitle&&
      matchesCreatedAt
    );
  });

  const renderFieldWorkData = (data) => {
    return
  };


  const updateWithPhase = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];

    const Parameter = {
      url: `/api/audit_engagement/audit/date/${id}`,
      payload: {
        date_of: 'reporting',
        start_date: currentDate,
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/auditExecution/${id}/reporting/`,
    }
    updateDataV2(Parameter);

  };


  const phaseTrue = {
    phase: auditData.phase
  }
  const name = auditData?.name;

  const FieldworkStatusColor = (status) => {
    if (status === "Open") {
      return <span className="status_todo">{status}</span>;
    } else if (status === "Completed") {
      return <span className="status_complete">{status}</span>;
    } else {
      return <span className="status_default">{status}</span>; // Default case
    }
  };


  // Pagination Logic
const pageCount = Math.ceil(filteredData.length / itemsPerPage);
const startOffset = currentPage * itemsPerPage;
const paginatedData = filteredData.slice(
  startOffset,
  startOffset + itemsPerPage
);

const handlePageClick = ({ selected }) => {
  setCurrentPage(selected);
};


  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}
      {showFielworkPopup && (
        <div className="popupATP" style={{ width: "600px" }}>
          <div className="subpopupATP">
            <p style={{ wordWrap: "wrap", textAlign: "center" }}>
              Fieldwork phase will be completed, and Reporting phase will be
              started. Please confirm?
            </p>
            <div className="subpopupBtnDiv">
              <button onClick={(e) => updateWithPhase(e)}>Yes</button>
              <button onClick={handlePopupFiledworkCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />

        <NavRow
          parentName={name}
          name={name}
          Create={"Create"}
          link={
            auditData.phase === "Planning Complete" ||
            auditData.phase === "Fieldwork"
              ? `/audit-execution/${id}/fieldwork/create-workpaper`
              : null
          }
          trueback={true}
          phaseTrue={phaseTrue}
          backToExecution={`/audit-execution`}
        />

        <div
          className="fieldwork_table_con"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <table>
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead"> UID </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("workpaper_id", e)}
                    value={filters.workpaper_id}
                  />
                </th>

                <th>
                  <h4 className="thhead"> Title</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_title", e)}
                    value={filters.risk_title}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Objective</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("objective", e)}
                    value={filters.objective}
                  />
                </th>
                <th>
                  <h4 className="thhead">Status</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("status", e)}
                    value={filters.status}
                  />
                </th>

                <th>
                  <h4 className="thhead">Preparer</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("preparer", e)}
                    value={filters.preparer}
                  />
                </th>

                <th>
                  <h4 className="thhead">Reviewer</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("reviewer", e)}
                    value={filters.reviewer}
                  />
                </th>

                <th>
                  <h4 className="thhead">Created At</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("created_at", e)}
                    value={filters.created_at}
                  />
                </th>
              </tr>
            </thead>
            {auditData.phase !== "Open" && auditData.phase !== "Planning" ? (
              <tbody>
                {loading ? (
                  <div className="spinner-bounce-loader">
                    <BounceLoader color="#a6fafa" />
                  </div>
                ) : paginatedData.length === 0 ? (
                  <p className="data-error-text">No data found</p>
                ) : (
                  paginatedData
                    .sort((u, v) => u.id - v.id)
                    .map((data) => (
                      <tr onClick={() => Viewuser(data.id)} key={data.id}>
                        <td>
                          {" "}
                          <span>{data.workpaper_id}</span>{" "}
                        </td>
                        <td>
                          <span>
                            {data.risk_title
                              ? data.risk_title.length > 35
                                ? `${data.risk_title.substring(0, 35)}...`
                                : data.risk_title
                              : data.title.length > 35
                              ? `${data.title.substring(0, 35)}...`
                              : data.title}
                          </span>
                        </td>
                        <td>
                          <span>
                            {data.objective.length > 35
                              ? `${data.objective.substring(0, 35)}...`
                              : data.objective}
                          </span>
                        </td>
                        <td>{FieldworkStatusColor(data.status)}</td>
                        <td>{data?.preparer ? data?.preparer?.name : ""}</td>
                        <td>{data?.reviewer ? data?.reviewer?.name : ""}</td>
                        <td>{convertDate(data.created_at)}</td>
                      </tr>
                    ))
                )}
              </tbody>
            ) : (
              <p className="data-error-empty-text">
                Planning Must Be Completed
              </p>
            )}
          </table>

          <div className="button_container">
            {auditData.phase === "Planning Complete" &&
              filteredData.length !== 0 && (
                <button
                  className="cmn_btn"
                  style={{
                    backgroundColor: !APIData.every(
                      (task) => task.status === "Completed"
                    )
                      ? "grey"
                      : "#5757df",
                  }}
                  onClick={() => setShowFieldworkPopup(true)}
                  disabled={
                    !APIData.every((task) => task.status === "Completed")
                  }
                >
                  Start Reporting
                </button>
              )}
          </div>
        </div>

        <div className="cardscontainer">
          {paginatedData.map((data) => (
            <div
              className="deptcard"
              onClick={() => Viewuser(data.id)}
              key={data.id}
            >
              <div className="bunstkhlder">
                {console.log(data)}
                <h2>
                  {data.risk_title ? (
                    data.risk_title
                  ) : (
                    <p className="buplacehldr"></p>
                  )}
                </h2>
              </div>

              <div className="deptnauthnbtn">
                <h5>
                  {data?.preparer?.name ? (
                    data?.preparer?.name
                  ) : (
                    <p className="buplacehldr"></p>
                  )}
                </h5>
                <h6>
                  <p className="buplacehldr">Bu ID - </p>
                  <p className="buplacehldr">{data.id ? data.id : "Bu ID"}</p>
                </h6>
              </div>
              {/* <img src={openarrow} alt="Open Arrow" /> */}
            </div>
          ))}
        </div>

        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination-container"
          activeClassName={"active"}
        />
      </section>
    </>
  );
};

export default AuditFieldwork;
