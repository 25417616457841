import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../css/viewuser.css";
import "../issuespage/issues.css";
import { fetchDataV2, postDataV2 } from "../../apiUtils";
import { issueTracking } from "../../subroutes";
import SingleOptionSelector from "../../SingleOptionSelector";
import AuditReportingSeclector from "../../auditExecution/auditReporting/AuditReportingSelector";
import savesubObv from "../../../images/saveBtn.svg";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import OptionDropDown2 from "../../OptionDropDown2";

const Issuedetailpage = ({ tableSize }) => {
  const [APIQData, setAPIQData] = useState("");
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [selectedObservationId, setSelectedObservationId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [assign_to_1_id, setAssign_to_id] = useState("");
  const [assign_to_idError, setAssign_to_idError] = useState(false);
  const [selectAuditId, setSelectAuditId] = useState("");
  const [selectAuditIdError, setSelectAuditIdError] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const successMessage = location.state?.successMessage;
  const { id } = useParams();
  const [sub_observation, setSubObservations] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [summary, setSummary] = useState("");
  const [objective, setTitle] = useState("");
  const [auditData, setAuditData] = useState([]);
  const [rating, setObservationRating] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [management_response, setActionPlan] = useState("");
  const [owner_id, setOwner] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState("");
  const [assign_to_2_id, setLevel2Owner] = useState("");
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [management_response_due_date, setActionDueDate] = useState("");
  const [response_audit, setAuditResponse] = useState("");
  const [response_audit_due_date, setResponseActionDueDate] = useState("");
  const [short_description, setShortDescription] = useState("");
  const [observationDetails, setObservationDetails] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [taskErrors, setTaskErrors] = useState("");
  const [inputList, setInputList] = useState([]);
  const [maketrue, setTrue] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const RatingOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
    { label: "Very High", value: "Very High" },
    { label: "Critical", value: "Critical" },
  ];

  useEffect(() => {
    setEditMode(false);
    console.log(selectAuditId);
    if (selectAuditId) {
      const parameter = {
        url: `/api/audit_engagement/observation/?audit_id=${selectAuditId}&status=Published`,
        setterFunction: setAPIData,
        setErrors: setErrors,
      };
      fetchDataV2(parameter);
    }
  }, [selectAuditId]);

  useEffect(() => {
    if (selectedObservationId) {
      const parameter = {
        url: `/api/audit_engagement/observation/${selectedObservationId}/`,
        setterFunction: setObservationDetails,
        setErrors: setErrors,
      };
      fetchDataV2(parameter);
    }
  }, [selectedObservationId]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/`,
      setterFunction: setAuditData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    if (observationDetails) {
      setTitle(observationDetails?.objective);

      setObservationRating(observationDetails?.rating);
      setShortDescription(observationDetails?.short_description);

      setAPIQData(observationDetails?.task?.id);
      setStartdate(observationDetails.due_date);
      setActionPlan(observationDetails?.action_plan);
      setActionDueDate(observationDetails?.management_response_due_date);
      setSummary(observationDetails?.summary);
      setRecommendation(observationDetails.recommendation);
      setAuditResponse(observationDetails?.response_audit);
      setResponseActionDueDate(observationDetails?.response_audit_due_date);
      setSubObservations(observationDetails?.data?.data?.sub_observations);
      setSubObservations(observationDetails?.data?.subObservations || []);
      if (
        APIData.task?.status === "review" ||
        APIData.task?.status === "done" ||
        APIData.task?.status === "todo" ||
        APIData.task?.status === "rejected"
      ) {
        setEditMode(false);
      }
    }
  }, [observationDetails]);

  const [taskTitle, setTaskTitle] = useState("");

  useEffect(() => {
    if (APIData) {
      setAPIQData(APIData?.task?.id);
      setTaskTitle(APIData?.task?.title);
    }
  }, [APIData]);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });
  const handleSelectionChange = (id) => {
    setSelectedObservationId(id);
  };

  const CreateIssue = () => {
    const papameter = {
      url: `/api/issues/`,
      payload: {
        objective,
        short_description,
        owner_id,
        assign_to_1_id,
        management_response_due_date,
        response_audit,
        response_audit_due_date,
        sub_observation,
        management_response,
        assign_to_2_id,
        observation_id: parseInt(selectedObservationId),
        rating,
        summary,
        audit_id: parseInt(selectAuditId),
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/issue-tracking/issues`,
    };
    postDataV2(papameter);
  };

  function convertDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate() - 15);
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    }
  }

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (id) => {
    handleSelectionChange(id);
    setIsOpen(false);
  };

  const selectedObservation = APIData.find(
    (obs) => obs.id === selectedObservationId
  );

  console.log("selectedObservationId:", selectedObservationId);
  console.log("APIData:", APIData);
  console.log("selectedObservation:", selectedObservation);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={issueTracking} />
        <NavRow name={"Create Issue"} idtrue={true} />

        <div
          className="issues_container"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <div className="issue_grid_container">
            <div className="issue_grid_item_span_2">
              <h4>Overview</h4>
              <form>
                <div className="lbl_inpt">
                  <label>Title</label>
                  <input
                    type="text"
                    placeholder="Title"
                    value={objective}
                    onChange={(e) => setTitle(e.target.value)}
                    tabIndex={1}
                  />
                </div>{" "}
                <SingleOptionSelector
                  apiUrl="/api/audit_engagement/audit/"
                  setSelectorId={setSelectAuditId}
                  setSelectorError={setSelectAuditIdError}
                  Name="name"
                  title="Select Audit"
                  editMode={!editMode}
                  setTrue={setTrue}
                  maketrue={maketrue}
                  tabIndex={2}
                />
                <div
                  className="lbl_txa"
                  onChange={handleSelectionChange}
                  value={selectedObservationId}
                >
                  <label> Select an observation </label>
                  <div
                    className="textarea_issue"
                    onClick={handleDropdownClick}
                    tabIndex={3}
                  >
                    <p style={{
                                fontSize: "12px",
                                fontFamily: "intersemibold",
                                color:selectedObservation? "#000" :"#5A5A5A"
                              }}>
                      {selectedObservation
                        ? `(${selectedObservation.id}): ${selectedObservation.summary}`
                        : "Choose an observation"}
                    </p>
                  </div>

                  {isOpen && (
                    <div
                      className="opt_drp_dwn"
                      style={{ minHeight: "50px", top: "105%" }}
                    >
                      <>
                        {selectAuditId ? (
                          APIData.map((observation) => (
                            <div
                              key={observation.id}
                              className="opt_drp_dwn_optns"
                              onClick={() => handleOptionClick(observation.id)}
                            >
                              <p style={{
                                fontSize: "12px",
                                fontFamily: "intersemibold",
                                color: "#000"
                              }}>
                                ({observation.id}): {observation.summary}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="slct_plchldr">
                            {" "}
                            Please Select Audit First
                          </p>
                        )}
                      </>
                    </div>
                  )}
                </div>
                <AuditReportingSeclector
                  apiUrl="/api/audit_universe/admin-audit-team/"
                  setSelectorId={setOwner}
                  setSelectorError={setLevel1OwnerError}
                  selectorError={level_1_ownerError}
                  Name="name"
                  title="Owner"
                  editMode={!editMode}
                  initialId={
                    observationDetails?.preparer
                      ? observationDetails?.preparer?.id
                      : ""
                  }
                  initialName={
                    observationDetails?.preparer
                      ? observationDetails?.preparer?.name
                      : ""
                  }
                  tabIndex={4}
                />
                <AuditReportingSeclector
                  apiUrl="/api/audit_universe/business-stakeholders/"
                  setSelectorId={setAssign_to_id}
                  setSelectorError={setAssign_to_idError}
                  selectorError={assign_to_idError}
                  Name="name"
                  title="Assign To"
                  editMode={!editMode}
                  initialId={
                    observationDetails?.assign_to
                      ? observationDetails?.assign_to.id
                      : ""
                  }
                  initialName={
                    observationDetails?.assign_to
                      ? observationDetails?.assign_to.name
                      : ""
                  }
                  tabIndex={5}
                />
              </form>
            </div>

            <div className="issue_grid_item_span_2">
              <h5>Issue Detail</h5>

              <form>
                <div className="lbl_txa">
                  <label>Issue description</label>
                  <textarea
                    type="text"
                    placeholder="Issue description"
                    value={summary}
                    // disabled={!editMode}
                    onChange={(e) => setSummary(e.target.value)}
                    tabIndex={6}
                  />
                </div>

                <div className="lbl_inpt">
                  <OptionDropDown2
                    tableSize={tableSize}
                    label="Issue Rating"
                    inputType="text"
                    inputPlaceholder="Title"
                    inputStyle={{
                      backgroundColor: "#EEF1FC",
                    }}
                    inputValue={rating}
                    onInputChange={(e) => {
                      setObservationRating(e.target.value);
                    }}
                    options={RatingOptions}
                    editMode={true}
                    tabIndex={7}
                  />
                </div>
              </form>
            </div>

            <div className="issue_grid_item_span_2">
              <h5>Management Response</h5>
              <form>
                <div className="lbl_txa">
                  <label>Action Plan</label>
                  <textarea
                    style={{
                      backgroundColor: "#EEF1FC",
                    }}
                    type="text"
                    placeholder="Action Plan"
                    value={management_response}
                    onChange={(e) => setActionPlan(e.target.value)}
                    tabIndex={8}
                  />
                </div>

                <div className="lbl_inpt">
                  <label>Due Date</label>
                  <input
                    type="date"
                    value={convertDate(management_response_due_date)}
                    onChange={(e) => setActionDueDate(e.target.value)}
                    style={{
                      backgroundColor: "#EEF1FC",
                      borderRadius: "5px",
                    }}
                    tabIndex={9}
                  />
                </div>

                <AuditReportingSeclector
                  apiUrl="/api/audit_universe/business-stakeholders/"
                  setSelectorId={setLevel2Owner}
                  setSelectorError={setLevel2OwnerError}
                  selectorError={level_2_ownerError}
                  Name="name"
                  title="Level 2 Owner"
                  editMode={!editMode}
                  initialId={
                    observationDetails?.secondary_assign_to
                      ? observationDetails?.secondary_assign_to?.id
                      : ""
                  }
                  initialName={
                    observationDetails?.secondary_assign_to
                      ? observationDetails?.secondary_assign_to?.name
                      : ""
                  }
                  tabIndex={10}
                />
              </form>
            </div>

            {/* <div className="issue_grid_item_span_2">
            <h5>Internal Audit Response</h5>

            <div className="lbl_txa">
              <label>Audit Response</label>
              <textarea
                type="text"
                placeholder="Internal Audit Response"
                value={response_audit}
                
                onChange={(e) => setAuditResponse(e.target.value)}
                style={{
                  backgroundColor: "#EEF1FC",
                }}
              />
            </div>

            <div className="lbl_inpt">
              <label>Due Date</label>
              <input
                type="date"
                value={convertDate(response_audit_due_date)}
              
                onChange={(e) => setResponseActionDueDate(e.target.value)}
                style={{
                  backgroundColor: "#EEF1FC", borderRadius: "5px",
                }}
              />
            </div>
          </div> */}
          </div>
          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={CreateIssue}
              tabIndex={11}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create Issue
            </button>
          </div>

          <div className="cardscontainer"></div>
        </div>
      </section>
    </>
  );
};

export default Issuedetailpage;
