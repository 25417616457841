import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../../../css/viewuser.css";
import { fetchDataV2 } from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import "../createtask/createtask.css";
import MultiOptionSelector from "../../../MultiOptionSelector";
import { postDataV2 } from "../../../apiUtils";
import SingleOptionSelector from "../../../SingleOptionSelector";

const CreateTask = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [audit_id, setAudit_id] = useState("");
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [reviewer_id, setReviewer_id] = useState("");
  const [preparer_id, setPreparer_id] = useState("");
  const [assign_to_id, setAssign_to_id] = useState("");
  const [scheduled_start_date, setScheduled_start_date] = useState("");
  const [scheduled_end_date, setScheduled_end_date] = useState("");

  const [titleError, setTitleError] = useState("");
  const [preparer_idError, setPreparer_idError] = useState("");
  const [assign_to_idError, setAssign_to_idError] = useState("");
  const [typeError, setTypeError] = useState("");

  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;

  const { id } = useParams();


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter={
      url:`/api/audit_engagement/audit/`,
      setterFunction:setAPIData,
      setErrors:setErrors,
  }
    fetchDataV2(parameter);
  }, []);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const renderIcons = () => {
    return auditExecution.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
        <NavLink
          to={`${route.path.replace(":id",  id)}`}
          key={index}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">
            {<img src={route.icon} alt="Dashboard Icon" />}
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const postApi = (e) => {
    e.preventDefault();

    if (!title) {
      setTitleError(!title ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const parameter ={
      url : `/api/audit_engagement/plan/`,
      payload : { 
        audit_id:id,
        type,
        title,
        reviewer_id,
        preparer_id,
        assign_to_id,
        scheduled_start_date,
        scheduled_end_date,
      },
      navigate : navigate,
      setErrors : setErrors,
      navUrl :  `/audit-execution/${id}/Planning`
    
    }

    postDataV2(parameter); 
  };

  const handleStartDateChange = (event) => {
    setScheduled_start_date(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setScheduled_end_date(event.target.value);
  };
  const [typeData, setTypeData] = useState([
    { id: "Task", name: "Task" },
    { id: "Event", name: "Event" },
    { id: "File Upload Request", name: "File Upload" },
    { id: "Kick Off Meeting", name: "Kick Off Meeting" },
    { id: "Audit Engagement Memo", name: "Audit Engagement Memo" },
    { id: "Risk Control Metrix", name: "Risk Control Metrix" },
  ]);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h3_interextrabold" >Audit Name</h3>
            </div>

            <div className="searchncbtn">
              <input type="text" placeholder="Search" className="searchbar" />
              <div className="riskcontrol_createuser"></div>

            </div>
          </div>
        </div>

        <hr className="breadcrumbabv" />

        <Breadcrumb />

        <div className="bumenucontainer">{renderIcons()}
        <button onClick={postApi}>create</button>
</div>

        <div
          className="createttask_con"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >
        
          <form action="">
            <div
              className="plan_title_con"
              style={{ width: tableSize ? "70vw" : "80vw" }}
            >
              <div className="plan_title_con_sel">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  className="create_plan_title"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
            </div>

            <div
              className="assign_to_con"
              style={{ width: tableSize ? "70vw" : "80vw" }}
            >
              <div className="label_mul_selector">
                <SingleOptionSelector
                  setSelectorId={setType}
                  setSelectorError={setTypeError}
                  selectorError={typeError}
                  title="Type"
                  Name="name"
                  typeData={typeData}
                />{" "}
              </div>
            </div>

            <div
              className="assign_to_con"
              style={{ width: tableSize ? "70vw" : "80vw" }}
            >
              <div className="label_mul_selector">
                <label> Start Date: </label>{" "}
                <input
                  type="datetime-local"
                  value={scheduled_start_date}
                  onChange={handleStartDateChange}
                />
              </div>

              <div className="label_mul_selector">
                <label>End Date</label>{" "}
                <input
                  type="datetime-local"
                  value={scheduled_end_date}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>

            <div
              className="assign_to_con"
              style={{ width: tableSize ? "70vw" : "80vw" }}
            >
              <div className="label_mul_selector">
                <SingleOptionSelector
                  apiUrl="/api/audit_universe/admin-audit-team/"
                  setSelectorId={setPreparer_id}
                  setSelectorError={setPreparer_idError}
                  selectorError={preparer_idError}
                  Name="name"
                  title="Preparer"
                />
              </div>

              <div className="label_mul_selector">
                <SingleOptionSelector
                  apiUrl="/api/audit_universe/admin-audit-team/"
                  setSelectorId={setReviewer_id}
                  setSelectorError={setAssign_to_idError}
                  selectorError={assign_to_idError}
                  Name="name"
                  title="Reviewer"
                />
              </div>
            </div>

            <div
              className="assign_to_con"
              style={{ width: tableSize ? "70vw" : "80vw" }}
            >
              <div className="label_mul_selector">
                <SingleOptionSelector
                  apiUrl="/api/audit_universe/business-stakeholders/"
                  setSelectorId={setAssign_to_id}
                  setSelectorError={setAssign_to_idError}
                  selectorError={assign_to_idError}
                  Name="name"
                  title="Assign to"
                />{" "}
              </div>
            </div>
          </form>
        </div>

        <div className="cardscontainer"></div>
      </section>
    </>
  );
};

export default CreateTask;
