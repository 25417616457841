import React, { useState, useEffect,useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../css/viewuser.css";
import { fetchDataObjectV2, updateDataNavigate, updateDataV3,deleteDataV2, justUpdateDataV2 } from "../../apiUtils";
import {
  auditExecution,
  auditExecutionInnerBU,
  auditExecutionInnerQA,
} from "../../subroutes";
import "../createaudit/viewaudit.css";
import SingleOptionSelector from "../../SingleOptionSelector";
import MultiOptionSelector from "../../MultiOptionSelector";
import NavRow from "../../NavRow";
import WindowComponent from "../../WindowComponent";
import RenderIcons from "../../RenderIcons";
import InitialSingleOptionSelector from "../../InitialSingleOptionSelector";
import BounceLoader from "react-spinners/BounceLoader";

const ViewAudits = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [selectedPreparerError, setSelectedPreparerError] = useState(false);
  const [selectedReviewerError, setSelectedReviewerError] = useState(false);
  const [selectedassign_toError, setSelectedassign_toError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setAuditName] = useState("");
  const [objectives, setObjectives] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [audit_background, setAudit_background] = useState("");
  const [final_opinion, setFinal_opinion] = useState("");
  const [preparer_id, setPreparer_id] = useState([]);
  const [reviewer_id, setReviewer_id] = useState([]);
  const [assign_to_id, setAssign_to_id] = useState([]);
  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const [risk_assessment, setAPIRiskData] = useState([]);
  const [business_users_id, setAPIBSData] = useState([]);
  const [auditors_id, setAPIAuditorData] = useState([]);
  const [audit_entity_id, setAPIAuditData] = useState("");
  const [qa_id, setQAID] = useState("");
  const [end_date, setEndDate] = useState("");
  const [start_date, setStartDate] = useState("");
  const [actual_end_date, setActualEndDate] = useState("");
  const [actual_start_date, setActualStartDate] = useState("");
  const [AuditErrors, setAuditErrors] = useState(false);
  const [qaErrors, setQAErrors] = useState(false);
  const [selectedRiskError, setSelectedRiskError] = useState(false);
  const [stakeholders_ids, setstakeholders_ids] = useState([]);
  const [stakeholders_names, setstakeholdersnames] = useState([]);
  const [auIds, setAuIds] = useState([]);
  const [auNames, setAuNames] = useState([]);
  const [audit_scope, setScope] = useState();
  const [audit_phases, setPhases] = useState();
  const [other_relevant_information, setRelevant] = useState();
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState(
    location.state?.successMessage || ""
  );
  const [successMessageUpdate, setSuccessMessageUpdate] = useState(false);

  const prepareId = APIData.preparer ? APIData.preparer.id : "";
  const reviewerId = APIData.reviewer ? APIData.reviewer.id : "";
  const assigned_Id = APIData.assign_to ? APIData.assign_to.id : "";
  const qaId = APIData.qa ? APIData.qa.id : "";
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewerError, setReviewerError] = useState(false);
  const [level_1_ownerError, setLevel1OwnerError] = useState(null);
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  var routes = [];


  if (is_Admin) {
    routes = auditExecution;
  } else if (Object.keys(userRoles).some(key => key.includes("Business"))) {
    console.log("i am here t")
    routes = auditExecutionInnerBU;
  } else if (Object.keys(userRoles).some(key => key.includes("Audit Team"))) {
    routes = auditExecution;
  } else if (Object.keys(userRoles).some(key => key.includes("Audit Commsion"))) {
    routes = auditExecution;
  } else if (Object.keys(userRoles).some(key => key.includes("Quality Assurance"))) {
    routes = auditExecutionInnerQA;
  } else {
    console.log("No matching role found");
  }

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
      setLoading: setLoading
    }
    fetchDataObjectV2(parameter);

  }, [id]);

  useEffect(() => {
    if (editMode) {
      if (!preparer_id) {
        setPreparerError(!preparer_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null)
        }, 5000);
        return;

      }
      const parameter = {
        url: `/api/audit_engagement/audit/preparer/${id}/`,
        payload: {
          preparer_id: preparer_id,
        },
        setErrors: setErrors,
      }
      
      justUpdateDataV2(parameter);
    }
  }, [preparer_id]);

  useEffect(() => {
    if (editMode) {
      if (!reviewer_id) {
        setReviewerError(!reviewer_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null)
        }, 5000);
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/audit/reviewer/${id}/`,
        payload: {
          reviewer_id: reviewer_id,
        },
        setErrors: setErrors,
      }

      justUpdateDataV2(parameter);
      
    }
  }, [reviewer_id]);

  useEffect(() => {
    if (editMode) {
      if (!assign_to_id) {
        setSelectedassign_toError(!assign_to_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null)
        }, 5000);
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/audit/assign/${id}/`,
        payload: {
          assign_to_id: assign_to_id,
        },
        setErrors: setErrors,
      }

      justUpdateDataV2(parameter);
    }
  }, [assign_to_id]);

  useEffect(() => {
    if (editMode) {
      if (!qa_id) {
        setQAErrors(!qa_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null)
        }, 5000);
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/audit/qa/${id}/`,
        payload: {
          qa_id: qa_id,
        },
        setErrors: setErrors,
      }

      justUpdateDataV2(parameter);
    }
  }, [qa_id]);

  useEffect(() => {
    setScope(APIData.audit_scope);
    setPhases(APIData.audit_phases);
    setRelevant(APIData.other_relevant_information);
    setAuditName(APIData.name);
    setObjectives(APIData?.objectives);
    setIntroduction(APIData?.introduction);
    setAudit_background(APIData?.audit_background);
    setFinal_opinion(APIData?.final_opinion);
  }, [APIData]);

  function convertDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];

      return formattedDate;
    }
  }

  const handleEditClick = () => {
    if(Object.keys(userRoles).some(key => key.includes("Audit Team"))){
    setEditMode(true);
    }
  };

  const Id = APIData.id;
  const riskTitle = APIData.risk_assessment
    ? APIData.risk_assessment.title
    : "";
  const riskId = APIData.risk_assessment ? APIData.risk_assessment.id : "";

  useEffect(() => {
    if (APIData && APIData.business_users) {
      const stakeholdersids = [];
      const stakeholdersnames = [];

      APIData.business_users.forEach((auditor) => {
        stakeholdersids.push(auditor.id);
        stakeholdersnames.push(auditor.name);
      });

      setstakeholders_ids(stakeholdersids);
      setstakeholdersnames(stakeholdersnames);
    }

    if (APIData && APIData.auditors) {
      const auids = [];
      const aunames = [];

      APIData.auditors.map((auditor) => {
        auids.push(auditor.id);
        aunames.push(auditor.name);
      });
      setAuIds(auids);
      setAuNames(aunames);
    }
  }, [
    APIData,
    setstakeholders_ids,
    setstakeholdersnames,
    setAuIds,
    setAuNames,
  ]);
  const auditEntityId = APIData.audit_entity ? APIData.audit_entity.id : "";
  const auditEntityName = APIData.audit_entity ? APIData.audit_entity.name : "";
  const prepareName = APIData.preparer ? APIData.preparer.name : "";
  const reviewerName = APIData.reviewer ? APIData.reviewer.name : "";

  const assignedName = APIData.assign_to ? APIData.assign_to.name : "";
  const qaName = APIData.qa ? APIData.qa.name : "";

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const actualAuditStartDate = APIData.actual_start_date
    ? formatDate(APIData.actual_start_date)
    : "";

  const auditStartDate = APIData.audit_event?.planned_start_date
    ? formatDate(APIData.audit_event?.planned_start_date)
    : "";

  const actualAuditEndDate = APIData.actual_end_date
    ? formatDate(APIData.actual_end_date)
    : "";

  const auditEndDate = APIData.audit_event?.planned_end_date
    ? formatDate(APIData.audit_event?.planned_end_date)
    : "";
  const eventStartDate = APIData.audit_event
    ? formatDate(APIData.audit_event.planned_start_date)
    : "";

  const eventEndDate = APIData.audit_event
    ? formatDate(APIData.audit_event.planned_end_date)
    : "";

  const eventId = APIData.audit_event ? APIData.audit_event.id : "";

  useEffect(() => {
    if (APIData) {
      setStartDate(convertDate(APIData.start_date) || "");
      setEndDate(convertDate(APIData.end_date) || "");
    }
  }, [APIData]);
  useEffect(() => {
    if (APIData) {
      setActualStartDate(formatDate(APIData.actual_start_date) || "");
      setActualEndDate(formatDate(APIData.actual_end_date) || "");
    }
  }, [APIData]);

  const onDelete = (e) => {
    e.preventDefault();
    const parameter = {
      url: `/api/audit_engagement/audit/${Id}`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution`
    };

    deleteDataV2(parameter);

  };

  const updateWithPhase = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];
    const parameter = {
      url: `/api/audit_engagement/audit/date/${id}`,
      payload: {
        start_date: currentDate,
        date_of: "planning",
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/planning`,
    };
    updateDataNavigate(parameter);
  };

  const updateAPIData = (e) => {
    console.log("got clicked ");
    e.preventDefault();

    if (!audit_entity_id) {
      setAuditErrors(!audit_entity_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const formattedActualStartDate = actual_start_date
      ? actual_start_date
      : null;
    const formattedActualEndDate = actual_end_date ? actual_end_date : null;
    const formattedStartDate = start_date ? `${start_date}` : null;
    const formattedEndDate = end_date ? `${end_date}` : null;

    if (
      actual_start_date &&
      actual_end_date &&
      new Date(actual_end_date) < new Date(actual_start_date)
    ) {
      setErrors(
        "Actual End Date should be greater than or equal to Actual Start Date."
      );
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (new Date(end_date) < new Date(start_date)) {
      setErrors("End Date should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    if (eventId) {
      const parameter = {
        url: `/api/audit_universe/audit-events/${eventId}/`,
        payload: {
          planned_start_date: actual_start_date
            ? actual_start_date
            : eventStartDate,
          planned_end_date: actual_end_date ? actual_end_date : eventEndDate,
        },
        setErrors: setErrors,
      }
      justUpdateDataV2(parameter);
    }
    const prepareId = parseInt(preparer_id, 10);
    const reviewId = parseInt(reviewer_id, 10);
    const assignId = parseInt(assign_to_id, 10);
    const riskassessmentId = parseInt(risk_assessment, 10);
    const qaid = parseInt(qa_id, 10);
    if(!assignId||!qaid){
      setSelectedassign_toError(!assignId?true:false);
      setQAErrors(!qaid?true:false)
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const Parameter = {
      url: `/api/audit_engagement/audit/${Id}`,
      payload: {
        name,
        objectives,
        introduction,
        audit_background,
        risk_assessment: riskassessmentId,
        audit_entity_id,
        auditors_id,
        business_users_id,
        actual_start_date: formattedActualStartDate,
        actual_end_date: formattedActualEndDate,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        audit_scope,
        audit_phases,
        other_relevant_information,
        qa_id: qaid,
        preparer_id: prepareId,
        reviewer_id: reviewId,
        assign_to_id: assignId,
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageUpdate,
        items: [{ fetchurl: `/api/audit_engagement/audit/${id}/`, dataset: setAPIData }],
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/auditExecution/${id}/detail/`,
    };

    updateDataV3(Parameter);
    setEditMode(false)
  };

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  const phaseTrue = {
    phase: APIData.phase,
    
  };

  const firstInputRef = useRef(null);
    const lastInputRef = useRef(null);
  
    // Move focus back to the first input after last tabIndex
    useEffect(() => {
      const handleTabKey = (event) => {
        if (event.key === "Tab" && document.activeElement === lastInputRef.current) {
          event.preventDefault();
          firstInputRef.current.focus();
        }
      };
  
      window.addEventListener("keydown", handleTabKey);
  
      return () => {
        window.removeEventListener("keydown", handleTabKey);
      };
    }, []);

    const [maketrue, setTrue] = useState(false);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageUpdate && (
        <p className="successmessage">Updated SuccessFully</p>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={routes} id={id} />
        <NavRow
          parentName={name}
          phaseTrue={phaseTrue}
          istrue={true}
          name={editMode ? "Edit " + name : name}
          backToExecution={`/audit-execution`}
        />


        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="view_audit_container"
            style={{ width: tableSize ? "100%" : "100%" }}
          >
            <form onDoubleClick={handleEditClick}>
              <div className="lng_lbl_inpt">
                <label>Audit Name</label>
                <input
                  type="text"
                  value={name}
                  placeholder="Audit Name"
                  onChange={(e) => setAuditName(e.target.value)}
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  readOnly={!editMode}
                  tabIndex={1}
                  ref={firstInputRef}
                />
              </div>

              <div className="lbl_txa">
                <label>Objectives</label>
                <textarea
                  type="text"
                  value={objectives}
                  readOnly={!editMode}
                  placeholder="Audit Objectives"
                  onChange={(e) => setObjectives(e.target.value)}
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  tabIndex={2}
                />
              </div>

              <SingleOptionSelector
                apiUrl="/api/audit_universe/risk-assessments/?status=Closed"
                setSelectorId={setAPIRiskData}
                setSelectorError={setSelectedRiskError}
                Name="title"
                title="Risk Assessments"
                initialId={riskId}
                initialName={riskTitle}
                editMode={APIData.phase==='Open'?editMode:false}
                tabIndex={3}
                maketrue={maketrue}
                setTrue={setTrue}
              />

              <MultiOptionSelector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setAPIBSData}
                Name="name"
                title="Stakeholders"
                initialIds={stakeholders_ids ? stakeholders_ids : []}
                initialNames={stakeholders_names ? stakeholders_names : []}
                editMode={editMode}
                tabIndex={4}
              />

              <div className="lbl_inpt">
                <label> Actual Start Date: </label>{" "}
                <input
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  type="date"
                  value={actual_start_date || ""}
                  onChange={(e) => setActualStartDate(e.target.value)}
                  className={editMode ? "" : "noEditInput"}
                  readOnly={!editMode}
                  tabIndex={5}
                />
              </div>

              <div className="lbl_inpt">
                <label>Actual End Date:</label>
                <input
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  type="date"
                  value={actual_end_date || ""}
                  onChange={(e) => setActualEndDate(e.target.value)}
                  className={editMode ? "" : "noEditInput"}
                  readOnly={!editMode}
                  tabIndex={6}
                />
              </div>

              <div className="lbl_inpt">
                <label> Start Date: </label>{" "}
                <input
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  type="date"
                  value={start_date ? start_date : auditStartDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  className={editMode ? "" : "noEditInput"}
                  readOnly={!editMode}
                  tabIndex={7}
                />
              </div>


              <div className="lbl_inpt">
                <label>End Date:</label>
                <input
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  type="date"
                  value={end_date ? end_date : auditEndDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    if (new Date(e.target.value) < new Date(start_date)) {
                      setErrors(
                        "End Date should be greater than or equal to Start Date."
                      );
                      setTimeout(() => {
                        setErrors(null);
                      }, 5000);
                    }
                  }}
                  className={editMode ? "" : "noEditInput"}
                  readOnly={!editMode}
                  tabIndex={8}
                />
              </div>

              <SingleOptionSelector
                apiUrl="/api/audit_universe/audit-entities/"
                setSelectorId={setAPIAuditData}
                setSelectorError={setAuditErrors}
                selectorError={AuditErrors}
                Name="name"
                title="Audit Entity"
                initialId={auditEntityId}
                initialName={auditEntityName}
                editMode={APIData.phase==='Open'?editMode:false}
                tabIndex={9}
                maketrue={maketrue}
                setTrue={setTrue}
              />

              <MultiOptionSelector
                apiUrl="/api/audit_universe/audit-team/"
                setSelectorId={setAPIAuditorData}
                Name="name"
                title="Auditor"
                initialIds={auIds ? auIds : []}
                initialNames={auNames ? auNames : []}
                editMode={editMode}
                tabIndex={10}
              />

              <InitialSingleOptionSelector
                apiUrl="/api/audit_universe/audit-team/"
                setSelectorId={setPreparer_id}
                setSelectorError={setSelectedPreparerError}
                selectorError={selectedPreparerError}
                Name="name"
                title="Preparer"
                initialIds={auditors_id}
                initialId={prepareId}
                initialName={prepareName}
                editMode={editMode}
                tabIndex={11}
              />

              <InitialSingleOptionSelector
                apiUrl="/api/audit_universe/audit-team/"
                setSelectorId={setReviewer_id}
                setSelectorError={setSelectedReviewerError}
                selectorError={selectedReviewerError}
                Name="name"
                title="Reviewer"
                initialIds={auditors_id}
                initialId={reviewerId}
                initialName={reviewerName}
                editMode={editMode}
                tabIndex={12}
              />

              <InitialSingleOptionSelector
                apiUrl="/api/audit_universe/admin-business-stakeholders/"
                setSelectorId={setAssign_to_id}
                setSelectorError={setSelectedassign_toError}
                selectorError={selectedassign_toError}
                Name="name"
                title="Business Owner"
                initialIds={business_users_id}
                initialId={assigned_Id}
                initialName={assignedName}
                editMode={editMode}
                // selectorError={level_1_ownerError}
                tabIndex={13}
              />

              <SingleOptionSelector
                apiUrl="/api/audit_universe/quality-assurance/"
                setSelectorId={setQAID}
                setSelectorError={setQAErrors}
                selectorError={qaErrors}          
                Name="name"
                title="QA"
                initialId={qaId}
                initialName={qaName}
                editMode={editMode}
                tabIndex={14}
                maketrue={maketrue}
                setTrue={setTrue}
              />
            </form>

            <form onDoubleClick={handleEditClick}>
              <h3>Audit Memo</h3>
              <div className="lbl_txa">
                <label>Audit Scope</label>

                <textarea
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Define Audit Scope"
                  value={audit_scope}
                  onChange={(e) => {
                    setScope(e.target.value);
                  }}
                  readOnly={!editMode}
                  tabIndex={15}
                />
              </div>

              <div className="lbl_txa">
                <label>Audit Key Phases/Duration</label>

                <textarea
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Audit Key Phases/Duration"
                  value={audit_phases}
                  onChange={(e) => {
                    setPhases(e.target.value);
                  }}
                  readOnly={!editMode}
                  tabIndex={16}
                />
              </div>

              <div className="lbl_txa">
                <label>Other Relevant Information</label>

                <textarea
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Other Relevant Information"
                  value={other_relevant_information}
                  onChange={(e) => {
                    setRelevant(e.target.value);
                  }}
                  readOnly={!editMode}
                  tabIndex={17}
                />
              </div>
            </form>

            <div className="button_container">
              <button
                className="cmn_btn"
                style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
                disabled={!editMode}
                onClick={updateAPIData}
                tabIndex={18}
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Save
              </button>

              {editMode && (
                <button
                  onClick={handleCancelClick}
                  className="cmn_btn"
                  style={{ backgroundColor: "#d6d6d6", color: "#000" }}
                  tabIndex={19}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L10.9989 10.9989"
                      stroke="#292D32"
                      stroke-width="1.76758"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.00107 10.9989L11 1"
                      stroke="#292D32"
                      stroke-width="1.76758"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel Edit
                </button>
              )}

              <WindowComponent onDelete={onDelete} ref={lastInputRef} tabIndex={20} text="Delete Audit" disabled={!Object.keys(userRoles).some(key => key.includes("Audit Team"))}  />
              {APIData.phase === "Open" && (
                <button
                  className="cmn_btn"
                  style={{ backgroundColor: "#5757df" }}
                  onClick={(e) => updateWithPhase(e)}
                >
                  <svg
                    width="12"
                    height="10"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.72621L3.18182 6.90803L9 1.08984"
                      stroke="white"
                      stroke-width="1.45455"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Start Planning
                </button>
              )}
            </div>
          </div>
        )}

      </section>
    </>
  );
};
export default ViewAudits;
