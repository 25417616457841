import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import SidebarMenu from "../../../SidebarMenu";
import "../answerQuestionnaire/AnswerQuestionnaire.css";
import "../../../../css/viewuser.css";
import QuestionsComps from "./QuestionsComps";
import sendComment from "../../../../images/send.svg";
import SuperAvatar from "../../../SuperAvatar";
import { convertDate, convertDateTime, fetchDataObjectV2, fetchDataV2, justPostDataV2 } from "../../../apiUtils";
import UserDropdown from "./UserDropdown";
import { auditRoutes,auditRoutesBu } from "../../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";

const AnswerQuestionnaire = ({ tableSize }) => {
  const [APIData, setAPIData] = useState([]);
  const [RAData, setRAData] = useState([]);
  const [APIQData, setAPIQData] = useState([]);
  const [formStatus, setFormStatus] = useState("");
  const [otherKeysData, setOtherKeysData] = useState([]);
  const [taskName, settaskName] = useState([]);
  const [taskNameError, setTaskNameError] = useState("");
  const [assigned_toName, setAssigned_toName] = useState("");
  const [otherData, setOtherData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [errors, setErrors] = useState("");
  const [qErrors, setQErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [isComments, setIsComments] = useState(true);
  const [taskErrors, setTaskErrors] = useState("");
  const [otherKeyErrors, setOtherKeyErrors] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [activitylog, setActivityLog] = useState([])
  const avaUserName = localStorage.getItem("userName");
  const lastName = localStorage.getItem("userLastName");
  const taskID = localStorage.getItem("taskID");
  const taskTitle = localStorage.getItem("taskTitle");
  const {raid}=useParams()
  const location = useLocation();
    const userRoles = JSON.parse(localStorage.getItem("userRoles"));
    const BusinessRole = Object.keys(userRoles).some(key => key.includes("Business"))

  const is_Admin = localStorage.getItem("is_Admin");
  const handleCommentsToggle = () => {
    setShowComments(!showComments);
  };
 

  const containsRiskAssessment = window.location.href.includes("riskAssesment");

  useEffect(() => {
    const parameter = {
      url:  `/api/audit_universe/answers/questionnaire/${location?.state?.questionnaire_id}`,
      setterFunction: setAPIData,
      setErrors: setTaskNameError,
    }
    fetchDataV2(parameter);
  }, [location?.state?.questionnaire_id]);

  useEffect(() => {
    if(raid){
      const parameter = {
        url: `/api/audit_universe/risk-assessments/${raid}/`,
        setterFunction: setRAData,
        setErrors: setErrors,
        setLoading: setLoading,
      };
      fetchDataObjectV2(parameter);
    }
  }, [raid]);

  // const raid = [...new Set(APIData.map(rid => Number(rid.risk_assessment)))];

  const navigate = useNavigate();

  const commentRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
        commentRef.current &&
        !commentRef.current.contains(event.target) &&
        !event.target.closest('.cmmnt_tggle')
    ) {
        setShowComments(false);
    }
};

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  useEffect(() => {
    if (raid) {
      const parameter = {
        url: `/api/system/activity/?action_on=RiskAssessment&action_on_id=${raid}`,
        setterFunction: setActivityLog,
        setErrors: setErrors,
      }
      fetchDataV2(parameter)
    }


  }, [raid])

  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/questionnaire/${location?.state?.questionnaire_id}/`,
      setterFunction: setAPIQData,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
    if (APIQData && APIData.status) {
      setFormStatus(APIData.status);
    }
  }, [location?.state?.questionnaire_id]);

  useEffect(() => {
    if (APIQData.task) {
      const parameter = {
        url: `/api/system/task/${APIQData?.task?.id}/`,
        setterFunction: settaskName,
        setErrors: setTaskNameError,
      }
      fetchDataV2(parameter);
    }

    if (APIQData.task) {
      const parameter = {
        url: `/api/system/comments/?task_id=${APIQData?.task?.id}`,
        setterFunction: setTaskData,
        setErrors: setTaskErrors,
      }
      fetchDataV2(parameter);
    }
  }, [APIQData]);

  useEffect(() => {
    if (taskName.assigned_to) {
      setAssigned_toName(taskName ? taskName.assigned_to.name : "");
      console.log(taskName.assigned_to.name);
    }
  }, [taskName]);


  const uniquename = [
    ...new Set(
      APIData.map((item) => item.audit_entity?.name).filter(Boolean)
    ),
  ];
  console.log(uniquename)

  useEffect(() => {
    if (APIData && Array.isArray(APIData)) {
      const uniqueAuditEntityIds = [
        ...new Set(
          APIData.map((item) => item.audit_entity?.id).filter(Boolean)
        ),
      ];

      uniqueAuditEntityIds.forEach((id) => {
        const parameter = {
          url: `/api/audit_universe/audit-entities/${id}/`,
          setterFunction: setOtherKeysData,
          setErrors: setOtherKeyErrors,
        }
        fetchDataV2(parameter);
      });
    }
  }, [APIData]);

  useEffect(() => {
    if (otherKeysData) {
      const otherStakeholders = Array.isArray(otherKeysData.other_stakeholders)
        ? otherKeysData.other_stakeholders
        : []; 
  
      const keyStakeholderAsArray = otherKeysData.key_stakeholder
        ? [{ ...otherKeysData.key_stakeholder }]
        : [];
  
      const combinedData = [...otherStakeholders, ...keyStakeholderAsArray];
      
      setOtherData(combinedData);
    }
  }, [otherKeysData]);
  
  

  useEffect(() => {
    if (APIData && APIData.length > 0) {
      const questionnaireID = APIData[0].questionnaire;
      localStorage.setItem("questionnaireID", questionnaireID);
    }
  }, [APIData]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      const parameter = {
        url: `/api/system/comments/`,
        payload: {
          user_id: localStorage.getItem("user_id"),
          task_id: APIQData?.task?.id,
          text: inputValue,
        },
        setErrors: setErrors,
      }
      justPostDataV2(parameter);



      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };

  const transformData = (data) => {
    return data.reduce((acc, item) => {
      const sectionName = item.section.section_name;
      const questionId = item.question.id;
      const businessUnit = {
        id: item.audit_unit.id,
        name: item.audit_unit.name,
      };
      const remark = item.remark;

      if (!acc[sectionName]) {
        acc[sectionName] = {
          section_name: sectionName,
          questions: {},
          remark,
        };
      }

      if (!acc[sectionName].questions[questionId]) {
        acc[sectionName].questions[questionId] = {
          question_id: questionId,
          question: item.question.question,
          answers: [],
          remark,
        };
      }

      const selectedOption =
        ["Yes", "Partially Yes","No", "Don't Know","N/A"][item.answer - 1] || null;
      acc[sectionName].questions[questionId].answers.push({
        id: item.id,
        audit_unit: businessUnit,
        selected_option: selectedOption,
      });

      return acc;
    }, {});
  };

  const transformedData = transformData(APIData);

  const sections = Object.values(transformedData).map((section) => ({
    section_name: section.section_name,
    questions: Object.values(section.questions),
  }));

  const data = { sections };

  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };

  const isAdminRole =
    is_Admin || Object.keys(userRoles).some(key => key.includes("Audit Team"));
  var showSubmit = false;
  var showApproval = false;
  var showStatus = false;
  var incomingStatus = APIQData?.status?.toLowerCase();
  if (isAdminRole) {
    switch (incomingStatus) {
      case "rejected":
      case "open":
        showStatus = false;
        showApproval = false;
        showSubmit = true;
        break;
      case "submitted":
        showStatus = false;
        showApproval = true;
        showSubmit = false;
        break;
      default:
        showStatus = true;
        showApproval = false;
        showSubmit = false;
    }
  } else {
    switch (incomingStatus) {
      case "rejected":
      case "open":
        showStatus = false;
        showApproval = false;
        showSubmit = true;
        break;
      default:
        showStatus = true;
        showApproval = false;
        showSubmit = false;
    }
  }

  const queStatus = {
    questatus: APIQData?.status,
    showStatus: showStatus,
    incomingStatus: incomingStatus,
  };

  const name=RAData.title

  

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={BusinessRole?auditRoutesBu:auditRoutes} />

        <NavRow
          name={"Answer Questionnaire for " + uniquename[0]}
          parentName ={containsRiskAssessment ? name : "Audit Entity"}
          idtrue={true}
          queStatus={queStatus}
        />
        {/* <h1>{JSON.stringify(APIData.audit_entity)}</h1>
        { } */}

        {showComments && (
          <div className="comment_con_AQ" ref={commentRef}>
            <div className="heading_toggle_aq">
              <h2>Comments & Activity</h2>

              <div className="togglecon">
                <div
                  className="toggle-option"
                  onClick={() => setIsComments(true)}
                  style={{
                    backgroundColor: isComments ? "#fff" : "",
                    borderRadius: "999px",
                  }}
                >
                  Comments
                </div>

                <div
                  className="toggle-option"
                  onClick={() => setIsComments(false)}
                  style={{
                    backgroundColor: !isComments ? "#fff" : "",
                    borderRadius: "999px",
                  }}
                >
                  Activity
                </div>
              </div>
            </div>

            {isComments ? (
              <>
                <div className="initial_comment">
                  <input
                    type="text"
                    placeholder="Enter a comment..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="sendComm">
                    <img
                      onClick={handleAddComment}
                      src={sendComment}
                      alt="Send Comment"
                    />
                  </div>
                </div>

                <div className="all_comments">
                  {taskData
                    .slice()
                    .reverse()
                    .map((comment, index) => {
                      const { text, date, time } = comment;
                      return (
                        <div className="comment_wrapper_" key={index}>
                          <div className="comment_head">
                            <div className="avatar_name_AQ">
                              <SuperAvatar
                                firstName={
                                  comment.user
                                    ? comment.user.name
                                    : `${avaUserName} ${lastName}`
                                }
                                classOfAvatar="Avtaraudit"
                              />
                              <h5>
                                {comment.user
                                  ? comment.user.name
                                  : `${avaUserName} ${lastName}`}
                              </h5>
                            </div>
                            <div className="time_date_AQ">
                              <span>
                                <sub>{convertDateTime(comment.created_at)}</sub>
                              </span>
                            </div>
                          </div>
                          <div className="comment_AQ">
                            <p>{text}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : (
              <div className="all_comments">
                {activitylog.map((activity, index) => (
                  <div className="activity_wrapper_com">
                    <div className="activity_head">
                      <span className="status_complete">{activity.action}</span>
                      <div className="time_date_AQ">
                        <span>
                          <sub>{convertDate(activity.created_at)}</sub>
                        </span>
                      </div>
                    </div>

                    <div className="activity_AQ">
                      <div className="activity_transaction">
                        <div className="avatar_name_AQ">
                          <SuperAvatar
                            firstName={activity.action_by_id.name}
                            classOfAvatar="Avtaraudit"
                          />
                          <h5>{activity.action_by_id.name}</h5>
                        </div>

                        <h4 style={{ wordWrap: "break-word" }}>
                          {activity.log.length > 30
                            ? `${activity.log.substring(0, 30)}...`
                            : activity.log}
                        </h4>

                        {activity.action !== "CREATE" &&
                          activity.action !== "UPDATE" && activity.action !== "DELETE" && (
                            <div className="avatar_name_AQ">
                              <SuperAvatar
                                firstName={"Affan Shaikh"}
                                classOfAvatar="Avtaraudit"
                              />
                              <h5> Affan Shaikh </h5>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIQData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <><div className="ans_questionniarecontainer" style={{ width: tableSize ? "100%" : "100%" }}>

            <div className="assigning_section">
              {otherData && (
                <>
                    <UserDropdown
                      taskId={APIQData?.task?.id}
                      otherKeyData={otherData}
                      task={APIQData.id}
                      APIQData={APIQData}
                      setAPIQuestionnaireData={setAPIData}
                      title={APIQData.title}
                      assigned_toName={assigned_toName}
                      readOnly={BusinessRole}
                      labelFor={BusinessRole? "Assigned To":"Assignee"} />
                  
                </>
              )}
            </div>

            <div className="answer_sec__con_sec">

              <div className="ans_section_container">

                <div
                  className="ans_section"
                  style={{
                    width: tableSize?"100%":"100%"}}
                >

                  {APIData.length > 0 && APIQData.status && (
                    <QuestionsComps
                      data={data}
                      formStatus={formStatus}
                      APIQData={APIQData}
                      showComments={showComments}
                      handleCommentsToggle={handleCommentsToggle} />
                  )}{" "}

                </div>

              </div>
            </div>

            <h5 className="ans_message" >Please operate from web app to fill answer questionnaire.</h5>

          </div>
          </>

        )}
      </section>
    </>
  );
};

export default AnswerQuestionnaire;