import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { justUpdateDataV2, fetchDataV2, updateDataV3 } from "../../../apiUtils";

const UserDropdown = ({
  otherKeyData,
  task,
  title,
  assigned_toName,
  taskId,
  readOnly,
  setAPIQuestionnaireData,
  labelFor,
  APIQData
}) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [pendingAssignment, setPendingAssignment] = useState(null);
  const dropdownRef = useRef(null);
  const taskID = localStorage.getItem("user_id");
  const taskAssinged = localStorage.getItem("taskAssinged");
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const hasbusinessRole= Object.keys(userRoles).some((key) => key.includes("Business"))
  const location = useLocation();
  const isAdminRole =
    is_Admin ||
    Object.keys(userRoles).some((key) => key.includes("Audit Team"));
  const [successMessageAssign, setSuccessMessageAssign] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (taskId) {
      const parameter = {
        url: `/api/system/task/${taskId}/`,
        setterFunction: setAPIData,
        setErrors: setErrors,
      };
      fetchDataV2(parameter);
    }
  }, [taskId]);

  useEffect(() => {
    const user = otherKeyData.find((u) => u.id === parseInt(taskAssinged));
    if (user) {
      setSelectedUser(user);
    }
  }, [otherKeyData, taskAssinged]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleToggleDropdown = () => {
    if (isAdminRole ||hasbusinessRole) {
      if(APIQData.status==="Open"){
        setIsOpen(!isOpen);
      }
      if (!isOpen) {
        setSearchTerm("");
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectChange = (user) => {
    setSelectedUser(user);
    setPendingAssignment(user);
    setIsOpen(false);
  };

  const handleAssignTo = () => {
    if (title && pendingAssignment) {
      const parameter = {
        url: `/api/system/task/${taskId}`,
        payload: {
          title: title,
          assigned_to_id: pendingAssignment.user_id,
        },
        tofetch: {
          setSuccessMessage: setSuccessMessageAssign,
          items: [
            {
              fetchurl: `/api/audit_universe/answers/questionnaire/${location?.state?.questionnaire_id}`,
              dataset: setAPIQuestionnaireData,
            },
          ],
        },
        setErrors: setErrors,
      };
      updateDataV3(parameter);
      setSelectedUser(pendingAssignment);
      setPendingAssignment(null);
    }
  };

  const filteredUsers = otherKeyData.filter((user) =>
    user.name ? user.name.toLowerCase().includes(searchTerm.toLowerCase()) : ""
  );

  return (
    <>
      {successMessageAssign && (
        <p className="successmessage">Questionnnaire Successfully Assigned </p>
      )}
      <div
        className="lbl_selector"
        style={{ flexDirection: "row", alignItems: "center" }}
      >
        <label htmlFor="mySelect">{labelFor}</label>
        <div
          ref={dropdownRef}
          className="ref_div"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="sub_ref_div" onClick={handleToggleDropdown}>
            <label style={{ fontSize: "12px" }}>
              {selectedUser
                ? selectedUser.name
                : assigned_toName || " Key Stakeholders"}
            </label>
          </div>
          {isOpen && (
            <div
              className="opt_drp_dwn"
              style={{ width: "65%", left: "15.8%" }}
            >
              <input
                type="text"
                placeholder="Search by first name..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <div style={{ textAlign: "center" }}>
                {filteredUsers.map((user) => (
                  <div
                    key={user.id}
                    className="opt_drp_dwn_optns"
                    onClick={() => handleSelectChange(user)}
                  >
                    <label>{user.name}</label>
                    <input
                      type="checkbox"
                      checked={selectedUser && selectedUser.id === user.id}
                      readOnly={readOnly}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {(isAdminRole||hasbusinessRole) && (
          <button
            className="cmn_btn"
            style={{ backgroundColor: APIQData.status!=="Open"?'#7a7a7a': "#5757df" }}
            onClick={handleAssignTo} 
            disabled={APIQData.status!=="Open"}// Call API only when this button is clicked
          >
            Assign To
          </button>
        )}
      </div>
    </>
  );
};

export default UserDropdown;
