import React, { useState, useEffect, useRef } from "react";
import { fetchDataV2 } from "./apiUtils";
import "../css/department.css";
import arrowToggle from "../images/toggle_side_down.svg";

const MultiOptionTest = ({
  setItem,
  editMode,
  item,
  setDataTest,
  dataTest,
  setData1,
  data1,
  setTestIds,
  testIds,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);
  const [expandedUnits, setExpandedUnits] = useState({});
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [checkedId, setCheckedId] = useState({});
  const [noFailedTests, setNoFailedTests] = useState(false); 
  const [showPopup, setShowPopup] = useState(false);


  useEffect(() => {
    if (dataTest) {
      const parameter = {
        url: `/api/audit_engagement/workpapertestcase/?workpaper_id=${dataTest}&status=Failed`,
        setterFunction: (response) => {
          console.log(response);
          const ids = response.map((elem) => elem.id);

          let newCheckedId = {};
          if (!testIds) {
            item[dataTest] = ids;
          } else {
            item[dataTest] = ids.filter((i) => testIds.includes(i));
          }

          if (ids) {
            ids.forEach((element) => {
              if (!testIds || testIds.length === 0 || testIds.includes(element)) {
                newCheckedId[element] = true;
              } else {
                newCheckedId[element] = false;
              }
            });
          }

          // Set the checked IDs and data state.
          setCheckedId(newCheckedId);
          setData(response);

          // Check if there are no "Failed" tests.
          if (response.length === 0) {
            setNoFailedTests(true);
            setShowPopup(true)
          } else {
            setNoFailedTests(false);
            setShowPopup(false)

          }
        },
      };
      fetchDataV2(parameter);
    }
  }, [dataTest, setItem, setData]);

  useEffect(() => {
    if (expanded) {
      inputRef.current.focus();
    }
  }, [expanded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChangeDepts = (evt, workpaperId, unitId) => {
    var newCheckedId = { ...checkedId };
    console.log(
      "checkbox before:",
      item[workpaperId].includes(unitId),
      checkedId[unitId]
    );
    if (item[workpaperId].includes(unitId)) {
      if (item[workpaperId].includes(unitId)) {
        item[workpaperId] = item[workpaperId].filter((u) => u !== unitId);
      }
      newCheckedId[unitId] = false;
      setCheckedId(newCheckedId);
    } else {
      if (!item[workpaperId].includes(unitId)) {
        item[workpaperId] = [...item[workpaperId], unitId];
      }

      newCheckedId[unitId] = true;
      setCheckedId(newCheckedId);
    }

    setItem(item);
    console.log("item", item);
    console.log("data1", data1);
    console.log(
      "checkbox after:",
      item[workpaperId].includes(unitId),
      checkedId[unitId]
    );
  };

  const toggleTestDiv = (unitId) => {
    setExpandedUnits((prevState) => ({
      ...prevState,
      [unitId]: !prevState[unitId],
    }));
  };

  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };
  return (
    <div className="cover_test">
      <div ref={dropdownRef}>
        {noFailedTests && ( // Conditional rendering for inline message.
          <p style={{ color: "red", marginBottom: "10px" }}>
            Please select a workpaper with at least one "Failed" test case.
          </p>
        )}
        {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>
            Please select a workpaper with at least one "Failed" test case.
            </p>
            <div className="subpopupBtnDiv">
              <button onClick={handlePopupCloseNo}>Close</button>
            </div>
          </div>
        </div>
      )}
        <div
          className="testDiv"
          style={{
            visibility: expanded ? "visible" : "visible",
            height: expanded ? "auto" : "auto",
          }}
        >
          <div className="test_container">
            {data.map((unit) => (
              <div key={unit.id}>
                <div className="test_view">
                  <div className="toggle_checkbox_tit_notes">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleTestDiv(unit.id);
                      }}
                    >
                      <img src={arrowToggle} alt="Toggle" />
                    </div>

                    <div className="checkbox_test_title">
                      <input
                        type="checkbox"
                        id={unit.id}
                        value={unit.id}
                        checked={checkedId[unit.id]}
                        disabled={editMode}
                        onChange={(event) =>
                          handleCheckboxChangeDepts(event, dataTest, unit.id)
                        }
                      />
                      <h6>{unit.step}</h6>
                      <span>Failed</span>
                    </div>
                  </div>

                  {expandedUnits[unit.id] && (
                    <div className="wkp_notes">
                      <hr className="test_head_body_sepr" />
                      <h3>Preliminary Observation (Reason of Fail)</h3>
                      <p>{unit?.note}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiOptionTest;
