import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../css/viewuser.css";
import "../auditUniverse/rcm/listofrcap.css";
import { fetchDataV2} from "../apiUtils";
import RenderIcons from "../RenderIcons";
import { templates } from "../subroutes";
import "../auditUniverse/rcm/departmentViewPage/departmentRCM.css"
import NavRow from "../NavRow";
import ReactPaginate from "react-paginate";
const RcmTemplate = ({ tableSize }) => {
    const [errors, setErrors] = useState("");
    const [showDone, setShowDone] = useState(false);
    const [APIData, setAPIData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const successMessageColor = location.state?.successMessageColor;
    const successMessage = location.state?.successMessage;
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 11; 

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate(".", { replace: true });
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const parameter ={
            url :`/api/audit_universe/rcap/`, 
            setterFunction :setAPIData,
            setErrors : setErrors
           }
        fetchDataV2(parameter);
    }, []);

    useEffect(() => {
        const parameter ={
            url :`/api/system/departments/`, 
            setterFunction :setDepartmentData,
            setErrors : setErrors
           }
        fetchDataV2(parameter);
    }, []);

    useEffect(() => {
        if (APIData.length > 0) {
            setShowDone(true);
        }
    }, [APIData]);

    const Viewuser = (data) => {
        const departmentId = data.id;
        navigate(`/audit-universe/rcm/${departmentId}`);

    };

    const handleFilterChange = (filterKey, e) => {
        if (filterKey === "business_unit") {
            setFilters({
                ...filters,
                business_unit: { name: e.target.value },
            });
        } else {
            setFilters({ ...filters, [filterKey]: e.target.value });
        }
    };

    const [filters, setFilters] = useState({
        departmentname: '',
        departmenthead: '',
        departmentdesigantion: '',
    });

    const filteredData = useMemo(() => {
        return departmentData.filter((data) => {
            const deptname = data?.name?.toLowerCase() || "--";
            const name = data?.department_head?.name?.toLowerCase() || "";
            const designation = data?.department_head?.designation?.toLowerCase() || "";

            return (
                (!filters?.departmentname || deptname?.includes(filters?.departmentname)) &&
                (!filters?.departmenthead || name?.includes(filters?.departmenthead)) &&
                (!filters?.departmentdesigantion || designation?.includes(filters?.departmentdesigantion))
            );
        });
    }, [departmentData, filters]);


    // Pagination Logic
const pageCount = Math.ceil(filteredData.length / itemsPerPage);
const startOffset = currentPage * itemsPerPage;
const paginatedData = filteredData.slice(
  startOffset,
  startOffset + itemsPerPage
);

const handlePageClick = ({ selected }) => {
  setCurrentPage(selected);
};


    return (
        <>
            {errors && <p className="errormessage">{errors}</p>}
            {successMessage && <p className="successmessage">{successMessage}</p>}

            <section className="navroutes">
                <RenderIcons homeRoutes={templates} />
                <NavRow name={"Risk Control Matrix"} Create={'Create'} idtrue={true} />

                <div className="tablecontainer" style={{ width: tableSize ? "100%" : "100%" }}>

                    <table>
                        <thead
                            className="tablehead"
                            style={{ position: "sticky", top: "0", zIndex: 1000 }}
                        >
                            <tr>
                                <th>
                                    <h4>Department Name</h4>
                                    <input
                                        type="text"
                                        placeholder="--"
                                        className="thfilter"
                                        onChange={(e) => handleFilterChange("departmentname", e)}
                                        value={filters?.departmentname}
                                    />
                                </th>
                                <th>
                                    <h4>Department Head</h4>
                                    <input
                                        type="text"
                                        placeholder="--"
                                        className="thfilter"
                                        onChange={(e) => handleFilterChange("departmenthead", e)}
                                        value={filters?.departmenthead}
                                    />

                                </th>
                                <th>
                                    <h4>Department Designation</h4>
                                    <input
                                        type="text"
                                        placeholder="--"
                                        className="thfilter"
                                        onChange={(e) => handleFilterChange("departmentdesigantion", e)}
                                        value={filters?.departmentdesigantion}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((data, index) => (
                                <tr onClick={() => Viewuser(data)} key={data.id}>
                                    <td>
                                        {data?.name}
                                    </td>
                                    <td>{data?.department_head ? data?.department_head?.name : ''}</td>
                                    <td>{data?.department_head ? data?.department_head?.designation : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                </div>

                <div className="cardscontainer"></div>

                <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container"
            activeClassName={"active"}
          />
            </section>
        </>
    );
};

export default RcmTemplate;
