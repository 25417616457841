import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../AnswerQA/AnswerQA.css";
import "../../../../css/viewuser.css";
import RenderIcons from "../../../RenderIcons";
import save from "../../../../images/saveBtn.svg";

import {
  fetchDataV2,
  updateDataV2,
  fetchDataObjectV2,
  updateDataV3,
} from "../../../apiUtils";
import {
  auditExecution,
  auditExecutionInnerBU,
  auditExecutionInnerQA,
} from "../../../subroutes";
import NavRow from "../../../NavRow";
const AnswerQA = ({ tableSize }) => {
  const [APIData, setAPIData] = useState([]);
  const [APIData1, setAPIData1] = useState([]);
  const [answer1, setAnswer1] = useState([]);
  const [taskNameError, setTaskNameError] = useState("");
  const [errors, setErrors] = useState("");
  const [data, setData] = useState(null);
  const [surveyId, setQaSurveyId] = useState([]);
  const [editableQuestionId, setEditableQuestionId] = useState(null);
  const { id } = useParams();
  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessageSave, setSuccessMessageSave] = useState(false);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);

  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");

  var routes = [];
  if (is_Admin) {
    routes = auditExecution;
  } else if (Object.keys(userRoles).some((key) => key.includes("Business"))) {
    routes = auditExecutionInnerBU;
  } else if (
    Object.keys(userRoles).some((key) => key.includes("Quality Assurance"))
  ) {
    routes = auditExecutionInnerQA;
  } else {
    console.log("No matching role found");
  }

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/qa-survey/?audit_id=${id}`,
      setterFunction: setQaSurveyId,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);
  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/qa-survey/answers/?audit_id=${id}`,
      setterFunction: setAnswer1,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    const transformData = (APIData) => {
      const sectionMap = {};

      APIData.forEach((item) => {
        console.log(item);
        const { section, question, answer } = item;
        if (!sectionMap[section]) {
          sectionMap[section] = {
            section_name: section,
            questions: [],
          };
        }
        sectionMap[section].questions.push({
          question,
          answer,
        });
      });

      return Object.values(sectionMap);
    };

    if (APIData && APIData.length > 0) {
      const transformedData = transformData(APIData);

      const sections = transformedData.sort().map((section) => ({
        section_name: section.section_name,
        questions: section.questions,
      }));

      setData({ sections });
    }
  }, [APIData]);

  const [mergedData, setMergedData] = useState([]);
  useEffect(() => {
    const groupedAnswers = {};
    answer1.forEach((answer) => {
      const key = `${answer.question?.id}-${answer?.question?.section}`;
      if (!groupedAnswers[key]) {
        groupedAnswers[key] = [];
      }
      groupedAnswers[key].push(answer);
    });

    const merged = answer1.map((question) => {
      const answerKey = `${question?.question?.id}-${question?.question?.section}`;
      const answerObj = groupedAnswers[answerKey]?.[0];

      let value;
      let answerId;
      if (answerObj) {
        value = answerObj.answer.value;
        answerId = answerObj?.id;
      } else {
        value = null;
      }

      return {
        ...question.question,
        value: value,
        answerId: answerId || null,
      };
    });

    setMergedData(merged);
  }, [answer1]);

  const handleRadioChange = (itemId, value) => {
    setMergedData((prevData) =>
      prevData.map((item) => (item?.id === itemId ? { ...item, value } : item))
    );
  };

  const handleUpdate = (answerId, itemId, value, answerType) => {
    const qaSurveyId = surveyId[0]?.id;

    if (!answerId) {
      console.error("Answer ID is missing. Cannot update the answer.");
      setErrors("Answer ID is missing.");
      return;
    }
    const Parameter = {
      url: `/api/audit_engagement/qa-survey/answers/${answerId}`,
      payload: {
        qa_survey_id: qaSurveyId,
        answer: { value: value },
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageSave,
        items: [
          {
            fetchurl: `/api/audit_engagement/qa-survey/answers/?audit_id=${id}`,
            dataset: setAnswer1,
          },
        ],
      },
      setErrors: setErrors,
    };

    updateDataV3(Parameter);
    setEditMode(false);
    setEditableQuestionId(false);
  };

  const groupedData = mergedData.reduce((acc, item) => {
    if (!acc[item?.section]) {
      acc[item?.section] = [];
    }
    acc[item?.section].push(item);
    return acc;
  }, {});

  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const [activeAnswerId, setActiveAnswerId] = useState(null);
  const [activeValue, setActiveValue] = useState(null);
  const [activeAnswerType, setActiveAnswerType] = useState(null);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = () => {
    // Save changes for all edited questions
    mergedData.forEach((item) => {
      if (item.value !== null && item.answerId) {
        handleUpdate(item.answerId, item.id, item.value, item.answer);
      }
    });
    setEditMode(false); // Exit edit mode
  };

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessageSave && (
        <p className="successmessage">Saved Successfully</p>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />

        <NavRow
          name={"Q.A Survey"}
          trueback={true}
          parentName={APIaudit?.name}
        />
        <div
          className="qasurveycontainer"
          style={{
            width: tableSize ? "100%" : "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div className="backToReport">
            <button
              className="cmn_btn"
              onClick={handleEditClick}
              disabled={editMode}
              style={{
                backgroundColor: "#5571DF",
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Edit
            </button>
            {editMode && (
              <button
                className="cmn_btn"
                style={{ backgroundColor: "#d6d6d6", color: "#000" }}
                onClick={() => handleCancelClick()}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L10.9989 10.9989"
                    stroke="#292D32"
                    stroke-width="1.76758"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.00107 10.9989L11 1"
                    stroke="#292D32"
                    stroke-width="1.76758"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Cancel Edit
              </button>
            )}
            <button
              className="cmn_btn"
              onClick={handleSaveClick}
              disabled={!editMode}
              style={{
                backgroundColor: editMode ? "#65CC5C" : "#7A7A7A",
              }}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Save
            </button>
          </div>
          <div className="questionnaire-qa-survey">
            {Object.entries(groupedData).map(([sectionId, questions]) => (
              <div key={sectionId} className="section-qa-survey">
                <h2>{sectionId}</h2>
                <div className="sub-section">
                  {questions.map((item) => (
                    <div key={item?.id} className="question-qa-survey">
                      <h6>{item.question}</h6>

                      {/* Render Answer Types */}
                      {item.answer === "boolean" ? (
                        <div>
                          <div className="boolean-qa-survey">
                            <label>
                              <input
                                type="radio"
                                name={`question-${item?.id}`}
                                value="Yes"
                                checked={item.value === "Yes"}
                                onChange={() =>
                                  handleRadioChange(item?.id, "Yes")
                                }
                                disabled={!editMode}
                              />
                              Yes
                            </label>
                            <label style={{ marginLeft: "10px" }}>
                              <input
                                type="radio"
                                name={`question-${item.id}`}
                                value="No"
                                checked={item.value === "No"}
                                onChange={() =>
                                  handleRadioChange(item?.id, "No")
                                }
                                disabled={!editMode}
                              />
                              No
                            </label>
                          </div>
                        </div>
                      ) : item.answer === "text" ? (
                        <div className="lbl_txa">
                          <textarea
                            type="text"
                            value={item.value || ""}
                            onChange={(e) =>
                              handleRadioChange(item?.id, e.target.value)
                            }
                            placeholder="Type your answer here"
                            disabled={!editMode}
                          />
                        </div>
                      ) : item.answer === "checkbox" ? (
                        <div className="checkbox-qa-survey">
                          {[
                            "Very Satisfied",
                            "Satisfied",
                            "Dissatisfied",
                            "Very Dissatisfied",
                          ].map((option) => (
                            <div key={option}>
                              <label style={{ display: "flex" }}>
                                <input
                                  type="radio"
                                  name={`question-${item?.id}`}
                                  value={option}
                                  checked={item.value === option}
                                  style={{ marginRight: ".5vw" }}
                                  onChange={() => {
                                    handleRadioChange(item?.id, option);
                                  }}
                                  disabled={!editMode}
                                />
                                {option}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <>
                          <strong>Answer Type:</strong> {item.answer} <br />
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AnswerQA;
