import React, { useState, useEffect, useRef } from "react";
import { fetchDataV2 } from "./apiUtils";
import cut from "../images/cut.svg";
import "../css/department.css";

const BusinessMultiOptionSelector = ({
  setIData,
  typeData,
  apiUrl,
  setSelectorId,
  setSelectorError,
  selectorError,
  Name,
  title,
  initialId,
  initialName,
  editMode,
  makeTrue,
  setTrue,
  tabIndex=0
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null); // Single object { id, Name }
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  // Reset selection when makeTrue is triggered
  useEffect(() => {
    if (makeTrue) {
      setSelected(null);
    }
  }, [makeTrue]);

  // Fetch data from API or use provided typeData
  useEffect(() => {
    const fetchData = async () => {
      if (apiUrl) {
        const parameter = {
          url: apiUrl,
          setterFunction: setData,
          setErrors: setSelectorError,
        };
        await fetchDataV2(parameter);
      } else if (typeData) {
        setData(typeData);
      }
    };

    fetchData();
  }, [apiUrl, typeData, setSelectorError]);

  // Set initial selected value
  useEffect(() => {
    if (initialId && initialName && data.length > 0) {
      const initialSelection = { id: initialId, Name: initialName };
      setSelected(initialSelection);
      setSelectorId(initialId);
      setSelectorError(false);
    }
  }, [initialId, initialName, data, setSelectorError, setSelectorId]);

  // Focus input when expanded
  useEffect(() => {
    if (expanded) {
      inputRef.current.focus();
    }
  }, [expanded]);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle selection change
  const handleSelectionChange = (departmentId, departmentName, unit) => {
    const newSelection = { id: departmentId, Name: departmentName };
    setSelected(newSelection);
    setSelectorId(departmentId);
    setSelectorError(false);

    if (typeof setIData === "function") {
      setIData(unit);
    }
  };

  // Handle click on dropdown options
  const handleDivClick = (departmentId, departmentName, unit) => {
    setTrue(false);
    handleSelectionChange(departmentId, departmentName, unit);
  };

  // Handle search input change
  const handleSearchChangeDepts = (event) => {
    setSearchTerm(event.target.value);
  };

  // Toggle dropdown expansion
  const toggleExpandDept = () => {
    setExpanded(!expanded);
  };

  // Remove selected department
  const handleRemoveSelected = () => {
    setSelected(null);
    setSelectorId(null);
    setSelectorError(true);
  };

  return (
    <div className="lbl_selector">
      <label>{title}</label>

      <div
        ref={dropdownRef}
        className="ref_div"
        style={{
          border: selectorError ? "1px solid #FF4646" : "",
          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
        }}
        tabIndex={tabIndex}
        onKeyDown={(e) => {
          if (e.key === "Enter" && editMode) {
            toggleExpandDept();
          }
          else if (e.key === "Tab") {
            setExpanded(false);
          }
        }}
      >
        <div
          className="sub_ref_div"
          onClick={() => {
            if (editMode) {
              toggleExpandDept();
            }
          }}
        >
          {!selected ? (
            <p className="slct_plchldr">{title}</p>
          ) : (
            <span
              className="slct_tag"
              style={{
                backgroundColor: editMode ? "#5757df" : "#5f5f5f",
              }}
            >
              <span>{selected.Name}</span>
              <img
                src={cut}
                alt="Remove"
                onClick={() => {
                  if (editMode) {
                    handleRemoveSelected();
                  }
                }}
              />
            </span>
          )}
        </div>

        <div
          className="opt_drp_dwn"
          style={{
            visibility: expanded ? "visible" : "hidden",
            height: expanded ? "auto" : "0vh",
          }}
        >
          <input
            ref={inputRef}
            type="text"
            placeholder={`Search ${title}`}
            value={searchTerm}
            onChange={handleSearchChangeDepts}
          />

          <div>
            {data.filter((unit) =>
              unit[Name]
                ? unit[Name].toLowerCase().includes(searchTerm.toLowerCase())
                : true
            ).length === 0 ? (
              <div className="noDataFound">No data found</div>
            ) : (
              data
                .filter((unit) =>
                  unit[Name]
                    ? unit[Name].toLowerCase().includes(searchTerm.toLowerCase())
                    : true
                )
                .map((unit) => (
                  <div
                    key={unit.id}
                    className="opt_drp_dwn_optns"
                    onClick={() =>
                      handleDivClick(unit.id, unit[Name], unit)
                    }
                  >
                    <label>{unit[Name]}</label>
                  </div>
                ))
            )}
          </div>

          <div className="closebutton" onClick={toggleExpandDept}>
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessMultiOptionSelector;
