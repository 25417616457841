import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate, useParams } from "react-router-dom";

import "../../css/viewuser.css";
import { fetchDataV2 } from "../apiUtils";
import { issueTracking } from "../subroutes";
import openarrow from "../../images/openarrow.svg";
// import "../issues.css";
// import Select from "react-select";
import RenderIcons from "../RenderIcons";
import NavRow from "../NavRow";

const AuditIssue = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [IssueData, setIssueData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const [auditData1, setAuditData1] = useState([]);
  
  const originalUrl = location.state?.from;
  const {auid}=useParams()
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/audit-entities/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
  }, []);
  
  useEffect(() => {
    const parameter = {
      url: `/api/issues/?audit_id=${auid}`,
      setterFunction: setIssueData,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
  }, []);





  const issueStatusOptions = [
    { value: "open", label: "To Do" },
    { value: "in progress", label: "In Progress" },
    { value: "review", label: "Review" },
    { value: "done", label: "Done" },
  ];

  const issueRatingOptions = [
    { value: "high", label: "High" },
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
  ];

  const [filters, setFilters] = useState({
    id: "",
    objective: "",
    rating: "",
    status: "",
    name: "",
    created_at: "",
  });

  const handleFilterChange = (filterKey, e, selectedOption) => {
    var value;
    if (!selectedOption) {
      // When selectedOption is null (i.e., when clearing the selection)
      value = e ? e.target.value.toLowerCase() : ""; // In case of text inputs
    } else {
      value = selectedOption ? selectedOption.value : ""; // For dropdowns
    }

    setFilters({ ...filters, [filterKey]: value });
  };

  const filteredData = IssueData.filter((data) => {
    const id = filters.id ? data.id.toString() : "---";
    const idMatch = id?.includes(filters.id.toString());
    // const auid=filters.audit ? data?.audit?.toString() : "---";
    // const auidMatch = auid?.includes(filters?.auid?.toString());
    const title = filters.objective ? data.objective.toLowerCase() : "";
    const titleMatch = title?.includes(filters.objective);

    const rating = filters?.observation
      ? data?.observation?.rating?.toLowerCase()
      : "--";
    const ratingMatch = rating?.includes(filters.rating);

    const status = filters.status ? data.status.toLowerCase() : "";
    const statusMatch = status?.includes(filters.status);

    const owner = filters?.owner ? data?.owner?.name?.toLowerCase() : "--";
    const nameMatch = owner?.includes(filters.name);



    const date = filters.created_at
      ? new Date(data.created_at).toLocaleDateString("en-US")
      : "---";
    const dateMatch = date?.includes(filters.created_at);

    return (
      // auidMatch 
      // &&
      idMatch
      &&
      titleMatch
      &&
      ratingMatch
      &&
      nameMatch &&
      dateMatch &&
      statusMatch
    );
  });
  const showStatusColor = (status) => {
    switch (status) {
      case "Open":
        return <span className="status_todo">{status}</span>;
      case "Closed":
        return <span className="status_complete">{status}</span>;
      case "In Progress":
        return <span className="status_inprogress">{status}</span>;

      default:
        return <span className="status_rejected">No Status Found</span>;
    }
  };
  const showRatingColor = (status) => {
    switch (status) {
      case "Low":
        return <span className="status_low">{status}</span>;
      case "Medium":
        return <span className="status_medium">{status}</span>;
      case "High":
        return <span className="status_high">{status}</span>;
      case "Very High":
        return <span className="status_veryhigh">{status}</span>;
      case "Critical":
        return <span className="status_critical">{status}</span>;

      default:
        return <span className="status_rejected">No Status Found</span>;
    }
  };

  useEffect(() => {
    console.log(filteredData)
  }, [filteredData])
 useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${auid}/`,
      setterFunction: setAuditData1,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);
  const name=auditData1.name

  const ViewIssue = (data) => {
    const issueid = data;
    navigate(`/issue-tracking/analytics/audit-issue/${auid}/${issueid}`);
  };

   const viewaudit=(auditid)=>{
    const auidtid=auditid;
    navigate(`/audit-execution/${auidtid}/detail`)
   }

  console.log(location)
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={issueTracking} />
        <NavRow name={name}  idtrue={true} parentName={name} />



        <div
          className="issues_table_container"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <table>
            <thead>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">Isuse ID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("id", e)}
                    value={filters.id}
                  />
                </th>
                <th>
                  <h4 className="thead">Audit ID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("id", e)}
                    value={filters.auid}
                  />
                </th>

                <th>
                  <h4 className="thhead"> Issue Title </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("objective", e)}
                    value={filters.objective}
                  />
                </th>

                {/* <th>
                  <h4 className="thhead"> Audit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("businessUnit", e)}
                    value={filters.businessUnit}
                  />
                </th> */}

                <th>
                  <h4 className="thhead">Issue Rating</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("businessUnit", e)}
                    value={filters.businessUnit}
                  />
                  {/* <Select
                    options={issueRatingOptions}
                    placeholder="Select Rating"
                    classNamePrefix="custom-select"
                    onChange={(selectedOption) =>
                      handleFilterChange("rating", null, selectedOption)
                    }
                    value={
                      issueRatingOptions.find(
                        (option) => option.value === filters.rating
                      ) || null
                    }
                    isClearable
                    isSearchable
                  /> */}
                </th>

                <th>
                  <h4 className="thhead">Issue Status</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("businessUnit", e)}
                    value={filters.businessUnit}
                  />  
                  {/* <Select
                    options={issueStatusOptions}
                    placeholder="Select Status"
                    classNamePrefix="custom-select"
                    onChange={(selectedOption) =>
                      handleFilterChange("status", null, selectedOption)
                    }
                    value={
                      issueStatusOptions.find(
                        (option) => option.value === filters.status
                      ) || null
                    }
                    isClearable
                    isSearchable
                  /> */}
                </th>

                <th>
                  <h4 className="thhead">Identified By</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("name", e)}
                    value={filters.name}
                  />
                </th>

                <th>
                  <h4 className="thhead">Identified Date</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("created_at", e)}
                    value={filters.created_at}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredData.sort((u,v)=>u.id-v.id).map((data) => (
                <tr onClick={() => ViewIssue(data.id)} key={data.id}>
                  <td>
                    <span className="status_todo">{data.id}</span>
                  </td>
                  <td onClick={(e)=>{
                    e.stopPropagation();
                    viewaudit(data.audit.id)
                  }
                  }><span className="status_todo">{data.audit.id}</span></td>
                  <td>{data.objective ? data.objective : ""}</td>
                  {/* <td>
                    <span>
                      {data.observation?.audit ? data.observation?.audit : ""}
                    </span>
                  </td> */}
                  <td>
                    <span>
                      {showRatingColor(data.observation?.rating ? data.observation?.rating : "")}
                    </span>
                  </td>
                  <td>
                    <span>
                      {showStatusColor(data?.status ? data?.status : "")}
                    </span>
                  </td>
                  <td>{data?.owner ? data.owner?.name : ""}</td>
                  <td>
                    {data.created_at
                      ? new Date(data.created_at).toLocaleDateString()
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>

        <div className="cardscontainer">
          {filteredData.map((data) => (
            <div className="deptcard" key={data.id} onClick={() => ViewIssue(data.id)}>
              <h1>Issue ID: {data.id}</h1>
              <h2>Title: {data.objective || "No Title"}</h2>
              <h3>Rating: {data.observation?.rating || "No Rating"}</h3>
              <img src={openarrow} alt="arrow" />
            </div>
          ))}
        </div>


      </section>
    </>
  );
};

export default AuditIssue;
