import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import LoginForm from "./components/LoginForm";
import Top from "./components/Top";
import SideBar from "./components/SideBar";
import Users from "./components/user/Users";
import CreateUser from "./components/user/CreateUser";
import Viewuser from "./components/user/ViewUser";
import ForgotPassword from "./components/user/ForgotPassword";
import Department from "./components/department/Departments";
import Createdepartment from "./components/department/CreateDepartment";
import Viewdepartment from "./components/department/ViewDepartment";
import Businessunit from "./components/businessunit/Businessunit";
import Createbu from "./components/businessunit/CreateBu";
import Viewbusinessunit from "./components/businessunit/ViewBusinessunit";
import Teams from "./components/Teams/Teams";
import ViewTeams from "./components/Teams/ViewTeams";
import CorporateStructure from "./components/corporateStructure/CorporateStructure";
import Auditentity from "./components/auditUniverse/auditEntities/AuditEntity";
import Createentity from "./components/auditUniverse/auditEntities/CreateEntity";
import Viewaudit from "./components/auditUniverse/auditEntities/ViewAudit";
import Userhome from "./components/auditUniverse/userHome/Userhome";
import Riskassessment from "../src/components/auditUniverse/riskAssesment/Riskassessment";
import RiskassessmentTable from "../src/components/auditUniverse/riskAssesment/RiskassesmentTable";
import CreateQuestionnaire from "./components/auditUniverse/riskAssesment/createQuestionnaire/CreateQuestionnaire";
import CreateRiskAssessment from "./components/auditUniverse/riskAssesment/createRiskAssessment/CreateRiskAssessment";
import AnswerQuestionnaire from "./components/auditUniverse/riskAssesment/answerQuestionnaire/AnswerQuestionnaire";
import AnswerQA from "./components/auditExecution/auditWrapUp/AnswerQA/AnswerQA";
import CalenderViewPage from "./components/auditUniverse/auditPlan/CalenderViewPage";
import AuditPlanTable from "./components/auditUniverse/auditPlan/AuditPlanTable";
import GraphViewRa from "./components/auditUniverse/riskAssesment/graphViewRa/GraphViewRa";
import AuditExecution from "./components/auditExecution/AuditExecution";
import RcmTemplate from "./components/auditExecution/RcmTemplate";
import QaCheckTemplate from "./components/auditExecution/QaCheckTemplate";
import QaSurveyTemplate from "./components/auditExecution/QaSurveyTemplate";
import Createaudit from "./components/auditExecution/createaudit/Createaudit";
import ViewAudits from "./components/auditExecution/createaudit/Viewaudit";
import Auditplan from "./components/auditExecution/auditplan/Auditplan";
import Schedulemeeting from "./components/auditExecution/auditplan/schedulemeeting/Schedulemeeting";
import Createauditmemo from "./components/auditExecution/auditplan/auditmemo/Createauditmemo";
import CreateInitialReqList from "./components/auditExecution/auditplan/initialreqlist/CreateInitialReqList";
import GenericTask from "./components/auditExecution/auditplan/GenericTask";
import RiskControlMatrix from "./components/auditExecution/auditplan/RiskControlMatrix";
import AuditFieldwork from "./components/auditExecution/auditfieldwork/AuditFieldwork";
import ViewAuditFieldwork from "./components/auditExecution/auditfieldwork/ViewAuditFieldwork";
import AuditReporting from "./components/auditExecution/auditReporting/AuditReporting";
import Issuetracking from "./components/issueTracking/Issuetracking";
import AuditIssue from "./components/issueTracking/AuditIssue";
import Issues from "./components/issueTracking/issuespage/Issues";
import CreateIssue from "./components/issueTracking/issuespage/CreateIssue";
import AuditWrapUp from "./components/auditExecution/auditWrapUp/AuditWrapUp";
import CreateObservation from "./components/auditExecution/auditReporting/createObservation/CreateObservation";
import CreateTask from "./components/auditExecution/auditplan/createtask/CreateTask";
import ViewTask from "./components/auditExecution/auditplan/viewTask/ViewTask";
import ListOfRcapPage from "./components/auditUniverse/rcm/ListOfRcapPage";
import ListOfAllRcapPage from "./components/auditUniverse/rcm/ListOfAllRcapPage";
import ViewObservation from "./components/auditExecution/auditReporting/createObservation/viewObservation";
import Initial from "./components/auditExecution/auditReporting/Observations/Initial";
import QAInitaiApproval from "./components/auditExecution/auditReporting/Observations/QAInitaiApproval";
import BusinessInitial from "./components/auditExecution/auditReporting/Observations/BusinessInitial";
import CreateRCAP from "./components/auditUniverse/rcm/createRcm/CreateRCAP";
import RcmTask from "./components/auditUniverse/rcm/RcmTask";
import ViewRcmTask from "./components/auditUniverse/rcm/RcmTask";
import ViewRCAP from "./components/auditUniverse/rcm/createRcm/ViewRCAP";
import PDFPage from "./components/auditExecution/pdfpage/PDFPage";
import CreateWorkpaper from "./components/auditExecution/auditfieldwork/CreateAuditFieldwork";
import DepartmentRCMPage from "./components/auditUniverse/rcm/departmentViewPage/DepartmentRCMPage";
import ReportingTasksPage from "./components/auditExecution/auditReporting/ReportingTasksPage/ReportingTasksPage";
import GenerateReportList from "./components/auditExecution/auditReporting/GenerateReportList";
import GenerateReport from "./components/auditExecution/auditReporting/Observations/GenerateReport";
import QAChecklist from "./components/auditExecution/auditWrapUp/QAChecklistFolder/QAChecklist";
import FileManager from "./components/auditExecution/fillemanager/FileManager";
import Issuedetailpage from "./components/issueTracking/issuedetailpage/Issuedetailpage";
import AuditReport from "./components/auditExecution/auditReporting/AuditReport";
import ScheduleMeetingSendInitial from "./components/auditExecution/auditReporting/ScheduleMeetingSendInitial";
import GenerateReportMoreDetails from "./components/auditExecution/auditReporting/GenerateReportMoreDetails";
import PublishReport from "./components/auditExecution/auditReporting/PublishReport";
// import CreateQasurvey from "./components/auditExecution/auditWrapUp/QASurveyFolder/CreateQasurvey";
import AnswerQASurvey from "./components/auditExecution/auditWrapUp/AnswerQA/AnswerQA";
import FinalizeDraft from "./components/auditExecution/auditReporting/Observations/FinalizeDraft";
import QAFinalApproval from "./components/auditExecution/auditReporting/Observations/QAFinalApproval";
import AuditDirectorApproval from "./components/auditExecution/auditReporting/Observations/AuditDirectorApproval";
import AuditAnalytics from "./components/issueTracking/AuditAnalytics/AuditAnalytics";
import DetailRcapPwa from "./components/auditUniverse/rcm/DetailRcapPWA/DetailRcapPwa";
import UserSetting from "./components/user/UserSetting";
import ErrorPage from "./ErrorPage";
const QaRoutes = ({ tableSize }) => {
  return (
    <>
     <Route path="*" element={<ErrorPage errorCode={404} />} />
     <Route path="/server-error" element={<ErrorPage errorCode={500} />} />
      <Route
        path="/templates/"
        element={<Navigate replace to="/templates/qachecklist" />}
      />
      <Route
        path="/templates/qachecklist"
        element={<QaCheckTemplate tableSize={tableSize} />}
      />
      <Route
        path="/templates/qasurvey"
        element={<QaSurveyTemplate tableSize={tableSize} />}
      />

      <Route path="/" element={<Userhome tableSize={tableSize} />} />
      <Route
        path="/audit-execution"
        element={<Navigate replace to="/audit-execution/Audits" />}
      />
      <Route
        path="/audit-execution/"
        element={<AuditExecution tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/Rcmtemp"
        element={<RcmTemplate tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/QAChecklist"
        element={<QaCheckTemplate tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/QaSurvey"
        element={<QaSurveyTemplate tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/Createaudit"
        element={<Createaudit tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/"
        element={<ViewAudits tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/Detail"
        element={<ViewAudits tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/file-manager"
        element={<FileManager tableSize={tableSize} />}
      />
      <Route
        path="/user-settings"
        element={<UserSetting tableSize={tableSize} />}
      />
      <Route
        path="/user-settings/:id/forgot-password"
        element={<ForgotPassword tableSize={tableSize} />}
      />
      {/* <Route
            path="/audit-execution/:id/planning"
            element={<Auditplan tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/Createtask"
            element={<CreateTask tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/RcmTask"
            element={<RcmTask tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/:planid"
            element={<ViewTask tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/:planid/riskcontrolmatrix/"
            element={<ListOfAllRcapPage tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/:planid/riskcontrolmatrix/:rid/"
            element={<ViewRCAP tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/:planid/riskcontrolmatrix/creatercap"
            element={<CreateRCAP tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/GenericTask"
            element={<GenericTask tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/planning/RiskControlMatrix"
            element={<RiskControlMatrix tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/ScheduleMeeting"
            element={<Schedulemeeting tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/Createauditmemo"
            element={<Createauditmemo tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/pdfpage"
            element={<PDFPage tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/planning/CreateInitialReqList"
            element={<CreateInitialReqList tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/Fieldwork"
            element={<AuditFieldwork tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/Fieldwork/CreateWorkpaper/"
            element={<CreateWorkpaper tableSize={tableSize} />}
          />
          <Route
            path="/audit-execution/:id/Fieldwork/:workId/"
            element={<ViewAuditFieldwork tableSize={tableSize} />}
          /> */}
      <Route
        path="/audit-execution/reporting/reportingTask"
        element={<ReportingTasksPage tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/:reportId/"
        element={<AuditReporting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/CreateObservation"
        element={<CreateObservation tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/wrapup-qa"
        element={<AuditWrapUp tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/wrapup-qa/qachecklist"
        element={<QAChecklist tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/wrapup-qa/qasurvey"
        element={<AnswerQA tableSize={tableSize} />}
      />
      {/* <Route path="/audit-execution/:id/WrapupQA/Qasurvey/CreateQasurvey" element={<CreateQasurvey tableSize={tableSize} />} /> */}
      <Route
        path="/audit-execution/planning/RiskControlMatrix"
        element={<RiskControlMatrix tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/planning/ScheduleMeeting"
        element={<Schedulemeeting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/planning/Createauditmemo"
        element={<Createauditmemo tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/planning/pdfpage"
        element={<PDFPage tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/planning/CreateInitialReqList"
        element={<CreateInitialReqList tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/Fieldwork"
        element={<AuditFieldwork tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/Fieldwork/CreateWorkpaper/"
        element={<CreateWorkpaper tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/Fieldwork/:workId/"
        element={<ViewAuditFieldwork tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/reporting/reportingTask"
        element={<ReportingTasksPage tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/"
        element={<ReportingTasksPage tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/initialobservation"
        element={<AuditReporting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/qaapprovalinitialobservation"
        element={<AuditReporting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/scheduleclosingmeeting/"
        element={<ScheduleMeetingSendInitial tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/businessownerapproval"
        element={<AuditReporting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/finalizeobservation"
        element={<AuditReporting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/generatereportlist"
        element={<GenerateReportList tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/generatereportmoredetails"
        element={<GenerateReportMoreDetails tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/qaapprovalfinalizeobservation"
        element={<AuditReporting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/auditdirectorapproval"
        element={<AuditReporting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/publishreport"
        element={<PublishReport tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/"
        element={<ReportingTasksPage tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingObservationCreation/:obejctionId/"
        element={<ViewObservation tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/initialobservation/:obejctionId/"
        element={<Initial tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/qaapprovalinitialobservation/:obejctionId/"
        element={<QAInitaiApproval tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/finalizeobservation/:obejctionId/"
        element={<FinalizeDraft tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/generatereportlist/:obejctionId/"
        element={<GenerateReport tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/qaapprovalfinalizeobservation/:obejctionId/"
        element={<QAFinalApproval tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/auditdirectorapproval/:obejctionId/"
        element={<AuditDirectorApproval tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/publishreport/:obejctionId/"
        element={<ViewObservation tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingObservationUpdate/"
        element={<ReportingTasksPage tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingReportCreation/"
        element={<ReportingTasksPage tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingReportCreation/"
        element={<ReportingTasksPage tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingObservationCreation/"
        element={<ReportingTasksPage tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingObservationCreation/:reportId/"
        element={<AuditReporting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingObservationUpdate/:reportId/"
        element={<AuditReporting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingReportCreation/:reportId/"
        element={<AuditReporting tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/Report"
        element={<AuditReport tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/wrapup-qa"
        element={<AuditWrapUp tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingObservationCreation/:reportId/CreateObservation/"
        element={<CreateObservation tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingObservationUpdate/:reportId/:obejctionId/"
        element={<ViewObservation tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingReportCreation/:reportId/:obejctionId/"
        element={<ViewObservation tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/reportingObservationCreation/:reportId/:obejctionId/"
        element={<ViewObservation tableSize={tableSize} />}
      />
      <Route
        path="/audit-execution/:id/reporting/Observation/:obejctionId"
        element={<ViewObservation tableSize={tableSize} />}
      />
      <Route path="*" element={<> not found </>} />
    </>
  );
};

export default QaRoutes;
