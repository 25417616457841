import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import "../../../css/global.css";
import "../riskAssesment/riskassessment.css";
import DeleteIcon from "../../../images/deleteBtn.svg";
import createBtnfile from "../../../images/createbutton.svg";
import {
  deleteDataV2,
  fetchDataV2,
  postDataV2,
  fetchDataObjectV2,
  postDataWithFile,
} from "../../apiUtils";
import { auditRoutes, auditRoutesBu } from "../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import ReactPaginate from "react-paginate";

const Riskassessment = ({ tableSize }) => {
  const [APIData, setAPIData] = useState({});
  const [APIAllData, setAPIAllData] = useState([]);
  const [APIAllDataPlan, setAPIAllDataPlan] = useState([]);
  const [removeId, setRemoveId] = useState(false);
  const [isAnyOpen, setIsAnyOpen] = useState(false);
  const [isAnyOpenPlan, setIsAnyOpenPlan] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [expandSubTable, setSubExpandTable] = useState({});
  const [toggleAll, setToggleAll] = useState(false);
  const [Errors, setErrors] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [entityFiles, setEntityFiles] = useState(new Map());
  const [uploadStatus, setUploadStatus] = useState(new Map());
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [successMessageFileUpload, setSuccessMessage] = useState(false);
  const [showSelectFileButton, setShowSelectFileButton] = useState(true);
  const [file, setFile] = useState(null);

  const [currentPageTop, setCurrentPageTop] = useState(0);
  const itemsPerPage = 11; 

  const [currentPageBottom, setCurrentPageBottom] = useState(0);


  const userRoles = JSON.parse(localStorage.getItem("userRoles"));

  const BusinessRole = Object.keys(userRoles).some((key) =>
    key.includes("Business")
  );
  const user_id = localStorage.getItem("user_id");
  const AuditTeamRole = Object.keys(userRoles).some((key) =>
    key.includes("Audit Team")
  );

  const is_Admin = localStorage.getItem("is_Admin");
  const { raid } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [activeView, setActiveView] = useState("List View");
  const [entitycount, setEntityCount] = useState([]);
  const location = useLocation();
  const [entityCounts, setEntityCounts] = useState(new Map());
  const hasbusinessRole = Object.keys(userRoles).some((key) =>
    key.includes("Business")
  );

  const successMessage = location.state?.successMessage;

  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/audit-plan/`,
      setterFunction: (data) => {
        setAPIAllDataPlan(data);
        setLoading(false);
      },
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    setLoading(true);

    const parameter = {
      url: `/api/audit_universe/risk-assessments/${raid}/`,
      setterFunction: (data) => {
        setAPIData(data);
        setLoading(false);
      },
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);

    const anyOpenPlan = APIAllDataPlan.some((item) => item.status === "Open");
    setIsAnyOpenPlan(anyOpenPlan);
    const anyOpen = APIData.status === "Open";

    setIsAnyOpen(anyOpen);
  }, [raid]);

  useEffect(() => {
    if (APIData.risk_analysis) {
      const auditEntityIds = APIData.risk_analysis.map(
        (analysis) => analysis.audit_entity.id
      );

      auditEntityIds.forEach((id) => {
        const parameter = {
          url: `/api/audit_universe/upload/${id}/count/`,
          setterFunction: (data) => {
            setEntityCounts((prevCounts) =>
              new Map(prevCounts).set(id, data.answer_count)
            );
          },
          setErrors: setErrors,
        };

        fetchDataV2(parameter);
      });
    }
  }, [APIData]);

  const toggleAllTables = () => {
    setToggleAll(!toggleAll);
    const expandedAll = {};
    if (APIData.risk_analysis) {
      APIData.risk_analysis.forEach(
        (analysis) => (expandedAll[analysis.audit_entity.name] = !toggleAll)
      );
    }
    setSubExpandTable(expandedAll);
  };

  const navigateGrapp = () => {
    navigate(`/audit-universe/risk-assessments/${raid}/graphview`);
  };

  const handleFileSelect = (event, entityId) => {
    const file = event.target.files[0];
    if (file) {
      setEntityFiles((prev) => {
        const updatedFiles = new Map(prev);
        const existingFiles = updatedFiles.get(entityId) || [];
        updatedFiles.set(entityId, [...existingFiles, file]);
        return updatedFiles;
      });
    }
  };
  const handleFileRemove = (entityId, index) => {
    setEntityFiles((prev) => {
      const updatedFiles = new Map(prev);
      const files = updatedFiles.get(entityId) || [];
      files.splice(index, 1);
      if (files.length > 0) {
        updatedFiles.set(entityId, files);
      } else {
        updatedFiles.delete(entityId);
      }
      return updatedFiles;
    });
  };

  const handleUpload = (entityId) => {
    const files = entityFiles.get(entityId) || [];
    if (files.length === 0) {
      setErrors("Please select a file to upload.");
      return;
    }

    const parameter = {
      url: "/api/audit_universe/questions-risk/upload/",
      files,
      data: {
        audit_entity_id: entityId,
        risk_assessment_id: raid,
      },
      setErrors: setErrors,
      setSuccessMessage: () => {
        setUploadStatus((prev) => new Map(prev).set(entityId, "success"));
      },
    };

    postDataWithFile(parameter);

    setEntityFiles((prev) => {
      const updatedFiles = new Map(prev);
      updatedFiles.delete(entityId);
      return updatedFiles;
    });
  };

  const goToAnswer = (analysis) => {
    if (!analysis || !analysis.questionnaire) {
      return;
    }
    return function () {
      navigate(hasbusinessRole?`/risk-assessments/${raid}/answer-questionnaire`:`/audit-universe/risk-assessments/${raid}/answer-questionnaire`, {
        state: { questionnaire_id: analysis.questionnaire.id },
      });
    };
  };

  const createRisk = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const parameter = {
      url: `/api/audit_universe/risk-assessments/`,
      payload: {
        title: `Risk Assessment For The Year Of ${year}`,
        assessment_date: `${year}-${month}-${day}`,
        details: "start risk assessment now",
      },
      setErrors: setErrors,
    };

    postDataV2(parameter);
  };

  const filteredRiskAnalyses = APIData.risk_analysis
    ? APIData.risk_analysis.filter((analysis) =>
        analysis.audit_entity.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    : [];

  const renderStatus = (status) => {
    switch (status) {
      case "Open":
        return <span className="status_todo">Planned</span>;
      case "Closed":
        return <span className="status_complete">Done</span>;

      default:
        return <span className="status_inprogress">In Progress</span>;
    }
  };

  const createPlan = () => {
    const parameter = {
      url: `/api/audit_universe/audit-plan/`,
      payload: {
        risk_assessment_id: APIData.id,
        title: `Audit Plan for ${APIData.title}`,
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-universe/audit-planning`,
      state: {
        successMessage: "Audit Plan Created Successfully",
      },
    };

    postDataV2(parameter);
  };



  const toggleListView = () => {
    setActiveView("List View");
  };

  const toggleGraphView = () => {
    setActiveView("Graph View");
    navigateGrapp();
  };

  const deleterisksassessmentdata = () => {
    const parameter = {
      url: `/api/audit_universe/risk-assessments/${raid}`,
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/auditUniverse/riskAssesment`,
    };

    deleteDataV2(parameter);
  };
  const handlePopupClose = () => {
    setShowDeletePopup(true);
  };

  const handlePopupCloseNo = () => {
    setShowDeletePopup(false);
  };

  const trueRisk = {
    toggleListView: toggleListView,
    activeView: activeView,
    toggleGraphView: toggleGraphView,
    activeView: activeView,
    deleterisksassessmentdata: deleterisksassessmentdata,
    DeleteIcon: DeleteIcon,
    APIDataStatus: APIData.status,
    isAnyOpenPlan: isAnyOpenPlan,
    createRisk: createRisk,
    createPlan: createPlan,
    showDeletePopup: showDeletePopup,
    handlePopupClose: handlePopupClose,
    handlePopupCloseNo: handlePopupCloseNo,
    APIData: APIData,
    setAPIData: setAPIData,
  };


// Pagination Logic
const pageCountTop = Math.ceil(filteredRiskAnalyses.length / itemsPerPage);
const startOffsetTop = currentPageTop * itemsPerPage;
const paginatedDataTop = filteredRiskAnalyses.slice(
  startOffsetTop,
  startOffsetTop + itemsPerPage
);

const handlePageClickTop = ({ selected }) => {
  setCurrentPageTop(selected);
};


// Pagination Logic
const pageCountBootom = Math.ceil(filteredRiskAnalyses.length / itemsPerPage);
const startOffsetBotton = currentPageBottom * itemsPerPage;
const paginatedDataBotton = filteredRiskAnalyses.slice(
  startOffsetBotton,
  startOffsetBotton + itemsPerPage
);

const handlePageClickBotton = ({ selected }) => {
  setCurrentPageBottom(selected);
};
const name=APIData.title

  return (
    <>
            {Errors && <p className="errormessage">{Errors}</p>}
      <section className="navroutes">
        <RenderIcons
          homeRoutes={BusinessRole && !is_Admin ? auditRoutesBu : auditRoutes}
        />
        <NavRow name={name} trueRisk={trueRisk} />

        <div
          className="aetablecontainer"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <div className="riskassesstable1">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>Audit Entity</th>
                  <th>Status</th>
                  <th>Upload Questionnaire</th>
                  <th>Action</th>
                  <th>Reminder</th>
                </tr>
              </thead>

              <tbody>
                {paginatedDataTop.every(
                  (analysis) => analysis.questionnaire.status === "Closed"
                ) ? (
                  // If all questionnaires are closed, show the message
                  <tr>
                    <td colSpan={5}>
                      <h2 style={{ textAlign: "center" }}>
                        All questionnaire responses have been submitted and
                        approved
                      </h2>
                    </td>
                  </tr>
                ) : (
                  // Otherwise, render the analyses
                  paginatedDataTop
                    .filter((analysis) => {
                      const hasBusinessRole = Object.keys(userRoles).some(
                        (key) => key.includes("Business")
                      );

                      // If 'Business' is found, apply the third filter condition
                      if (hasBusinessRole) {
                        return (
                          (analysis.questionnaire.status === "Open" ||
                            analysis.questionnaire.status === "Submitted") &&
                          (analysis.audit_entity.key_stakeholder.user_id ==
                            user_id ||
                            analysis.audit_entity.other_stakeholders.some(
                              (stakeholder) => stakeholder.user_id == user_id
                            ))
                        );
                      }

                      // If 'Business' is not found, return only based on status and user_id
                      return (
                        analysis.questionnaire.status === "Open" ||
                        analysis.questionnaire.status === "Submitted"
                      );
                    })
                    .sort((a, b) =>
                      a.audit_entity.name.localeCompare(b.audit_entity.name)
                    )
                    .map((analysis, index) => {
                      const entityId = analysis.audit_entity.id;
                      const files = entityFiles.get(entityId) || [];
                      const isUploaded = uploadStatus.get(entityId) === "success";
                      return (
                        <React.Fragment key={index}>
                          <tr className="riskBU" style={{ height: "70px" }}>
                            <td onClick={goToAnswer(analysis)}>
                              <h6 className="h6_intersemibold">
                                {analysis.audit_entity.name}
                              </h6>
                            </td>
                            <td>
                              {renderStatus(analysis.questionnaire.status)}
                            </td>
                            <td>
                              {console.log(entityCounts)}
                              {!isUploaded &&entityCounts.get(entityId) === 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="questionnaire_file_name">
                                    {files.map((file, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <h6>{file.name}</h6>
                                        <svg
                                          onClick={() =>
                                            handleFileRemove(entityId, index)
                                          }
                                          width="10"
                                          height="10"
                                          viewBox="0 0 12 12"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1 1L10.9989 10.9989"
                                            stroke="#292D32"
                                            strokeWidth="1.76758"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M1.00107 10.9989L11 1"
                                            stroke="#292D32"
                                            strokeWidth="1.76758"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    ))}
                                  </div>
                                  <button
                                    className="select-file-button"
                                    onClick={() =>
                                      document
                                        .getElementById(`fileInput-${entityId}`)
                                        .click()
                                    }
                                  >
                                    <img src={createBtnfile} alt="" />
                                    <input
                                      type="file"
                                      id={`fileInput-${entityId}`}
                                      style={{ display: "none" }}
                                      onChange={(e) =>
                                        handleFileSelect(e, entityId)
                                      }
                                    />
                                  </button>
                                  <button
                                    className="cmn_btn"
                                    style={{ backgroundColor: "#65CC5C" }}
                                    onClick={() => handleUpload(entityId)}
                                  >
                                    Upload Files
                                  </button>
                                </div>
                              )}
                            </td>

                            <td className="score_tag">
                              <button
                                className="cmn_btn"
                                style={{
                                  backgroundColor: "#5771DF",
                                  display: "inline-flex",
                                }}
                                onClick={goToAnswer(analysis)}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 20 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 5H12C14 5 14 4 14 3C14 1 13 1 12 1H8C7 1 6 1 6 3C6 5 7 5 8 5Z"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12 20.9995H7C2 20.9995 1 18.9995 1 14.9995V8.99953C1 4.43953 2.67 3.19953 6 3.01953"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M14 3.01953C17.33 3.19953 19 4.43953 19 8.99953V14.9995C19 18.9995 17.33 20.9995 12 20.9995Z"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Answer
                              </button>
                            </td>
                            <td></td>
                          </tr>
                        </React.Fragment>
                      );
                    })
                )}
              </tbody>
            </table>
            <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCountTop}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClickTop}
            containerClassName="pagination-container"
            activeClassName={"active"}
          />
          </div>

          <div className="riskassesstable2" style={{ width: "100%" }}>
            {(!BusinessRole || AuditTeamRole) && (
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>
                      <span onClick={toggleAllTables}>
                        <div className="tableToggle">
                          {toggleAll ? (
                            <span className="show_details">Hide Details</span>
                          ) : (
                            <span className="show_details">Show Details</span>
                          )}{" "}
                        </div>
                      </span>
                    </th>
                    <th>Impact</th>
                    <th>Likelihood</th>
                    <th>Risk Score</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {paginatedDataBotton.every(
                    (analysis) =>
                      analysis.questionnaire.status === "Open" ||
                      analysis.questionnaire.status === "Submitted"
                  ) ? (
                    // If all questionnaires are closed, show the message
                    <tr>
                      <td colSpan={5}>
                        <h2 style={{ textAlign: "center" }}>
                          No Questionnaire is filled
                        </h2>
                      </td>
                    </tr>
                  ) : (
                    paginatedDataBotton
                      .filter(
                        (analysis) => analysis.questionnaire.status === "Closed"
                      ) // Filter analyses where status is "open"
                      .sort((a, b) =>
                        a.audit_entity.name.localeCompare(b.audit_entity.name)
                      ) // Sort the filtered analyses
                      .map((analysis, index) => (
                        <React.Fragment key={index}>
                          <tr
                            className="riskBU"
                            style={{ height: "70px" }}
                            onClick={goToAnswer(analysis)}
                          >
                            <td>
                              <div className="vsfvf">
                                <div className="toggle_btn_heading">
                                  <h6 className="h6_intersemibold">
                                    {analysis.audit_entity.name}
                                  </h6>
                                </div>
                              </div>
                            </td>

                            <td className="score_tag">
                              <span className="high">
                                {analysis["impact"] ?? "--"}
                              </span>
                            </td>

                            <td className="score_tag">
                              <span className="moderate">
                                {analysis["likelihood"] ?? "--"}
                              </span>
                            </td>

                            <td className="score_tag">
                              <span className="low">
                                {analysis["total score"] ?? "--"}
                              </span>
                            </td>

                            <td className="score_tag">
                              {renderStatus(analysis.questionnaire.status)}
                            </td>
                          </tr>

                          <tr>
                            <td
                              colSpan={7}
                              style={{ padding: "0px", height: "0px" }}
                            >
                              <table
                                className="innertable"
                                style={{
                                  display: toggleAll
                                    ? ""
                                    : expandSubTable[analysis.audit_entity.name]
                                    ? ""
                                    : "none",
                                  width: "100%",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>
                                      Financial <br /> Statement <br /> /
                                      Materiality
                                    </th>
                                    <th>
                                      Legal/ <br />
                                      Compliance
                                    </th>
                                    <th>Operational</th>
                                    <th>IT</th>
                                    <th>Total Impact</th>
                                    <th>
                                      Complexity <br /> of <br /> Process
                                    </th>
                                    <th>Volume</th>
                                    <th>
                                      Known <br /> Issues
                                    </th>
                                    <th>
                                      Changes in <br /> Personnel or <br />{" "}
                                      Processes
                                    </th>
                                    <th>Monitoring</th>
                                    <th>Total Likelihood</th>
                                    <th>
                                      Fraud Risk <br /> (F)
                                    </th>
                                    <th>
                                      Management <br /> Initiative <br /> (A)
                                    </th>
                                    <th>Total Score</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td>{analysis.audit_entity.name}</td>
                                    <td>
                                      {analysis["financial and materiality"] ??
                                        "--"}
                                    </td>
                                    <td>
                                      {analysis["legal & compliance"] ?? "--"}
                                    </td>
                                    <td>{analysis["operational"] ?? "--"}</td>
                                    <td>{analysis["it"] ?? "--"}</td>
                                    <td>{analysis["impact"] ?? "--"}</td>
                                    <td>
                                      {analysis["complexity of process"] ??
                                        "--"}
                                    </td>
                                    <td>{analysis["volume"] ?? "--"}</td>
                                    <td>{analysis["known issues"] ?? "--"}</td>
                                    <td>
                                      {analysis[
                                        "changes in personnel or processes"
                                      ] ?? "--"}
                                    </td>
                                    <td>{analysis["monitoring"] ?? "--"}</td>
                                    <td>{analysis["likelihood"] ?? "--"}</td>
                                    <td>{analysis["fraud risk"] ?? "--"}</td>
                                    <td>
                                      {analysis["management initiatives"] ??
                                        "--"}
                                    </td>
                                    <td>{analysis["total score"] ?? "--"}</td>
                                  </tr>
                                  {analysis.business_units.map((BU, bIndex) => (
                                    <React.Fragment key={bIndex}>
                                      <tr>
                                        <td>{BU.audit_unit.name}</td>
                                        <td>
                                          {BU["financial and materiality"] ??
                                            "--"}
                                        </td>
                                        <td>
                                          {BU["legal & compliance"] ?? "--"}
                                        </td>
                                        <td>{BU["operational"] ?? "--"}</td>
                                        <td>{BU["it"] ?? "--"}</td>
                                        <td>{BU["impact"] ?? "--"}</td>
                                        <td>
                                          {BU["complexity of process"] ?? "--"}
                                        </td>
                                        <td>{BU["volume"] ?? "--"}</td>
                                        <td>{BU["known issues"] ?? "--"}</td>
                                        <td>
                                          {BU[
                                            "changes in personnel or processes"
                                          ] ?? "--"}
                                        </td>
                                        <td>{BU["monitoring"] ?? "--"}</td>
                                        <td>{BU["likelihood"] ?? "--"}</td>
                                        <td>{BU["fraud risk"] ?? "--"}</td>
                                        <td>
                                          {BU["management initiatives"] ?? "--"}
                                        </td>
                                        <td>{BU["total score"] ?? "--"}</td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                  )}
                </tbody>
                <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCountBootom}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClickBotton}
            containerClassName="pagination-container"
            activeClassName={"active"}
          />
              </table>
            )}
           
          </div>
        </div>

        {showPopup && (
          <div className="popup">
            <p>Notification Sent</p>
          </div>
        )}
      </section>
    </>
  );
};

export default Riskassessment;
