import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import "../../css/viewuser.css";
import { justFetchDataV2, convertDate } from "../apiUtils";
import { auditBusiness, auditExecutionTop, auditExecutionQA } from "../subroutes";
import "../auditExecution/auditexecution.css";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../RenderIcons";
import NavRow from "../NavRow";
import ReactPaginate from "react-paginate";

const AuditExecution = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const [successMessage, setSuccessMessage] = useState(location.state?.successMessage || "");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 11; 

  const originalUrl = location.state?.from;


    const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const userid = localStorage.getItem("user_id");


  let routes = [];
  if (is_Admin) {
    routes = auditExecutionTop;
  } else if (Object.keys(userRoles).some(key => key.includes("Business"))) {
    routes = auditBusiness;
  } else if (Object.keys(userRoles).some(key => key.includes("Quality Assurance"))) {
    routes = auditExecutionQA;
  } else {
    console.log("No matching role found");
  }



  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/user/?user_id=${userid}`,
      setterFunction: (data) => { setAPIData(data) },
      setErrors: (error) => { setErrors(error) },
      setLoading: setLoading,
    }

    justFetchDataV2(parameter);
  }, []);
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 5000); // Clear after 3 seconds

      // Clean up the timeout when the component unmounts
      return () => clearTimeout(timer);
    }
  }, [successMessage]);





  const [filters, setFilters] = useState({
    id: "",
    name: "", // Changed from audit_entity to name
    business_users: "",
    status: "",
    actual_start_date: "",
    actual_end_date: "",
    auditors:"",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const [openDropdown, setOpenDropdown] = useState(null);

  const renderIcons = () => {
    const handleDropdownToggle = (index) => {
      setOpenDropdown(openDropdown === index ? null : index);
    };

    return routes.map((route, index) => {
      if (route.routes) {
        const isActive = route.routes.some((subroute) =>
          location.pathname.startsWith(subroute.path.replace(/:\w+/, ""))
        );

        const isDropdownOpen = openDropdown === index || isActive;

        return (
          <div key={index} className="dropdown-container-exec">
            <div
              className="dropdown-toggle"
              style={{ display: "flex", gap: ".5vw" }}
              onClick={() => handleDropdownToggle(index)}
            >
              <div className="navicon">
                <img src={route.icon} alt="Dropdown Icon" />
              </div>

              <div className="navicon">{route.name}</div>

            </div>
            {isDropdownOpen && (
              <div className="dropdown-template">
                {route.routes.map((subroute, subindex) => (
                  <NavLink
                    to={subroute.path}
                    key={subindex}
                    className="navlink template-nav"
                    activeClassName="active1"
                  >
                    <div className="navicon"></div>
                    <div className="navicon">{subroute.name}</div>
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        );
      }

      return (
        <NavLink
          to={route.path}
          key={index}
          className="navlink"
          activeClassName="active1"
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="h5_intersemibold">{route.name}</div>
        </NavLink>
      );
    });
  };

  const filteredData = APIData.filter((data) => {
    const {
      id,
      name,
      phase,
      business_users,
      actual_start_date,
      actual_end_date,
      auditors,
    } = data;
  
    // Check if the ID matches the filter
    const matchesId = filters.id ? id.toString().includes(filters.id) : true;
  
    // Check if the entity name matches the filter
    const entityName = name ? name.toLowerCase() : "";
    const matchesEntity = filters.name
      ? entityName.includes(filters.name.toLowerCase())
      : true;
  
    // Check if any business user matches the filter
    const matchesKeyStakeholder = filters.business_users
      ? business_users.some((user) =>
          user.name.toLowerCase().includes(filters.business_users.toLowerCase())
        )
      : true;
    const matchAuditorName = filters.auditors
      ? auditors.some((user) =>
          user.name.toLowerCase().includes(filters.auditors.toLowerCase())
        )
      : true;
  
    // Check if the phase matches the filter
    const matchesStatus = filters.phase
      ? phase.toLowerCase().includes(filters.phase.toLowerCase())
      : true;
  
    // Add filters for dates if needed
    // const matchesStartDate =
    //   filters.actual_start_date && actual_start_date
    //     ? new Date(actual_start_date) >= new Date(filters.actual_start_date)
    //     : true;

        const matchesStartDate = filters.actual_start_date
      ? (actual_start_date || "").toString().includes(filters.actual_start_date)
      : true;
  
    const matchesEndDate =
      filters.actual_end_date
        ? new Date(actual_end_date ||"").toString().includes(filters. actual_end_date)
        : true;
  
    // Combine all conditions to filter the data
    return (
      matchesId &&
      matchesEntity &&
      matchesKeyStakeholder &&
      matchAuditorName&&
      matchesStatus &&
      matchesStartDate &&
      matchesEndDate
    );
  });
  

  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/audit-execution/${id}/detail`);
  };

  const StatusColor = (status) => {
    if (status === "Open") {
      return <span className="status_todo">{"Not Started"}</span>;
    } else if (status === "Planning") {
      return <span className="status_between">{status}</span>;
    } else if (status === "Planning Complete") {
      return <span className="status_inprogress">{status}</span>;
    } else if (status === "Fieldwork") {
      return <span className="status_between">{status}</span>;
    } else if (status === "Fieldwork Complete") {
      return <span className="status_inprogress">{status}</span>;
    } else if (status === "Reporting") {
      return <span className="status_between">{status}</span>;
    } else if (status === "Reporting Complete") {
      return <span className="status_inprogress">{status}</span>;
    } else if (status === "Wrapup Qa") {
      return <span className="status_inprogress">{status}</span>;
    } else if (status === "Wrapup Qa Complete") {
      return <span className="status_complete">{'Closed'}</span>;
    } else {
      return <span className="status_default">{status}</span>; 
    }
  };


  // Pagination Logic
const pageCount = Math.ceil(filteredData.length / itemsPerPage);
const startOffset = currentPage * itemsPerPage;
const paginatedData = filteredData.slice(
  startOffset,
  startOffset + itemsPerPage
);

const handlePageClick = ({ selected }) => {
  setCurrentPage(selected);
};

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && (
        <p className="successmessage">{successMessage}</p>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecutionTop} />
        <NavRow name={'Audits'} Create={Object.keys(userRoles).some(key => key.includes("Audit Team")) ?'Create' : ''} link={`/audit-execution/create`} idtrue={true} />


        <div className="aexe_tablecontainer" style={{ width: tableSize ? "100%" : "100%" }} >

          <table>

            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">UID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("id", e)}
                    value={filters.id}
                  />
                </th>
                <th>
                  <h4 className="thhead">Audit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("name", e)}
                    value={filters.name}
                  />
                </th>
                <th>
                  <h4 className="thhead">Business Stakeholder</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("business_users", e)
                    }
                    value={filters.business_users}
                  />
                </th>
                <th>
                  <h4 className="thhead">Auditor</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("auditors", e)
                    }
                    value={filters.auditors}
                  />
                </th>
                <th>
                  <h4 className="thhead">Status</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("phase", e)}
                    value={filters.phase}
                  />
                </th>
                <th>
                  <h4 className="thhead">Actual Start Date</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("actual_start_date", e)
                    }
                    value={filters.actual_start_date}
                  />
                </th>
                <th>
                  <h4 className="thhead">Actual End Date</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("actual_end_date", e)
                    }
                    value={filters.actual_end_date}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>
              ) : paginatedData.length === 0 ? (
                <tr>
                  <td colSpan="6" className="data-error-text">
                    No data found
                  </td>
                </tr>
              ) : (
                paginatedData
                  .sort((a, b) => a.id - b.id)
                  .map((data) => (
                    <tr
                      className="tabledata"
                      onClick={() => Viewuser(data)}
                      key={data.id}
                    >
                      <td>
                        <span className="status_todo" >{data.id}</span>
                      </td>
                      <td>{data.name}</td>
                      <td>
                        {data.business_users
                          ? data.business_users
                            .map((user) => user.name)
                            .slice(0, 2)
                            .join(", ") +
                          (data.business_users.length > 2 ? "..." : "")
                          : ""}
                      </td>
                      <td>
                        {data.auditors
                          ? data.auditors
                            .map((user) => user.name)
                            .slice(0, 2)
                            .join(", ") +
                          (data.auditors.length > 2 ? "..." : "")
                          : ""}
                      </td>
                      <td>
                        {StatusColor(data.phase)}
                      </td>
                      <td>
                        <span>{convertDate(data.actual_start_date)}</span>
                      </td>
                      <td>
                        <span>{convertDate(data.actual_end_date)}</span>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>

          </table>
          
        </div>

        <div className="cardscontainer">
          {paginatedData
            .sort((a, b) => a.id - b.id)
            .map((data) => (
              <div
                className="deptcard"
                onClick={() => Viewuser(data)}
                key={data.id}
              >
                <h2>{data.name}</h2>
              </div>
            ))}
        </div>

        <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container"
            activeClassName={"active"}
          />

      </section>
    </>
  );
};

export default AuditExecution;
