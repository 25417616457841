import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../../components/Breadcrumb";
import SidebarMenu from "../../../components/SidebarMenu";
import "../../../css/viewuser.css";
import { fetchDataV2, justFetchDataV2 } from "../../apiUtils";
import { issueTracking,issueTrackingBusiness } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "./issues.css";
import Select from "react-select";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import CustomSelect from "./CustomSelect";
import BounceLoader from "react-spinners/BounceLoader";
import ReactPaginate from "react-paginate";

const Issuetracking = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [IssueData, setIssueData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const [loading, setLoading] = useState(false); 
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 11;

  const originalUrl = location.state?.from;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const userid = localStorage.getItem("user_id");
  console.log(userid)

  const AuditTeam =Object.keys(userRoles).some(key => key.includes("Audit Team"))|| is_Admin;

  let routes = [];
  if (AuditTeam) {
    routes = issueTracking;
  } else if (Object.keys(userRoles).some(key => key.includes("Business"))) {
    routes = issueTrackingBusiness;
  } else {
    console.log("No matching role found");
  }


  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/audit-entities/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/issues/user/?user_id=${userid}`,
      setterFunction: setIssueData,
      setErrors: setErrors,
      setLoading: setLoading,

    };
    justFetchDataV2(parameter);
  }, []);

  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/audit-execution/${id}/Detail`);
  };

  const issueStatusOptions = [
    { value: "open", label: "Open" },
    { value: "in progress", label: "In Progress" },
    { value: "closed", label: "Closed" },
  ];

  const issueRatingOptions = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
    { value: "very high", label: "Very High" },
    { value: "critical", label: "Critical" },
  ];

  const [filters, setFilters] = useState({
    issue_id: "",
    auditId: "",
    auditName: "",
    objective: "",
    rating: "",
    status: "",
    name: "",
    audit: "",
    owner: "",
    created_at: "",
  });

  const handleFilterChange = (filterKey, e, selectedOption) => {
    var value;
    if (!selectedOption) {
      // When selectedOption is null (i.e., when clearing the selection)
      value = e ? e.target.value.toLowerCase() : ""; // In case of text inputs
    } else {
      value = selectedOption ? selectedOption.value : "";
      console.log(selectedOption.value); // For dropdowns
    }

    setFilters({ ...filters, [filterKey]: value });
  };

  const filteredData = IssueData.filter((data) => {
    const id = filters.issue_id ? data?.issue_id?.toLowerCase() : "---";
    const idMatch = id?.includes(filters?.issue_id?.toLowerCase());

    const auditId = filters.auditId ? data?.audit?.id?.toString() : "---";
    const auditidMatch = auditId?.includes(filters?.auditId?.toString());

    const title = filters.objective ? data.objective.toLowerCase() : "";
    const titleMatch = title?.includes(filters.objective);

    const auditName = filters.auditName ? data.audit?.name?.toLowerCase() : "";
    const auditNameMatch = auditName?.includes(filters.auditName);

    const rating = filters?.rating ? data?.rating?.toLowerCase() : "--";
    const ratingMatch = rating?.includes(filters.rating);

    const status = filters.status ? data.status.toLowerCase() : "";
    const statusMatch = status?.includes(filters.status);

    const owner = filters?.owner ? data?.owner?.name?.toLowerCase() : "--";
    const nameMatch = owner?.includes(filters.owner);

    const date = data.created_at
      ? new Date(data.created_at).toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        })
      : "---";
    const dateMatch = filters.created_at
      ? date?.includes(filters.created_at)
      : true;

    return (
      auditidMatch &&
      auditNameMatch &&
      idMatch &&
      titleMatch &&
      ratingMatch &&
      nameMatch &&
      dateMatch &&
      statusMatch
    );
  });

  useEffect(() => {
    console.log(filteredData);
  }, [filteredData]);

  const ViewIssue = (data) => {
    const issueid = data;
    navigate(`/issue-tracking/issues/${issueid}`);
  };

  const viewaudit=(auditid)=>{
    if(auditid){
      const auidtid=auditid;
      navigate(`/audit-execution/${auidtid}/detail`)
    }
   }



  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
 const startOffset = currentPage * itemsPerPage;
 const paginatedData = filteredData.slice(
   startOffset,
   startOffset + itemsPerPage
 );

 const handlePageClick = ({ selected }) => {
  setCurrentPage(selected);
};

  const showStatusColor = (status) => {
    switch (status) {
      case "Open":
        return <span className="status_todo">{status}</span>;
      case "Closed":
        return <span className="status_complete">{status}</span>;
      case "In Progress":
        return <span className="status_inprogress">{status}</span>;

      default:
        return <span className="status_rejected">No Status Found</span>;
    }
  };

  const showRatingColor = (status) => {
    switch (status) {
      case "Low":
        return <span className="status_low">{status}</span>;
      case "Medium":
        return <span className="status_medium">{status}</span>;
      case "High":
        return <span className="status_high">{status}</span>;
      case "Very High":
        return <span className="status_veryhigh">{status}</span>;
      case "Critical":
        return <span className="status_critical">{status}</span>;

      default:
        return <span className="status_rejected">No Status Found</span>;
    }
  };
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={routes} />
        <NavRow
          name={"Issues"}
          Create={AuditTeam ?"Create":''}
          link={`/issue-tracking/issues/create`}
          idtrue={true}
        />

        <div
          className="issues_table_container"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <table>
            <thead style={{ position: "sticky", top: "0", zIndex: "1000" }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">Isuse ID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("issue_id", e)}
                    value={filters.issue_id}
                  />
                </th>
                <th>
                  <h4 className="thead">Audit ID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("auditId", e)}
                    value={filters.auditId}
                  />
                </th>
                <th>
                  <h4 className="thead">Audit Name</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("auditName", e)}
                    value={filters.auditName}
                  />
                </th>

                <th>
                  <h4 className="thhead"> Issue Title </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("objective", e)}
                    value={filters.objective}
                  />
                </th>

                <th>
                  <h4 className="thhead">Issue Rating</h4>

                  <CustomSelect
                    options={issueRatingOptions}
                    placeholder="--"
                    onChange={(selectedOption) =>
                      handleFilterChange("rating", null, selectedOption)
                    }
                    value={
                      issueRatingOptions.find(
                        (option) => option.value === filters.rating
                      ) || null
                    }
                    isClearable={true}
                  />
                </th>

                <th>
                  <h4 className="thhead">Implementation timeline</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("created_at", e)}
                    value={filters.created_at}
                  />
                </th>

                <th>
                  <h4 className="thhead">Issue Status</h4>
                  <CustomSelect
                    options={issueStatusOptions}
                    placeholder="--"
                    onChange={(selectedOption) =>
                      handleFilterChange("status", null, selectedOption)
                    }
                    value={
                      issueStatusOptions.find(
                        (option) => option.value === filters.status
                      ) || null
                    }
                  />
                </th>

                <th>
                  <h4 className="thhead">Action Owner Name</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("owner", e)}
                    value={filters.owner}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
            {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>

              ) : paginatedData.length === 0 ? (
                <p className="data-error-text">No data found</p>
              ) : (
                paginatedData
                .sort((u, v) => u.id - v.id)
                .map((data) => (
                  <tr onClick={() => ViewIssue(data.id)} key={data.id}>
                    <td>
                      <span className="status_todo">{data.issue_id}</span>
                    </td>
                    <td  onClick={(e)=>{
                    e.stopPropagation();
                    viewaudit(data?.audit?.id)
                  }}>
                      <span className="status_todo">{data?.audit?.id}</span>
                    </td>
                    <td>{data?.audit?.name}</td>
                    <td
                      style={{
                        minWidth: "100px",
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      title={data.objective ? data.objective : ""}
                    >
                      {data.objective
                        ? data.objective.split(" ").slice(0, 3).join(" ") +
                          (data.objective.split(" ").length > 3 ? "..." : "")
                        : ""}
                    </td>

                    <td>
                      <span>
                        {showRatingColor(
                          data?.rating
                            ? data?.rating
                            : ""
                        )}
                      </span>
                    </td>
                    <td>
                      {data.created_at
                        ? new Date(data.created_at).toLocaleDateString()
                        : ""}
                    </td>
                    <td>
                      <span>
                        {showStatusColor(data?.status ? data?.status : "")}
                      </span>
                    </td>
                    <td>{data?.owner ? data.owner?.name : ""}</td>
                  </tr>
                )))}
            </tbody>
          </table>
        </div>

        <div className="cardscontainer">
          {filteredData.map((data) => (
            <div
              className="deptcard"
              key={data.id}
              onClick={() => ViewIssue(data.id)}
            >
              <h1>Issue ID: {data.id}</h1>
              <h2>Title: {data.objective || "No Title"}</h2>
              <h3>Rating: {data.observation?.rating || "No Rating"}</h3>
              <img src={openarrow} alt="arrow" />
            </div>
          ))}
        </div>
        <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container"
            activeClassName={"active"}
          />
      </section>
    </>
  );
};

export default Issuetracking;
