import React, { useState, useEffect ,useRef} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../css/viewuser.css";
import { postDataReturnId } from "../../apiUtils";
import { auditExecutionTop } from "../../subroutes";
import "../createaudit/createaudit.css";
import SingleOptionSelector from "../../SingleOptionSelector";
import InitialSingleOptionSelector from "../../InitialSingleOptionSelector";
import MultiOptionSelector from "../../MultiOptionSelector";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";

const Createaudit = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [AuditErrors, setAuditErrors] = useState(false);
  const [name, setAuditName] = useState("");
  const [nameError, setAuditNameError] = useState(false);
  const [objectives, setObjectives] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [audit_background, setAudit_background] = useState("");
  const [final_opinion, setFinal_opinion] = useState("");
  const [audit_scope, setScope] = useState();
  const [audit_phases, setPhases] = useState();
  const [other_relevant_information, setRelevant] = useState();
  const [selectedRiskError, setSelectedRiskError] = useState(false);
  const [selectedPreparerError, setSelectedPreparerError] = useState(false);
  const [selectedReviewerError, setSelectedReviewerError] = useState(false);
  const [selectedassign_toError, setSelectedassign_toError] = useState(false);
  const [qaError, setQAError] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [Data, setIData] = useState("");
  const [risk_assessment_id, setAPIRiskData] = useState([]);
  const [preparer_id, setPreparer_id] = useState([]);
  const [reviewer_id, setReviewer_id] = useState([]);
  const [assign_to_id, setAssign_to_id] = useState([]);
  const [business_users_id, setAPIBSData] = useState([]);
  const [qa_id, setQA_id] = useState([]);
  const [auditors_id, setAPIAuditorData] = useState([]);
  const [audit_entity_id, setAPIAuditData] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [assigned_Id, setAssigned_Id] = useState("");
  const [assigned_Name, setAssigned_Name] = useState("");
  const [editMode, setEditMode] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(location.state?.successMessage || "");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);




  
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    if(new Date(event.target.value)< new Date(start_date)){
      setErrors("End Date should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const postApi = (e) => {
    console.log("got clicked ");
    e.preventDefault();

    if (!audit_entity_id || !name) {
      setAuditErrors(!audit_entity_id ? true : false);
      setAuditNameError(!name? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (new Date(end_date) < new Date(start_date)) {
      setErrors("End Date should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const prepareId=parseInt(preparer_id,10);
    const reviewId=parseInt(reviewer_id,10);
    const assignId=parseInt(assign_to_id,10);
    const riskassessmentId=parseInt(risk_assessment_id,10);
    const qaid=parseInt(qa_id,10);

    const formattedStartDate = start_date ? `${start_date}` : null;
    const formattedEndDate = end_date ? `${end_date}` : null;

    const parameter = {
      url: `/api/audit_engagement/audit/`,
      payload: {
        name,
        objectives,
        introduction,
        audit_background,
        final_opinion,
        risk_assessment_id:riskassessmentId,
        audit_entity_id,
        auditors_id,
        business_users_id,
        qa_id:qaid,
        start_date:formattedStartDate,
        end_date:formattedEndDate,
        preparer_id:prepareId,
        reviewer_id:reviewId,
        assign_to_id:assignId,
        audit_scope,
        audit_phases,
        other_relevant_information,
      },
      
      navigate: navigate,
      setErrors: setErrors,
      navUrl:  `/auditExecution`,
      setSuccessCallback : (data) => {
        console.log("data : ", data);
        const successMessageColor = "#4285F4";
        navigate(`/audit-execution/${data.id}/detail`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      }

    };

    postDataReturnId(parameter);
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setStartDate(currentDate);
    setEndDate(currentDate);
  }, []);

  useEffect(() => {
    console.log(Data);
    setAssigned_Name(Data.key_stakeholder ? Data.key_stakeholder.name : "");
    setAssigned_Id(Data.key_stakeholder ? Data.key_stakeholder.id : "");
  }, [Data]);

  const [maketrue, setTrue] = useState(false);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      <RenderIcons homeRoutes={auditExecutionTop} />
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      <NavRow name={'Create Audit'}    idtrue={true}/>
          <div className="create_audit_container" style={{ width: tableSize ? "100%" : "100%" }}>
            <form action="">

              <div className="lng_lbl_inpt ">
                <label>Audit Name</label>
                <input
                 style={{
                  border: nameError ? "1px solid #FF4646" : "",
                }}
                  type="text"
                  value={name}
                  placeholder="Audit Name"
                  onChange={(e) => {
                  setAuditName(e.target.value);
                  setAuditNameError(false);
                  }}
                  tabIndex={1}
                />
              </div>
              <div className="lbl_txa">
                <label>Objectives</label>
                <textarea
                  type="text"
                  value={objectives}
                  placeholder="Audit Objectives"
                  onChange={(e) => setObjectives(e.target.value)}
                  tabIndex={2}
                />
              </div>
                 <SingleOptionSelector
                    apiUrl="/api/audit_universe/risk-assessments/?status=Closed"
                    setSelectorId={setAPIRiskData}
                    setSelectorError={setSelectedRiskError}
                    Name="title"
                    title="Risk Assessments"
                    editMode={editMode}
                    tabIndex={3}
                    maketrue={maketrue}
                    setTrue={setTrue}
                  />
                
                  <MultiOptionSelector
                    apiUrl="/api/audit_universe/business-stakeholders/"
                    setSelectorId={setAPIBSData}
                    setSelectorError={setSelectedRiskError}
                    Name="name"
                    title="StakeHolder"
                    initialId={assigned_Id}
                    initialName={assigned_Name}
                    editMode={editMode}
                    tabIndex={4}
                  />
                
                <div
                  className="lbl_inpt"
                  
                >
                  <label> Start Date: </label>{" "}
                  <input
                    type="date"
                    value={start_date}
                    onChange={handleStartDateChange}
                    tabIndex={5}
                  />
                </div>

                <div
                  className="lbl_inpt"
                >
                  <label>End Date:</label>
                  <input
                    type="date"
                    value={end_date}
                    onChange={handleEndDateChange}
                    tabIndex={6}
                  />
                </div>
              
                  <SingleOptionSelector
                    apiUrl="/api/audit_universe/audit-entities/"
                    setSelectorId={setAPIAuditData}
                    setSelectorError={setAuditErrors}
                    selectorError={AuditErrors}
                    setIData={setIData}
                    Name="name"
                    title="Audit Entity"
                    editMode={editMode}
                    maketrue={maketrue}
                    setTrue={setTrue}
                    tabIndex={7}
                  />
               
                  <MultiOptionSelector
                    apiUrl="/api/audit_universe/audit-team/"
                    setSelectorId={setAPIAuditorData}
                    Name="name"
                    title="Auditor"
                    editMode={editMode}
                    tabIndex={8}
                  />
                
              
                  <InitialSingleOptionSelector
                    apiUrl="/api/audit_universe/audit-team/"
                    setSelectorId={setPreparer_id}
                    setSelectorError={setSelectedPreparerError}
                    Name="name"
                    title="Preparer"
                    initialIds={auditors_id}
                    editMode={editMode}
                    tabIndex={9}
                  />
                

                
                  <InitialSingleOptionSelector
                    apiUrl="/api/audit_universe/audit-team/"
                    setSelectorId={setReviewer_id}
                    setSelectorError={setSelectedReviewerError}
                    Name="name"
                    title="Reviewer"
                    initialIds={auditors_id}
                    editMode={editMode}
                    tabIndex={10}
                  />
                
                  <InitialSingleOptionSelector
                    apiUrl="/api/audit_universe/admin-business-stakeholders/"
                    setSelectorId={setAssign_to_id}
                    setSelectorError={setSelectedassign_toError}
                    selectorError={selectedassign_toError}
                    Name="name"
                    title="Business Owner"
                    initialIds={business_users_id}
                    editMode={editMode}
                    tabIndex={11}
                  />
                
                  <SingleOptionSelector
                    apiUrl="/api/audit_universe/quality-assurance/"
                    setSelectorId={setQA_id}
                    setSelectorError={setQAError}
                    Name="name"
                    title="QA"
                    editMode={editMode}
                    tabIndex={12}
                    maketrue={maketrue}
                    setTrue={setTrue}
                  />
                
              <div className="lbl_txa">
                <label>Audit Scope</label>

                <textarea
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Define Audit Scope"
                  value={audit_scope}
                  onChange={(e) => {
                    setScope(e.target.value);
                  }}
                  disabled={!editMode}
                  tabIndex={13}

                />
              </div>
              
              <div className="lbl_txa">
                <label>Audit Key Phases/Duration</label>

                <textarea
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Audit Key Phases/Duration"
                  value={audit_phases}
                  onChange={(e) => {
                    setPhases(e.target.value);
                  }}
                  disabled={!editMode}
                  tabIndex={14}
                />
              </div>
             
              <div className="lbl_txa">
                <label>Other Relevant Information</label>

                <textarea
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Other Relevant Information"
                  value={other_relevant_information}
                  onChange={(e) => {
                    setRelevant(e.target.value);
                  }}
                  disabled={!editMode}
                  tabIndex={15}
                />
              </div>
            </form>

            <div className="button_container" >
             
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={postApi}
              tabIndex={16}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create Audit
            </button>
            </div>
 
          </div>
      </section>
    </>
  );
};

export default Createaudit;
