import star from "../images/star.svg";
import risk from "../images/risk.svg";
import corporate from "../images/corporate.svg";
import auditPlan from "../images/auditPlan.svg";
import user from "../images/user.svg";
import department from "../images/department.svg";
import BU from "../images/BU.svg";
import teams from "../images/teams.svg";
import audit_icon from "../images/audit_icon.svg";
import planning_icon from "../images/planning_icon.svg";
import fieldwork_icon from "../images/fieldwork_icon.svg";
import reporting_icon from "../images/reporting_icon.svg";
import wrapupqa_icon from "../images/wrapup_icon.svg";
import analytics_icon from "../images/analytics_icon.svg";
import issues_icon from "../images/issues_icon.svg";
import Admin from "../images/Admin.svg";
import audit from "../images/audit.svg";
import arrowFlip from "../images/arrow.svg";
import auditentity from "../images/auditentity.svg";
import issuetracking from "../images/issuetracking.svg";
import administration from "../images/administration.svg";
import filemanager_icon from "../images/file_manager.svg";
import { useParams } from "react-router-dom";
import template_icon from "../images/template_icon.svg";
import rcm_template from "../images/rcm_template.svg";
import qa_checklist from "../images/qa_checklist.svg";
import qa_survey from "../images/qa_survey.svg";

const auditRoutes = [
  {
    path: "/audit-universe/corporate-structure",
    name: "Corporate Structure",
    icon: corporate,
  },
  { path: "/audit-universe/audit-entities", name: "Audit Entities", icon: star },
  {
    path: "/audit-universe/risk-assessments",
    name: "Risk Assessment",
    icon: risk,
  },
  {
    path: "/audit-universe/audit-planning",
    name: "Audit Planning",
    icon: auditPlan,
  },
  { path: "/audit-universe/rcm", name: "RCM", icon: rcm_template },
];

const auditRoutesRCM = [
  {
    path: "/audit-universe/corporate-structure",
    name: "Corporate Structure",
    icon: corporate,
  },
  { path: "/audit-universe/audit-entities", name: "Audit Entities", icon: star },
  {
    path: "/audit-universe/risk-assessments",
    name: "Risk Assessment",
    icon: risk,
  },
  {
    path: "/audit-universe/audit-planning",
    name: "Audit Planning",
    icon: auditPlan,
  },
  {
    path: ["/audit-execution/rcm", "/audit-universe/rcm"], // Multiple paths for the same component
    name: "RCM",
    icon: rcm_template,
  },
];

const auditRoutesBu = [
  {
    path: "/risk-assessments",
    name: "Risk Assessment",
    icon: risk,
  },

];

const homeRoutes = [
  { path: "/administration/users", name: "User Management", icon: user },
  { path: "/administration/departments", name: "Department", icon: department },
  { path: "/administration/business-unit", name: "Business Unit", icon: BU },
  { path: "/administration/teams", name: "Teams", icon: teams },


];

const auditExecution = [
  { path: `/audit-execution/:id/detail`, name: "Detail", icon: audit_icon,
    match: [`/audit-execution/:id`, `/audit-execution/:id/detail`], // Paths to match

   },
  {
    path: `/audit-execution/:id/file-manager`,
    name: "File Manager",
    icon: filemanager_icon,
  },
  {
    path: `/audit-execution/:id/planning`,
    name: "Planning",
    icon: planning_icon,
  },
  {
    path: `/audit-execution/:id/fieldwork`,
    name: "Fieldwork",
    icon: fieldwork_icon,
  },
  {
    path: `/audit-execution/:id/reporting`,
    name: "Reporting",
    icon: reporting_icon,
  },
  {
    path: `/audit-execution/:id/wrapup-qa`,
    name: "WrapupQA",
    icon: wrapupqa_icon,
  },
];

const auditExecutionInnerQA = [
  { path: `/audit-execution/:id/detail`, name: "Detail", icon: audit_icon },
  {
    path: `/audit-execution/:id/file-manager`,
    name: "FileManager",
    icon: filemanager_icon,
  },
  {
    path: `/audit-execution/:id/reporting`,
    name: "Reporting",
    icon: reporting_icon,
  },
  {
    path: `/audit-execution/:id/wrapup-qa`,
    name: "WrapupQA",
    icon: wrapupqa_icon,
  },
];

const auditExecutionInnerBU = [
  { path: `/audit-execution/:id/detail`, name: "Detail", icon: audit_icon },
  {
    path: `/audit-execution/:id/file-manager`,
    name: "File Manager",
    icon: filemanager_icon,
  },
  {
    path: `/audit-execution/:id/reporting`,
    name: "Reporting",
    icon: reporting_icon,
  },
];

const auditExecutionTop = [
  { path: `/audit-execution`, name: "Audit Execution", icon: audit_icon },
];

const templates = [
  { path: "/templates/rcmtemp", name: "RCM Template", icon: rcm_template },
  { path: "/templates/qachecklist", name: "QA Checklist", icon: qa_checklist },
  { path: "/templates/qasurvey", name: "QA Survey", icon: qa_survey },
];
const templatesQA = [
  { path: "/templates/qachecklist", name: "QA Checklist", icon: qa_checklist },
  { path: "/templates/qasurvey", name: "QA Survey", icon: qa_survey },
];
const auditExecutionQA = [
  { path: `/audit-execution`, name: "Audit Execution", icon: audit_icon },
  {
    name: "Templates",
    icon: template_icon,
    routes: [
      { path: "/audit-execution/qachecklist", name: "QA Checklist" },
      { path: "/audit-execution/qasurvey", name: "QA Survey" },
    ],
  },
];

const auditBusiness = [
  { path: `/audit-execution`, name: "Audit Execution", icon: audit_icon },
];

const issueTracking = [
  { path: "/issue-tracking/analytics", name: "Analytics", icon: analytics_icon },
  { path: "/issue-tracking/issues", name: "Issues", icon: issues_icon },
];
const issueTrackingBusiness = [
  { path: "/issue-tracking/issues", name: "Issues", icon: issues_icon },
];

const forAdmin = [
  {
    path: "/",
    name: "Home",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.37742 1.82258L2.98742 6.02258C2.08742 6.72258 1.35742 8.21258 1.35742 9.34258V16.7526C1.35742 19.0726 3.24742 20.9726 5.56742 20.9726H17.1474C19.4674 20.9726 21.3574 19.0726 21.3574 16.7626V9.48258C21.3574 8.27258 20.5474 6.72258 19.5574 6.03258L13.3774 1.70258C11.9774 0.722584 9.72742 0.772584 8.37742 1.82258Z"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.3574 16V13"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Home</h5>

      </div>
    ),
  },
  {
    path: "/Administration",
    name: "Administration",
    icon: (
      <div className="icon_name  ">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 19 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.07999 1.2325L3.09 3.1025C1.94 3.5325 1 4.89248 1 6.12248V13.5525C1 14.7325 1.78001 16.2825 2.73001 16.9925L7.03 20.2025C8.44 21.2625 10.76 21.2625 12.17 20.2025L16.47 16.9925C17.42 16.2825 18.2 14.7325 18.2 13.5525V6.12248C18.2 4.89248 17.26 3.5325 16.11 3.1025L11.12 1.2325C10.27 0.9225 8.90999 0.9225 8.07999 1.2325Z"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.59003 9.52197C9.55003 9.52197 9.50002 9.52197 9.46002 9.52197C8.52002 9.49197 7.77002 8.71196 7.77002 7.76196C7.77002 6.79196 8.56003 6.00195 9.53003 6.00195C10.5 6.00195 11.29 6.79196 11.29 7.76196C11.28 8.72196 10.53 9.49197 9.59003 9.52197Z"
            stroke="white"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.93161 12.241C5.68946 12.9884 5.68946 14.2147 6.93161 14.9621C8.34196 15.8147 10.658 15.8147 12.0684 14.9621C13.3105 14.2147 13.3105 12.9884 12.0684 12.241C10.671 11.3884 8.3549 11.3884 6.93161 12.241Z"
            stroke="white"
            stroke-linecap="round"
            stroke-width="1.4"
            stroke-linejoin="round"
          />
        </svg>
        <h5  className="pwa_naming ">Admin</h5>
      </div>
    ),
  },
  {
    path: "/audit-universe",
    name: "Audit Universe",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4599 26.412C20.3413 26.412 25.9198 20.8335 25.9198 13.9521C25.9198 7.07067 20.3413 1.49219 13.4599 1.49219C6.57848 1.49219 1 7.07067 1 13.9521C1 20.8335 6.57848 26.412 13.4599 26.412Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.47596 2.73438H9.72195C7.29227 10.011 7.29227 17.8856 9.72195 25.1622H8.47596"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.1979 2.73438C19.6276 10.011 19.6276 17.8856 17.1979 25.1622"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.246 18.9335V17.6875C9.52258 20.1172 17.3972 20.1172 24.6738 17.6875V18.9335"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.246 10.2129C9.52258 7.78321 17.3972 7.78321 24.6738 10.2129"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Universe</h5>
      </div>
    ),
  },
  {
    path: "/audit-execution",
    name: "Audit Execution",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 25 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.94654 6.52302H14.9305C17.4225 6.52302 17.4225 5.27703 17.4225 4.03104C17.4225 1.53906 16.1765 1.53906 14.9305 1.53906H9.94654C8.70055 1.53906 7.45456 1.53906 7.45456 4.03104C7.45456 6.52302 8.70055 6.52302 9.94654 6.52302Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.4225 4.0625C21.5716 4.28678 23.6524 5.81934 23.6524 11.5135V18.9895C23.6524 23.9734 22.4064 26.4654 16.1765 26.4654H8.70055C2.4706 26.4654 1.22461 23.9734 1.22461 18.9895V11.5135C1.22461 5.83181 3.30541 4.28678 7.45456 4.0625"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.20856 11.5078H17.4225"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
          <path
            d="M6.20856 16.4922H12.4385"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Execution</h5>
      </div>
    ),
  },
  {
    path: "/issue-tracking",
    name: "Issue Tracking",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.22995 17.3035L9.48604 13.9194C9.93802 13.2414 10.9485 13.2862 11.3387 14.0016L12.9142 16.8901C13.2943 17.5868 14.2698 17.652 14.7391 17.012L19.6898 10.261M7.22995 26.4589H19.6898C23.1305 26.4589 25.9198 23.6696 25.9198 20.2289V7.76901C25.9198 4.32831 23.1305 1.53906 19.6898 1.53906H7.22995C3.78924 1.53906 1 4.32831 1 7.76901V20.2289C1 23.6696 3.78924 26.4589 7.22995 26.4589Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Issues</h5>
      </div>
    ),
  },
  {
    path: "/templates",
    name: "Templates",
    icon: (
      <div className="icon_name  ">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 2V5"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 2V5"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21 8.5V13.63C20.11 12.92 18.98 12.5 17.75 12.5C16.52 12.5 15.37 12.93 14.47 13.66C13.26 14.61 12.5 16.1 12.5 17.75C12.5 18.73 12.78 19.67 13.26 20.45C13.63 21.06 14.11 21.59 14.68 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 11H13"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 16H9.62"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M23 17.75C23 18.73 22.72 19.67 22.24 20.45C21.96 20.93 21.61 21.35 21.2 21.69C20.28 22.51 19.08 23 17.75 23C16.6 23 15.54 22.63 14.68 22C14.11 21.59 13.63 21.06 13.26 20.45C12.78 19.67 12.5 18.73 12.5 17.75C12.5 16.1 13.26 14.61 14.47 13.66C15.37 12.93 16.52 12.5 17.75 12.5C18.98 12.5 20.11 12.92 21 13.63C22.22 14.59 23 16.08 23 17.75Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.75 20.25C17.75 18.87 18.87 17.75 20.25 17.75C18.87 17.75 17.75 16.63 17.75 15.25C17.75 16.63 16.63 17.75 15.25 17.75C16.63 17.75 17.75 18.87 17.75 20.25Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h5 className="pwa_naming">Templates</h5>
      </div>
    ),
  },
];

const forAdminPWA = [
  {
    path: "/",
    name: "Home",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.37742 1.82258L2.98742 6.02258C2.08742 6.72258 1.35742 8.21258 1.35742 9.34258V16.7526C1.35742 19.0726 3.24742 20.9726 5.56742 20.9726H17.1474C19.4674 20.9726 21.3574 19.0726 21.3574 16.7626V9.48258C21.3574 8.27258 20.5474 6.72258 19.5574 6.03258L13.3774 1.70258C11.9774 0.722584 9.72742 0.772584 8.37742 1.82258Z"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.3574 16V13"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Home</h5>

      </div>
    ),
  },
  {
    path: "/audit-universe",
    name: "Audit Universe",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4599 26.412C20.3413 26.412 25.9198 20.8335 25.9198 13.9521C25.9198 7.07067 20.3413 1.49219 13.4599 1.49219C6.57848 1.49219 1 7.07067 1 13.9521C1 20.8335 6.57848 26.412 13.4599 26.412Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.47596 2.73438H9.72195C7.29227 10.011 7.29227 17.8856 9.72195 25.1622H8.47596"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.1979 2.73438C19.6276 10.011 19.6276 17.8856 17.1979 25.1622"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.246 18.9335V17.6875C9.52258 20.1172 17.3972 20.1172 24.6738 17.6875V18.9335"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.246 10.2129C9.52258 7.78321 17.3972 7.78321 24.6738 10.2129"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Universe</h5>
      </div>
    ),
  },
  {
    path: "/audit-execution",
    name: "Audit Execution",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 25 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.94654 6.52302H14.9305C17.4225 6.52302 17.4225 5.27703 17.4225 4.03104C17.4225 1.53906 16.1765 1.53906 14.9305 1.53906H9.94654C8.70055 1.53906 7.45456 1.53906 7.45456 4.03104C7.45456 6.52302 8.70055 6.52302 9.94654 6.52302Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.4225 4.0625C21.5716 4.28678 23.6524 5.81934 23.6524 11.5135V18.9895C23.6524 23.9734 22.4064 26.4654 16.1765 26.4654H8.70055C2.4706 26.4654 1.22461 23.9734 1.22461 18.9895V11.5135C1.22461 5.83181 3.30541 4.28678 7.45456 4.0625"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.20856 11.5078H17.4225"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
          <path
            d="M6.20856 16.4922H12.4385"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Execution</h5>
      </div>
    ),
  },
  {
    path: "/issue-tracking",
    name: "Issue Tracking",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.22995 17.3035L9.48604 13.9194C9.93802 13.2414 10.9485 13.2862 11.3387 14.0016L12.9142 16.8901C13.2943 17.5868 14.2698 17.652 14.7391 17.012L19.6898 10.261M7.22995 26.4589H19.6898C23.1305 26.4589 25.9198 23.6696 25.9198 20.2289V7.76901C25.9198 4.32831 23.1305 1.53906 19.6898 1.53906H7.22995C3.78924 1.53906 1 4.32831 1 7.76901V20.2289C1 23.6696 3.78924 26.4589 7.22995 26.4589Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Issues</h5>
      </div>
    ),
  }
];

const forBusiness = [
  {
    path: "/",
    name: "Home",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.37742 1.82258L2.98742 6.02258C2.08742 6.72258 1.35742 8.21258 1.35742 9.34258V16.7526C1.35742 19.0726 3.24742 20.9726 5.56742 20.9726H17.1474C19.4674 20.9726 21.3574 19.0726 21.3574 16.7626V9.48258C21.3574 8.27258 20.5474 6.72258 19.5574 6.03258L13.3774 1.70258C11.9774 0.722584 9.72742 0.772584 8.37742 1.82258Z"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.3574 16V13"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Home</h5>
      </div>
    ),
  },
  {
    path: `/risk-assessments`,
    name: "Questionnaire",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4599 26.412C20.3413 26.412 25.9198 20.8335 25.9198 13.9521C25.9198 7.07067 20.3413 1.49219 13.4599 1.49219C6.57848 1.49219 1 7.07067 1 13.9521C1 20.8335 6.57848 26.412 13.4599 26.412Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.47596 2.73438H9.72195C7.29227 10.011 7.29227 17.8856 9.72195 25.1622H8.47596"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.1979 2.73438C19.6276 10.011 19.6276 17.8856 17.1979 25.1622"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.246 18.9335V17.6875C9.52258 20.1172 17.3972 20.1172 24.6738 17.6875V18.9335"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.246 10.2129C9.52258 7.78321 17.3972 7.78321 24.6738 10.2129"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Universe</h5>
      </div>
    ),
  },
  {
    path: "/audit-execution",
    name: "Audits",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 25 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.94654 6.52302H14.9305C17.4225 6.52302 17.4225 5.27703 17.4225 4.03104C17.4225 1.53906 16.1765 1.53906 14.9305 1.53906H9.94654C8.70055 1.53906 7.45456 1.53906 7.45456 4.03104C7.45456 6.52302 8.70055 6.52302 9.94654 6.52302Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.4225 4.0625C21.5716 4.28678 23.6524 5.81934 23.6524 11.5135V18.9895C23.6524 23.9734 22.4064 26.4654 16.1765 26.4654H8.70055C2.4706 26.4654 1.22461 23.9734 1.22461 18.9895V11.5135C1.22461 5.83181 3.30541 4.28678 7.45456 4.0625"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.20856 11.5078H17.4225"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
          <path
            d="M6.20856 16.4922H12.4385"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Execution</h5>
      </div>
    ),
  },
  {
    path: "/issue-tracking",
    name: "Issue Tracking",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.22995 17.3035L9.48604 13.9194C9.93802 13.2414 10.9485 13.2862 11.3387 14.0016L12.9142 16.8901C13.2943 17.5868 14.2698 17.652 14.7391 17.012L19.6898 10.261M7.22995 26.4589H19.6898C23.1305 26.4589 25.9198 23.6696 25.9198 20.2289V7.76901C25.9198 4.32831 23.1305 1.53906 19.6898 1.53906H7.22995C3.78924 1.53906 1 4.32831 1 7.76901V20.2289C1 23.6696 3.78924 26.4589 7.22995 26.4589Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Issues</h5>
      </div>
    ),
  },
];

const forQA = [
  {
    path: "/",
    name: "Home",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.37742 1.82258L2.98742 6.02258C2.08742 6.72258 1.35742 8.21258 1.35742 9.34258V16.7526C1.35742 19.0726 3.24742 20.9726 5.56742 20.9726H17.1474C19.4674 20.9726 21.3574 19.0726 21.3574 16.7626V9.48258C21.3574 8.27258 20.5474 6.72258 19.5574 6.03258L13.3774 1.70258C11.9774 0.722584 9.72742 0.772584 8.37742 1.82258Z"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.3574 16V13"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Home</h5>
      </div>
    ),
  },
  {
    path: "/audit-execution",
    name: "Audit Execution",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 25 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.94654 6.52302H14.9305C17.4225 6.52302 17.4225 5.27703 17.4225 4.03104C17.4225 1.53906 16.1765 1.53906 14.9305 1.53906H9.94654C8.70055 1.53906 7.45456 1.53906 7.45456 4.03104C7.45456 6.52302 8.70055 6.52302 9.94654 6.52302Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.4225 4.0625C21.5716 4.28678 23.6524 5.81934 23.6524 11.5135V18.9895C23.6524 23.9734 22.4064 26.4654 16.1765 26.4654H8.70055C2.4706 26.4654 1.22461 23.9734 1.22461 18.9895V11.5135C1.22461 5.83181 3.30541 4.28678 7.45456 4.0625"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.20856 11.5078H17.4225"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
          <path
            d="M6.20856 16.4922H12.4385"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Execution</h5>
      </div>
    ),
  },
  {
    path: "/templates",
    name: "Templates",
    icon: (
      <div className="icon_name">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 2V5"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 2V5"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21 8.5V13.63C20.11 12.92 18.98 12.5 17.75 12.5C16.52 12.5 15.37 12.93 14.47 13.66C13.26 14.61 12.5 16.1 12.5 17.75C12.5 18.73 12.78 19.67 13.26 20.45C13.63 21.06 14.11 21.59 14.68 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 11H13"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 16H9.62"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M23 17.75C23 18.73 22.72 19.67 22.24 20.45C21.96 20.93 21.61 21.35 21.2 21.69C20.28 22.51 19.08 23 17.75 23C16.6 23 15.54 22.63 14.68 22C14.11 21.59 13.63 21.06 13.26 20.45C12.78 19.67 12.5 18.73 12.5 17.75C12.5 16.1 13.26 14.61 14.47 13.66C15.37 12.93 16.52 12.5 17.75 12.5C18.98 12.5 20.11 12.92 21 13.63C22.22 14.59 23 16.08 23 17.75Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.75 20.25C17.75 18.87 18.87 17.75 20.25 17.75C18.87 17.75 17.75 16.63 17.75 15.25C17.75 16.63 16.63 17.75 15.25 17.75C16.63 17.75 17.75 18.87 17.75 20.25Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h5 className="pwa_naming">Templates</h5>
      </div>
    ),
  },
];
const forQAPWA = [
  {
    path: "/",
    name: "Home",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.37742 1.82258L2.98742 6.02258C2.08742 6.72258 1.35742 8.21258 1.35742 9.34258V16.7526C1.35742 19.0726 3.24742 20.9726 5.56742 20.9726H17.1474C19.4674 20.9726 21.3574 19.0726 21.3574 16.7626V9.48258C21.3574 8.27258 20.5474 6.72258 19.5574 6.03258L13.3774 1.70258C11.9774 0.722584 9.72742 0.772584 8.37742 1.82258Z"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.3574 16V13"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Home</h5>
      </div>
    ),
  },
  {
    path: "/audit-execution",
    name: "Audit Execution",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 25 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.94654 6.52302H14.9305C17.4225 6.52302 17.4225 5.27703 17.4225 4.03104C17.4225 1.53906 16.1765 1.53906 14.9305 1.53906H9.94654C8.70055 1.53906 7.45456 1.53906 7.45456 4.03104C7.45456 6.52302 8.70055 6.52302 9.94654 6.52302Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.4225 4.0625C21.5716 4.28678 23.6524 5.81934 23.6524 11.5135V18.9895C23.6524 23.9734 22.4064 26.4654 16.1765 26.4654H8.70055C2.4706 26.4654 1.22461 23.9734 1.22461 18.9895V11.5135C1.22461 5.83181 3.30541 4.28678 7.45456 4.0625"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.20856 11.5078H17.4225"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
          <path
            d="M6.20856 16.4922H12.4385"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Execution</h5>
      </div>
    ),
  }
];

const forAuditCommission = [
  {
    path: "/",
    name: "Home",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.37742 1.82258L2.98742 6.02258C2.08742 6.72258 1.35742 8.21258 1.35742 9.34258V16.7526C1.35742 19.0726 3.24742 20.9726 5.56742 20.9726H17.1474C19.4674 20.9726 21.3574 19.0726 21.3574 16.7626V9.48258C21.3574 8.27258 20.5474 6.72258 19.5574 6.03258L13.3774 1.70258C11.9774 0.722584 9.72742 0.772584 8.37742 1.82258Z"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.3574 16V13"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Home</h5>
      </div>
    ),
  },
  {
    path: "/audit-execution",
    name: "Audit Execution",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 25 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.94654 6.52302H14.9305C17.4225 6.52302 17.4225 5.27703 17.4225 4.03104C17.4225 1.53906 16.1765 1.53906 14.9305 1.53906H9.94654C8.70055 1.53906 7.45456 1.53906 7.45456 4.03104C7.45456 6.52302 8.70055 6.52302 9.94654 6.52302Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.4225 4.0625C21.5716 4.28678 23.6524 5.81934 23.6524 11.5135V18.9895C23.6524 23.9734 22.4064 26.4654 16.1765 26.4654H8.70055C2.4706 26.4654 1.22461 23.9734 1.22461 18.9895V11.5135C1.22461 5.83181 3.30541 4.28678 7.45456 4.0625"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.20856 11.5078H17.4225"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
          <path
            d="M6.20856 16.4922H12.4385"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Execution</h5>
      </div>
    ),
  },
];

const forAuditTeam = [
  {
    path: "/",
    name: "Home",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.37742 1.82258L2.98742 6.02258C2.08742 6.72258 1.35742 8.21258 1.35742 9.34258V16.7526C1.35742 19.0726 3.24742 20.9726 5.56742 20.9726H17.1474C19.4674 20.9726 21.3574 19.0726 21.3574 16.7626V9.48258C21.3574 8.27258 20.5474 6.72258 19.5574 6.03258L13.3774 1.70258C11.9774 0.722584 9.72742 0.772584 8.37742 1.82258Z"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.3574 16V13"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Home</h5>
      </div>
    ),
  },
  {
    path: "/audit-universe",
    name: "Audit Universe",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4599 26.412C20.3413 26.412 25.9198 20.8335 25.9198 13.9521C25.9198 7.07067 20.3413 1.49219 13.4599 1.49219C6.57848 1.49219 1 7.07067 1 13.9521C1 20.8335 6.57848 26.412 13.4599 26.412Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.47596 2.73438H9.72195C7.29227 10.011 7.29227 17.8856 9.72195 25.1622H8.47596"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.1979 2.73438C19.6276 10.011 19.6276 17.8856 17.1979 25.1622"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.246 18.9335V17.6875C9.52258 20.1172 17.3972 20.1172 24.6738 17.6875V18.9335"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.246 10.2129C9.52258 7.78321 17.3972 7.78321 24.6738 10.2129"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Universe</h5>
      </div>
    ),
  },
  {
    path: "/audit-execution",
    name: "Audit Execution",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 25 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.94654 6.52302H14.9305C17.4225 6.52302 17.4225 5.27703 17.4225 4.03104C17.4225 1.53906 16.1765 1.53906 14.9305 1.53906H9.94654C8.70055 1.53906 7.45456 1.53906 7.45456 4.03104C7.45456 6.52302 8.70055 6.52302 9.94654 6.52302Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.4225 4.0625C21.5716 4.28678 23.6524 5.81934 23.6524 11.5135V18.9895C23.6524 23.9734 22.4064 26.4654 16.1765 26.4654H8.70055C2.4706 26.4654 1.22461 23.9734 1.22461 18.9895V11.5135C1.22461 5.83181 3.30541 4.28678 7.45456 4.0625"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.20856 11.5078H17.4225"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
          <path
            d="M6.20856 16.4922H12.4385"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Execution</h5>
      </div>
    ),
  },
  {
    path: "/issue-tracking",
    name: "Issue Tracking",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.22995 17.3035L9.48604 13.9194C9.93802 13.2414 10.9485 13.2862 11.3387 14.0016L12.9142 16.8901C13.2943 17.5868 14.2698 17.652 14.7391 17.012L19.6898 10.261M7.22995 26.4589H19.6898C23.1305 26.4589 25.9198 23.6696 25.9198 20.2289V7.76901C25.9198 4.32831 23.1305 1.53906 19.6898 1.53906H7.22995C3.78924 1.53906 1 4.32831 1 7.76901V20.2289C1 23.6696 3.78924 26.4589 7.22995 26.4589Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Issues</h5>
      </div>
    ),
  },
  {
    path: "/templates",
    name: "Templates",
    icon: (
      <div className="icon_name">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 2V5"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 2V5"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21 8.5V13.63C20.11 12.92 18.98 12.5 17.75 12.5C16.52 12.5 15.37 12.93 14.47 13.66C13.26 14.61 12.5 16.1 12.5 17.75C12.5 18.73 12.78 19.67 13.26 20.45C13.63 21.06 14.11 21.59 14.68 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 11H13"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 16H9.62"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M23 17.75C23 18.73 22.72 19.67 22.24 20.45C21.96 20.93 21.61 21.35 21.2 21.69C20.28 22.51 19.08 23 17.75 23C16.6 23 15.54 22.63 14.68 22C14.11 21.59 13.63 21.06 13.26 20.45C12.78 19.67 12.5 18.73 12.5 17.75C12.5 16.1 13.26 14.61 14.47 13.66C15.37 12.93 16.52 12.5 17.75 12.5C18.98 12.5 20.11 12.92 21 13.63C22.22 14.59 23 16.08 23 17.75Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.75 20.25C17.75 18.87 18.87 17.75 20.25 17.75C18.87 17.75 17.75 16.63 17.75 15.25C17.75 16.63 16.63 17.75 15.25 17.75C16.63 17.75 17.75 18.87 17.75 20.25Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h5 className="pwa_naming">Templates</h5>
      </div>
    ),
  },
];
const forAuditTeamPWA = [
  {
    path: "/",
    name: "Home",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.37742 1.82258L2.98742 6.02258C2.08742 6.72258 1.35742 8.21258 1.35742 9.34258V16.7526C1.35742 19.0726 3.24742 20.9726 5.56742 20.9726H17.1474C19.4674 20.9726 21.3574 19.0726 21.3574 16.7626V9.48258C21.3574 8.27258 20.5474 6.72258 19.5574 6.03258L13.3774 1.70258C11.9774 0.722584 9.72742 0.772584 8.37742 1.82258Z"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.3574 16V13"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Home</h5>
      </div>
    ),
  },
  {
    path: "/audit-universe",
    name: "Audit Universe",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4599 26.412C20.3413 26.412 25.9198 20.8335 25.9198 13.9521C25.9198 7.07067 20.3413 1.49219 13.4599 1.49219C6.57848 1.49219 1 7.07067 1 13.9521C1 20.8335 6.57848 26.412 13.4599 26.412Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.47596 2.73438H9.72195C7.29227 10.011 7.29227 17.8856 9.72195 25.1622H8.47596"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.1979 2.73438C19.6276 10.011 19.6276 17.8856 17.1979 25.1622"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.246 18.9335V17.6875C9.52258 20.1172 17.3972 20.1172 24.6738 17.6875V18.9335"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.246 10.2129C9.52258 7.78321 17.3972 7.78321 24.6738 10.2129"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h5 className="pwa_naming">Universe</h5>
      </div>
    ),
  },
  {
    path: "/audit-execution",
    name: "Audit Execution",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 25 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.94654 6.52302H14.9305C17.4225 6.52302 17.4225 5.27703 17.4225 4.03104C17.4225 1.53906 16.1765 1.53906 14.9305 1.53906H9.94654C8.70055 1.53906 7.45456 1.53906 7.45456 4.03104C7.45456 6.52302 8.70055 6.52302 9.94654 6.52302Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.4225 4.0625C21.5716 4.28678 23.6524 5.81934 23.6524 11.5135V18.9895C23.6524 23.9734 22.4064 26.4654 16.1765 26.4654H8.70055C2.4706 26.4654 1.22461 23.9734 1.22461 18.9895V11.5135C1.22461 5.83181 3.30541 4.28678 7.45456 4.0625"
            stroke="white"
            stroke-width="1.86898"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.20856 11.5078H17.4225"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
          <path
            d="M6.20856 16.4922H12.4385"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Execution</h5>
      </div>
    ),
  },
  {
    path: "/issue-tracking",
    name: "Issue Tracking",
    icon: (
      <div className="icon_name">
        <svg
          className="sidebar_icons"
          // width="1.2vw"
          // height="1.2vw"
          viewBox="0 0 27 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.22995 17.3035L9.48604 13.9194C9.93802 13.2414 10.9485 13.2862 11.3387 14.0016L12.9142 16.8901C13.2943 17.5868 14.2698 17.652 14.7391 17.012L19.6898 10.261M7.22995 26.4589H19.6898C23.1305 26.4589 25.9198 23.6696 25.9198 20.2289V7.76901C25.9198 4.32831 23.1305 1.53906 19.6898 1.53906H7.22995C3.78924 1.53906 1 4.32831 1 7.76901V20.2289C1 23.6696 3.78924 26.4589 7.22995 26.4589Z"
            stroke="white"
            stroke-width="1.86898"
            stroke-linecap="round"
          />
        </svg>
        <h5 className="pwa_naming">Issues</h5>
      </div>
    ),
  }
];

export {
  auditRoutes,
  auditRoutesBu,
  homeRoutes,
  auditExecution,
  auditExecutionTop,
  issueTracking,
  forAdmin,
  forBusiness,
  forQA,
  forAuditCommission,
  forAuditTeam,
  auditBusiness,
  auditExecutionInnerBU,
  auditExecutionQA,
  auditExecutionInnerQA,
  templates,
  templatesQA,
  forAdminPWA,
  forQAPWA,
  forAuditTeamPWA,
  auditRoutesRCM,
  issueTrackingBusiness,
};
