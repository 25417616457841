import React, { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import "../../../css/viewuser.css";
import { fetchDataV2, updateDataV2, fetchDataObjectV2 } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import "../auditplan/auditplan.css";
import "./auditreporting.css";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";

const GenerateReportList = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const { id } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/observation/?audit_id=${id}`,
      setterFunction: setAPIData,
      setErrors: setErrors
    }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAuditData,
      setErrors: setErrors
    }
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    setAreAllTasksDone(APIData.every((task) => task?.task?.status === "done"));
  }, [APIData]);

  const [filters, setFilters] = useState({
    id: "",
    status: "",
    workpaper_id: "",
    summary: "",
    preparer: "",
    reviewer_name: "",
    created_at: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };


  const filteredData = APIData.filter((data) => {
    const {
      id,
      summary,
      preparer,
      reviewer,
      created_at,
      task,
      workpapertestcase,
    } = data;

    const matchesId = filters.id
      ? (id || "").toString().includes(filters.id)
      : true;

    const matchesStatus = filters.status
      ? task && task.status.toLowerCase().includes(filters.status.toLowerCase())
      : true;


    const matchesWorkpaperId = filters.workpaper_id
      ? workpapertestcase.some((wpTestCase) =>
        wpTestCase.workpaper.id
          .toString()
          .includes(filters.workpaper_id.toString())
      )
      : true;

    const matchesSummary = filters.summary
      ? (summary || "").toLowerCase().includes(filters.summary.toLowerCase())
      : true;

    const matchesPreparer = filters.preparer
      ? preparer &&
      preparer.name.toLowerCase().includes(filters.preparer.toLowerCase())
      : true;

    const matchesReviewer = filters.reviewer
      ? reviewer &&
      reviewer.name.toLowerCase().includes(filters.reviewer.toLowerCase())
      : true;

    const matchesCreatedAt = filters.created_at
      ? (created_at || "").toString().includes(filters.created_at)
      : true;

    return (
      matchesId &&
      matchesStatus &&
      matchesSummary &&
      matchesWorkpaperId &&
      matchesPreparer &&
      matchesReviewer &&
      matchesCreatedAt
    );
  });


  const updateWithPhase = (e) => {
    e.preventDefault();
    const Parameter = {
      url: `/api/audit_engagement/audit/${id}`,
      payload: {
        risk_assessment: auditData?.risk_assessment?.id,
        audit_entity_id: auditData?.audit_entity?.id,
        auditors_id: auditData?.auditors.map((auditor) => auditor.id),
        business_users_id: auditData?.business_users.map(
          (business_users) => business_users.id
        ),
        actual_start_date: auditData?.actual_start_date,
        actual_end_date: auditData?.actual_end_date,
        planned_start_date: auditData?.audit_event?.planned_start_date,
        planned_end_date: auditData?.audit_event?.planned_end_date,
        audit_scope: auditData?.audit_scope,
        audit_phases: auditData?.audit_phases,
        other_relevant_information: auditData?.other_relevant_information,
        phase: "Reporting Complete",
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/audit-execution/${id}/reporting/`,
    };

    updateDataV2(Parameter);

  };

  const handlePopupClose = (task) => {

    const Parameter = {
      url: `/api/system/task/${task?.task?.id}`,
      payload: {
        title: task.objective,
        status: "done",
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/audit-execution/${id}/reporting`
    };

    updateDataV2(Parameter);


    setAPIData((prevAPIData) =>
      prevAPIData.map((task) => {
        if (task.id === selectedTaskId) {
          return {
            ...task,
            task: {
              ...task.task,
              status: "Done",
            },
          };
        }
        return task;
      })
    );
    window.location.reload();

    setShowPopup(false);
  };

  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };

  const viewObservation = (obejctionId) => {
    navigate(`/audit-execution/${id}/reporting/generatereportlist/${obejctionId}`)
  };

  const renderAuditObservationStatus = (status) => {
    console.log("i am here ")
  };



  const moreDetails = () => {

    navigate(`/audit-execution/${id}/reporting/generatereportmoredetails`)
  }


  const generateReport = () => {

    const Parameter = {
      url: `/api/audit_engagement/audit/${id}`,
      payload: {
        report_generated: true
      },
      setErrors: setErrors,
    };

    updateDataV2(Parameter);

  };


  const phaseTrue = {
    phase: auditData.phase
  }

  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
      setLoading: setLoading
    }
    fetchDataObjectV2(parameter);
  }, [id]);

  const showPhaseColor = (status) => {
    switch (status) {
      case "Generate Report":
        return <span className="status_complete">{status}</span>;
      case "Finalized Draft Qa Approve":
        return <span className="status_complete">{status}</span>;
      case "Finalized Draft Admin Approve":
        return <span className="status_complete">{status}</span>;
      case "Closed":
        return <span className="status_veryhigh">{status}</span>;
      case "Rejected":
        return <span className="status_critical">{status}</span>;

      default:
        return <span className="status_rejected">No Status Found</span>;
    }
  };


  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}
      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow name={'Generate Report'} phaseTrue={phaseTrue} trueReport={() => moreDetails()} moreReport={false} parentName={APIaudit?.name} />





        <div className="reportingTaskTableDiv"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <table>
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">Status</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("status", e)}
                    value={filters.status}
                  />
                </th>

                <th>
                  <h4 className="thhead"> UID </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("id", e)}
                    value={filters.id}
                  />
                </th>
                <th>
                  <h4 className="thhead"> WorkPaperId </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("workpaper_id", e)}
                    value={filters.workpaper_id}
                  />
                </th>

                <th>
                  <h4 className="thhead"> Title</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("summary", e)}
                    value={filters.summary}
                  />
                </th>

                <th>
                  <h4 className="thhead">Preparer</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("preparer", e)}
                    value={filters.preparer}
                  />
                </th>

                <th>
                  <h4 className="thhead">Reviewer</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("reviewer", e)}
                    value={filters.reviewer}
                  />
                </th>

                <th>
                  <h4 className="thhead">Certified On</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("created_at", e)}
                    value={filters.created_at}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {
                filteredData.
                  filter((filterData) => filterData.status === "Finalized Draft Qa Approve")
                  .map((data) => {
                    const uniqueWorkpaperIds = data.workpapertestcase
                      ? [
                        ...new Set(
                          data.workpapertestcase.map(
                            (testcase) => testcase.workpaper.id
                          )
                        ),
                      ]
                      : [];

                    return (

                      <tr
                        key={data.id}
                        onClick={() => viewObservation(data.id)}
                      >

                        <td>
                          {showPhaseColor(data.status ? data.status : "")}


                        </td>
                        <td>

                        <span className="status_todo">{data.id}</span>
                        </td>


                        <td>
                        <span className="status_todo">{uniqueWorkpaperIds.length > 0
                            ? uniqueWorkpaperIds.map((id) => (
                              <span key={id}>{id} </span>
                            ))
                            : ""}
                            </span>
                        </td>
                        <td>
                          <span className="truncate-wrap">
                            {data.summary ? data.summary : ""}
                          </span>
                        </td>
                        <td>{data.preparer ? data.preparer.name : ""}</td>
                        <td>{data.reviewer ? data.reviewer.name : ""}</td>
                        <td>
                          {data.created_at
                            ? new Date(data.created_at).toLocaleDateString()
                            : ""}
                        </td>
                      </tr>
                    );
                  })}

            </tbody>
          </table>
        <div className="button_container">
          <button onClick={generateReport} className="cmn_btn" style={{ backgroundColor: "#5771DF" }}>
            Ready To Generate Report</button>
        </div>
        </div>


        {/* <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard" >
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div> */}
      </section>
    </>
  );
};

export default GenerateReportList;
