import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import WindowComponent from "../../WindowComponent";
import createBtnfile from "../../../images/createbutton.svg";
import "../../../css/viewuser.css";
import "../../../css/createentity.css";
import {
  postDataWithFile,
  fetchDataV2,
  fetchDataObjectV2,
  updateReturnResponse,
  postDataReturnId,
  deleteDataV2,
  deleteDataWithFetchV2,
} from "../../apiUtils";
import SingleOptionSelector from "../../SingleOptionSelector";
import { auditRoutes } from "../../subroutes";
import LongMultiOptionSelector from "../../LongMultiOptionSelector";
import MultiOptionSelector from "../../MultiOptionSelector";
import IconButton from "../../iconbutton/IconButton";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import WindowComponentV2 from "../../WindowComponentV2";
import MultiDepartmentBu from "../../MultiDepartmentBu";
import BumultiSelectV2 from "../../BumultiSelectV2";
const ViewAudit = ({ tableSize }) => {
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [errors, setErrors] = useState("");
  const [tmpId, setTmpId] = useState("");
  const [rcmId, setRcmId] = useState("");

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedKeyError, setSelectedKeyError] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();

  const [APIData, setAPIData] = useState([]);
  const [APIDatacount, setAPIDataCount] = useState([]);
  const [file, setFile] = useState(null);
  const location = useLocation();

  const [showSelectFileButton, setShowSelectFileButton] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [successMessageFileUpload, setSuccessMessageFileUplaod] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState(
    location.state?.successMessage || ""
  );
  const [showWindow, setShowWindow] = useState(false);
  const [deleteQues, setDeleteQues] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [business_unit_id, setBusiness_unit_id] = useState([]);
  const [business_units_id, setBusinessUnit_id] = useState([]);

  const [selectedOtherKey, setSelectedOtherKey] = useState([]);
  const [other_stakeholders_ids, setOther_stakeholders_id] = useState([]);
  const [other_stakeholders_id, setOther_stakeholdersId] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [selectedKeys, setSelectedDepts] = useState([]);
  const [key_stakeholder_id, setKey_stakeholder_id] = useState("");
  const [selectedDept, setSelectedDept] = useState([]);
  const [selectedBUList, setSelectedBUList] = useState([]);
  const [initialDeptp, setInitialDeptp] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/audit-entities/${id}/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);
  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/questionnaire-templates/${id}/count/`,
      setterFunction: setAPIDataCount,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);

  useEffect(() => {
      const timeoutId = setTimeout(() => {
        navigate(".", { replace: true });
      }, 5000);
  
      return () => clearTimeout(timeoutId);
    }, [navigate]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessageFileUplaod(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileUpload]);

  useEffect(() => {
    setTmpId(
      APIData.questionnaire_template ? APIData.questionnaire_template.id : ""
    );
  }, [APIData]);
  useEffect(() => {
    setRcmId(APIData.rcm_template ? APIData.rcm_template.id : "");
  }, [APIData]);

  const Id = APIData.id;
  const auditName = APIData.name;
  const auditDescription = APIData.description;
  const auditSelectedKeysid = APIData.key_stakeholder
    ? APIData.key_stakeholder.id
    : "";
  const auditSelectedKeysname = APIData.key_stakeholder
    ? APIData.key_stakeholder.name
    : "";
  const buUnitsString = APIData.audit_unit;

  useEffect(() => {
    const auditSelectedKeysid = APIData.key_stakeholder
      ? APIData.key_stakeholder.id
      : "";
    const auditSelectedKeysname = APIData.key_stakeholder
      ? APIData.key_stakeholder.name
      : "";
    setKey_stakeholder_id(auditSelectedKeysid);
    setSelectedDepts([auditSelectedKeysname]);
    const buUnitsString = APIData.audit_unit;

    let buUnits = [];
    if (buUnitsString) {
      try {
        buUnits = buUnitsString;
      } catch (e) {
        console.error("Failed to parse buUnitsString:", e);
      }
    }
    const ids = buUnits.map((item) => item.business_units_id);
    const Names = buUnits.map((item) => item.name);



    setSelectedDept(APIData?.department ? APIData?.department?.department : [] )
    setInitialDeptp(APIData?.department?.department)
    setBusinessUnit_id(ids);
    const auditSelectedKeysOther = APIData.other_stakeholders
      ? APIData.other_stakeholders
      : [];
    const otherIds = auditSelectedKeysOther.map((item) => item.id);
    const otherNames = auditSelectedKeysOther.map((item) => item.name);
    setSelectedUnits(Names);
    setOther_stakeholders_id(otherIds);
    setSelectedOtherKey(otherNames);
    setName(auditName);
    setDescription(auditDescription);
    const parameter = {
      url: `/api/audit_universe/audit-entities/${id}/history/`,
      setterFunction: setHistoryData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
    setName(auditName || "");
    setDescription(auditDescription || "");
  }, [buUnitsString]);

  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const Roles = Object.keys(userRoles).some((key) =>
    key.includes("Audit Team")
  );

  const handleFirstNameChange = (event) => {
    const newName = event.target.value;
    setFirstName(newName);
    setFirstNameError(false);
    setName(newName);

    if (newName.length > 0) {
      const initialLetter = newName[0].toUpperCase();
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const updateAPIData = (e) => {
    e.preventDefault();
    if (!name || !description || !key_stakeholder_id) {
      setFirstNameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      setSelectedKeyError(!key_stakeholder_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const parameter = {
      url: `/api/audit_universe/audit-entities/${Id}/`,
      payload: {
        name,
        description,
        business_units_id,
        key_stakeholder_id,
        other_stakeholders_id,
        department : {department :selectedDept}
      },
      setErrors: setErrors,
      setSuccessCallback: (data) => {
        const successMessageColor = "#4285F4";
        navigate(`/audit-universe/audit-entities/${data.id}/`, {
          state: {
            successMessage: "Updated Successfully",
            successMessageColor,
          },
        });
      },
    };

    updateReturnResponse(parameter);
    setEditMode(false);
  };

  const onDelete = (e) => {
    e.preventDefault();

    const parameter = {
      url: `/api/audit_universe/audit-entities/${Id}`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-universe/audit-entities`,
    };

    deleteDataV2(parameter);
  };

  const onDeleteQuestionnsire = (e) => {
    e.preventDefault();
    const parameter = {
      url: `/api/audit_universe/questionnaire-templates/${Id}/delete/`,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_universe/audit-entities/${id}/`,
            dataset: setAPIData,
          },
          {
            fetchurl: `/api/audit_universe/questionnaire-templates/${id}/count/`,
            dataset: setAPIDataCount,
          },
          {
            fetchurl: `/api/audit_universe/audit-entities/${id}/history/`,
            dataset: setHistoryData,
          },
        ],
        setSuccessMessage: setDeleteQues,
        setErrors: setErrors,
      },
    };

    deleteDataWithFetchV2(parameter);
  };
  useEffect(() => {
    setShowWindow(false);
  }, [deleteQues]);

  const handleChange = (event) => {
    const newDescription = event.target.value;
    setDescription(newDescription);
    setDescriptionError(false);
  };

 


  const handleRemoveSelectedKey = (unitToRemove) => {
    setSelectedKeyError(true);
    setSelectedDepts(selectedKeys.filter((unit) => unit !== unitToRemove));
  };

  const handleFileChange = (event) => {
    // Get the selected file
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles((prevSelectedFiles) => {
        if (!Array.isArray(prevSelectedFiles)) {
          console.error("selectedFiles is not an array", prevSelectedFiles);
          return [file];
        }
        return [...prevSelectedFiles, file];
      });
      setShowSelectFileButton(false);
    }
  };

  const handleUpload = () => {
    if (selectedFiles.length === 0) {
      setErrors("Please select a file to upload.");
      return;
    }

    const parameter = {
      url: "/api/audit_universe/questions/upload/",
      files: selectedFiles,
      data: {
        audit_entity_id: APIData?.id,
      },
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_universe/audit-entities/${id}/`,
            dataset: setAPIData,
          },
        ],
        setSuccessMessage: setSuccessMessageFileUplaod,
      },
      setErrors: setErrors,
      setSuccessMessage: setSuccessMessageFileUplaod,
    };

    postDataWithFile(parameter);
    setSelectedFiles(null);
    setShowSelectFileButton(true);
  };

  console.log(APIData);

  const createQuestionnaire = (quid) => {
    navigate(`/audit-universe/audit-entities/${id}/${quid}`, {
      // state: { data: data },
    });
  };

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessageFileUpload && (
        <p className="successmessage">File Uploaded SuccessFully</p>
      )}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {deleteQues && (
        <p className="successmessage">Questionaire Delete SuccessFully</p>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditRoutes} />
        <NavRow name={editMode ? "Edit " + name : name} />

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="allofauditentity"
            style={{ width: tableSize ? "100%" : "100%" }}
          >
            <form onDoubleClick={handleEditClick}>
              <div className="lbl_inpt">
                <label>Name</label>
                <input
                  style={{
                    border: firstNameError ? "1px solid #FF4646" : "",
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  type="text"
                  placeholder="Audit Entity Name"
                  value={name}
                  id="firstName"
                  onChange={handleFirstNameChange}
                  readOnly={!editMode}
                  tabIndex={1}
                />
              </div>

              <SingleOptionSelector
                apiUrl="/api/audit_universe/admin-business-stakeholders/"
                setSelectorId={setKey_stakeholder_id}
                setSelectorError={setSelectedKeyError}
                selectorError={selectedKeyError}
                Name="name"
                title="Key Stakeholder"
                initialId={auditSelectedKeysid}
                initialName={auditSelectedKeysname}
                editMode={editMode}
                tabIndex={2}
              />

              <div className="lbl_txa">
                <label>Description</label>
                <textarea
                  style={{
                    border: descriptionError ? "1px solid #FF4646" : "",
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Description"
                  value={description}
                  onChange={handleChange}
                  readOnly={!editMode}
                  tabIndex={3}
                />
              </div>

              <MultiDepartmentBu
                apiUrl="/api/system/departments/"
                setSelectorId={setSelectedDept}
                selectedIds={selectedDept}
                setBusinessUnit_id={setBusinessUnit_id}
                business_units_id={business_units_id}
                selectedBUList={selectedBUList}
                setSelectedBUList={setSelectedBUList}
                initialDeptp ={initialDeptp}
                Name="name"
                title="Department"
                editMode={editMode}
                tabIndex={4}
              />

              <BumultiSelectV2
                apiUrl="/api/system/business-units/"
                setSelectorId={setBusinessUnit_id}
                selectedIds={business_units_id}
                Name="name"
                title="Business Unit "
                selectedBUList={selectedBUList}
                setSelectedDept={setSelectedDept}
                selectedDept={selectedDept}
                editMode={editMode}
                tabIndex={4}
              />

              <LongMultiOptionSelector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setOther_stakeholdersId}
                Name="name"
                title="Other Key Stakeholders"
                initialIds={other_stakeholders_ids}
                initialNames={selectedOtherKey}
                editMode={editMode}
                tabIndex={5}
              />
            </form>

            <div className="button_container">
              <button
                className="cmn_btn"
                style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
                disabled={!editMode}
                onClick={updateAPIData}
                tabIndex={6}
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Save
              </button>

              {editMode && (
                <button
                  onClick={handleCancelClick}
                  className="cmn_btn"
                  style={{ backgroundColor: "#d6d6d6", color: "#000" }}
                  tabIndex={7}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L10.9989 10.9989"
                      stroke="#292D32"
                      stroke-width="1.76758"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.00107 10.9989L11 1"
                      stroke="#292D32"
                      stroke-width="1.76758"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel Edit
                </button>
              )}

              <WindowComponent
                tabIndex={8}
                onDelete={onDelete}
                text="Delete Audit Entity"
                showWindow={showWindow}
                setShowWindow={setShowWindow}
              />
            </div>

            <div
              className="questionnairesection"
              style={{ display: Roles ? "" : "" }}
            >
              <h6>Questionnaire</h6>

              <div
                className="quecrud"
                style={{ width: tableSize ? "80vw" : "90.5vw" }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div className="questionnaire_file_name">
                    {selectedFiles && selectedFiles.length > 0 ? (
                      selectedFiles?.map((file, index) => (
                        <>
                          <h6 key={index}>{file.name}</h6>

                          <svg
                            onClick={() => {
                              setSelectedFiles(
                                selectedFiles.filter((_, i) => i !== index)
                              );
                            }}
                            width="10"
                            height="10"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L10.9989 10.9989"
                              stroke="#292D32"
                              stroke-width="1.76758"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1.00107 10.9989L11 1"
                              stroke="#292D32"
                              stroke-width="1.76758"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </>
                      ))
                    ) : (
                      <h6 className="slct_plchldr">Select Files</h6>
                    )}
                  </div>

                  {showSelectFileButton ? (
                    <button
                      className="select-file-button"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    >
                      <img src={createBtnfile} alt="" />
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleFileSelect}
                      />
                    </button>
                  ) : (
                    <button
                      className="cmn_btn"
                      style={{ backgroundColor: "#65CC5C" }}
                      onClick={handleUpload}
                    >
                      Upload Files
                    </button>
                  )}
                </div>
                <div className="view_delete_qestionnnaire">
                  <button
                    className="cmn_btn"
                    style={{
                      backgroundColor:
                        APIDatacount.question_count === 0 ? "grey" : "#5771df",
                    }}
                    onClick={() =>
                      createQuestionnaire(APIData.questionnaire_template.id)
                    }
                    disabled={APIDatacount.question_count === 0}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.0109 14.5717L18.0109 5.98745L9.42661 5.98745"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.99002 18.01L17.8906 6.10938"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    View
                  </button>
                  <WindowComponentV2
                    onDelete={onDeleteQuestionnsire}
                    text="Delete Questionaire"
                    disabled={APIDatacount.question_count === 0}
                    style={{
                      backgroundColor:
                        APIDatacount.question_count === 0 ? "grey" : "#FE2768",
                    }}
                    showWindow={showWindow}
                    setShowWindow={setShowWindow}
                  />
                </div>

                <div className="view_update_qestionnnaire">
                  <h6>Risk Assessment Questionnaire Template</h6>

                  <div className="view_edit_upload">
                    <button>View</button>
                    <button>Edit</button>
                    <button>Upload</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="historysection">
              <h3>Risk Assessment History</h3>

              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Total Impact</th>
                    <th>Total Likelihood</th>
                    <th>Risk Score</th>
                  </tr>
                </thead>

                <tbody>
                  {historyData.map((item, index) => (
                    <tr key={index}>
                      <td className="score_tag">
                        <span>{item.assessment_year}</span>
                      </td>
                      <td className="score_tag">
                        <span className="high">{item.total_impact}</span>
                      </td>
                      <td className="score_tag">
                        <span className="moderate">
                          {item.total_likelihood}
                        </span>
                      </td>
                      <td className="score_tag">
                        <span className="low">{item.risk_score}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ViewAudit;
