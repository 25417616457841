import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  Bar,
} from "recharts";

// Define the colors for the bars
const COLORS = ['#82ca9d', '#8884d8', '#ff8042', '#ffbb28', '#ff6361'];

const PriorityBarChart = ({ apiResponse,keyMapping }) => {



  // Converting API response to chartData format
  const chartData = Object.entries(apiResponse).map(([key, value]) => ({
    name: keyMapping[key] || key,  // Ensure there is a default mapping for missing keys
    value,
  }));

  console.log("chartData : ", chartData);  // Add logging to check the final chart data

  const [maxValue, setMaxValue] = useState();

  // Calculate the maximum value for scaling the Y-axis
  useEffect(() => {
    setMaxValue(Math.max(...chartData.map((item) => item.value)));
  }, [apiResponse]);

  return maxValue ? (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData}
       
      
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          interval={0}  // Force every label to be displayed
          tick={{ dy: 10 }} // Add space between the labels and the axis
        />
        <YAxis domain={[0, maxValue + 1]} />
        <Tooltip formatter={(value, name) => [`Value: ${value}`, name]} />
        <Bar
          dataKey="value"
          isAnimationActive={true}
          shape={(props) => {
            const { x, y, width, height, index } = props;
            const fill = COLORS[index % COLORS.length]; // Cycle through the colors array
            return (
              <rect
                x={x}
                y={y}
                width={width}
                height={height}
                fill={fill}
              />
            );
          }}
        >
          <LabelList
            dataKey="value"
            position="top"
            formatter={(value) => `${value}`}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  ) : null;
};

export default PriorityBarChart;
