import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WindowComponent from "../../../WindowComponent";
import "../../../../css/viewuser.css";
import cutbutton from "../.../../../../../images/cut.svg";
import {
  postDataWithFile,
  fetchDataV2,
  fetchDataObjectV2,
  justUpdateDataV2,
  deleteDataV2,
  updateDataV3,
  postDataWithFetchV2,
  deleteDataWithFetchV2,
} from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import "../createObservation/createobservation.css";
import savesubObv from "../../../../images/saveBtn.svg";
import remove_button from "../../../../images/remove_button.svg";
import blue_add_button from "../../../../images/blue_plus_button.svg";
import OptionDropDown2 from "../../../OptionDropDown2";
import createBtn from "../../../../images/createbutton.svg";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";
import ObservationForm from "../Observations/ObservationForm";

const QAInitaiApproval = ({ tableSize }) => {
  const lastName = localStorage.getItem("userLastName");
  const avaUserName = localStorage.getItem("userName");
  const [APIQData, setAPIQData] = useState("");
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [summary, setSummary] = useState("");
  const [item, setItem] = useState({});
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [assign_to_business_teammate_id, setTeammate_id] = useState("");
  const [teammate_idError, setTeammate_idError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [testIds, setTestIds] = useState([]);
  const [workPId, setWorkPId] = useState([]);
  const [workPojcts, setWorkPojcts] = useState([]);
  const [workPIdError, setWorkPIdError] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [editModeBusiness, setEditModeBusiness] = useState(true);
  const [taskData, setTaskData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);
  const successMessage = location.state?.successMessage;
  const { id, reportId, obejctionId } = useParams();
  const [subObservations, setSubObservations] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [objective, setTitle] = useState("");
  const [rating, setObservationRating] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [recommendation, setRecommendation] = useState("");
  const [exposure_to_losses, setExposureLosses] = useState("");
  const [potential_impact, setPotentialImpact] = useState("");
  const [likelyhood, setLikelihood] = useState("");
  const [risk1, setRisk01] = useState("");
  const [risk2, setRisk02] = useState("");
  const [risk3, setRisk03] = useState("");
  const [action_plan, setActionPlan] = useState("");
  const [action_planError, setActionPlanError] = useState(false);
  const [fileObjective, setFileObjective] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [assign_to_id, setLevel1Owner] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState(null);
  const [secondary_assign_to_id, setLevel2Owner] = useState(null);
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [management_response_due_date, setActionDueDate] = useState("");
  const [management_response_due_dateError, setActionDueDateError] =
    useState("");
  const [response_audit, setAuditResponse] = useState("");
  const [response_audit_due_date, setResponseActionDueDate] = useState("");
  const [preliminary_close_observation, setPreliminaryCloseObservation] =
    useState("");
  const [
    preliminary_close_observationError,
    setPreliminaryCloseObservationError,
  ] = useState(false);
  const [short_description, setShortDescription] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [inputList, setInputList] = useState([]);
  const [qa_id, setQAID] = useState("");
  const [qa_idError, setQAIDError] = useState(false);
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const [showbtns, setShowbtns] = useState();
  const [showComments, setShowComments] = useState(false);
  const businessRole = Object.keys(userRoles).some((key) =>
    key.includes("Business")
  );
  const businessOnlyRole = userRoles === "Business";
  const commentRef = useRef(null);
  const [observationUrl, setObservationUrl] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [commetIdTask, setCommentIdTask] = useState();
  const [APIaudit, setAudit] = useState([]);
  const [successMessageFileUpload, setSuccessMessage] = useState(false);
  const [successMessageFileDelete, setSuccessMessageDelete] = useState(false);
  const [successMessageUpdate, setSuccessMessageUpdate] = useState(false);
  const [successMessageSubmit, setSuccessMessageSubmit] = useState(false);
  const [successMessageAccept, setSuccessMessageAccept] = useState(false);
  const [successMessageReject, setSuccessMessageReject] = useState(false);
  const [successMessageRequest, setSuccessMessageRequest] = useState(false);
  const [successMessageClosed, setSuccessMessageClosed] = useState(false);
  const [successMessageobs, setSuccessMessageSubObs] = useState(false);
  const [successMessagedeleteobs, setSuccessMessageDeleteSubObs] =
    useState(false);
  const [subObservationsdata, setSubObservationsData] = useState([]);

  const preparerUserId = parseInt(localStorage.getItem("user_id"));
  console.log(preparerUserId);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileUpload]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessageDelete(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileDelete]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/sub-observation/?observation_id=${obejctionId}`,
      setterFunction: setSubObservationsData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const fetchAuditData = () => {
      const parameter = {
        url: `/api/audit_engagement/audit/${id}/`,
        setterFunction: setAudit,
        setErrors: setErrors,
        setLoading: setLoading,
      };
      fetchDataObjectV2(parameter);

      const parameter1 = {
        url: `/api/audit_engagement/observation/${obejctionId}/`,
        setterFunction: setAPIData,
        setErrors: setErrors,
        setLoading: setLoading,
      };
      fetchDataObjectV2(parameter1);

      const parameter2 = {
        url: `/api/audit_engagement/observationfile/?observation_id=${obejctionId}`,
        setterFunction: setFileData,
        setErrors: setErrors,
      };
      fetchDataV2(parameter2);
    };

    fetchAuditData();
  }, [id, obejctionId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (APIData) {
      const taskMap = {
        initialobservation: "task_initial_admin_approval",
        qaapprovalinitialobservation: "task_initial_qa_approval",
        businessownerapproval: "task_initial_business_approval",
        qaapprovalfinalizeobservation: "task_final_qa_approval",
        auditdirectorapproval: "task_final_admin_approval",
      };

      const taskKey = taskMap[observationUrl] || "task_initial_admin_approval";

      const taskData = APIData[taskKey];
      setCommentIdTask(taskData?.id);
      setShowbtns(taskData?.status === "done");

      if (taskData?.id) {
        const parameter = {
          url: `/api/system/comments/?task_id=${taskData.id}`,
          setterFunction: setTaskData,
          setErrors: setErrors,
        };
        fetchDataV2(parameter);
      }

      setTitle(APIData?.objective);
      setObservationRating(APIData?.rating);
      setShortDescription(APIData?.short_description);
      setExposureLosses(APIData?.exposure_to_losses);
      setPotentialImpact(APIData?.potential_impact);
      setLikelihood(APIData?.likelyhood);

      const risks = APIData?.risks || {};
      setRisk01(risks?.risk1);
      setRisk02(risks?.risk2);
      setRisk03(risks?.risk3);

      setAPIQData(taskData?.id);
      setStartdate(APIData.due_date);
      setActionPlan(APIData?.action_plan);
      setActionDueDate(APIData?.management_response_due_date);
      setSummary(APIData.summary);
      setRecommendation(APIData.recommendation);
      setAuditResponse(APIData?.response_audit);
      setResponseActionDueDate(APIData?.response_audit_due_date);
      setPreliminaryCloseObservation(APIData?.preliminary_close_observation);

      const workpapers = APIData.workpapertestcase || [];
      setTestIds(workpapers.map((item) => item.id));
      setWorkPId([...new Set(workpapers.map((item) => item.workpaper?.id))]);
      setWorkPojcts([
        ...new Set(workpapers.map((item) => item.workpaper?.objective)),
      ]);
      setSubObservations(APIData?.data?.subObservations || []);
      setTaskTitle(taskData?.title);

      setEditMode(taskData?.status !== "todo");
    }
  }, [APIData, observationUrl]);

  useEffect(() => {
    if (errors) {
      const timer = setTimeout(() => {
        setErrors("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errors]);

  useEffect(() => {
    const observationValue = window.location.href.split("/").slice(-2, -1)[0];
    setObservationUrl(observationValue);
  }, []);

  useEffect(() => {
    if (!editMode) {
      if (!preparer_id) {
        setPreparerError(!preparer_id ? true : false);
        setErrors("Please Fill the Field");
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/observation/${obejctionId}/preparer/`,
        payload: {
          preparer_id,
        },
        setErrors: setErrors,
      };
      justUpdateDataV2(parameter);
    }
  }, [preparer_id]);

  useEffect(() => {
    if (!editMode) {
      if (!reviewer_id) {
        setReviewerError(!reviewer_id ? true : false);
        setErrors("Please Fill the Field");
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/observation/${obejctionId}/reviewer/`,
        payload: {
          reviewer_id,
        },
        setErrors: setErrors,
      };
      justUpdateDataV2(parameter);
    }
  }, [reviewer_id]);

  useEffect(() => {
    if (!editMode) {
      if (!assign_to_id) {
        setLevel1OwnerError(!assign_to_id ? true : false);
        setErrors("Please Fill the Field");
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/observation/${obejctionId}/assign/`,
        payload: {
          assign_to_type: "Primary",
          value: assign_to_id,
        },
        setErrors: setErrors,
      };
      justUpdateDataV2(parameter);
    }
  }, [assign_to_id]);

  // useEffect(() => {
  //   if (!editMode) {
  //     if (!secondary_assign_to_id) {
  //       setLevel2OwnerError(!secondary_assign_to_id ? true : false);
  //       setErrors("Please Fill the Field");
  //       return;
  //     }
  //     const parameter = {
  //       url: `/api/audit_engagement/observation/${obejctionId}/assign/`,
  //       payload: {
  //         assign_to_type: "secondary",
  //         value: secondary_assign_to_id,
  //       },
  //       setErrors: setErrors,
  //     };
  //     justUpdateDataV2(parameter);
  //   }
  // }, [secondary_assign_to_id]);

  const handlepopup = () => {
    setIsModalOpen(true);
  };

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      const parameter = {
        url: `/api/system/comments/`,
        payload: {
          user_id: localStorage.getItem("user_id"),
          task_id: APIQData,
          text: inputValue,
        },
        setErrors: setErrors,
        tofetch: {
          items: [
            {
              fetchurl: `/api/system/comments/?task_id=${APIQData} `,
              dataset: setTaskData,
            },
          ],
          setSuccessMessage: setSuccessMessageRequest,
          setErrors: setErrors,
        },
      };
      postDataWithFetchV2(parameter);

      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };

  const handleSave = (index) => {
    const updatedList = [...inputList];
    updatedList[index].isEditing = false;
    const text = updatedList[index].value;

    const parameter = {
      url: "/api/audit_engagement/sub-observation/",
      payload: {
        observation_id: obejctionId,
        management_response: text,
      },
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/sub-observation/?observation_id=${obejctionId}`,
            dataset: setSubObservationsData,
          },
        ],
        setSuccessMessage: setSuccessMessageSubObs,
        setErrors: setErrors,
      },
    };

    postDataWithFetchV2(parameter);
    setInputList(updatedList);
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleCancel = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleRemove = (index, subobsid) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);
    const parameter = {
      url: `/api/audit_engagement/sub-observation/${subobsid}/`,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/sub-observation/?observation_id=${obejctionId}`,
            dataset: setSubObservationsData,
          },
        ],
        setSuccessMessage: setSuccessMessageDeleteSubObs,
        setErrors: setErrors,
      },
    };
    deleteDataWithFetchV2(parameter);
    const updatedObservations = subObservations.filter((_, i) => i !== index);
    setSubObservations(updatedObservations);
  };

  const handleChange = (e, index) => {
    const updatedList = [...inputList];
    updatedList[index].value = e.target.value;
    setInputList(updatedList);
  };

  function convertDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    }
  }
  const handleDeleteModalOpen = (fileId) => {
    setFileToDelete(fileId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setFileToDelete(null);
  };

  const confirmDeleteFile = () => {
    if (fileToDelete) {
      removeFile(fileToDelete);
      handleDeleteModalClose();
    }
  };

  const removeFile = (file_id) => {
    const parameter = {
      url: `/api/audit_engagement/observationfile/${file_id}/`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/Reporting/ReportingObservationUpdate/${reportId}/${obejctionId}/`,
    };
    deleteDataV2(parameter);
  };

  const handleAdd = () => {
    setInputList([...inputList, { value: "", isEditing: true }]);
  };

  const closeObservation = () => {
    if (!preliminary_close_observation) {
      setErrors("Please Fill the Field");
      setPreliminaryCloseObservationError(
        !preliminary_close_observation ? true : false
      );
    } else {
      const Parameter = {
        url: `/api/audit_engagement/observation/${obejctionId}/status`,
        payload: {
          status: "Closed",
        },
        tofetch: {
          setSuccessMessage: setSuccessMessageClosed,
          items: [
            {
              fetchurl: `/api/audit_engagement/observation/${obejctionId}/`,
              dataset: setAPIData,
            },
          ],
        },
        setErrors: setErrors,
        navigate: navigate,
      };
      updateDataV3(Parameter);
    }
  };

  const RejectObservation = () => {
    const Parameter = {
      url: `/api/audit_engagement/observation/${obejctionId}/status`,
      payload: {
        status: "Rejected",
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageReject,
        items: [
          {
            fetchurl: `/api/audit_engagement/observation/${obejctionId}/`,
            dataset: setAPIData,
          },
        ],
      },
      setErrors: setErrors,
      navigate: navigate,
    };
    updateDataV3(Parameter);
  };

  const approveObservation = (approvedFor, approved) => {
    const Parameter = {
      url: `/api/audit_engagement/observation/${obejctionId}/approve`,
      payload: {
        approve_for: approvedFor,
        approved,
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageAccept,
        items: [
          {
            fetchurl: `/api/audit_engagement/observation/${obejctionId}/`,
            dataset: setAPIData,
          },
        ],
      },
      setErrors: setErrors,
      navigate: navigate,
    };

    updateDataV3(Parameter);
  };

  const requestObservation = (approvedFor, approved) => {
    const Parameter = {
      url: `/api/audit_engagement/observation/${obejctionId}/approve`,
      payload: {
        approve_for: approvedFor,
        approved,
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageRequest,
        items: [
          {
            fetchurl: `/api/audit_engagement/observation/${obejctionId}/`,
            dataset: setAPIData,
          },
        ],
      },
      setErrors: setErrors,
      navigate: navigate,
    };

    updateDataV3(Parameter);
  };

  const SubmitObservation = (submitFor) => {
    const Parameter = {
      url: `/api/audit_engagement/observation/${obejctionId}/submit`,
      payload: {
        submit_for: submitFor,
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageSubmit,
        items: [
          {
            fetchurl: `/api/audit_engagement/observation/${obejctionId}/`,
            dataset: setAPIData,
          },
        ],
      },
      setErrors: setErrors,
      navigate: navigate,
    };

    updateDataV3(Parameter);
  };

  const updateObservation = () => {
    const Parameter = {
      url: `/api/audit_engagement/observation/${obejctionId}`,
      payload: {
        audit_id: id,
        objective,
        short_description,
        rating,
        recommendation,
        exposure_to_losses,
        potential_impact,
        likelyhood,
        risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
        action_plan,
        summary,
        preparer_id,
        reviewer_id,
        assign_to_id,
        assign_to_id,
        qa_id,
        management_response_due_date,
        response_audit,
        response_audit_due_date,
        preliminary_close_observation,
        workpapertestcase_id: Object.values(item).flat(),
        data: { subObservations },
        action_plan,
        due_date: startdate,
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageUpdate,
        items: [
          {
            fetchurl: `/api/audit_engagement/observation/${obejctionId}/`,
            dataset: setAPIData,
          },
        ],
      },
      setErrors: setErrors,
      navigate: navigate,
    };

    updateDataV3(Parameter);
    setEditMode(true);
  };

  const handleFileUpload = () => {
    if (!selectedFile || !fileObjective) {
      setErrors("Please Select Image And Fill Description");
      return;
    }

    const parameter = {
      url: "/api/audit_engagement/observationfile/",
      data: {
        observation_id: obejctionId,
        text: fileObjective,
      },
      files: [selectedFile],
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/observationfile/?observation_id=${obejctionId}`,
            dataset: setFileData,
          },
        ],
        setSuccessMessage: setSuccessMessage,
      },
    };

    postDataWithFile(parameter);
    setFileObjective("");
    setIsModalOpen(false);
  };

  const handleCloseClick = () => {
    setIsModalOpen(false);
  };

  const [auditData, setAuditData] = useState([]);

  const obvStatus = {
    observationUrl: observationUrl,
    setAPIData: setAPIData,
    APIData: APIData,
    status: APIData?.status,
    task_initial_admin_approval: APIData?.task_initial_admin_approval?.status,
    task_initial_qa_approval: APIData?.task_initial_qa_approval?.status,
    task_final_admin_approval: APIData?.task_final_admin_approval?.status,
    task_initial_business_approval:
      APIData?.task_initial_business_approval?.status,
    task_final_qa_approval: APIData?.task_final_qa_approval?.status,
  };

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };
  const onDelete = (e) => {
    e.preventDefault();
    const parameter = {
      url: `/api/audit_engagement/observation/${obejctionId}`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/reporting/initialobservation`,
    };
    deleteDataV2(parameter);
  };

  const [isReviewerMismatch, setIsReviewerMismatch] = useState(false);

  useEffect(() => {
    setIsReviewerMismatch(
      APIData?.task_initial_qa_approval?.status !== "review" ||
        APIData?.status === "Closed" ||
        preparerUserId != APIData?.task_initial_qa_approval?.owner?.id
    );
  }, [preparerUserId, APIData]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handlePopupSubmit = () => {
    requestObservation("Initial_Qa", false);
    handleAddComment();
    togglePopup();
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow
          name={editMode ? "Edit View Observation" : "View Observation"}
          parentName={APIaudit?.name}
          obvStatus={obvStatus}
        />

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="create_observation_con"
            style={{ width: tableSize ? "100%" : "100%" }}
          >
            <div className="backToReport">
              <button
                className="cmn_btn"
                style={{
                  backgroundColor: isReviewerMismatch ? "#7A7A7A" : "#65CC5C",
                }}
                disabled={isReviewerMismatch}
                onClick={() => approveObservation("Initial_Qa", true)}
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Accept
              </button>
              <button
                style={{
                  backgroundColor: isReviewerMismatch ? "#7A7A7A" : "#FE2768",
                }}
                className="cmn_btn"
                disabled={isReviewerMismatch}
                onClick={() => RejectObservation()}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99967 14.6654C11.6663 14.6654 14.6663 11.6654 14.6663 7.9987C14.6663 4.33203 11.6663 1.33203 7.99967 1.33203C4.33301 1.33203 1.33301 4.33203 1.33301 7.9987C1.33301 11.6654 4.33301 14.6654 7.99967 14.6654Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.11328 9.88661L9.88661 6.11328"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.88661 9.88661L6.11328 6.11328"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Reject
              </button>

              <div className="request_changes_div">
                <button
                  style={{
                    backgroundColor: isReviewerMismatch ? "#7A7A7A" : "#FE2768",
                  }}
                  className="cmn_btn"
                  disabled={isReviewerMismatch}
                  onClick={togglePopup}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 14.668H14"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Request Change
                </button>

                {isPopupOpen && (
                  <div className="request_changes_popup">
                    <div className="lbl_txa">
                      <label>List out changes below</label>
                      <textarea
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Add your comment"
                      />
                    </div>
                    <div className="request_changes_button_container">
                      <button
                        className="cmn_btn"
                        style={{
                          backgroundColor: "#65CC5C",
                        }}
                        onClick={handlePopupSubmit}
                      >
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 10 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 4.72621L3.18182 6.90803L9 1.08984"
                            stroke="white"
                            stroke-width="1.45455"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Publish Request
                      </button>
                      <button onClick={togglePopup}>Cancel</button>
                    </div>
                  </div>
                )}
              </div>

              <button
                className="cmn_btn"
                style={{
                  backgroundColor:
                    (preparerUserId !=
                      APIData?.task_initial_qa_approval?.owner?.id &&
                    preparerUserId !=
                      APIData?.task_initial_qa_approval?.assigned_to?.id) ||
                    APIData?.task_initial_qa_approval?.status !==
                      "in progress" ||
                    APIData?.task_initial_qa_approval?.status === "review"
                      ? "#7A7A7A"
                      : "#5571DF",
                }}
                disabled={
                  (preparerUserId !=
                    APIData?.task_initial_qa_approval?.owner?.id &&
                  preparerUserId !=
                    APIData?.task_initial_qa_approval?.assigned_to?.id )||
                  APIData?.task_initial_qa_approval?.status !== "in progress" ||
                  APIData?.task_initial_qa_approval?.status === "review"
                }
                onClick={() => SubmitObservation("Initial_Qa")}
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Submit
              </button>

              <button
                className="cmn_btn"
                style={{
                  backgroundColor:
                    editMode ||
                    (preparerUserId !=
                      APIData?.task_initial_qa_approval?.owner?.id &&
                    preparerUserId !=
                      APIData?.task_initial_qa_approval?.assigned_to?.id) ||
                    APIData?.task_initial_qa_approval?.status === "done"
                      ? "#7A7A7A"
                      : "#65CC5C",
                }}
                onClick={updateObservation}
                disabled={
                  editMode ||
                  APIData?.task_initial_qa_approval?.status === "done" ||
                  (preparerUserId !=
                    APIData?.task_initial_qa_approval?.owner?.id &&
                  preparerUserId !=
                    APIData?.task_initial_qa_approval?.assigned_to?.id)
                }
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Save
              </button>

              <button
                className="cmn_btn"
                style={{
                  backgroundColor:
                  (preparerUserId !=
                    APIData?.task_initial_qa_approval?.owner?.id &&
                  preparerUserId !=
                    APIData?.task_initial_qa_approval?.assigned_to?.id) ||
                    APIData?.task_initial_qa_approval?.status === "done" ||
                    APIData?.status === "Closed"
                      ? "#7A7A7A"
                      : "#5571DF",
                }}
                onClick={handleEditClick}
                disabled={
                  APIData?.task_initial_qa_approval?.status === "done" ||
                  APIData?.status === "Closed" ||
                  (preparerUserId !=
                    APIData?.task_initial_qa_approval?.owner?.id &&
                  preparerUserId !=
                    APIData?.task_initial_qa_approval?.assigned_to?.id)
                }
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Edit
              </button>

              {!editMode && (
                <button
                  className="cmn_btn"
                  style={{ backgroundColor: "#d6d6d6", color: "#000" }}
                  onClick={() => handleCancelClick()}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L10.9989 10.9989"
                      stroke="#292D32"
                      stroke-width="1.76758"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.00107 10.9989L11 1"
                      stroke="#292D32"
                      stroke-width="1.76758"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel Edit
                </button>
              )}
            </div>

            <ObservationForm
              obejctionId={obejctionId}
              APIaudit={APIaudit}
              editMode={!editMode}
              handleEditClick={handleEditClick}
              observationUrl={observationUrl}
              APIData={APIData}
              editModeBusiness={editModeBusiness}
              setTeammate_id={setTeammate_id}
              assign_to_business_teammate_id={assign_to_business_teammate_id}
              setTeammate_idError={setTeammate_idError}
              teammate_idError={teammate_idError}
              businessRole={!businessRole}
              tableSize={tableSize}
              setPreparerId={setPreparerId}
              setPreparerError={setPreparerError}
              PreparerError={PreparerError}
              setReviewerId={setReviewerId}
              setReviewerError={setReviewerError}
              reviewerError={reviewerError}
              setQAIDError={setQAIDError}
              setQAID={setQAID}
              qa_idError={qa_idError}
              auditData={auditData}
              setLevel1Owner={setLevel1Owner}
              setLevel1OwnerError={setLevel1OwnerError}
              level_1_ownerError={level_1_ownerError}
              displayStatus={APIData?.status}
              objective={objective}
              setTitle={setTitle}
              rating={rating}
              setObservationRating={setObservationRating}
              short_description={short_description}
              shortDescriptionError={shortDescriptionError}
              businessOnlyRole={businessOnlyRole}
              setWorkPIdError={setWorkPIdError}
              workPIdError={workPIdError}
              workPId={workPId}
              workPojcts={workPojcts}
              setTestIds={setTestIds}
              testIds={testIds}
              setShortDescription={setShortDescription}
              setShortDescriptionError={setShortDescriptionError}
              item={item}
              setItem={setItem}
              summary={summary}
              setSummary={setSummary}
              handleAdd={handleAdd}
              blue_add_button={blue_add_button}
              inputList={inputList}
              handleChange={handleChange}
              handleSave={handleSave}
              subObservationsdata={subObservationsdata}
              savesubObv={savesubObv}
              handleCancel={handleCancel}
              remove_button={remove_button}
              handleRemove={handleRemove}
              handlepopup={handlepopup}
              fileData={fileData}
              cutbutton={cutbutton}
              handleDeleteModalOpen={handleDeleteModalOpen}
              OptionDropDown2={OptionDropDown2}
              exposure_to_losses={exposure_to_losses}
              setExposureLosses={setExposureLosses}
              potential_impact={potential_impact}
              setPotentialImpact={setPotentialImpact}
              likelyhood={likelyhood}
              setLikelihood={setLikelihood}
              risk1={risk1}
              setRisk01={setRisk01}
              risk2={risk2}
              setRisk02={setRisk02}
              risk3={risk3}
              setRisk03={setRisk03}
              recommendation={recommendation}
              setRecommendation={setRecommendation}
              action_plan={action_plan}
              setActionPlan={setActionPlan}
              action_planError={action_planError}
              setLevel2Owner={setLevel2Owner}
              convertDate={convertDate}
              management_response_due_date={management_response_due_date}
              setActionDueDate={setActionDueDate}
              management_response_due_dateError={
                management_response_due_dateError
              }
              response_audit={response_audit}
              setAuditResponse={setAuditResponse}
              response_audit_due_date={response_audit_due_date}
              setResponseActionDueDate={setResponseActionDueDate}
              preliminary_close_observation={preliminary_close_observation}
              setPreliminaryCloseObservation={setPreliminaryCloseObservation}
              preliminary_close_observationError={
                preliminary_close_observationError
              }
              taskId={APIData?.task_initial_qa_approval?.id}
              commentData={taskData}
              setComment={setTaskData}
              setLevel2OwnerError={setLevel2OwnerError}
              successMessageFileUpload={successMessageFileUpload}
              successMessageFileDelete={successMessageFileDelete}
              successMessagedeleteobs={successMessagedeleteobs}
              successMessageUpdate={successMessageUpdate}
              successMessageSubmit={successMessageSubmit}
              successMessageobs={successMessageobs}
              successMessageAccept={successMessageAccept}
              successMessageReject={successMessageReject}
              successMessageRequest={successMessageRequest}
              successMessageClosed={successMessageClosed}
              isDeleteModalOpen={isDeleteModalOpen}
              confirmDeleteFile={confirmDeleteFile}
              handleDeleteModalClose={handleDeleteModalClose}
              isModalOpen={isModalOpen}
              handleCloseClick={handleCloseClick}
              createBtn={createBtn}
              setSelectedFile={setSelectedFile}
              fileObjective={fileObjective}
              setFileObjective={setFileObjective}
              selectedFile={selectedFile}
              handleFileUpload={handleFileUpload}
            />

            <div className="close_delete_btn">
              <button
                disabled={isReviewerMismatch}
                onClick={closeObservation}
                style={{
                  backgroundColor: isReviewerMismatch ? "#7A7A7A" : "#5771DF",
                }}
                className="cmn_btn"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2334_5872)">
                    <path
                      d="M7.99967 14.6654C11.6663 14.6654 14.6663 11.6654 14.6663 7.9987C14.6663 4.33203 11.6663 1.33203 7.99967 1.33203C4.33301 1.33203 1.33301 4.33203 1.33301 7.9987C1.33301 11.6654 4.33301 14.6654 7.99967 14.6654Z"
                      stroke="#FBFBFB"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328"
                      stroke="#FBFBFB"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>

                  <defs>
                    <clipPath id="clip0_2334_5872">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Close Observation
              </button>

              <WindowComponent
                disabled={isReviewerMismatch}
                onDelete={onDelete}
                text="Delete Observation"
              />
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default QAInitaiApproval;
