import React, { useState, useRef, useEffect } from "react";
import add_button from "../../images/add_button.svg";
import deleteQuestion from "../../images/delete_wbg.svg";
import saveQuestion from "../../images/confirm.svg";
import {
  fetchDataV2,
  postDataWithFetchV2,
  deleteDataV2,
  updateDataNavigate,
  updatewithFetchDataV2,
  deleteDataWithFetchV2,
} from "../apiUtils";
import { useNavigate, useLocation, useParams } from "react-router-dom";

function YourComponent({ tableSize }) {
  const location = useLocation();
  const [inputs, setInputs] = useState({});
  const [trackId, setTrackId] = useState([]);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const [showButton, setShowButton] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [editingInputId, setEditingInputId] = useState(null);

  const inputRefs = useRef([]);

  const [sectionsData, setSectionsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [users, setUsers] = useState([]);
  const [sectionErrors, setSectionErrors] = useState("");
  const [questionErrors, setQuestionErrors] = useState("");
  const [initialQuestions, setInitialQuestions] = useState([]);
  const [inputTypes, setInputTypes] = useState({});
  const [successMessageQuestion, setSuccessMessage] = useState(false);
  const [successMessageQuestionDelete, setSuccessMessageDelete] =
    useState(false);
  const [successMessageQuestionUpdate, setSuccessMessageUopdate] =
    useState(false);
  const { id } = useParams();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    setSectionsData([
      {
        id: 1,
        section_name: "PLANNING THE AUDIT",
      },
      {
        id: 2,
        section_name: "CONDUCTING THE AUDIT",
      },
      {
        id: 3,
        section_name: "REPORTING AUDIT RESULTS",
      },
      {
        id: 4,
        section_name: "QUALITY OF INTERNAL AUDIT",
      },
      {
        id: 5,
        section_name: "OTHER SERVICES PROVIDED BY INTERNAL AUDIT",
      },
      {
        id: 6,
        section_name: "Open-ended Questions",
      },
    ]);

    const parameter = {
      url: `/api/audit_engagement/qa-survey/template/`,
      setterFunction: setQuestionsData,
      setErrors: setQuestionErrors,
    };
    fetchDataV2(parameter);
  }, [location.state?.data]);

  useEffect(() => {
    const initialQuestions1 = sectionsData.map((section) => ({
      section: section.section_name,
      id: section.id,
      questions: questionsData
        .filter((question) => question.section_id === section.id)
        .map((question) => ({
          id: question.id,
          text: question.question,
        })),
    }));
    setInitialQuestions(initialQuestions1);
  }, [sectionsData, questionsData]);

  const addInput = (sectionId, inputId) => {
    const newId = Date.now();
    setInputs({
      ...inputs,
      [sectionId]: [
        ...(inputs[sectionId] || []),
        { id: newId, editing: true, value: "" },
      ],
    });
    setEditingInputId(newId);
  };

  const handleTypeChange = (sectionId, inputId, value) => {
    setInputTypes({
      ...inputTypes,
      [sectionId]: {
        ...inputTypes[sectionId],
        [inputId]: value,
      },
    });
  };

  const removeInput = (sectionId, inputId) => {
    // Update the inputs state, removing the input with the given inputId
    setInputs((prevInputs) => ({
      ...prevInputs,
      [sectionId]: prevInputs[sectionId].filter(
        (input) => input.id !== inputId
      ),
    }));

    // Update the inputRefs, removing the reference for the given inputId
    inputRefs.current = inputRefs.current.filter(
      (_, index) => index !== inputId // Assuming inputId refers to the index in inputRefs
    );
  };

  const removeQuestion = (inputId, sectionId) => {
    const parameter = {
      url: `/api/audit_engagement/qa-survey/template/${inputId}/`,
      navigate: navigate,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/qa-survey/template/`,
            dataset: setQuestionsData,
          },
        ],
        setSuccessMessage: setSuccessMessageDelete,
        setErrors: setErrors,
      },
    };

    deleteDataWithFetchV2(parameter);
  };

  const handleInputChange = (sectionId, inputId, value) => {
    setInputValues({
      ...inputValues,
      [sectionId]: {
        ...inputValues[sectionId],
        [inputId]: value,
      },
    });
  };

  const handleEdit = (inputId) => {
    setEditingInputId(inputId);
  };

  const handshowHideButton = (inputId, lastQuestionIndex) => {
    // Check if the input ID is present in the trackId array
    if (trackId.includes(lastQuestionIndex)) {
      // Remove the input ID from the trackId array
      const updatedTrackId = trackId.filter((id) => id !== lastQuestionIndex);
      setTrackId(updatedTrackId, () => {
        // Callback function to ensure the button is shown after updating trackId
        setShowButton(true);
      });
    } else {
      console.log("ID does not exist in trackId array");

      // Hide the button
      setShowButton(false);
    }
  };

  const handleSave = (sectionId, inputId) => {
    setShowButton(false);

    const sectionData = sectionsData.find(
      (section) => section.id === sectionId
    );

    const parameter = {
      url: `/api/audit_engagement/qa-survey/template/`,
      payload: {
        question: inputValues[sectionId]?.[inputId],
        section_id: sectionId,
        section: sectionData ? sectionData.section_name : "",
        answer: inputTypes[sectionId]?.[inputId] || "text",
      },
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/qa-survey/template/`,
            dataset: setQuestionsData,
          },
        ],
        setSuccessMessage: setSuccessMessage,
        setErrors: setErrors,
      },
    };

    postDataWithFetchV2(parameter);

    setInputs("");
    setInputValues("");

    // window.location.reload();
  };

  const saveInputValue = (sectionId, inputId) => {
    if (!trackId.includes(inputId)) {
      setTrackId([...trackId, inputId]);
    }
    if (inputValues[sectionId]?.[inputId] !== undefined) {
      handleSave(sectionId, inputId);
    }
    setShowButton(true);
  };

  const updateInputValue = (sectionId, inputId) => {
    const selectedAnswer =
      inputTypes[sectionId]?.[inputId] ||
      questionsData.find((q) => q.id === inputId)?.answer;

    const parameter = {
      url: `/api/audit_engagement/qa-survey/template/${inputId}`,
      payload: {
        question: inputValues[sectionId]?.[inputId],
        answer: selectedAnswer,
      },
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/qa-survey/template/`,
            dataset: setQuestionsData,
          },
        ],
        setSuccessMessage: setSuccessMessageUopdate,
        setErrors: setErrors,
      },
    };

    updatewithFetchDataV2(parameter);

    setEditingInputId("");
  };

  return (
    <>
      {successMessageQuestion && (
        <p className="successmessage">Question Added Successfully</p>
      )}
      {successMessageQuestionDelete && (
        <p className="successmessage">Question Deleted Successfully</p>
      )}
      {successMessageQuestionUpdate && (
        <p className="successmessage">Question Updated Successfully</p>
      )}
      {initialQuestions.map((section) => (
        <div
          className="section"
          key={section.id}
          style={{ width: tableSize ? "72vw" : "85vw" }}
        >
          <div
            className="section_top"
            style={{ width: tableSize ? "63vw" : "75vw" }}
          >
            <h5>{section.section}</h5>
          </div>
          <div className="questions_con">
            {questionsData
              .filter((question) => question.section === section.section)
              .map((question) => (
                <div
                  key={question.id}
                  className="question"
                  style={{ alignItems: "center" }}
                >
                  <input
                    type="text"
                    placeholder="Question"
                    style={{
                      border:
                        editingInputId === question.id
                          ? "2px solid #BECBF9"
                          : "none",
                      width: "70%",
                      height: "40px",
                      fontSize: "12px",
                      padding: "12px 12px",
                      backgroundColor: "#eef1fc",
                      borderRadius: "4px",
                      border: "2px solid #cfcfcf",
                      fontFamily: "intersemibold",
                    }}
                    value={
                      editingInputId === question.id
                        ? inputValues[section.id]?.[question.id] !== undefined
                          ? inputValues[section.id][question.id]
                          : question.question
                        : inputValues[section.id]?.[question.id] !== undefined
                        ? inputValues[section.id][question.id]
                        : question.question
                    }
                    readOnly={editingInputId !== question.id}
                    onClick={() => handleEdit(question.id)}
                    onChange={(e) =>
                      handleInputChange(section.id, question.id, e.target.value)
                    }
                  />

                  {console.log(question.question)}

                  {editingInputId === question.id && (
                    <>
                      <select
                        className="custom-select"
                        style={{
                          width: "150px",
                          height: "39px",
                          fontSize: "12px",
                          border: "none",
                          padding: "10px",
                          backgroundColor: " #eef1fc",
                          borderRadius: "4px",
                          border: "2px solid #cfcfcf",
                          color: "#000",
                        }}
                        value={
                          inputTypes[section.id]?.[question.id] ||
                          question.answer
                        }
                        onChange={(e) =>
                          handleTypeChange(
                            section.id,
                            question.id,
                            e.target.value
                          )
                        }
                      >
                        <option value=" ">--Select--</option>
                        <option value="checkbox">Checkbox</option>
                        <option value="text">Text</option>
                        <option value="boolean">Boolean</option>
                      </select>
                      <div className="buttonCQ">
                        <button
                          onClick={() =>
                            updateInputValue(section.id, question.id)
                          }
                        >
                          <img src={saveQuestion} className="saveCQ" />
                        </button>
                        <button
                          onClick={() => {
                            removeQuestion(question.id);
                          }}
                        >
                          <img src={deleteQuestion} className="deleteCQ" />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))}
            {inputs[section.id]?.map((input, index) => (
              <div key={input.id} className="questionCQ">
                {section.id === 6 ? (
                  <textarea
                    style={{
                      width: tableSize ? "51.7vw" : "62.5vw",
                      backgroundColor: "#eef1fc",
                      height: "10vh",
                      borderRadius: "5px",
                    }}
                    value={inputValues[section.id]?.[input.id] || ""}
                    onChange={(e) => {
                      handleInputChange(section.id, input.id, e.target.value);
                      handshowHideButton(section.id, input.id);
                    }}
                    onClick={() => handshowHideButton(section.id, input.id)}
                    onFocus={() => handshowHideButton(section.id, input.id)}
                    autoFocus
                  />
                ) : (
                  <input
                    style={{
                      width: "70%",
                      height: "40px",
                      fontSize: "12px",
                      padding: "12px 12px",
                      backgroundColor: "#eef1fc",
                      borderRadius: "4px",
                      border: "2px solid #cfcfcf",
                      fontFamily: "intersemibold",
                    }}
                    type="text"
                    value={inputValues[section.id]?.[input.id] || ""}
                    onChange={(e) => {
                      handleInputChange(section.id, input.id, e.target.value);
                      handshowHideButton(section.id, input.id);
                    }}
                    onClick={() => handshowHideButton(section.id, input.id)}
                    onFocus={() => handshowHideButton(section.id, input.id)}
                    autoFocus
                  />
                )}
                <div
                  className="questionCQ"
                  style={{ alignItems: "center", alignSelf: "center" }}
                >
                  <select
                    className="custom-select"
                    style={{
                      width: "150px",
                      height: "39px",
                      fontSize: "12px",
                      border: "none",
                      padding: "10px",
                      backgroundColor: " #eef1fc",
                      borderRadius: "4px",
                      border: "2px solid #cfcfcf",
                      color: "#000",
                    }}
                    onChange={(e) =>
                      handleTypeChange(section.id, input.id, e.target.value)
                    }
                  >
                    <option value=" ">--Select--</option>
                    <option value="checkbox">Checkbox</option>
                    <option value="text">Text</option>
                    <option value="boolean">Boolean</option>
                  </select>
                </div>

                {!showButton && !trackId.includes(input.id) && (
                  <>
                    <div className="buttonCQ">
                      {inputValues[section.id]?.[input.id] && (
                        <button
                          onClick={() => saveInputValue(section.id, input.id)}
                        >
                          <img src={saveQuestion} className="saveCQ" />
                        </button>
                      )}
                      <button onClick={() => removeInput(section.id, input.id)}>
                        <img src={deleteQuestion} className="deleteCQ" />
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <div
            className="add_question_con"
            style={{ width: tableSize ? "63vw" : "75vw" }}
          >
            <button onClick={() => addInput(section.id, inputs.id)}>
              <img src={add_button} alt="Add" /> Add Question
            </button>
          </div>
        </div>
      ))}
    </>
  );
}

export default YourComponent;
