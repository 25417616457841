import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import sendComment from "../../../../images/send.svg";
import "../../../../css/viewuser.css";
import cutbutton from "../.../../../../../images/cut.svg";
import {
  postDataWithFile,
  fetchDataV2,
  fetchDataObjectV2,
  updateDataV2,
  justPostDataV2,
  deleteDataV2,
  deleteDataWithFetchV2,
  postDataWithFetchV2,

} from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import "../createObservation/createobservation.css";
import SuperAvatar from "../../../SuperAvatar";
import savesubObv from "../../../../images/saveBtn.svg";
import remove_button from "../../../../images/remove_button.svg";
import blue_add_button from "../../../../images/blue_plus_button.svg";
import OptionDropDown2 from "../../../OptionDropDown2";
import createBtn from "../../../../images/createbutton.svg";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";
import ObservationForm from "../Observations/ObservationForm";

const GenerateReport = ({ tableSize }) => {
  const lastName = localStorage.getItem("userLastName");
  const avaUserName = localStorage.getItem("userName");
  const [APIQData, setAPIQData] = useState("");
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [summary, setSummary] = useState("");
  const [item, setItem] = useState({});
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [assign_to_business_teammate_id, setTeammate_id] = useState("");
  const [teammate_idError, setTeammate_idError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [testIds, setTestIds] = useState([]);
  const [workPId, setWorkPId] = useState([]);
  const [workPojcts, setWorkPojcts] = useState([]);
  const [workPIdError, setWorkPIdError] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [editModeBusiness, setEditModeBusiness] = useState(true);
  const [taskData, setTaskData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);
  const successMessage = location.state?.successMessage;
  const { id, reportId, obejctionId } = useParams();
  const [subObservations, setSubObservations] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [objective, setTitle] = useState("");
  const [rating, setObservationRating] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [recommendation, setRecommendation] = useState("");
  const [exposure_to_losses, setExposureLosses] = useState("");
  const [potential_impact, setPotentialImpact] = useState("");
  const [likelyhood, setLikelihood] = useState("");
  const [risk1, setRisk01] = useState("");
  const [risk2, setRisk02] = useState("");
  const [risk3, setRisk03] = useState("");
  const [action_plan, setActionPlan] = useState("");
  const [action_planError, setActionPlanError] = useState(false);
  const [fileObjective, setFileObjective] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [assign_to_id, setLevel1Owner] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState(null);
  const [secondary_assign_to_id, setLevel2Owner] = useState(null);
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [management_response_due_date, setActionDueDate] = useState("");
  const [management_response_due_dateError, setActionDueDateError] =
    useState("");
  const [response_audit, setAuditResponse] = useState("");
  const [response_audit_due_date, setResponseActionDueDate] = useState("");
  const [preliminary_close_observation, setPreliminaryCloseObservation] =
    useState("");
  const [
    preliminary_close_observationError,
    setPreliminaryCloseObservationError,
  ] = useState(false);
  const [short_description, setShortDescription] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [inputList, setInputList] = useState([]);
  const [qa_id, setQAID] = useState("");
  const [qa_idError, setQAIDError] = useState("");
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const [showbtns, setShowbtns] = useState();
  const [showComments, setShowComments] = useState(false);
  const businessRole = Object.keys(userRoles).some((key) =>
    key.includes("Business")
  );
  const businessOnlyRole = userRoles === "Business";
  const commentRef = useRef(null);
  const [observationUrl, setObservationUrl] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [commetIdTask, setCommentIdTask] = useState();
  const [APIaudit, setAudit] = useState([]);
  const [successMessageFileUpload, setSuccessMessage] = useState(false);
  const [successMessageFileDelete, setSuccessMessageDelete] = useState(false);
    const [successMessageobs, setSuccessMessageSubObs] = useState(false);
    const [successMessagedeleteobs, setSuccessMessageDeleteSubObs] = useState(false);
    const [subObservationsdata, setSubObservationsData] = useState([]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileUpload]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessageDelete(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileDelete]);

  useEffect(() => {
    const fetchAuditData = () => {
      const parameter = {
        url: `/api/audit_engagement/audit/${id}/`,
        setterFunction: setAudit,
        setErrors: setErrors,
        setLoading: setLoading,
      };
      fetchDataObjectV2(parameter);

      const parameter1 = {
        url: `/api/audit_engagement/observation/${obejctionId}/`,
        setterFunction: setAPIData,
        setErrors: setErrors,
        setLoading: setLoading,
      };
      fetchDataObjectV2(parameter1);

      const parameter2 = {
        url: `/api/audit_engagement/observationfile/?observation_id=${obejctionId}`,
        setterFunction: setFileData,
        setErrors: setErrors,
      };
      fetchDataV2(parameter2);
    };

    fetchAuditData();
  }, [id, obejctionId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  
      useEffect(()=>{
        const parameter={
          url:`/api/audit_engagement/sub-observation/?observation_id=${obejctionId}`,
          setterFunction:setSubObservationsData,
          setErrors:setErrors,
        }
        fetchDataV2(parameter)
      },[])

  useEffect(() => {
    if (APIData) {
      const taskMap = {
        initialobservation: "task_initial_admin_approval",
        qaapprovalinitialobservation: "task_initial_qa_approval",
        businessownerapproval: "task_initial_business_approval",
        qaapprovalfinalizeobservation: "task_final_qa_approval",
        auditdirectorapproval: "task_final_admin_approval",
      };

      const taskKey = taskMap[observationUrl] || "task_initial_admin_approval";

      const taskData = APIData[taskKey];
      setCommentIdTask(taskData?.id);
      setShowbtns(taskData?.status === "done");

      if (taskData?.id) {
        const parameter = {
          url: `/api/system/comments/?task_id=${taskData.id}`,
          setterFunction: setTaskData,
          setErrors: setErrors,
        };
        fetchDataV2(parameter);
      }

      setTitle(APIData?.objective);
      setObservationRating(APIData?.rating);
      setShortDescription(APIData?.short_description);
      setExposureLosses(APIData?.exposure_to_losses);
      setPotentialImpact(APIData?.potential_impact);
      setLikelihood(APIData?.likelyhood);

      const risks = APIData?.risks || {};
      setRisk01(risks?.risk1);
      setRisk02(risks?.risk2);
      setRisk03(risks?.risk3);

      setAPIQData(taskData?.id);
      setStartdate(APIData.due_date);
      setActionPlan(APIData?.action_plan);
      setActionDueDate(APIData?.management_response_due_date);
      setSummary(APIData.summary);
      setRecommendation(APIData.recommendation);
      setAuditResponse(APIData?.response_audit);
      setResponseActionDueDate(APIData?.response_audit_due_date);
      setPreliminaryCloseObservation(APIData?.preliminary_close_observation);

      const workpapers = APIData.workpapertestcase || [];
      setTestIds(workpapers.map((item) => item.id));
      setWorkPId([...new Set(workpapers.map((item) => item.workpaper?.id))]);
      setWorkPojcts([
        ...new Set(workpapers.map((item) => item.workpaper?.objective)),
      ]);
      setSubObservations(APIData?.data?.subObservations || []);
      setTaskTitle(taskData?.title);

      setEditMode(taskData?.status !== "todo");
    }
  }, [APIData, observationUrl]);

  useEffect(() => {
    if (errors) {
      const timer = setTimeout(() => {
        setErrors("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errors]);

  useEffect(() => {
    const observationValue = window.location.href.split("/").slice(-2, -1)[0];
    setObservationUrl(observationValue);
  }, []);

  const handleSave = (index) => {
    const updatedList = [...inputList];
    updatedList[index].isEditing = false;
    const text = updatedList[index].value;

    const parameter={
      url:'/api/audit_engagement/sub-observation/',
      payload:{
        observation_id:obejctionId,
        management_response:text,
      },
      setErrors:setErrors,
      tofetch: {
          items: [
            {
              fetchurl: `/api/audit_engagement/sub-observation/?observation_id=${obejctionId}`,
              dataset: setSubObservationsData,
            },
          ],
          setSuccessMessage:setSuccessMessageSubObs,
          setErrors: setErrors,
        },
    

    }

    postDataWithFetchV2(parameter)
    setInputList(updatedList);
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleCancel = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleRemove = (index,subobsid) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);
    const parameter={
          url:`/api/audit_engagement/sub-observation/${subobsid}/`,
          setErrors:setErrors,
          tofetch: {
            items: [
              {
                fetchurl: `/api/audit_engagement/sub-observation/?observation_id=${obejctionId}`,
                dataset: setSubObservationsData,
              },
            ],
            setSuccessMessage: setSuccessMessageDeleteSubObs,
            setErrors: setErrors,
          },
        }
        deleteDataWithFetchV2(parameter)
    const updatedObservations = subObservations.filter((_, i) => i !== index);
    setSubObservations(updatedObservations);
  };

  const handleChange = (e, index) => {
    const updatedList = [...inputList];
    updatedList[index].value = e.target.value;
    setInputList(updatedList);
  };

  function convertDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    }
  }

  const handleAdd = () => {
    setInputList([...inputList, { value: "", isEditing: true }]);
  };

  const [auditData, setAuditData] = useState([]);

  const obvStatus = {
    observationUrl: observationUrl,
    setAPIData: setAPIData,
    APIData: APIData,
    status: APIData?.status,
    task_initial_admin_approval: APIData?.task_initial_admin_approval?.status,
    task_initial_qa_approval: APIData?.task_initial_qa_approval?.status,
    task_final_admin_approval: APIData?.task_final_admin_approval?.status,
    task_initial_business_approval:
      APIData?.task_initial_business_approval?.status,
    task_final_qa_approval: APIData?.task_final_qa_approval?.status,
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow
          name={editMode ? "Edit View Observation" : "View Observation"}
          parentName={APIaudit?.name}
          obvStatus={obvStatus}
        />

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="create_observation_con"
            style={{ width: tableSize ? "100%" : "100%" }}
          >
            <ObservationForm
            obejctionId={obejctionId}
              editMode={!editMode}
              observationUrl={observationUrl}
              APIData={APIData}
              editModeBusiness={editModeBusiness}
              setTeammate_id={setTeammate_id}
              assign_to_business_teammate_id={assign_to_business_teammate_id}
              setTeammate_idError={setTeammate_idError}
              teammate_idError={teammate_idError}
              businessRole={!businessRole}
              tableSize={tableSize}
              setPreparerId={setPreparerId}
              setPreparerError={setPreparerError}
              PreparerError={PreparerError}
              setReviewerId={setReviewerId}
              setReviewerError={setReviewerError}
              reviewerError={reviewerError}
              setQAIDError={reviewerError}
              setQAID={setQAID}
              qa_idError={qa_idError}
              auditData={auditData}
              setLevel1Owner={setLevel1Owner}
              setLevel1OwnerError={setLevel1OwnerError}
              level_1_ownerError={level_1_ownerError}
              displayStatus={APIData?.status}
              objective={objective}
              setTitle={setTitle}
              rating={rating}
              setObservationRating={setObservationRating}
              short_description={short_description}
              shortDescriptionError={shortDescriptionError}
              businessOnlyRole={businessOnlyRole}
              setWorkPIdError={setWorkPIdError}
              workPIdError={workPIdError}
              workPId={workPId}
              workPojcts={workPojcts}
              setTestIds={setTestIds}
              testIds={testIds}
              item={item}
              setItem={setItem}
              summary={summary}
              setSummary={setSummary}
              handleAdd={handleAdd}
              blue_add_button={blue_add_button}
              inputList={inputList}
              handleChange={handleChange}
              handleSave={handleSave}
              subObservationsdata={subObservationsdata}
              savesubObv={savesubObv}
              handleCancel={handleCancel}
              remove_button={remove_button}
              handleRemove={handleRemove}
              fileData={fileData}
              cutbutton={cutbutton}
              OptionDropDown2={OptionDropDown2}
              exposure_to_losses={exposure_to_losses}
              setExposureLosses={setExposureLosses}
              potential_impact={potential_impact}
              setPotentialImpact={setPotentialImpact}
              likelyhood={likelyhood}
              setLikelihood={setLikelihood}
              risk1={risk1}
              setRisk01={setRisk01}
              risk2={risk2}
              setRisk02={setRisk02}
              risk3={risk3}
              setRisk03={setRisk03}
              recommendation={recommendation}
              setRecommendation={setRecommendation}
              action_plan={action_plan}
              setActionPlan={setActionPlan}
              action_planError={action_planError}
              setLevel2Owner={setLevel2Owner}
              setLevel2OwnerError={setLevel2OwnerError}
              level_2_ownerError={level_2_ownerError}
              convertDate={convertDate}
              management_response_due_date={management_response_due_date}
              setActionDueDate={setActionDueDate}
              management_response_due_dateError={
                management_response_due_dateError
              }
              response_audit={response_audit}
              setAuditResponse={setAuditResponse}
              response_audit_due_date={response_audit_due_date}
              setResponseActionDueDate={setResponseActionDueDate}
              preliminary_close_observation={preliminary_close_observation}
              setPreliminaryCloseObservation={setPreliminaryCloseObservation}
              preliminary_close_observationError={
                preliminary_close_observationError
              }
              commentData={taskData}
              setComment={setTaskData}
            />

          </div>
        )}

      </section>
    </>
  );
};

export default GenerateReport;
