import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../../css/viewuser.css";
import {
  fetchDataV2,
  fetchDataObjectV2,
  convertDate,
  updatewithFetchDataV2,
  deleteDataWithFetchV2,
} from "../../../apiUtils";
import {
  auditExecution,
  auditExecutionInnerBU,
  auditExecutionInnerQA,
} from "../../../subroutes";
import openarrow from "../../../../images/openarrow.svg";
import EditRow from "../../../../images/edit_pencil.svg";
import SaveRow from "../../../../images/saveBtn.svg";
import closeBtn from "../../../../images/closeicon.svg";
import deleteBtn from "../../../../images/deleteBtn.svg";
import "../../auditplan/auditplan.css";
import "./qachecklist.css";
import SingleOptionSelector from "../../../SingleOptionSelector";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";

const QAChecklist = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [performed_by, setPerformedby] = useState([]);
  const [performedById, setPerformedById] = useState("");
  const [performedbyError, setPerformedbyError] = useState([]);
  const [performedByOptions, setPerformedByOptions] = useState([]);
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [scoreError, setScoreError] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [checklistTask, setChecklistTask] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [auditData, setAuditData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const [successMessageUpdate, setSuccessMessageUpdate] = useState(false);
  const { id } = useParams();
  const is_Admin = localStorage.getItem("is_Admin");
  const userRolesCheck = JSON.parse(localStorage.getItem("userRoles"));
  const [successMessageDelete, setSuccessMessageDelete] = useState(false);

  var routes = [];
  if (
    is_Admin ||
    Object.keys(userRolesCheck).some((key) => key.includes("Audit Team"))
  ) {
    routes = auditExecution;
  } else if (
    Object.keys(userRolesCheck).some((key) => key.includes("Business"))
  ) {
    routes = auditExecutionInnerBU;
  } else if (
    Object.keys(userRolesCheck).some((key) => key.includes("Quality Assurance"))
  ) {
    console.log("i am here t");
    routes = auditExecutionInnerQA;
  } else {
    console.log("No matching role found");
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchDataV2({
      url: `/api/audit_universe/quality-assurance/`,
      setterFunction: setPerformedByOptions,
      setErrors: (error) => {
        setPerformedbyError(true);
        console.error("Error fetching performed by options:", error);
      },
    });
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAuditData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/qa-checklist/tasks/?audit_id=${id}`,
      setterFunction: setChecklistTask,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  function convertDate2(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];

      return formattedDate;
    }
  }

  const handleInputChange = (e, key) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      [key]: e.target.value,
    }));
    console.log("Updated editValues:", key, e.target.value);
  };

  const handleSaveClick = (taskid) => {
    if (
      editValues.start_date &&
      editValues.end_date &&
      new Date(editValues.end_date) < new Date(editValues.start_date)
    ) {
      setErrors("End Date Should Be Greater Then Or Equal To Start Date");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const parameter = {
      url: `/api/audit_engagement/qa-checklist/tasks/${taskid}`,
      payload: {
        audit_id: parseInt(id),
        phase: editValues.phase,
        step: editValues.step,
        performed_by_id: editValues?.performed_by,
        start_date: editValues.start_date,
        end_date: editValues.end_date,
        outcome: editValues.outcome,
        challenges: editValues.challenges,
        comment: editValues.comment,
        weight: parseInt(editValues.weight),
        score: parseInt(editValues.score),
      },
      setErrors: setErrors,

      tofetch: {
        setSuccessMessage: setSuccessMessageUpdate,
        items: [
          {
            fetchurl: `/api/audit_engagement/qa-checklist/tasks/?audit_id=${id}`,
            dataset: setChecklistTask,
          },
        ],
        setErrors: setErrors,
      },
    };

    updatewithFetchDataV2(parameter);
    setEditingIndex(null);
    setEditValues({});
  };

  const handleCancelClick = () => {
    setEditingIndex(null);
    setEditValues({});
  };

  const handleDeleteClick = (taskid) => {
    const parameter = {
      url: `/api/audit_engagement/qa-checklist/tasks/${taskid}/`,
      navigate: navigate,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/qa-checklist/tasks/?audit_id=${id}`,
            dataset: setChecklistTask,
          },
        ],
        setSuccessMessage: setSuccessMessageDelete,
        setErrors: setErrors,
      },
    };

    deleteDataWithFetchV2(parameter);
    setEditingIndex(null);
    setEditValues({});
    setShowWindow(false);
  };

  const handleEditClick = (question, index) => {
    setEditingIndex(index);
    setEditValues({
      ...question,
      performed_by: question.performed_by?.id || "", // Set performed_by as id or default to an empty string
    });
    console.log("Editing question:", question);
  };

  const handleSelectChange = (key, value) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const filteredData = APIData.filter((data) => {
    const fullName = data.name.toLowerCase();
    const NameMatch = filters.name
      ? fullName.includes(filters.name.toLowerCase())
      : true;
    const keyStakeholderName = "";
    const KeyStakeholder = filters.key_stakeholder
      ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase())
      : true;

    const businessUnitMatch = filters.business_unit
      ? data.business_units.some((unit) => {
          const unitNameLower = unit.name.toLowerCase();
          const filterUnitLower = filters.business_unit.toLowerCase();
          return unitNameLower.includes(filterUnitLower);
        })
      : true;

    return NameMatch && KeyStakeholder && businessUnitMatch;
  });

  const phaseOrder = ["Planning", "Fieldwork", "Reporting", "Follow Up"];

  const sortedData = Array.isArray(checklistTask)
    ? [...checklistTask].sort((a, b) => {
        return phaseOrder.indexOf(a.phase) - phaseOrder.indexOf(b.phase);
      })
    : [];

  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);

  const handlePerformedByChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10); // Ensure it's an integer
    setPerformedby(selectedValue);
    setEditValues((prevValues) => ({
      ...prevValues,
      performed_by: selectedValue,
    }));
  };

  console.log("Performed By:", editValues.performed_by);

  const [showWindow, setShowWindow] = useState(false);
  const toggleWindow = () => {
    setShowWindow(!showWindow);
  };
  const handleClose = () => {
    setShowWindow(false);
  };

  const firstInputRef = useRef(null);
  const lastInputRef = useRef(null);

  useEffect(() => {
    const handleTabKey = (event) => {
      if (
        event.key === "Tab" &&
        document.activeElement === lastInputRef.current
      ) {
        event.preventDefault();
        firstInputRef.current.focus();
      }
    };
    window.addEventListener("keydown", handleTabKey);

    return () => {
      window.removeEventListener("keydown", handleTabKey);
    };
  }, []);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      {successMessageUpdate && (
        <p className="successmessage">Updated Successfully</p>
      )}

      {successMessageDelete && (
        <p className="successmessage">Deleted Successfully</p>
      )}

      <section className="navroutes"
      style={{ width: "98.5%" }}>
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow
          name={"Wrapup Q.A"}
          trueback={true}
          parentName={APIaudit?.name}
        />

        <div
          className="qachecklist_table"
          style={{ width: "100%" }}
        >
          <table>
            <thead
              style={{
                height: "10vh",
                position: "sticky",
                top: "0",
                zIndex: 0,
              }}
            >
              <tr>
                <th>Action</th>
                <th>Sr No.</th>
                <th>Phase</th>
                <th>Steps taken</th>
                <th>Performed by</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Outcome</th>
                <th>Challenges</th>
                <th>QA Comments</th>
                <th>Weight</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((data, index) => (
                <tr
                  key={data.id}
                  onDoubleClick={() => handleEditClick(data, index)}
                >
                  {editingIndex === index ? (
                    <>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "1vw",
                          }}
                        >
                          <button
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={(e) => handleSaveClick(data.id)}
                            onKeyDown={(e) => handleSaveClick(data.id)}
                            tabIndex={1}
                            ref={firstInputRef}
                          >
                            <img src={SaveRow} style={{ width: "24px" }} />
                          </button>
                          {console.log(data.id)}
                          <button
                            style={{
                              border: "none",
                              background: "transparent",
                            }}
                            onClick={handleCancelClick}
                            onKeyDown={handleCancelClick}
                            tabIndex={2}
                          >
                            <img src={closeBtn} style={{ width: "24px" }} />
                          </button>
                        </div>
                      </td>
                      <td>{index + 1}</td>
                      <td>
                        <select
                          value={editValues?.phase || ""}
                          onChange={(e) =>
                            handleSelectChange("phase", e.target.value)
                          }
                          tabIndex={3}
                        >
                          <option value="">--Select a phase--</option>
                          <option value="Planning">Planning</option>
                          <option value="Fieldwork">Fieldwork</option>
                          <option value="Reporting">Reporting</option>
                          <option value="Follow Up">Follow Up</option>
                        </select>
                      </td>

                      <td>
                        <textarea
                          type="text"
                          value={editValues?.step}
                          onChange={(e) => handleInputChange(e, "step")}
                          tabIndex={4}
                        />
                      </td>

                      <td>
                        <select
                          value={editValues?.performed_by || ""}
                          onChange={handlePerformedByChange}
                          tabIndex={5}
                        >
                          <option value="">--Select an option--</option>
                          {performedByOptions?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </td>

                      <td>
                        <input
                          type="date"
                          value={convertDate2(editValues?.start_date)}
                          onChange={(e) => handleInputChange(e, "start_date")}
                          tabIndex={6}
                        />
                        {console.log(start_date)}
                      </td>

                      <td>
                        <input
                          type="date"
                          value={convertDate2(editValues?.end_date)}
                          onChange={(e) => handleInputChange(e, "end_date")}
                          tabIndex={7}
                        />
                        {console.log(end_date)}
                      </td>

                      <td>
                        <textarea
                          type="text"
                          value={editValues?.outcome}
                          onChange={(e) => handleInputChange(e, "outcome")}
                          tabIndex={8}
                        />
                      </td>
                      <td>
                        <textarea
                          type="text"
                          value={editValues?.challenges}
                          onChange={(e) => handleInputChange(e, "challenges")}
                          tabIndex={9}
                        />
                      </td>
                      <td>
                        <textarea
                          type="text"
                          value={editValues?.comment}
                          onChange={(e) => handleInputChange(e, "comment")}
                          tabIndex={10}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={editValues?.weight}
                          min={0}
                          max={10}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 && value <= 10) {
                              handleInputChange(e, "weight");
                            }
                          }}
                          tabIndex={11}
                        />
                      </td>

                      <td>
                        <input
                          type="number"
                          value={editValues?.score}
                          style={{
                            border: scoreError ? "1px solid #FF4646" : "",
                          }}
                          min={0}
                          max={10}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 && value <= 10) {
                              handleInputChange(e, "score");
                              setScoreError(false);
                            }
                          }}
                          tabIndex={12}
                          ref={lastInputRef}
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "1vw",
                          }}
                        >
                          <button
                            style={{
                              background: "transparent",
                              border: "none",
                            }}
                            onClick={() => handleEditClick(data, index)}
                          >
                            <img src={EditRow} style={{ width: "24px" }} />
                          </button>
                          {data.id && (
                            <button
                              style={{
                                background: "transparent",
                                border: "none",
                              }}
                              onClick={toggleWindow}
                            >
                              <img src={deleteBtn} style={{ width: "24px" }} />
                            </button>
                          )}
                        </div>
                      </td>
                      <td>{index + 1}</td>
                      <td>
                        <p style={{ maxWidth: "200px" }}>{data?.phase}</p>
                      </td>
                      <td>
                        <p
                          style={{
                            width: "600px",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                          }}
                        >
                          {data?.step}
                        </p>
                      </td>
                      <td>
                        <p style={{ maxWidth: "200px" }}>
                          {data?.performed_by?.user?.name}
                        </p>
                      </td>
                      <td>
                        <p style={{ maxWidth: "200px" }}>
                          {convertDate(data?.start_date)}
                        </p>
                      </td>
                      <td>
                        <p style={{ maxWidth: "200px" }}>
                          {convertDate(data?.end_date)}
                        </p>
                      </td>
                      <td>
                        <p style={{ maxWidth: "200px" }}>{data?.outcome}</p>
                      </td>
                      <td>
                        <p
                          style={{
                            width: "600px",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                          }}
                        >
                          {data?.challenges}
                        </p>
                      </td>
                      <td>
                        <p
                          style={{
                            width: "600px",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                          }}
                        >
                          {data?.comment}
                        </p>
                      </td>

                      <td>
                        <p style={{ maxWidth: "200px" }}>{data?.weight}</p>
                      </td>
                      <td>
                        <p style={{ maxWidth: "200px" }}>{data?.score}</p>
                      </td>
                    </>
                  )}

                  {showWindow && (
                    <div className="window">
                      Are you sure you want to delete?
                      <div className="btn-option">
                        <div
                          className="cmn_btn"
                          style={{ backgroundColor: "#ff4646" }}
                          onClick={() => handleDeleteClick(data.id)}
                        >
                          Delete
                        </div>

                        <div
                          className="cmn_btn"
                          style={{ backgroundColor: "#ff4646" }}
                          onClick={handleClose}
                        >
                          Close
                        </div>
                      </div>
                    </div>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="cardscontainer">
          {filteredData.map((data) => (
            <div className="aecard">
              <h1>{data.name} </h1>

              <img src={openarrow} />
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default QAChecklist;
