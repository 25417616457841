import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import create from "../../../../images/createbutton.svg";
import "../../../../css/viewuser.css"
import openarrow from '../../../../images/openarrow.svg'
import "../graphViewRa/graphviewra.css"
import { auditRoutes } from "../../../subroutes";
import Barchart from "./Barchart";
import Heatmap from "./HeatGraph";
import { fetchDataV2 } from "../../../apiUtils";
import BarchartSection from "./BarchartSection";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";


const GraphViewRa = ({ tableSize }) => {

  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const { raid } = useParams();
  console.log(raid)

  const originalUrl = location.state?.from;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate('.', { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const [APIAllData, setAPIAllData] = useState([]);
  const [Error, setErrors] = useState('');


  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/risk-assessments/${raid}/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);

  }, [raid]);



  const Viewuser = (data) => {
    const {
      id, Name, SelectedKeys, SelectedKeysOther, Description, BusinessUnit,
    } = data;

    const localStorageData = {
      ID: id, Name, SelectedKeys, SelectedKeysOther, Description, BusinessUnit,

    };

    Object.entries(localStorageData).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });

    navigate("/uditUniverse/auditEntities/ViewAuditEntity");
  };



  const renderIcons = () => {
    return auditRoutes.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
        <NavLink
          to={route.path}
          key={index}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">{<img src={route.icon} alt="Dashboard Icon" />}</div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };




  const navigateList = () => {
    navigate(`/auditUniverse/riskAssesment/${raid}`);
  }



  // const jsonData = {
  //   id: 5,
  //   status: "In progress",
  //   assessment_date: "2024-04-02",
  //   details: "nothing",
  //   approved: true,
  //   approved_date: "2024-04-03",
  //   approved_by: 1,
  //   risk_analyses: [
  //     {
  //       financial_statement: 1.37,
  //       legal_compliance: 1.03,
  //       operational: 1.4,
  //       iT: 1.7,
  //       total_impact: 1.07,
  //       complexity_process: 1.07,
  //       volume: 1.26,
  //       Known_issues: 3.0,
  //       changes_personnel_processes: 3.0,
  //       monitoring: 1.74,
  //       total_likelihood: 2.05,
  //       fraud_risk: 2.67,
  //       management_initiative: 1.0,
  //       risk_score: 6.5,
  //       risk_level: "High",
  //       total_inherent_score: "20",
  //       audit_entity: "Human Resources Unit",
  //       risk_assessment: 5,
  //       status: "In progress",
  //       BUs: [
  //         {
  //           financial_statement: 1.33,
  //           legal_compliance: 1.0,
  //           operational: 1.52,
  //           iT: 1.7,
  //           total_impact: 1.39,
  //           complexity_process: 1.0,
  //           volume: 1.0,
  //           Known_issues: 3.0,
  //           changes_personnel_processes: 3.0,
  //           monitoring: 1.78,
  //           total_likelihood: 1.98,
  //           fraud_risk: 2.67,
  //           management_initiative: 1.0,
  //           risk_score: 6.41,
  //           risk_level: "High",
  //           risk_score: 1.0,
  //           total_inherent_score: "20",
  //           BU: "Termination",
  //           risk_assessment: 5,
  //           status: "In progress",
  //         },
  //         {
  //           financial_statement: 1.33,
  //           legal_compliance: 1.0,
  //           operational: 1.42,
  //           iT: 1.7,
  //           total_impact: 1.35,
  //           complexity_process: 1.0,
  //           volume: 1.0,
  //           Known_issues: 3.0,
  //           changes_personnel_processes: 3.0,
  //           monitoring: 1.7,
  //           total_likelihood: 1.97,
  //           fraud_risk: 2.67,
  //           management_initiative: 1.0,
  //           risk_score: 6.33,
  //           risk_level: "High",
  //           risk_score: 1.0,
  //           total_inherent_score: "20",
  //           BU: "Employee Leave",
  //           risk_assessment: 5,
  //           status: "In progress",
  //         },
  //       ],
  //     },
  //     {
  //       financial_statement: 1.84,
  //       legal_compliance: 1.6,
  //       operational: 1.53,
  //       iT: 1.71,
  //       total_impact: 1.67,
  //       complexity_process: 3.0,
  //       volume: 2.33,
  //       Known_issues: 1.0,
  //       changes_personnel_processes: 1.0,
  //       monitoring: 1.67,
  //       total_likelihood: 1.8,
  //       fraud_risk: 2.75,
  //       management_initiative: 1.0,
  //       risk_score: 6.75,
  //       risk_level: "High",
  //       total_inherent_score: "20",
  //       audit_entity: "Administration Unit",
  //       risk_assessment: 5,
  //       status: "In progress",
  //       BUs: [
  //         {
  //           financial_statement: 1.84,
  //           legal_compliance: 1.6,
  //           operational: 1.53,
  //           iT: 1.71,
  //           total_impact: 1.67,
  //           complexity_process: 3.0,
  //           volume: 2.33,
  //           Known_issues: 1.0,
  //           changes_personnel_processes: 1.0,
  //           monitoring: 1.67,
  //           total_likelihood: 1.8,
  //           fraud_risk: 2.75,
  //           management_initiative: 1.0,
  //           risk_score: 6.75,
  //           risk_level: "High",
  //           total_inherent_score: "20",
  //           BU: "TELEPHONES",
  //           risk_assessment: 5,
  //           status: "In progress",
  //         },
  //         {
  //           financial_statement: 1.84,
  //           legal_compliance: 1.6,
  //           operational: 1.53,
  //           iT: 1.71,
  //           total_impact: 1.67,
  //           complexity_process: 3.0,
  //           volume: 2.33,
  //           Known_issues: 1.0,
  //           changes_personnel_processes: 1.0,
  //           monitoring: 1.67,
  //           total_likelihood: 1.8,
  //           fraud_risk: 2.75,
  //           management_initiative: 1.0,
  //           risk_score: 6.75,
  //           risk_level: "High",
  //           risk_score: 2.0,
  //           total_inherent_score: "20",
  //           BU: "PARKING",
  //           risk_assessment: 5,
  //           status: "In progress",
  //         },
  //       ],
  //     },
  //     {
  //       financial_statement: 1.2,
  //       legal_compliance: 1.1,
  //       operational: 1.2,
  //       iT: 1.5,
  //       total_impact: 1.2,
  //       complexity_process: 1.2,
  //       volume: 1.3,
  //       Known_issues: 2.5,
  //       changes_personnel_processes: 2.5,
  //       monitoring: 1.6,
  //       total_likelihood: 1.8,
  //       fraud_risk: 2.3,
  //       management_initiative: 1.0,
  //       risk_score: 5.5,
  //       risk_level: "Medium",
  //       total_inherent_score: "18",
  //       audit_entity: "Finance Department",
  //       risk_assessment: 4,
  //       status: "In progress",
  //       BUs: [
  //         {
  //           financial_statement: 1.2,
  //           legal_compliance: 1.1,
  //           operational: 1.2,
  //           iT: 1.5,
  //           total_impact: 1.2,
  //           complexity_process: 1.2,
  //           volume: 1.3,
  //           Known_issues: 2.5,
  //           changes_personnel_processes: 2.5,
  //           monitoring: 1.6,
  //           total_likelihood: 1.8,
  //           fraud_risk: 2.3,
  //           management_initiative: 1.0,
  //           risk_score: 5.5,
  //           risk_level: "Medium",
  //           total_inherent_score: "18",
  //           BU: "Accounting Department",
  //           risk_assessment: 4,
  //           status: "In progress",
  //         },
  //         {
  //           financial_statement: 1.1,
  //           legal_compliance: 1.0,
  //           operational: 1.3,
  //           iT: 1.4,
  //           total_impact: 1.1,
  //           complexity_process: 1.1,
  //           volume: 1.4,
  //           Known_issues: 2.3,
  //           changes_personnel_processes: 2.3,
  //           monitoring: 1.5,
  //           total_likelihood: 1.7,
  //           fraud_risk: 2.1,
  //           management_initiative: 1.0,
  //           risk_score: 4.8,
  //           risk_level: "Low",
  //           total_inherent_score: "16",
  //           BU: "Procurement Department",
  //           risk_assessment: 3,
  //           status: "In progress",
  //         },
  //       ],
  //     },
  //     {
  //       financial_statement: 1.6,
  //       legal_compliance: 1.4,
  //       operational: 1.8,
  //       iT: 1.9,
  //       total_impact: 1.7,
  //       complexity_process: 2.0,
  //       volume: 1.7,
  //       Known_issues: 2.7,
  //       changes_personnel_processes: 2.7,
  //       monitoring: 1.9,
  //       total_likelihood: 2.0,
  //       fraud_risk: 2.9,
  //       management_initiative: 1.0,
  //       risk_score: 7.0,
  //       risk_level: "High",
  //       total_inherent_score: "22",
  //       audit_entity: "Legal Department",
  //       risk_assessment: 6,
  //       status: "In progress",
  //       BUs: [
  //         {
  //           financial_statement: 1.6,
  //           legal_compliance: 1.4,
  //           operational: 1.8,
  //           iT: 1.9,
  //           total_impact: 1.7,
  //           complexity_process: 2.0,
  //           volume: 1.7,
  //           Known_issues: 2.7,
  //           changes_personnel_processes: 2.7,
  //           monitoring: 1.9,
  //           total_likelihood: 2.0,
  //           fraud_risk: 2.9,
  //           management_initiative: 1.0,
  //           risk_score: 7.0,
  //           risk_level: "High",
  //           total_inherent_score: "22",
  //           BU: "Legal Compliance",
  //           risk_assessment: 6,
  //           status: "In progress",
  //         },
  //         {
  //           financial_statement: 1.5,
  //           legal_compliance: 1.3,
  //           operational: 1.7,
  //           iT: 1.8,
  //           total_impact: 1.6,
  //           complexity_process: 1.9,
  //           volume: 1.6,
  //           Known_issues: 2.6,
  //           changes_personnel_processes: 2.6,
  //           monitoring: 1.8,
  //           total_likelihood: 1.9,
  //           fraud_risk: 2.8,
  //           management_initiative: 1.0,
  //           risk_score: 6.5,
  //           risk_level: "High",
  //           total_inherent_score: "21",
  //           BU: "Legal Affairs",
  //           risk_assessment: 5,
  //           status: "In progress",
  //         },
  //       ],
  //     },

  //   ],
  // };

  const navigateRisk = () => {
    navigate(`/audit-universe/risk-assessments/${raid}`);
  };

  const [activeView, setActiveView] = useState('Graph View'); // State to track active view

  const toggleListView = () => {
    setActiveView('List View');
    navigateRisk();
  };

  const toggleGraphView = () => {
    setActiveView('Graph View');
  };




  const trueRisk = {
    toggleListView: toggleListView,
    activeView: activeView,
    toggleGraphView: toggleGraphView,
    activeView: activeView
  }
  return (
    <>
      {/* <Graph jsonData={jsonData} /> */}
      {successMessage && (
        <p className="successmessage">{successMessage}</p>
      )}

      <section className="navroutes">


        <RenderIcons homeRoutes={auditRoutes} />
        <NavRow name={"Risk Assessment"} trueRisk={trueRisk} parentName={"Risk Assessment"} />







        <div className="graphcontainer" style={{ width: tableSize ? "100%" : "100%" }}>

          <div className="factor_n_rate">

            <div className="risk_factor_con" style={{ width: tableSize ? "100%" : "100%" }}>
              {APIData && <Barchart APIData={APIData} />}
            </div>

            <div className="risk_rate_con" style={{ width: tableSize ? "100%" : "100%" }}>

              {APIData && <BarchartSection APIData={APIData} />}

            </div>

          </div>

          <div className="heatmap_con">
            {APIData && <Heatmap APIData={APIData} />}
          </div>

        </div>



      </section>
    </>
  );
};

export default GraphViewRa;






