import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  fetchDataV2,
  updateDataNavigate,
  updateReturnResponse,
  postDataV2,
  fetchDataObjectV2,
  deleteDataV2,
  deleteDataWithFetchV2,
  postDataWithFile,
} from "../../apiUtils";
import { auditExecution, auditExecutionInnerBU ,auditExecutionInnerQA} from "../../subroutes";
import "./filemanager.css";
import create_blue_button from "../../../images/plus_icon_blue.svg";
import cut_button from "../../../images/cutbutton.svg";
import createBtnfile from "../../../images/createbutton.svg";
import RenderIcons from "../../RenderIcons";
import "../createaudit/createaudit.css";
import NavRow from "../../NavRow";
import cancelbtn from "../../../images/closeicon.svg";

const FileManager = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [isModalOpen, setisModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [activePanel, setActivePanel] = useState("files");
  const [tasks, setTasks] = useState([]);
  const [APIData, setAPIData] = useState([]);
  const [showSelectFileButton, setShowSelectFileButton] = useState(true);
  const [requestedFiles, setRequestedFiles] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showRow, setShowRow] = useState(false);
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const [visibleTasks, setVisibleTasks] = useState({});
  const location = useLocation();
  const [successMessage, setSuccessmessage] = useState(
    location.state?.successMessage
  );
  const [successMessageFileUpload, setSuccessMessage] = useState(false);
  const [successMessageFileDelete, setSuccessMessageDelete] = useState(false);

  const name = APIData.name;
  var routes = [];
  if (is_Admin) {
    routes = auditExecution;
  } else if (Object.keys(userRoles).some(key => key.includes("Business"))) {
    console.log("i am here t")
    routes = auditExecutionInnerBU;
  } else if (Object.keys(userRoles).some(key => key.includes("Quality Assurance"))) {
    console.log("i am here t")
    routes = auditExecutionInnerQA;
  } else if (Object.keys(userRoles).some(key => key.includes("Audit Team"))) {
    routes = auditExecution;
  } else if (Object.keys(userRoles).some(key => key.includes("Audit Commsion"))) {
    routes = auditExecution;
  
  } else {
    console.log("No matching role found");
  }
  const createRow = (e) => {
    e.preventDefault();
    setInputList((prevList) => [...prevList, ""]);
    setShowRow(true);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileUpload]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessageDelete(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileDelete]);

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    setInputList((prevList) => {
      const updatedList = [...prevList];
      updatedList[index] = value;
      setAllData(inputList);
      return updatedList;
    });
  };

  const handleCancel = (index, e) => {
    e.preventDefault();
    setAllData((prevData) => prevData.filter((u, i) => i != index));
    setInputList((prevList) => prevList.filter((u, i) => i != index));
  };

  const handleDeleteModalOpen = (fileId) => {
    setFileToDelete(fileId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setFileToDelete(null);
  };

  const handleToggle = (taskId) => {
    setVisibleTasks((prevState) => ({
      ...prevState,
      [taskId]: !prevState[taskId],
    }));
  };

  const confirmDeleteFile = () => {
    if (fileToDelete) {
      handleDeleteFile(fileToDelete);
      handleDeleteModalClose();
    }
  };

  const handleModalOpen = () => {
    setisModalOpen(true);
  };

  const handleModalClose = () => {
    setisModalOpen(false);
  };

  useEffect(() => {
    const parameter ={
      url :  `/api/audit_engagement/audit/${id}/`,
      setterFunction : setAPIData,
      setErrors : setErrors,
      setLoading : setLoading
     }
    fetchDataObjectV2(parameter);
   
  }, [id]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/auditfilerequirements/?audit_id=${id}`,
      setterFunction: setRequestedFiles,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/auditfile/?audit_id=${id}`,
      setterFunction: setUploadedFiles,
      setErrors: setErrors,
    };
    fetchDataV2(parameter, (data) => {
      console.log("Fetched files:", data.files);
      setUploadedFiles(data.files || []);
    });
  }, [id]);

  useEffect(() => {
    const parameter = {
      url: `/api/system/task/?data_type=audit_file_upload&audit_id=${id}`,
      setterFunction: (data) => {
        console.log("Fetched tasks:", data);
        setTasks(data);
      },
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  const handleDeleteFile = (fileId) => {
    const parameter = {
      url: `/api/audit_engagement/auditfile/${fileId}/`,
      navigate: navigate,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/auditfile/?audit_id=${id}`,
            dataset: setUploadedFiles,
          },
        ],
        setSuccessMessage: setSuccessMessageDelete,
      },
    };
    deleteDataWithFetchV2(parameter);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
        setSelectedFiles((prevSelectedFiles) => {
            if (!Array.isArray(prevSelectedFiles)) {
                console.error("selectedFiles is not an array", prevSelectedFiles);
                return [file];
            }
            return [...prevSelectedFiles, file];
        });
        setShowSelectFileButton(false);
    }
};



  const handleUploadFiles = () => {
    if (selectedFiles.length === 0) {
      setErrors("Please select a file to upload.");
      return;
    }

    const parameter = {
      url: "/api/audit_engagement/auditfile/",
      data: { audit_id: id },
      files: selectedFiles,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/auditfile/?audit_id=${id}`,
            dataset: setUploadedFiles,
          },
        ],
        setSuccessMessage: setSuccessMessage
      },
    };

    postDataWithFile(parameter);
    setSelectedFiles();
    setShowSelectFileButton(true);
  };

  const handleSendRequest = (e) => {
    e.preventDefault();
    const parameter = {
      url: `/api/system/task/`,
      payload: {
        title: taskTitle,
        audit_id: id,
        data_type: "audit_file_upload",
        file_list: allData,
      },
      navigate: !navigate,
      setErrors: setErrors,
    };
    postDataV2(parameter);
  };

  const handleCheckboxChange = (taskId, taskTitle) => {
    try {
      const parameter = {
        url: `/api/system/task/${taskId}/`,
        payload: {
          status: "done",
          title: taskTitle,
        },
        setErrors: setErrors,
        setSuccessCallback: (data) => {
          const parameter = {
            url: `/api/system/task/?data_type=audit_file_upload&audit_id=${id}`,
            setterFunction: (data) => {
              setTasks(data);
            },
            setErrors: setErrors,
          };
          fetchDataV2(parameter);
        },
      };

      updateReturnResponse(parameter);
    } catch (error) {
      setErrors("Error updating task status: " + error.message);
    }
  };

  const handleFileUploaded = (Id) => {
    try {
      const parameter = {
        url: `/api/audit_engagement/auditfilerequirements/${Id}`,
        payload: {
          file_approve: true,
          audit_id: id,
        },
        setErrors: setErrors,
      };
      updateDataNavigate(parameter);

      setRequestedFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === Id ? { ...file, file_approve: true } : file
        )
      );
      console.log("File status updated successfully");
    } catch (error) {
      setErrors("Error updating file status: " + error.message);
    }
  };

  const phaseTrue = {
    phase: APIData.phase,
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageFileUpload && (
        <p className="successmessage">File Uploaded SuccessFully</p>
      )}

      {successMessageFileDelete && (
        <p className="successmessage">File Deleted SuccessFully</p>
      )}

      {isDeleteModalOpen && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Are you sure you want to delete this file?</p>
            <div className="subpopupBtnDiv">
              <button onClick={confirmDeleteFile}>Yes</button>
              <button onClick={handleDeleteModalClose}>No</button>
            </div>
          </div>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={routes} id={id} />
        <NavRow
          parentName={name}
          name={name}
          phaseTrue={phaseTrue}
          backToExecution={`/audit-execution`}
        />

        <div className="file_manager_con" style={{ width: tableSize ? "100%" : "100%" }}>
          
          <div className="files_section">
            <div className="files_section_header">
              <h4>Upload files</h4>
              <div className="filemanager-action-btn-div">
                {showSelectFileButton ? (
                  <button
                    className="select-file-button"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <img src={createBtnfile} alt="" />
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                  </button>
                ) : (
                  <button className="save-files" onClick={handleUploadFiles}>
                    Upload Files
                  </button>
                )}
              </div>
            </div>

            <div
              className="selected_files_section"
              style={{ display: showSelectFileButton ? "none " : "" }}
            >
              <h4>Selected Files</h4>
              <div className="selected_files_section_inner">
                {selectedFiles?.map((file, index) => (
                  <div className="fm_selected_file" key={index}>
                    <img
                      src={cut_button}
                      className="deleteFile"
                      onClick={() => {
                        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
                    }}
                    />
                    <div className="extension_section">
                      <h5>.{file.name.split(".").pop()}</h5>
                    </div>
                    <h6>{file.name}</h6>
                  </div>
                ))}
              </div>
            </div>

            <div className="uploaded_section_header">
              <h4>Recently Uploaded</h4>
            </div>

            <div className="uploaded_files_section_inner">
              {uploadedFiles.map((file, index) => {
                const fileName = file?.filename || "Unnamed File";
                const fileExtension = fileName.split(".").pop();
                const fileId = file?.id; // Ensure this matches your data structure

                if (!fileId) {
                  console.error("File ID is missing for:", fileName);
                  return null; // Skip rendering if ID is missing
                }

                return (
                  <div className="fm_selected_file" key={index}>
                    <img
                      src={cut_button}
                      className="deleteFile"
                      onClick={() =>
                        handleDeleteModalOpen(file.associated_with_id)
                      }
                      alt="Delete"
                    />

                    <div className="extension_section">
                      <h5>.{fileExtension}</h5>
                    </div>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/api/system/documents/${fileId}/view`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h6>{fileName}</h6>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="request_section">
            <div className="requesting_panel_header">
              <h3>File Request</h3>
            </div>

            {activePanel === "files" ? (
              <div className="files_list_parent">
                <div className="files_list">
                  <div className="files_list_header">
                    <div
                      className="requesting_panel_header"
                      style={{ margin: "1vw 0" }}
                    >
                      <button onClick={handleModalOpen} disabled={Object.keys(userRoles).some(key => key.includes("Business"))}>
                        <img src={create_blue_button} />
                        <h6>Create Task</h6>
                      </button>
                    </div>
                    {isModalOpen && (
                      <div className="req_file_modal_auditor">
                        <div className="req_file_modal_auditor_hedaer">
                          <h2>Request Files</h2>
                          <img
                            onClick={handleModalClose}
                            src={cut_button}
                            alt="Close"
                          />
                        </div>

                        <form onSubmit={handleSendRequest}>
                          <div className="lng_lbl_inpt">
                            <label>Task Title</label>
                            <input
                              type="text"
                              value={taskTitle}
                              onChange={(e) => setTaskTitle(e.target.value)}
                              placeholder="File request for Audit-103 on 14-12-24"
                            />
                          </div>

                          <div className="lbl_txa">
                            <div
                              className="lbl_btn"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <label>
                                Files Names <span>(On new line*)</span>
                              </label>
                              <button
                                onClick={createRow}
                                style={{ background: "none" }}
                                type="button"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 216 216"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="108"
                                    cy="108"
                                    r="108"
                                    fill="#17d317"
                                  />
                                  <path
                                    d="M154 109H64"
                                    stroke="#FFFFFF"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M109 154V64"
                                    stroke="#FFFFFF"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>

                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "12px",
                              }}
                            >
                              {showRow &&
                                inputList.map((input, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      position: "relative",
                                      width: "100%",
                                    }}
                                    className="lng_lbl_inpt"
                                  >
                                    <input
                                      type="text"
                                      value={input}
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        padding: "0 40px 0 10px", // Adding padding to the right to make room for the button
                                      }}
                                      onChange={(e) =>
                                        handleInputChange(index, e)
                                      }
                                    />

                                    <button
                                      onClick={(e) => handleCancel(index, e)}
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "12px", // Positioning the button inside the input field
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        transform: "translateY(-50%)", // Center vertically
                                      }}
                                    >
                                      <img
                                        src={cancelbtn}
                                        alt="Cancel"
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }} // Adjust size if necessary
                                      />
                                    </button>
                                  </div>
                                ))}
                            </div>
                          </div>

                          <button
                            style={{ backgroundColor: "#5771DF" }}
                            className="cmn_btn"
                            type="button"
                            onClick={(e) => handleSendRequest(e)}
                          >
                            Send Request
                          </button>
                        </form>
                      </div>
                    )}
                    {/* <h5>Requested Files</h5> */}
                    <p>
                      Please ensure that the following documents are submitted
                      for review and processing.
                    </p>
                  </div>

                  <div className="req_files_con">
                    {tasks.map((task) => {
                      const filesForTask = requestedFiles.filter(
                        (file) => file.task.id === task.id
                      );

                      return (
                        <div key={task.id} className="files_w_task_title">
                          <div className="titletitle_svg">
                            <div className="input_title_fl_mng">
                              <input
                                type="checkbox"
                                checked={task.status === "done"}
                                onChange={() =>
                                  handleCheckboxChange(task.id, task.title)
                                }
                              />
                              <h4>{task.title}</h4>
                            </div>

                            <svg
                              style={{
                                rotate: visibleTasks[task.id]
                                  ? "180deg"
                                  : "0deg",
                              }}
                              onClick={() => handleToggle(task.id)}
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>

                          <div
                            style={{
                              display: visibleTasks[task.id] ? "block" : "none",
                            }}
                          >
                            <hr />

                            {filesForTask.length > 0
                              ? filesForTask.map((file) => (
                                  <div
                                    className="files_list_w_task_title"
                                    key={file.id}
                                    style={{
                                      display: visibleTasks[task.id]
                                        ? "flex"
                                        : "none",
                                      alignItems: "center",
                                      gap: ".5vw",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={file.file_approve}
                                      onClick={() =>
                                        handleFileUploaded(file.id)
                                      }
                                    />
                                    <h6>{file.name}</h6>
                                  </div>
                                ))
                              : visibleTasks[task.id] && (
                                  <div
                                    className="no_files_message"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "12px",
                                      color: "gray",
                                    }}
                                  >
                                    <h6>No requested files</h6>
                                  </div>
                                )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="requesting_panel">
                <div className="requesting_panel_header">
                  <button onClick={handleModalOpen}>
                    <img src={create_blue_button} />
                    <h6>Create Task</h6>
                  </button>
                </div>

                <div className="requesting_panel_task">
                  <div className="tasks-div">
                    {tasks.length > 0 ? (
                      tasks.map((task) => (
                        <div key={task.title} className="task-item">
                          <input
                            type="checkbox"
                            checked={task.status === "done"}
                            onChange={() =>
                              handleCheckboxChange(task.id, task.title)
                            }
                          />
                          <h6>{task.title}</h6>
                        </div>
                      ))
                    ) : (
                      <p>No tasks available.</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default FileManager;
