import React, { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import "../../../css/viewuser.css";
import "../AuditAnalytics/auditanalytics.css";
import { fetchDataObjectV2, fetchDataV2, justFetchDataV2 } from "../../apiUtils";
import { issueTracking } from "../../subroutes";
import "./../issuetracking.css";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import AllIssuePieChart from "../Charts/AllIssuePieChart";
import IssueRatingPieChart from "../Charts/IssueRatingPieChart";
import BounceLoader from "react-spinners/BounceLoader";
import SeverityBarChart from "../Charts/StatusChart/SeverityBarChart";
import PriorityBarChart from "../Charts/PriorityBarChart";


const AuditAnalytics = ({ tableSize }) => {
  const [activeView, setActiveView] = useState(true);

  const toggleView = (view) => {
    setActiveView(view);
  };



 

  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData1, setAuditData1] = useState([]);
  const [IssueData, setIssueData] = useState([]);
  const [issuecount, setIssueCount] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  const { auid } = useParams();
  const successMessage = location.state?.successMessage;
  const [APIDataRating, setAPIDataRating] = useState([]);
  const [isssueData, setIsssueData] = useState([]);
  const [isssueQData, setIsssueQData] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const parameter = {
      url: `/api/issues/audit/quater/count/${auid}/`,
      setterFunction: setIsssueQData,
      setErrors: setErrors,
      setLoading: setLoading
    };
    justFetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/issues/count/actionplan/issue/${auid}/`,
      setterFunction: setIsssueData,
      setErrors: setErrors,
      setLoading: setLoading
    };
    justFetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/issues/issue_audit_rating/${auid}/`,
      setterFunction: setAPIDataRating,
      setErrors: setErrors,
      setLoading: setLoading
    };
    fetchDataObjectV2(parameter);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);



  useEffect(() => {
    const parameter = {
      url: `/api/issues/issue_audit/${auid}/`,
      setterFunction: setIssueCount,
      setErrors: setErrors,
      setLoading: setLoading
    };
    fetchDataObjectV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${auid}/`,
      setterFunction: setAuditData1,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/issues/`,
      setterFunction: setIssueData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  const parentname = auditData1.name;


  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });



 
  const groupByQuarter = (data) => {
    // Group the data by year and quarter
    const grouped = data.reduce((acc, item) => {
      const key = `${item.quarter} ${item.year}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  
    // Sort the quarters by year (ascending), quarter (ascending), and issue_id (ascending)
    return Object.entries(grouped)
      .sort((a, b) => {
        const [quarterA, yearA] = a[0].split(" ");
        const [quarterB, yearB] = b[0].split(" ");
  
        if (parseInt(yearA) !== parseInt(yearB)) {
          return parseInt(yearA) - parseInt(yearB); // Ascending year order
        }
  
        return getQuarterNumber(quarterA) - getQuarterNumber(quarterB); // Ascending quarter order
      })
      .map(([key, values]) => ({
        quarter: key,
        data: values.sort((a, b) => a.issue_id - b.issue_id).map(issue => ({
          issue_id: issue.issue_id,
          issue_objective: issue.issue_objective,
          issue_actionplan: issue.issue_actionplan,
          action_plan_details: issue.action_plan_details,
          issue_status:issue.issue_status,
        }))
      }));
  };
  
  // Helper to convert quarter string (e.g., Q1) into a numeric value
  const getQuarterNumber = (quarter) => parseInt(quarter.replace(/\D/g, ""));
  

  
    
  function calculateAuditStatus(data, auditName) {
    let statusCounts = {
      open: 0,
      closed: 0,
      overdue: 0,
      extended: 0,
      closedUnderMonitoring: 0,
    };

    const audit = data.find((audit) => audit.auditName === auditName); // Find the specific audit

    if (audit) {
      audit.issues.forEach((issue) => {
        statusCounts.open += issue.status.open || 0;
        statusCounts.closed += issue.status.closed || 0;
        statusCounts.overdue += issue.status.overdue || 0;
        statusCounts.extended += issue.status.extended || 0;
        statusCounts.closedUnderMonitoring +=
          issue.status.closedUnderMonitoring || 0;
      });
    }

    return statusCounts;
  }

  // Example usage:
  const auditName = "Human Resource & Admin"; // The audit you want to focus on
  const result = calculateAuditStatus(isssueQData, auditName);

  const filteredData = APIData.filter((data) => {
    const fullName = data.name.toLowerCase();
    const NameMatch = filters.name
      ? fullName.includes(filters.name.toLowerCase())
      : true;
    const keyStakeholderName = "";
    const KeyStakeholder = filters.key_stakeholder
      ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase())
      : true;

    const businessUnitMatch = filters.business_unit
      ? data.business_units.some((unit) => {
        const unitNameLower = unit.name.toLowerCase();
        const filterUnitLower = filters.business_unit.toLowerCase();
        return unitNameLower.includes(filterUnitLower);
      })
      : true;

    return NameMatch && KeyStakeholder && businessUnitMatch;
  });
  const groupedData = groupByQuarter(isssueQData);

  const keyMappingStatus = {
    "status_closed": "Closed",
    "status_open": "Open",
    "overdue": "Overdue",
    "extended_date": "Extended",
    "closed_under_monitoring": "Closed(Monitoring)"
  };


  const keyMappingRating ={
    "rating_low": "Low",
    "rating_medium": "Medium",
    "rating_high": "High",
    "rating_very_high": "Very High",
    "rating_critical": "Critical"
  };


  const showRatingColor = (status) => {
    switch (status) {
      case "Low":
        return <span className="status_low">{status}</span>;
      case "Medium":
        return <span className="status_medium">{status}</span>;
      case "High":
        return <span className="status_high">{status}</span>;
      case "Very High":
        return <span className="status_veryhigh">{status}</span>;
      case "Critical":
        return <span className="status_critical">{status}</span>;

      default:
        return <span className="status_rejected">No Status Found</span>;
    }
  };

  const showStatusColor = (status) => {
    switch (status) {
      case "Open":
        return <span className="status_todo">{status}</span>;
      case "Closed":
        return <span className="status_complete">{status}</span>;
      case "In Progress":
        return <span className="status_inprogress">{status}</span>;

      default:
        return <span className="status_rejected">No Status Found</span>;
    }
  };


  console.log("groupedData : ", groupedData)

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={issueTracking} />
        <NavRow
          name={parentname}
          parentName={parentname}
          Create={"Create"}
          idtrue={true}
        />

        <div
          className="analytics_container"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <div className="analytics_grid_container">
            <div className="analytic_grid_item">
              <h5> Counts of Status {parentname} </h5>
              <PriorityBarChart  apiResponse ={issuecount} keyMapping ={keyMappingStatus}/>
              
            </div>

            <div className="analytic_grid_item">
              <h5> Counts of Rating {parentname} </h5>
              <PriorityBarChart  apiResponse ={APIDataRating} keyMapping ={keyMappingRating}/>
            </div>

            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>
                Issues
              </h2>

              <div className="togglecon">
                <div
                  className="toggle-option"
                  onClick={() => toggleView(true)}
                  style={{
                    backgroundColor: activeView === true ? "#fff" : "",
                  }}
                >
                  As per Rating
                </div>

                <div
                  className="toggle-option"
                  onClick={() => toggleView(false)}
                  style={{
                    backgroundColor: activeView === false ? "#fff" : "",
                  }}
                >
                  As per Quarter
                </div>
              </div>
            </div>

            {activeView === true ? (
              <div className="auditanalytic_grid_table_span_2">
                <table>
                  <thead>
                    <tr className="tablehead">
                      <th>
                        <h4 className="thhead">Issue Code</h4>
                        {/* <input type="text" placeholder="Search" className="thfilter" disabled /> */}
                      </th>
                      <th>
                        <h4 className="thhead">Issue Title</h4>
                        {/* <input type="text" placeholder="Search" className="thfilter" disabled /> */}
                      </th>
                      <th>
                        <h4 className="thhead">Sub Issue</h4>
                        {/* <input type="text" placeholder="Search" className="thfilter" disabled /> */}
                      </th>
                      <th>
                        <h4 className="thhead">Issue Rating</h4>
                        {/* <input type="text" placeholder="Search" className="thfilter" disabled /> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <div className="spinner-bounce-loader">
                        <BounceLoader color="#a6fafa" />
                      </div>
                    ) : isssueData === null ? (
                      <p className="data-error-text">No data found</p>
                    ) : (
                      isssueData
                        .sort((a, b) => {
                          const ratingsOrder = { High: 1, Medium: 2, Low: 3 };
                          return (
                            ratingsOrder[a.issue_rating] -
                            ratingsOrder[b.issue_rating]
                          );
                        })
                        .map((audit, index) => (
                          <tr key={index}>
                            <td>
                              <span>{audit?.issue_id}</span> {/* Audit Name */}
                            </td>
                            <td>
                              <span>{audit?.issue_objective}</span> {/* Total */}
                            </td>
                            <td>
                              <span>{audit?.issue_actionplan}</span> {/* Open */}
                            </td>
                            <td>
                                {showRatingColor(audit?.issue_rating)}
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="auditanalytic_grid_table_span_2">
                <table>
                  <thead>
                    <tr className="tableheadissue">
                      <th>
                        <h4 className="thhead">Issue Code</h4>
                      </th>
                      <th>
                        <h4 className="thhead">Issue Title</h4>
                      </th>
                      <th>
                        <h4 className="thead">Issue Status</h4>
                      </th>
                      <th>
                        <h4 className="thhead">Action Plan</h4>
                      </th>
                      <th>
                        <h4 className="thhead">Issue Obs</h4>
                      </th>
                    </tr>
                  </thead>
              {console.log(groupedData)}
                  {groupedData.map((group, index) => (
                    <React.Fragment key={index}>
                      {/* Render quarter header */}
                      <thead>
                        <tr>
                          <th colSpan={5} style={{ backgroundColor: "#EFEFEF" }}>
                            {group.quarter}
                          </th>
                        </tr>
                      </thead>

                      {/* Render quarter data */}
                      <tbody>
                        {group.data.map((audit, idx) => (
                          <tr key={idx}>
                            <td>
                              <span className="status_todo">{audit.issue_id}</span>
                            </td>
                            <td>
                              <span>{audit.issue_objective}</span>
                            </td>
                            <td>
                              <span>{showStatusColor(audit.issue_status)}</span>
                            </td>
                            <td>
                              {audit?.action_plan_details &&
                              audit.action_plan_details.length > 0 ? (
                                <span style={{display:"flex", gap:"0.3vw"}}>
                                  {audit.action_plan_details
                                    .slice(0, 2)
                                    .map((unit, index) => (
                                      <div className="status_todo" key={index}>
                                        {unit.id || "No ID"}
                                      </div>
                                    ))}
                                </span>
                              ) : (
                                <span>No action plans available</span>
                              )}
                            </td>
                            <td>
                              <span>{audit.issue_actionplan}</span>
                              
                            </td>
                          </tr>
                        ))}

                        {/* Summary row */}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td style={{ textAlign: "left" }}>
                            <span style={{ fontFamily: "intersemibold" }}>  {group.data.reduce((sum, audit) => sum + audit.issue_actionplan, 0)}</span>
                          </td>
                        </tr>
                      </tbody>
                    </React.Fragment>
                  ))}
                </table>
              </div>
            )}


          </div>
        </div>
      </section>
    </>
  );
};

export default AuditAnalytics;
