import React from "react";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { fetchDataObjectV2, updateDataNavigate } from "../apiUtils";
import { homeRoutes } from "../subroutes";
import CommonInput from "../CommonInput";
import NavRow from "../NavRow";

import RenderIcons from "../RenderIcons";

const ForgotPassword = ({ tableSize }) => {
  const [passwordError, setPasswordError] = useState("");
  const [npassword, setNpassword] = useState("");
  const [mpassword, setMpassword] = useState(false);
  const [passwordCError, setPasswordCError] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordC, setPasswordC] = useState("");
  //   const [editMode, setEditMode] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const UserSetting=window.location.pathname.includes('user-settings')

  const { id } = useParams();

  useEffect(() => {
    const parameter = {
      url: `/api/system/users/${id}/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  const updateAPIPassword = (e) => {
    e.preventDefault();
    if (!password) {
      setPasswordError(!password ? true : false);
      setErrors("Please fill in all required fields11.");

      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (password !== passwordC) {
      setErrors("Confirm Password Does Not Match");
      setPasswordError(true);
      setPasswordCError(true);
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const Parameter = {
      url: `/api/system/users/${id}/change_password`,
      payload: {
        password: npassword ? npassword : password,
        confirm_password: passwordC,
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: UserSetting?`/user-settings`: `/administration/users/${id}`,
    };
    updateDataNavigate(Parameter);
  };

  const handleEditClick = () => {
    // setEditMode(true);
  };
  const handleCancelClick = () => {
    // setEditMode(false);
    // window.location.reload();
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      <section className="navroutes">
        <RenderIcons homeRoutes={homeRoutes} />
        <NavRow name={"Edit User Details"} parentName={"User Detail"} />

        <div
          className="viewuserconatiner"
          style={{ width: tableSize ? "83.5vw" : "90.5vw" }}
        >
          <form onDoubleClick={handleEditClick}>
            <>
              <div
                className="forgotPasswordform"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "auto",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <CommonInput
                  labelName="Password"
                  error={passwordError}
                  type="password"
                  placeHolder="***********"
                  setText={setPassword}
                  setTextError={setPasswordError}
                  commanDivClass="lbl_inpt"
                  tabIndex={1}
                />
                <CommonInput
                  labelName="Confirm Password"
                  error={passwordCError}
                  type="password"
                  placeHolder="***********"
                  setText={setPasswordC}
                  setTextError={setPasswordCError}
                  commanDivClass="lbl_inpt"
                  tabIndex={2}
                />
                <div style={{ display: mpassword ? "block" : "none" }}>
                  <p
                    style={{
                      fontSize: "1vw",
                      color: "red",
                      fontFamily: "inter",
                      marginLeft: "9.8vw",
                    }}
                  >
                    Confirm Password doesn’t match{" "}
                  </p>
                </div>
              </div>
            </>
          </form>

          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              tabIndex={3}
              onClick={updateAPIPassword}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Update Password
            </button>

            <button
              tabIndex={4}
              onClick={handleCancelClick}
              className="cmn_btn"
              style={{ backgroundColor: "#d6d6d6", color: "#000" }}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L10.9989 10.9989"
                  stroke="#292D32"
                  stroke-width="1.76758"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.00107 10.9989L11 1"
                  stroke="#292D32"
                  stroke-width="1.76758"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Cancel Edit
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
