import React, { useState, useEffect, useMemo,useRef } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import "./teams.css";
import { justFetchDataV2 } from "../apiUtils";
import { homeRoutes } from "../subroutes";
import arrowIndicator from "../../images/arrow.svg";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../RenderIcons";
import NavRow from "../NavRow";
import ReactPaginate from "react-paginate";

const Teams = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [ApiData, setApiData] = useState([]);
  const [activeData, setActiveData] = useState("business-stakeholders"); 
  const [dropdownOpen, setDropdownOpen] = useState(false); 
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const dropdownRef = useRef(null);
  const lastSegment =location.pathname.split('/').pop()
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 13; 

  useEffect(() => {
    switch(lastSegment) {
  case 'teams-audit-team':
    renderData("audit-team"); 
    break;
  
  case 'teams-quality-assurance':
    renderData("quality-assurance");
    break;

    case 'teams-audit-committee':
      renderData("audit-committee");
           break;

  default:
    renderData("business-stakeholders"); 
       break;
}
  }, []);

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);



useEffect (()=>{
  console.log(lastSegment)
  switch(lastSegment) {
  case 'teams-audit-team':
    setActiveData("audit-team"); 
    break;
  
  case 'teams-quality-assurance':
    setActiveData("quality-assurance");
    break;

    case 'teams-audit-committee':
      setActiveData("audit-committee");
           break;

  default:
    setActiveData("business-stakeholders"); 
       break;
}
},[lastSegment])



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const Viewteams = (data) => {
    const id = data.user_id;
    navigate(`/administration/teams/${id}`);
  };

  const [filters, setFilters] = useState({
    user_id: "",
    name: "",
    designation: "",
    type: "",
    groups: "",
    business_units: "",
  });

  const handleFilterChange = (filterKey, e) => {
    const value = e.target.value.toLowerCase();
    setFilters({ ...filters, [filterKey]: value });
  };

  const filteredData = useMemo(() => {
    return ApiData?.filter((item) => {
      const { user_id, name, designation, groups, business_units } = item;

      const matchesId = user_id
        ? user_id.toString().includes(filters.user_id)
        : true;
      const nameMatch = name?.toLowerCase().includes(filters.name);
      const designationMatch = designation
        ?.toLowerCase()
        .includes(filters.designation);
        const businessUnitMatch = filters.business_units
        ? Array.isArray(business_units) &&
          business_units.some((unit) =>
            unit.name?.toLowerCase().includes(filters.business_units)
          )
        : true;
      const groupsMatch = filters.groups
        ? Array.isArray(groups) &&
          groups.some((group) =>
            group.name.toLowerCase().includes(filters.groups)
          )
        : true;

      return (
        matchesId &&
        nameMatch &&
        designationMatch &&
        businessUnitMatch &&
        groupsMatch
      );
    });
  }, [ApiData, filters]);


  const renderData = (activeData) => {
    switch (activeData) {
      case "business-stakeholders":
        const parameter ={
          url : `/api/audit_universe/business-stakeholders/`,
          setterFunction :(data) => {setApiData(data)},
          setErrors : (error) => {setErrors(error)},
          setLoading: setLoading,
        }
        
        justFetchDataV2(parameter);
        break;
      case "audit-team":
        const parameter2 ={
          url :  `/api/audit_universe/audit-team/`,
          setterFunction :(data) => {setApiData(data)},
          setErrors : (error) => {setErrors(error)},
          setLoading: setLoading,
        }
        
        justFetchDataV2(parameter2);
        break;
      case "quality-assurance":
        const parameter3 ={
          url : `/api/audit_universe/quality-assurance/`,
          setterFunction :(data) => {setApiData(data)},
          setErrors : (error) => {setErrors(error)},
          setLoading: setLoading,
        }
        
        justFetchDataV2(parameter3);
        break;
      case "audit-committee":
        const parameter4 ={
          url : `/api/audit_universe/audit-committee/`,
          setterFunction :(data) => {setApiData(data)},
          setErrors : (error) => {setErrors(error)},
          setLoading: setLoading,
        }
        
        justFetchDataV2(parameter4);
        break;
      default:
        console.log("activeData : ", activeData)
        const parameter5 ={
          url : `/api/audit_universe/business-stakeholders/`,
          setterFunction :(data) => {setApiData(data)},
          setErrors : (error) => {setErrors(error)},
          setLoading: setLoading,
        }
        
        justFetchDataV2(parameter5);
    }
  };


   // Pagination Logic
   const pageCount = Math.ceil(filteredData.length / itemsPerPage);
   const startOffset = currentPage * itemsPerPage;
   const paginatedData = filteredData.slice(
     startOffset,
     startOffset + itemsPerPage
   );
 
   const handlePageClick = ({ selected }) => {
     setCurrentPage(selected);
   };
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={homeRoutes} />


        <div className="filter-container">
          <div className="filter">
            <div >
            <NavRow name={'Teams'} Create ={'Create'} link={`/administration/user/createUser`} hide={true} idtrue={true}/>

            </div>

            <div className="dropdown-teams-container"
            style={{width : tableSize ? "24%" : "22%"}}

             onClick={toggleDropdown}
             ref={dropdownRef} >
              <div className="dropdown-header">
                {activeData.replace("-", " ").toUpperCase()}
                <img
                  src={arrowIndicator}
                  style={{ rotate: dropdownOpen ? "180deg" : "0deg" }}
                />
              </div>
              {dropdownOpen && (
                <div className="dropdown-teams">
                  <div
                    className={
                      activeData === "business-stakeholders" ? "activetab" : ""
                    }
                    onClick={() => {
                      setActiveData("business-stakeholders"); 
                      renderData("business-stakeholders");
                      setDropdownOpen(false);
                      navigate(`/administration/teams/teams-business-stakeholder`);
                    }}
                  >
                    Business Stakeholders
                  </div>
                  <div
                    className={activeData === "audit-team" ? "activetab" : ""}
                    onClick={() => {
                      setActiveData("audit-team"); 
                      renderData("audit-team");
                      setDropdownOpen(false);
                      navigate(`/administration/teams/teams-audit-team`);
                    }}
                  >
                    Audit Team
                  </div>
                  <div
                    className={
                      activeData === "quality-assurance" ? "activetab" : ""
                    }
                    onClick={() => {
                      setActiveData("quality-assurance"); // Update activeData
                      renderData("quality-assurance");
                      setDropdownOpen(false);
                      navigate(`/administration/teams/teams-quality-assurance`);
                    }}
                  >
                    Quality Assurance
                  </div>
                  <div
                    className={
                      activeData === "audit-committee" ? "activetab" : ""
                    }
                    onClick={() => {
                      setActiveData("audit-committee"); // Update activeData
                      renderData("audit-committee");
                      setDropdownOpen(false);
                      navigate(`/administration/teams/teams-audit-committee`);
                    }}
                  >
                    Audit Committee
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="teams-table-container" style={{ width: tableSize ? "100%" : "100%" }}>
          <table>
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">ID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("user_id", e)}
                    value={filters.user_id}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Name</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("name", e)}
                    value={filters.name}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Designation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("designation", e)}
                    value={filters.designation}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Business Unit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("business_units", e)}
                    value={filters.business_units}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Groups</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("groups", e)}
                    value={filters.groups}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>
              ) : paginatedData.length === 0 ? (
                <p className="data-error-text">No data found</p>
              ) : (
                paginatedData
                  .sort((a, b) => a.user_id - b.user_id)
                  .map((user) => (
                    <tr
                      className="tabledata"
                      onClick={() => Viewteams(user)}
                      key={user.user_id}
                    >
                      <td>
                        <span>{user.user_id}</span>
                      </td>
                      <td>{user.name}</td>
                      <td>{user.designation}</td>
                      <td>
                      <div className="tagscontainer">
                  {user.business_units && user.business_units.length > 0 ? (
                    user.business_units
                      .slice(0, 2)
                      .map((unit, index) => (
                        <div className="tag" key={index}>
                          {unit.name}
                        </div>
                      ))
                  ) : (
                    <div>No Business Unit</div>
                  )}
                  {user.business_units?.length > 2 && <div className="tag_last">...</div>}
                </div>
              </td>

                      <td>
                        {user.groups
                          ? user.groups

                              .map((user) => user.name)
                              .slice(0, 3)
                              .join(", ") +
                            (user.groups.length > 3 ? "..." : "")
                          : ""}
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
        <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container"
            activeClassName={"active"}
          />
      </section>
    </>
  );
};

export default Teams;
