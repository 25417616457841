import React, { useState, useEffect } from "react";
import SingleOptionSelector from "../../SingleOptionSelector";
import { postDataV2, fetchDataV2 } from "../../apiUtils";
import { useNavigate, useParams } from "react-router-dom";
import NavRow from "../../NavRow";
import SingleOptionSelectorAuditPR from "../../SingleOptionSelectorAuditPR";
import SingleOptionSelectorAuditBU from "../../SingleOptionSelectorAuditBU";
import RenderIcons from "../../RenderIcons";
import { auditExecution } from "../../subroutes";

const GenericTask = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [objective, setObjective] = useState("");
  const [objectiveError, setObjectiveError] = useState("");
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_id, setAssign_To] = useState("");
  const [assignError, setAssignError] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [scheduled_start_date, setStartDate] = useState("");
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState("");
  const [end_dateError, setEnd_dateError] = useState(false);
  const [APIaudit, setAudit] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const postApi = (e) => {
    e.preventDefault();
    if (
      !objective ||
      !preparer_id ||
      !reviewer_id ||
      !assign_to_id ||
      !id ||
      !scheduled_start_date ||
      !scheduled_end_date
    ) {
      setObjectiveError(!objective ? true : false);
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssignError(!assign_to_id ? true : false);
      setStart_dateError(!scheduled_start_date ? true : false);
      setEnd_dateError(!scheduled_end_date ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (new Date(scheduled_end_date) < new Date(scheduled_start_date)) {
      setErrors("End Date and Time should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const parameter = {
      url: `/api/audit_engagement/plan/`,
      payload: {
        objective,
        preparer_id,
        reviewer_id,
        audit_id: id,
        assign_to_id,
        plan_type: "Task",
        scheduled_start_date,
        scheduled_end_date,
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/planning`,
    };
    postDataV2(parameter);
  };

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  const [maketrue, setTrue] = useState(false);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow
          name={"Create Task"}
          parentName={APIaudit?.name}
          idtrue={true}
        />

        <div
          className="createmeeting"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <form>
            <div className="lng_lbl_inpt">
              <label>Objective</label>
              <input
                type="text"
                style={{ border: objectiveError ? "1px solid #FF4646" : "" }}
                placeholder="Objective"
                value={objective}
                onChange={(e) => {
                  setObjective(e.target.value);
                  setObjectiveError(false);
                }}
              />
            </div>

            <SingleOptionSelectorAuditPR
              id ={id}
              setSelectorId={setPreparerId}
              setSelectorError={setPreparerError}
              selectorError={PreparerError}
              Name="name"
              title="Preparer"
              editMode={editMode}
              maketrue={maketrue}
              setTrue={setTrue}
            />

            <SingleOptionSelectorAuditPR
              id ={id}
              setSelectorId={setReviewerId}
              setSelectorError={setReviewerError}
              selectorError={reviewerError}
              Name="name"
              title="Reviewer"
              editMode={editMode}
              maketrue={maketrue}
              setTrue={setTrue}
            />

            <div className="lbl_inpt">
              <label> Start Date: </label>{" "}
              <input
                style={{ border: start_dateError ? "1px solid #FF4646" : "" }}
                type="date"
                value={scheduled_start_date}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setStart_dateError(false);
                }}
              />
            </div>

            <div className="lbl_inpt">
              <label> End Date: </label>
              <input
                type="date"
                style={{
                  border: end_dateError ? "1px solid #FF4646" : "",
                }}
                value={scheduled_end_date}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setEnd_dateError(false);
                }}
              />
            </div>

            <SingleOptionSelectorAuditBU
              id={id}
              setSelectorId={setAssign_To}
              setSelectorError={setAssignError}
              selectorError={assignError}
              Name="name"
              title="Assign To"
              editMode={editMode}
              maketrue={maketrue}
              setTrue={setTrue}
            />
          </form>
          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={postApi}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create Task
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default GenericTask;
