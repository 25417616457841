import React, { useState, useEffect } from "react";
import arrowToggle from "../../../../images/toggle_side_down.svg";
import {
  justUpdateDataV2,
  justPostDataV2,
} from "../../../apiUtils";
import { useNavigate } from "react-router-dom";

const QuestionsComps = ({
  data,
  APIQData,
  showComments,
  tableSize,
  handleCommentsToggle,
}) => {
  const options = ["Yes","Partially Yes", "No", "Don't Know", "N/A"];
  const [errors, setErrors] = useState("");
  const [submitbtn, setSubmitbtn] = useState(false);
  const [successCallback, setSuccessCallback] = useState(false);
  const [showMessage, setshowResponse] = useState(false);
  const [showMessageSubmit, setshowResponseSubmit] = useState(false);

  const [showMessageSave, setshowResponseSave] = useState(false);

  const [showMessageReject, setshowResponseReject] = useState(false);
  const [showMessageApprove, setshowResponseApprove] = useState(false);
  const [showMessageClear, setshowResponseClear] = useState(false);

  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const questionnaireID = localStorage.getItem("questionnaireID");

  const isAdminRole =
    is_Admin ||
    Object.keys(userRoles).some(
      (key) => key.includes("Audit Team") 
    );
  var showSubmit = false;
  var showApproval = false;
  var showStatus = false;
  var incomingStatus = APIQData.status.toLowerCase();
  if (isAdminRole) {
    switch (incomingStatus) {
      case "rejected":
      case "open":
        showStatus = false;
        showApproval = false;
        showSubmit = true;
        break;
      case "submitted":
        showStatus = false;
        showApproval = true;
        showSubmit = false;
        break;
      default:
        showStatus = true;
        showApproval = false;
        showSubmit = false;
    }
  } else {
    switch (incomingStatus) {
      case "rejected":
      case "open":
        showStatus = false;
        showApproval = false;
        showSubmit = true;
        break;
      default:
        showStatus = true;
        showApproval = false;
        showSubmit = false;
    }
  }

  const initialAnswers = data.sections.map((section) =>
    section.questions.map((question) =>
      question.answers.reduce((acc, curr) => {
        acc[curr.id] =
          options.findIndex((option) => option === curr.selected_option) + 1 ||
          0;
        return acc;
      }, {})
    )
  );

  const initialRemarks = data.sections.map((section) =>
    section.questions.map((question) => question.remark || "")
  );

  const [answers, setAnswers] = useState(initialAnswers);
  const [remarks, setRemarks] = useState(initialRemarks);

  const [selectedColumnIndexes, setSelectedColumnIndexes] = useState(
    data.sections.map(() => Array(options.length).fill(null))
  );

  const [allAnswersFilled, setAllAnswersFilled] = useState(false);
  const [anyOneAnswersFilled, setAnyOneAllAnswersFilled] = useState(false);

  const [allAnswersFilledOnLoad, setAllAnswersFilledOnload] = useState(false);
  useEffect(() => {
    setAllAnswersFilledOnload(areAllAnswersFilled());
  }, []);

  const [allSelectedOptionsFilled, setAllSelectedOptionsFilled] =
    useState(false);

  const handleAnswerChange = (
    sectionIndex,
    questionIndex,
    answerId,
    optionIndex
  ) => {
    const newAnswers = [...answers];
    if (newAnswers[sectionIndex][questionIndex][answerId] === optionIndex + 1) {
      newAnswers[sectionIndex][questionIndex][answerId] = 0; // Uncheck if already checked
    } else {
      newAnswers[sectionIndex][questionIndex][answerId] = optionIndex + 1;
    }
    setAnswers(newAnswers);
  };

  const handleRemarkChange = (sectionIndex, questionIndex, value) => {
    const newRemarks = [...remarks];
    newRemarks[sectionIndex][questionIndex] = value;
    setRemarks(newRemarks);
  };
  const storedAnswers = [];

  data.sections.forEach((section, sectionIndex) => {
    section.questions.forEach((question, questionIndex) => {
      const answersForAllUnits = Object.entries(
        answers[sectionIndex][questionIndex]
      );

      // Check if all answers are the same and not 0
      const areAllAnswersSame = answersForAllUnits.every(
        ([_, answerValue], index, array) =>
          answerValue === array[0][1] && answerValue !== 0
      );

      if (answersForAllUnits[0][1] !== 0 && areAllAnswersSame) {
        // Store only if the answers are the same and not 0
        storedAnswers.push({
          sectionIndex,
          questionIndex,
          answer: answersForAllUnits[0][1], // store the answer value
        });
      } else if (
        answersForAllUnits.some(([_, answerValue]) => answerValue !== 0)
      ) {
        // Store indices where answers are not the same but not zero
        storedAnswers.push({
          sectionIndexNotSame: sectionIndex,
          questionIndexNotSame: questionIndex,
        });
      }
    });
  });

  const [tableVisibility, setTableVisibility] = useState(() => {
    return data.sections.map((section, sectionIndex) =>
      section.questions.map((_, questionIndex) => {
        // Initially set to false
        let isVisible = false;

        // Check for sectionIndexNotSame and questionIndexNotSame in storedAnswers
        if (
          storedAnswers.some(
            (answer) =>
              answer.sectionIndexNotSame === sectionIndex &&
              answer.questionIndexNotSame === questionIndex
          )
        ) {
          isVisible = true;
        }

        return isVisible;
      })
    );
  });

  const toggleTableVisibility = (sectionIndex, questionIndex) => {
    const newVisibility = [...tableVisibility];
    newVisibility[sectionIndex][questionIndex] =
      !newVisibility[sectionIndex][questionIndex];
    setTableVisibility(newVisibility);
  };

  const showResponse = () => {
    const answersToConsole = [];
    const remarksToConsole = [];

    answers.forEach((sectionAnswers, sectionIndex) => {
      sectionAnswers.forEach((questionAnswers, questionIndex) => {
        Object.entries(questionAnswers).forEach(
          ([answerId, selectedOption]) => {
            answersToConsole.push({
              answerId: answerId,
              answer: selectedOption,
              remark: remarks[sectionIndex][questionIndex],
            });
          }
        );
      });
    });


    
    const parameter = {
      url: `/api/audit_universe/answers/`,
      payload: {
        answers: answersToConsole,
      },
      setErrors: setErrors,
      setshowResponse: setshowResponse,
      setSuccessCallback: (success) => {
        setshowResponseSave(true);
      },
    };

    justPostDataV2(parameter);

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const showResponseForAdmin = () => {
    const answersToConsole = [];
    const remarksToConsole = [];

    answers.forEach((sectionAnswers, sectionIndex) => {
      sectionAnswers.forEach((questionAnswers, questionIndex) => {
        Object.entries(questionAnswers).forEach(
          ([answerId, selectedOption]) => {
            answersToConsole.push({
              answerId: answerId,
              answer: selectedOption,
              remark: remarks[sectionIndex][questionIndex],
            });
          }
        );
      });
    });


    
    const parameter = {
      url: `/api/audit_universe/answers/`,
      payload: {
        answers: answersToConsole,
      },
      setErrors: setErrors,
      setSuccessCallback: (success) => {
      },
    };

    justPostDataV2(parameter);
  };


  const clearResponse = () => {
    const answersToConsole = [];
    const remarksToConsole = [];

    answers.forEach((sectionAnswers, sectionIndex) => {
      sectionAnswers.forEach((questionAnswers, questionIndex) => {
        Object.entries(questionAnswers).forEach(
          ([answerId, selectedOption]) => {
            answersToConsole.push({
              answerId: answerId,
              answer: null,
              remark: remarks[sectionIndex][questionIndex],
            });
          }
        );
      });
    });

    const parameter = {
      url: `/api/audit_universe/answers/`,
      payload: {
        answers: answersToConsole,
      },
      setErrors: setErrors,
      setshowResponse: setshowResponseClear,
      setSuccessCallback: (success) => {
        setSuccessCallback(success);

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
    };

    justPostDataV2(parameter);
  };

  const approveStatus = () => {
    const parameter = {
      url: `/api/audit_universe/questionnaire/${questionnaireID}/approve`,
      payload: {
        approved: true,
        status: "Closed",
      },
      message: true,
      setshowResponse: setshowResponseApprove,
      setErrors: setErrors,
    };
    justUpdateDataV2(parameter);

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const setSubmittedStatus = () => {

    var parameter = {};
    if (isAdminRole) {
      if(APIQData.status !== "Submitted"){
      showResponseForAdmin();
      }
      parameter = {
        url: `/api/audit_universe/questionnaire/${questionnaireID}/`,
        payload: {
          approved: false,
          status: "Submitted",
        },
        setshowResponse: () => {
          approveStatus();
        },
        setErrors: setErrors,
      };
    } else {
        
      parameter = {
        url: `/api/audit_universe/questionnaire/${questionnaireID}/`,
        payload: {
          approved: false,
          status: "Submitted",
        },
        message: true,
        setshowResponse: setshowResponseSubmit,
        setErrors: setErrors,
      };
    }

    justUpdateDataV2(parameter);
    if(!isAdminRole){
      setTimeout(() => {
      window.location.reload();
    }, 1500);
    }
  };

  const rejectStatus = () => {
    const parameter = {
      url: `/api/audit_universe/questionnaire/${questionnaireID}/approve`,
      payload: {
        approved: false,
        status: "Open",
      },
      message: true,
      setshowResponse: setshowResponseReject,
      setErrors: setErrors,
    };
    justUpdateDataV2(parameter);
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const handleSelectAll = (sectionIndex, questionIndex, columnIndex) => {
    const newSelectedColumnIndexes = [...selectedColumnIndexes];
    const newAnswers = answers.map((section, secIdx) =>
      section.map((question, quesIdx) => {
        if (secIdx === sectionIndex && quesIdx === questionIndex) {
          const allChecked = Object.keys(question).every(
            (answerId) => question[answerId] === columnIndex + 1
          );

          const updatedAnswers = Object.keys(question).reduce(
            (acc, answerId) => {
              acc[answerId] = allChecked ? 0 : columnIndex + 1;
              return acc;
            },
            {}
          );

          return updatedAnswers;
        }
        return question;
      })
    );

    newSelectedColumnIndexes[sectionIndex][questionIndex] = columnIndex;
    setAnswers(newAnswers);
    setSelectedColumnIndexes(newSelectedColumnIndexes);
  };

  const areAllAnswersFilled = () => {
    return answers.every((section) =>
      section.every((question) =>
        Object.values(question).every((answer) => answer > 0)
      )
    );
  };

  const isAtLeastOneAnswerFilled = () => {
    return answers.some((section) =>
      section.some((question) =>
        Object.values(question).some((answer) => answer > 0)
      )
    );
  };

  const areAllSelectedOptionsFilled = (data) => {
    return data.sections.every((section) =>
      section.questions.every((question) =>
        question.answers.every((answer) => answer.selected_option !== null)
      )
    );
  };

  useEffect(() => {
    setAllAnswersFilled(areAllAnswersFilled());
  }, [answers]);

  const [AllAnswerFilled, setAllAnswerFilled]=useState();
  useEffect(() => {
    setAllAnswerFilled(areAllAnswersFilled());
  }, []);



  useEffect(() => {
    setAnyOneAllAnswersFilled(isAtLeastOneAnswerFilled());
  }, [answers]);

  useEffect(() => {
    setAllSelectedOptionsFilled(areAllSelectedOptionsFilled(data));
    if (allSelectedOptionsFilled) {
      setSubmitbtn(true);
    }
  }, [data]);

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setshowResponse(false);
      }, 5000); // Clear after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowResponseReject(false);
    }, 5000); // Clear after 3 seconds

    // Clean up the timeout when the component unmounts
    return () => clearTimeout(timer);
  }, [showMessageReject]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowResponseApprove(false);
    }, 5000); 
    return () => clearTimeout(timer);
  }, [showMessageApprove]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowResponseSubmit(false);
    }, 5000); 

    return () => clearTimeout(timer);
  }, [showMessageSubmit]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowResponseSave(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [showMessageSave]);



  return (
    <>
      <div className="backToReport">
        <button
          className="cmn_btn"
          style={{
            backgroundColor:
              (APIQData?.status === "Closed" || APIQData?.status === "Submitted" || !anyOneAnswersFilled)
                ? "#7A7A7A"
                : "#65CC5C",
          }}
          onClick={showResponse}
          disabled={
            APIQData?.status === "Closed" || APIQData?.status === "Submitted" || !anyOneAnswersFilled
          }
        >
          <svg
            width="12"
            height="10"
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 4.72621L3.18182 6.90803L9 1.08984"
              stroke="white"
              stroke-width="1.45455"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Save
        </button>

        <button
          className="cmn_btn"
          style={{
            backgroundColor:
              (APIQData?.status === "Closed" || APIQData?.status === "Submitted" || !anyOneAnswersFilled)
                ? "#7A7A7A"
                : "#FE2768",
          }}
          onClick={clearResponse}
          disabled={
            APIQData?.status === "Closed" || APIQData?.status === "Submitted" || !anyOneAnswersFilled
          }
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.9 2H9.10001C8.42001 2 7.46 2.4 6.98 2.88L2.88 6.98001C2.4 7.46001 2 8.42001 2 9.10001V14.9C2 15.58 2.4 16.54 2.88 17.02L6.98 21.12C7.46 21.6 8.42001 22 9.10001 22H14.9C15.58 22 16.54 21.6 17.02 21.12L21.12 17.02C21.6 16.54 22 15.58 22 14.9V9.10001C22 8.42001 21.6 7.46001 21.12 6.98001L17.02 2.88C16.54 2.4 15.58 2 14.9 2Z"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.5 15.5L15.5 8.5"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.5 15.5L8.5 8.5"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Clear
        </button>
        <button
          onClick={handleCommentsToggle}
          className="cmn_btn cmmnt_tggle"
          style={{ backgroundColor: "#5571DF" }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 6.25V11.35C22 12.62 21.58 13.69 20.83 14.43C20.09 15.18 19.02 15.6 17.75 15.6V17.41C17.75 18.09 16.99 18.5 16.43 18.12L15.46 17.48C15.55 17.17 15.59 16.83 15.59 16.47V12.4C15.59 10.36 14.23 9 12.19 9H5.39999C5.25999 9 5.13 9.01002 5 9.02002V6.25C5 3.7 6.7 2 9.25 2H17.75C20.3 2 22 3.7 22 6.25Z"
              stroke="#fff"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.59 12.4V16.47C15.59 16.83 15.55 17.17 15.46 17.48C15.09 18.95 13.87 19.87 12.19 19.87H9.47L6.45 21.88C6 22.19 5.39999 21.86 5.39999 21.32V19.87C4.37999 19.87 3.53 19.53 2.94 18.94C2.34 18.34 2 17.49 2 16.47V12.4C2 10.5 3.18 9.19002 5 9.02002C5.13 9.01002 5.25999 9 5.39999 9H12.19C14.23 9 15.59 10.36 15.59 12.4Z"
              stroke="#fff"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {showComments ? "Hide Comments" : "Show Comments"}
        </button>
        {showSubmit && (
          <button
            className="cmn_btn"
            style={{
              backgroundColor: isAdminRole ? allAnswersFilled ? "#5571DF" : "#7A7A7A" : AllAnswerFilled && allAnswersFilled ?  "#5571DF" : "#7A7A7A" ,
            }}
            onClick={setSubmittedStatus}
            disabled={isAdminRole ? !allAnswersFilled : !AllAnswerFilled || !allAnswersFilled ? true : false}
          >
            <svg
              width="12"
              height="10"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 4.72621L3.18182 6.90803L9 1.08984"
                stroke="white"
                stroke-width="1.45455"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Submit
          </button>
        )}

        {showApproval && (
          <>
            <button
              onClick={approveStatus}
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Approve
            </button>
            <button
              className="cmn_btn"
              style={{
                display: isAdminRole ? "" : "none",
                backgroundColor: "#FE2768",
              }}
              onClick={rejectStatus}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 14.668H14"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Request Changes
            </button>
          </>
        )}
      </div>

      <div className="allqueswithsection">
        {showMessageSubmit && (
          <p className="successmessage">Submited Successfully</p>
        )}
        {showMessageSave && <p className="successmessage">Saved Successfully</p>}
        {showMessageReject && (
          <p className="successmessage">Request Changed  Successfully</p>
        )}
        {showMessageApprove && (
          <p className="successmessage">Approved Successfully</p>
        )}
        {showMessageClear && (
          <p className="successmessage">All Answer Cleared Successfully</p>
        )}

        {data.sections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="sectionDiv">
            <h3>{section.section_name}</h3>

            {section.questions.map((question, questionIndex) => (
              <div key={questionIndex} className="questionDiv">
                <div
                  className="subquestionDiv"
                  style={{ width: tableSize ? "100%" : "100%" }}
                >
                  {(() => {
                    const answersForAllUnits = Object.entries(
                      answers[sectionIndex][questionIndex]
                    );
                    const areAllAnswersSame = answersForAllUnits.every(
                      ([_, answerValue], index, array) =>
                        answerValue === array[0][1] && answerValue !== 0
                    );

                    const questionId = question.id;
                    if (answersForAllUnits[0][1] !== 0) {
                      if (areAllAnswersSame) {

                      } else {

                        answersForAllUnits.forEach(
                          ([answerId, answerValue], index) => {
                          }
                        );
                      }
                    }

                    return (
                      <div className="tableDiv">
                        <table
                          className="answerQuestionnaireTable"
                          style={{ width: "100%" }}
                        >
                          <thead className="question_head">
                            <tr>
                              <td>
                                <div className="toggle_ques">
                                  <button
                                    onClick={() =>
                                      toggleTableVisibility(
                                        sectionIndex,
                                        questionIndex
                                      )
                                    }
                                    className="questionBtnSection"
                                  >
                                    <img
                                      src={arrowToggle}
                                      alt="Toggle Arrow"
                                      className={
                                        tableVisibility[sectionIndex][
                                          questionIndex
                                        ]
                                          ? "arrowToggle"
                                          : "arrowToggle rotated"
                                      }
                                    />
                                  </button>
                                  <h6>{question.question}</h6>
                                </div>
                              </td>
                              {options.map((option, optionIndex) => (
                                <td key={optionIndex}>
                                  <div className="thOption">
                                    {option}
                                    <input
                                      type="checkbox"
                                      onClick={() =>
                                        handleSelectAll(
                                          sectionIndex,
                                          questionIndex,
                                          optionIndex
                                        )
                                      }
                                      checked={
                                        (selectedColumnIndexes[sectionIndex][
                                          questionIndex
                                        ] === optionIndex &&
                                          Object.keys(
                                            answers[sectionIndex][questionIndex]
                                          ).every(
                                            (answerId) =>
                                              answers[sectionIndex][
                                                questionIndex
                                              ][answerId] ===
                                              optionIndex + 1
                                          )) ||
                                        storedAnswers.some(
                                          (answer) =>
                                            answer.sectionIndex ===
                                              sectionIndex &&
                                            answer.questionIndex ===
                                              questionIndex &&
                                            answer.answer === optionIndex + 1
                                        )
                                      }
                                      onChange={() =>
                                        handleSelectAll(
                                          sectionIndex,
                                          questionIndex,
                                          optionIndex
                                        )
                                      }
                                      disabled={
                                        showStatus &&
                                        !showApproval &&
                                        !showSubmit
                                      }
                                    />
                                  </div>
                                </td>
                              ))}
                            </tr>
                          </thead>
                          {tableVisibility[sectionIndex][questionIndex] && (
                            <tbody>
                              {Object.entries(
                                answers[sectionIndex][questionIndex]
                              ).map(([answerId, selectedOption]) => (
                                <tr key={answerId}>
                                  <td>
                                    {(() => {
                                      const answer = question.answers.find(
                                        (answer) =>
                                          answer.id === parseInt(answerId)
                                      );
                                      return (
                                        answer?.audit_unit?.name || "Loading..."
                                      );
                                    })()}
                                  </td>
                                  {options.map((option, optionIndex) => (
                                    <td key={optionIndex}>
                                      <input
                                        type="checkbox"
                                        checked={
                                          selectedOption === optionIndex + 1
                                        }
                                        onChange={() =>
                                          handleAnswerChange(
                                            sectionIndex,
                                            questionIndex,
                                            answerId,
                                            optionIndex
                                          )
                                        }
                                        disabled={
                                          showStatus &&
                                          !showApproval &&
                                          !showSubmit
                                        }
                                      />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>

                        <div className="remarknSaveAQ">
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            className="remarkAQ"
                            placeholder="Remark"
                            value={remarks[sectionIndex][questionIndex]}
                            onChange={(e) =>
                              handleRemarkChange(
                                sectionIndex,
                                questionIndex,
                                e.target.value
                              )
                            }
                          />
                        </div>

                        {APIQData.status.toLowerCase() !== "closed" && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1vw",
                              justifyContent: "end",
                              width: "100%",
                            }}
                          />
                        )}
                      </div>
                    );
                  })()}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default QuestionsComps;
