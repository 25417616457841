import React, { useState, useEffect, useRef } from "react";
import "../schedulemeeting/schedulemeeting.css";
import SingleOptionSelector from "../../../SingleOptionSelector";
import LongMultiOptionSelector from "../../../LongMultiOptionSelector";
import {
  deleteDataV2,
  fetchDataObjectV2,
  justUpdateDataV2,
  updateDataV3,
} from "../../../apiUtils";
import { useParams, useNavigate } from "react-router-dom";
import WindowComponent from "../../../WindowComponent";
import { auditExecution } from "../../../subroutes";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";
import SingleOptionSelectorAuditBU from "../../../SingleOptionSelectorAuditBU";
import SingleOptionSelectorAuditPR from "../../../SingleOptionSelectorAuditPR";

const SchedulemeetingView = ({ tableSize, APIData, setAPIData }) => {
  const [errors, setErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [objective, setObjective] = useState(APIData.objective);
  const [objectiveError, setObjectiveError] = useState(false);
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [participantError, setParticipantError] = useState(false);
  const [assign_to_id, setAssignTo] = useState("");
  const [assignError, setAssignError] = useState(false);
  const [participants_id, setParticipants] = useState([]);
  const [APIaudit, setAudit] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedOtherKey, setSelectedOtherKey] = useState([]);
  const [other_stakeholders_ids, setOther_stakeholders_ids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scheduled_start_date, setStartDate] = useState(
    APIData.scheduled_start_date
  );
  const [successMessageUpdate, setSuccessMessageUpdate] = useState(false);
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState(APIData.scheduled_end_date);
  const [end_dateError, setEnd_dateError] = useState(false);
  const { id, planid } = useParams();
  const navigate = useNavigate();

  const updateAPIData = (e) => {
    e.preventDefault();
    if (
      !objective ||
      !preparer_id ||
      !reviewer_id ||
      !assign_to_id ||
      !scheduled_start_date ||
      !scheduled_end_date ||
      !id ||
      !participants_id
    ) {
      setObjectiveError(!objective ? true : false);
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssignError(!assign_to_id ? true : false);
      setParticipantError(!participants_id ? true : false);
      setStart_dateError(!scheduled_start_date ? true : false);
      setEnd_dateError(!scheduled_end_date ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (new Date(scheduled_end_date) < new Date(scheduled_start_date)) {
      setErrors("End Date and Time should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const Parameter = {
      url: `/api/audit_engagement/plan/${planid}`,
      payload: {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        scheduled_start_date,
        scheduled_end_date,
        plan_type: "Schedule Kick-off Meeting",
        audit_id: id,
        participants_id,
      },
      setErrors:setErrors,
      tofetch: {
        setSuccessMessage: setSuccessMessageUpdate,
        items: [
          {
            fetchurl: `/api/audit_engagement/plan/${planid}/`,
            dataset: setAPIData,
          },
        ],
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/audit-execution/${id}/planning`,
    };

    updateDataV3(Parameter);
    setEditMode(false);
  };
   const updateTask=()=>{
    if( !participants_id){
      setParticipantError(!participants_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const Parameter = {
      url: `/api/audit_engagement/plan/${planid}`,
      payload: {

        type: "Schedule Kick-off Meeting",
        task_data:"Done",
      },
      setErrors:setErrors,
      tofetch: {
        setSuccessMessage: setSuccessMessageUpdate,
        items: [
          {
            fetchurl: `/api/audit_engagement/plan/${planid}/`,
            dataset: setAPIData,
          },
        ],
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/audit-execution/${id}/planning`,
    };

    updateDataV3(Parameter);
    setEditMode(false);
   }

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);

  const handleEditClick = () => {
    if(APIData.task.status==="in progress"){

      setEditMode(true);
    }

  };

  function convertDate(inputDate) {
    const date = new Date(inputDate);

    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function handleStartDateChange(e) {
    const inputValue = e.target.value;
    if (!inputValue) {
      setStartDate(null); 
      setStart_dateError(true);
      return;
    }
  
    const localDate = new Date(inputValue);
    const utcDate = new Date(
      localDate.getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000
    );
    setStartDate(utcDate.toISOString());
    setStart_dateError(false);
  }
  
  function handleEndDateChange(e) {
    const inputValue = e.target.value;
    if (!inputValue) {
      setEndDate(null); 
      setEnd_dateError(true);
      return;
    }
  
    const localDate = new Date(inputValue);
    const utcDate = new Date(
      localDate.getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000
    );
    setEndDate(utcDate.toISOString());
    setEnd_dateError(false);
  
    if (new Date(inputValue) < new Date(scheduled_start_date)) {
      setErrors("End Date and Time should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
    }
  }
  useEffect(() => {
    if (
      APIData.data &&
      APIData.data.details &&
      APIData.data.details.participants
    ) {
      const auditSelectedKeysOther = APIData.data.details.participants;
      const otherIds = auditSelectedKeysOther.map((item) => item.id);
      const otherNames = auditSelectedKeysOther.map((item) => item.name);
      setOther_stakeholders_ids(otherIds);
      setSelectedOtherKey(otherNames);
    }
  }, [APIData]);

  const onDelete = (e) => {
    e.preventDefault();
    const parameter = {
      url: `/api/audit_engagement/plan/${planid}`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/planning`,
    };

    deleteDataV2(parameter);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  useEffect(() => {
    if (editMode) {
      if (!preparer_id) {
        setPreparerError(!preparer_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null)
        }, 5000);
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/plan/preparer/${planid}/`,
        payload: {
          preparer_id: preparer_id,
        },
        setErrors: setErrors,
      };

      justUpdateDataV2(parameter);
    }
  }, [preparer_id]);

  useEffect(() => {
    if (editMode) {
      if (!reviewer_id) {
        setReviewerError(!reviewer_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null)
        }, 5000);
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/plan/reviewer/${planid}/`,
        payload: {
          reviewer_id: reviewer_id,
        },
        setErrors: setErrors,
      };

      justUpdateDataV2(parameter);
    }
  }, [reviewer_id]);

  useEffect(() => {
    if (editMode) {
      if (!assign_to_id) {
        setAssignError(!assign_to_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null)
        }, 5000);
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/plan/assign/${planid}/`,
        payload: {
          assign_to_id: assign_to_id,
        },
        setErrors: setErrors,
      };

      justUpdateDataV2(parameter);
    }
  }, [assign_to_id]);

  const [maketrue, setTrue] = useState(false);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageUpdate && (
        <p className="successmessage">Updated SuccessFully</p>
      )}
      <RenderIcons homeRoutes={auditExecution} id={id} />

      <section className="navroutes">
        <NavRow
          name={editMode ? "Edit Schedule Meeting" : " Schedule Meeting"}
          trytue={true}
          parentName={APIaudit?.name}
        />

        <div
          className="createmeeting"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <form onDoubleClick={handleEditClick}>
            <div className="lng_lbl_inpt">
              <label>Objective</label>
              <input
                type="text"
                style={{
                  border: objectiveError ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                placeholder="Objective"
                value={objective}
                onChange={(e) => {
                  setObjective(e.target.value);
                  setObjectiveError(false);
                }}
                readOnly={!editMode}
                tabIndex={1}
              />
            </div>

            <div className="lbl_inpt">
              <label> Start Date: </label>
              <input
                type="datetime-local"
                style={{
                  border: start_dateError ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                value={
                  scheduled_start_date ? convertDate(scheduled_start_date) : ""
                }
                onChange={handleStartDateChange}
                readOnly={!editMode}
                placeholder="Select a start date"
                tabIndex={2}
              />
            </div>

            <div className="lbl_inpt">
              <label> End Date: </label>
              <input
                type="datetime-local"
                style={{
                  border: end_dateError ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                value={
                  scheduled_end_date ? convertDate(scheduled_end_date) : ""
                }
                onChange={handleEndDateChange}
                readOnly={!editMode}
                placeholder="Select an end date"
                tabIndex={3}
              />
            </div>

            <SingleOptionSelectorAuditPR
              id={id}
              setSelectorId={setPreparerId}
              setSelectorError={setPreparerError}
              selectorError={PreparerError}
              Name="name"
              title="Preparer"
              initialId={APIData.preparer ? APIData.preparer.id : ""}
              initialName={APIData.preparer ? APIData.preparer.name : ""}
              editMode={editMode}
              tabIndex={4}
              maketrue={maketrue}
              setTrue={setTrue}
            />

            <SingleOptionSelectorAuditPR
              id={id}
              setSelectorId={setReviewerId}
              setSelectorError={setReviewerError}
              selectorError={reviewerError}
              Name="name"
              title="Reviewer"
              initialId={APIData.reviewer ? APIData.reviewer.id : ""}
              initialName={APIData.reviewer ? APIData.reviewer.name : ""}
              editMode={editMode}
              tabIndex={5}
              maketrue={maketrue}
              setTrue={setTrue}
            />

            <SingleOptionSelectorAuditBU
              id={id}
              setSelectorId={setAssignTo}
              setSelectorError={setAssignError}
              selectorError={assignError}
              Name="name"
              title="Assign To"
              initialId={APIData.assign_to ? APIData.assign_to.id : ""}
              initialName={APIData.assign_to ? APIData.assign_to.name : ""}
              editMode={editMode}
              tabIndex={6}
              maketrue={maketrue}
              setTrue={setTrue}
            />

            <LongMultiOptionSelector
              apiUrl="/api/audit_universe/business-stakeholders/"
              setSelectorId={setParticipants}
              Name="name"
              title="Participants"
              initialIds={other_stakeholders_ids}
              initialNames={selectedOtherKey}
              editMode={editMode}
              tabIndex={7}
            />
          </form>

          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
              disabled={!editMode}
              onClick={updateAPIData}
              tabIndex={8}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Save
            </button>

            {editMode && (
              <button
                onClick={handleCancelClick}
                tabIndex={9}
                className="cmn_btn"
                style={{ backgroundColor: "#d6d6d6", color: "#000" }}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L10.9989 10.9989"
                    stroke="#292D32"
                    stroke-width="1.76758"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.00107 10.9989L11 1"
                    stroke="#292D32"
                    stroke-width="1.76758"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Cancel Edit
              </button>
            )}

            <WindowComponent
              onDelete={onDelete}
              tabIndex={10}
              text="Delete Meeting"
              disabled={APIData.task.status==="Done"}
              style={{backgroundColor:APIData.task.status==="Done"?'#7a7a7a':'#fe2768'}}
            />
            <button className="cmn_btn" style={{backgroundColor:APIData.task.status==="Done"?'#7a7a7a':'#5757df'}} onClick={updateTask} disabled={APIData.task.status==="Done"}>
              Send Reminder
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default SchedulemeetingView;
