import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../css/createuser.css";
import RenderIcons from "../RenderIcons";
import { postDataV2, fetchDataV2 } from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import { homeRoutes } from "../subroutes";
import MultiOptionSelector from "../MultiOptionSelector";
import CommonInput from "../CommonInput";
import NavRow from "../NavRow";
import BusinessSingleOptionSelector from "../BusinessSingleOptionSelector";
import BusinessMultiOptionSelector from "../BusinessMultiOptionSelector";

const CreateUser = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [avatarColor, setAvatarColor] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const [first_name, setfName] = useState("");
  const [last_name, setlName] = useState("");
  const [employeed_id, setEmpid] = useState("");
  const [employeed_idError, setEmpidError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordC, setPasswordC] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordCError, setPasswordCError] = useState(false);
  const [contact, setContact] = useState("");
  const [contactError, setContactError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [designation, setDesignation] = useState("");
  const [designationError, setDesignationError] = useState(false);
  const navigate = useNavigate();
  const [selectedDeptsError, setSelectedDeptsError] = useState(false);
  const [department_id, setDepartmentId] = useState("");
  const [selectedBuError, setSelectedBuError] = useState(false);
  const [business_unit_id, setBusiness_unit_id] = useState([]);
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groups_id, setRole] = useState([]);
  const [expandedRoles, setExpandedKeyStakeholder] = useState(false);
  const roleDivRef = useRef(null);
  const [initialDepratment, setInitialDepartment] = useState();

  function getDepartmentById(id) {
    const result = APIData?.find((item) => item?.id === id);
    if (result) {
      return {
        departmentId: result?.department?.id,
        departmentName: result?.department?.name,
      };
    } else {
      return `No data found for ID ${id}`;
    }
  }

  useEffect(() => {
    const parameter = {
      url: `/api/system/business-units/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter, setLoading);
  }, []);

  const getRandomColor = (letter) => {
    const colorSeed = letter.charCodeAt(0) * 137;
    const color = `hsl(${colorSeed % 360}, 70%, 50%)`;
    return color;
  };

  const handleFirstNameChange = (event) => {
    const newName = event.target.value;
    setFirstName(newName);
    setFirstNameError(false);
    setfName(newName);

    if (newName.length > 0) {
      const initialLetter = newName[0].toUpperCase();
      setAvatarColor(getRandomColor(initialLetter));
    }
  };

  const handleLastNameChange = (event) => {
    const newName = event.target.value;
    setLastName(newName);
    setLastNameError(false);
    setlName(newName);
  };

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  const postApi = (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !designation ||
      !password ||
      !passwordC
    ) {
      setFirstNameError(!firstName ? true : false);
      setPasswordError(!password ? true : false);
      setPasswordCError(!passwordC ? true : false);
      setLastNameError(!lastName ? true : false);
      setEmailError(!email ? true : false);
      setDesignationError(!designation ? true : false);
      setErrors("This field cannot be left blank");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const departmentId = parseInt(department_id, 10);
    if (password !== passwordC) {
      setErrors("Confirm Password Does Not Match");
      setPasswordError(true);
      setPasswordCError(true);
      setTimeout(() => {
        setErrors(null);
      }, 5000);
    } else {
      const parameter = {
        url: "/api/system/users/",
        payload: {
          first_name,
          last_name,
          email,
          contact,
          designation,
          employeed_id:employeed_id?employeed_id:null,
          department_id: departmentId,
          business_unit_id,
          password,
          groups_id,
        },
        navigate: navigate,
        setErrors: setErrors,
        navUrl: `/administration/users`,
      };
      if (contact && contact.length < 10 || contact.length > 15) {;
        setErrors("Contact Number Should be 10-15 Digits");
        setTimeout(() => {
          setErrors(null);
        }, 5000);
        return;
      } else {
        postDataV2(parameter);
      }
    }
  };

  const groupData = [
    { id: 2, name: "Audit Team" },
    { id: 3, name: "Business" },
    { id: 4, name: "Quality Assurance" },
    { id: 5, name: "Audit Commission" },
  ];

  const toggleCloseRoles = () => {
    setExpandedKeyStakeholder(false);
  };

  const handleClickOutside = (event) => {
    if (roleDivRef.current && !roleDivRef.current.contains(event.target)) {
      setExpandedKeyStakeholder(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".subDiv") &&
        !event.target.closest(".addbusiness-roles")
      ) {
        toggleCloseRoles();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (business_unit_id) {
      setInitialDepartment(getDepartmentById(business_unit_id[0]));
    }
  }, [business_unit_id]);

  useEffect(() => {
    if (department_id && maketrue) {
      setBusiness_unit_id("");
    }
  }, [department_id]);
  const [maketrue, setTrue] = useState(false);

  return (
    <>
      {errors && <p className="errormessage">{errors.split("\n")[0]}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={homeRoutes} />

        <NavRow name={"Create User"} idtrue={true} />

        <div
          className="userconatiner"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <form>
            <div className="lbl_inpt">
              <label>First Name </label>
              <input
                style={{
                  border: firstNameError ? "1px solid #FF4646" : "",
                }}
                required
                type="text"
                placeholder="John"
                value={firstName}
                id="firstName"
                onChange={handleFirstNameChange}
                tabIndex={1}
              />
            </div>
            <div className="lbl_inpt">
              <label>Last Name</label>
              <input
                style={{
                  border: lastNameError ? "1px solid #FF4646" : "",
                }}
                type="text"
                placeholder="Doe"
                value={lastName}
                id="firstName"
                onChange={handleLastNameChange}
                tabIndex={2}
              />
            </div>
            <BusinessMultiOptionSelector
              apiUrl="/api/system/business-units/"
              setSelectorId={setBusiness_unit_id}
              setSelectorError={setSelectedBuError}
              selectorError={selectedBuError}
              Name="name"
              title="Business Unit"
              editMode={editMode}
              makeTrue={maketrue}
              setTrue={setTrue}
              tabIndex={3}
            />
            <CommonInput
              labelName="Email"
              error={emailError}
              type="email"
              placeHolder="noahfrost@example.com"
              setText={setEmail}
              setTextError={setEmailError}
              commanDivClass="lbl_inpt"
              tabIndex={4}
            />
            <CommonInput
              labelName="Password"
              error={passwordError}
              type="password"
              placeHolder="***********"
              setText={setPassword}
              setTextError={setPasswordError}
              commanDivClass="lbl_inpt"
              tabIndex={5}
            />
            <CommonInput
              labelName="Confirm Password"
              error={passwordCError}
              type="password"
              placeHolder="***********"
              setText={setPasswordC}
              setTextError={setPasswordCError}
              setPasswordError={setPasswordError}
              commanDivClass="lbl_inpt"
              tabIndex={6}
            />
            <MultiOptionSelector
              setSelectorId={setRole}
              Name="name"
              title="Role"
              placeHolder="User Role"
              rolesData={groupData}
              editMode={editMode}
              tabIndex={7}
            />
            <CommonInput
              labelName="Contact"
              error={contactError}
              type="number"
              placeHolder="+966 500000000"
              setText={setContact}
              setTextError={setContactError}
              commanDivClass="lbl_inpt"
              tabIndex={8}
            />
            <SingleOptionSelector
              apiUrl="/api/system/departments/"
              setSelectorId={setDepartmentId}
              setSelectorError={setSelectedDeptsError}
              selectorError={selectedDeptsError}
              Name="name"
              title="Department"
              editMode={editMode}
              initialId={initialDepratment?.departmentId}
              initialName={initialDepratment?.departmentName}
              setTrue={setTrue}
              maketrue={maketrue}
              tabIndex={9}
            />
            <CommonInput
              labelName="Designation"
              error={designationError}
              type="text"
              placeHolder="Sr. Expert - Finance"
              setText={setDesignation}
              setTextError={setDesignationError}
              commanDivClass="lbl_inpt"
              tabIndex={10}
            />{" "}
            <CommonInput
              labelName="Emp Id"
              error={employeed_idError}
              type="text"
              placeHolder="E000"
              setText={setEmpid}
              setTextError={setEmpidError}
              commanDivClass="lbl_inpt"
              tabIndex={11}
            />
          </form>

          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={postApi}
              tabIndex={12}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create User
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateUser;
