import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AuditReportingSeclector from "../AuditReportingSelector";
import MultiOptionWorkPaper from "../../../MultiOptionWorkPaper";
import "../Observations/observationgrid.css";
import "../Observations/observation.css";
import {
  convertDateTime,
  fetchDataV2,
  postDataWithFetchV2,
  updateDataV3,
} from "../../../apiUtils";
import { useNavigate } from "react-router-dom";
import sendComment from "../../../../images/send.svg";
import SuperAvatar from "../../../SuperAvatar";
import SingleOptionSelectorAuditPR from "../../../SingleOptionSelectorAuditPR";
import SingleOptionSelectorAuditBU from "../../../SingleOptionSelectorAuditBU";

const ObservationForm = ({
  APIaudit,
  obejctionId,
  observationUrl,
  setEditMode,
  editMode,
  handleEditClick,
  subObservationsdata,
  isPreparerMismatch,
  isReviewerMismatch,
  setAPIData,
  setActionPlanError,
  APIData,
  editModeBusiness,
  setTeammate_id,
  setTeammate_idError,
  teammate_idError,
  businessRole,
  tableSize,
  setPreparerId,
  setPreparerError,
  PreparerError,
  setReviewerId,
  setReviewerError,
  reviewerError,
  setQAIDError,
  setQAID,
  qa_idError,
  auditData,
  setLevel1Owner,
  setLevel1OwnerError,
  level_1_ownerError,
  displayStatus,
  objective,
  setTitle,
  rating,
  setObservationRating,
  short_description,
  shortDescriptionError,
  setShortDescription,
  setShortDescriptionError,
  businessOnlyRole,
  setWorkPIdError,
  workPIdError,
  workPId,
  workPojcts,
  setTestIds,
  testIds,
  item,
  setItem,
  summary,
  setSummary,
  handleAdd,
  blue_add_button,
  inputList,
  handleChange,
  handleSave,
  savesubObv,
  handleCancel,
  remove_button,
  handleRemove,
  handlepopup,
  fileData,
  cutbutton,
  handleDeleteModalOpen,
  OptionDropDown2,
  exposure_to_losses,
  setExposureLosses,
  potential_impact,
  setPotentialImpact,
  likelyhood,
  setLikelihood,
  risk1,
  setRisk01,
  risk2,
  setRisk02,
  risk3,
  setRisk03,
  recommendation,
  setRecommendation,
  action_plan,
  setActionPlan,
  action_planError,
  setLevel2Owner,
  setLevel2OwnerError,
  level_2_ownerError,
  convertDate,
  management_response_due_date,
  setActionDueDate,
  management_response_due_dateError,
  response_audit,
  setAuditResponse,
  response_audit_due_date,
  setResponseActionDueDate,
  preliminary_close_observation,
  setPreliminaryCloseObservation,
  preliminary_close_observationError,
  taskId,
  commentData,
  setComment,
  tabIndex,
  successMessageFileUpload,
  successMessageFileDelete,
  successMessagedeleteobs,
  successMessageUpdate,
  successMessageSubmit,
  successMessageobs,
  successMessageAccept,
  successMessageReject,
  successMessageRequest,
  successMessageClosed,
  successMessageFinalize,
  isDeleteModalOpen,
  confirmDeleteFile,
  handleDeleteModalClose,
  isModalOpen,
  handleCloseClick,
  createBtn,
  setSelectedFile,
  fileObjective,
  setFileObjective,
  selectedFile,
  handleFileUpload,
}) => {
  const commentRef = useRef(null);
  const [taskData, setTaskData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);
  const navigate = useNavigate();
  const [APIQData, setAPIQData] = useState("");
  const [errors, setErrors] = useState("");
  const avaUserName = localStorage.getItem("userName");
  const lastName = localStorage.getItem("userLastName");
  const [successMessageQuestion, setSuccessMessage] = useState(false);
  const [activitylog, setActivityLog] = useState([]);
  const [isComments, setIsComments] = useState(true);

  console.log(subObservationsdata);

  const { id } = useParams();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/system/activity/?action_on=Observation&action_on_id=${obejctionId}`,
      setterFunction: setActivityLog,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);
  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);
      const parameter = {
        url: `/api/system/comments/`,
        payload: {
          user_id: localStorage.getItem("user_id"),
          task_id: taskId,
          text: inputValue,
        },
        tofetch: {
          items: [
            {
              fetchurl: `/api/system/comments/?task_id=${taskId} `,
              dataset: setComment,
            },
          ],
          setSuccessMessage: setSuccessMessage,
          setErrors: setErrors,
        },
      };

      postDataWithFetchV2(parameter);
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };

  const RatingOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
    { label: "Very High", value: "Very High" },
    { label: "Critical", value: "Critical" },
  ];

  const options = [
    { label: " Financial", value: "Financial" },
    { label: "Operational", value: "Operational" },
    { label: "Strategic", value: "Strategic" },
    { label: "Compliance", value: "Compliance" },
    { label: "Reputational", value: "Reputational" },
  ];

  const PotetntialImpactOptions = [
    { label: "Low", value: 1 },
    { label: "Medium", value: 2 },
    { label: "High", value: 3 },
    { label: "Very High", value: 4 },
    { label: "Critical", value: 5 },
  ];

  const LikeOptions = [
    { label: "Low", value: 1 },
    { label: "Medium", value: 2 },
    { label: "High", value: 3 },
    { label: "Very High", value: 4 },
    { label: "Critical", value: 5 },
  ];

  const [maketrue, setTrue] = useState(false);

  const showStatusColor = (status) => {
    switch (status) {
      case "Initial Draft":
        return <span className="status_todo">Initial Draft</span>;
      case "Initial Draft Admin Approve":
        return <span className="status_todo">Initial Draft - Admin Approval</span>;
      case "Initial Draft Qa Approve":
        return <span className="status_todo">Initial Draft – QA Approval</span>;
      case "Initial Draft Business Approve":
        return <span className="status_inprogress">Initial Draft - Business Approve</span>;
      case "Approved Draft":
        return <span className="status_inprogress">Approved Draft</span>;
      case "Finalized Draft":
        return <span className="status_inprogress">Finalized Draft</span>;
      // case "Finalized Draft Business Approve":
      //   return <span className="status_inprogress">{status}</span>;
      // case "Finalized Draft Auditor Approve":
      //   return <span className="status_inprogress">{status}</span>;
      case "Generate Report":
        return <span className="status_complete">Generate Report</span>;
      case "Finalized Draft Qa Approve":
        return <span className="status_complete">Finalized Draft - Qa Approval</span>;
      case "Finalized Draft Admin Approve":
        return <span className="status_complete">Finalized Draft - Admin Approval</span>;
      case "Closed":
        return <span className="status_veryhigh">Closed</span>;
      case "Rejected":
        return <span className="status_critical">Rejected</span>;

      default:
        return <span className="status_rejected">No Status Found</span>;
    }
  };

  return (
    <>
      {successMessageQuestion && (
        <p className="successmessage">Comment Added Successfully</p>
      )}
      {successMessageFileUpload && (
        <p className="successmessage">Image Uploaded Successfully</p>
      )}

      {successMessageFileDelete && (
        <p className="successmessage">Image Deleted Successfully</p>
      )}
      {successMessagedeleteobs && (
        <p className="successmessage">Sub Observation Deleted Successfully</p>
      )}

      {successMessageUpdate && (
        <p className="successmessage">Updated Successfully</p>
      )}

      {successMessageSubmit && (
        <p className="successmessage">Submitted Successfully</p>
      )}
      {successMessageobs && (
        <p className="successmessage">Sub Observation Created</p>
      )}
      {successMessageAccept && <p className="successmessage">Accepted</p>}
      {successMessageReject && <p className="errormessage">Rejected</p>}
      {successMessageRequest && (
        <p className="successmessage">Requested Changes</p>
      )}
      {successMessageClosed && (
        <p className="successmessage">Closed Observation</p>
      )}
      {successMessageFinalize && (
        <p className="successmessage">Observation Finalized</p>
      )}

      {isDeleteModalOpen && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Are you sure you want to delete this file?</p>
            <div className="subpopupBtnDiv">
              <button onClick={confirmDeleteFile}> Delete</button>
              <button onClick={handleDeleteModalClose}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="popUPModal">
          <div onClick={handleCloseClick} className="closeBtnModalDiv">
            <img src={cutbutton} />
          </div>

          <div className="lbl_txa">
            <div className="ap-top-div">
              <label>Image Description</label>
              <img src={createBtn} />
              <div className="fileUpBtn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.type.startsWith("image/")) {
                      setSelectedFile(file);
                      setErrors("");
                    } else {
                      setErrors("Please upload only image files.");
                      e.target.value = "";
                    }
                  }}
                />
              </div>
            </div>
            <textarea
              type="text"
              placeholder="Description of the image"
              value={fileObjective}
              onChange={(e) => {
                setFileObjective(e.target.value);
              }}
            />
          </div>

          <div className="actionPlanBtnDiv">
            <h6>
              {selectedFile
                ? `Selected file: ${selectedFile.name}`
                : "No file selected"}
            </h6>
            <button className="actionPlanSubmitBtn" onClick={handleFileUpload}>
              Upload
            </button>
          </div>
        </div>
      )}
      <div className="grid_container">
        {/* 6 items that take up 1 cell each */}

        {/* 1 cell */}
        <div className="grid_item">
          <form>
            <div className="label_selector">
              <h3>Status</h3>

             {showStatusColor(displayStatus)}
            </div>
            <div className="lng_lbl_inpt">
              <label>Title</label>
              <input
                type="text"
                placeholder="Title"
                value={objective}
                readOnly={!editMode}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                tabIndex={1}
              />
            </div>
            <SingleOptionSelectorAuditPR
              id={id}
              setSelectorId={setPreparerId}
              setSelectorError={setPreparerError}
              selectorError={PreparerError}
              Name="name"
              title="Preparer"
              editMode={editMode}
              initialId={APIData.preparer ? APIData.preparer.id : ""}
              initialName={APIData.preparer ? APIData.preparer.name : ""}
              tabIndex={2}
              setTrue={setTrue}
              maketrue={maketrue}
            />
            <SingleOptionSelectorAuditPR
              id={id}
              setSelectorId={setReviewerId}
              setSelectorError={setReviewerError}
              selectorError={reviewerError}
              Name="name"
              title="Reviewer"
              editMode={editMode}
              initialId={APIData?.reviewer ? APIData?.reviewer.id : ""}
              initialName={APIData?.reviewer ? APIData?.reviewer.name : ""}
              tabIndex={3}
              setTrue={setTrue}
              maketrue={maketrue}
            />
            {(observationUrl === "qaapprovalinitialobservation" ||
              observationUrl === "qaapprovalfinalizeobservation") && (
              <AuditReportingSeclector
                apiUrl="/api/audit_universe/quality-assurance/"
                setSelectorId={setQAID}
                setSelectorError={setQAIDError}
                selectorError={qa_idError}
                Name="name"
                title="Q.A."
                editMode={false}
                initialId={APIaudit?.qa ? APIaudit?.qa.id : ""}
                initialName={APIaudit?.qa ? APIaudit?.qa.name : ""}
                tabIndex={4}
              />
            )}
            {observationUrl === "businessownerapproval" && (
              <SingleOptionSelectorAuditBU
                id={id}
                setSelectorId={setLevel1Owner}
                setSelectorError={setLevel1OwnerError}
                selectorError={level_1_ownerError}
                Name="name"
                title="Business Owner Approval"
                editMode={editMode}
                initialId={APIData?.assign_to ? APIData?.assign_to.id : ""}
                initialName={APIData?.assign_to ? APIData?.assign_to.name : ""}
                tabIndex={5}
                setTrue={setTrue}
                maketrue={maketrue}
              />
            )}
            {observationUrl === "businessownerapproval" && (
              <SingleOptionSelectorAuditBU
                id={id}
                setSelectorId={setTeammate_id}
                setSelectorError={setTeammate_idError}
                selectorError={teammate_idError}
                Name="name"
                title="Assign To Business  Teammate"
                editMode={editMode}
                initialId={
                  APIData.assign_to_business_teammate
                    ? APIData.assign_to_business_teammate.id
                    : ""
                }
                initialName={
                  APIData.assign_to_business_teammate
                    ? APIData.assign_to_business_teammate.name
                    : ""
                }
                setTrue={setTrue}
                maketrue={maketrue}
                tabIndex={6}
              />
            )}
            <OptionDropDown2
              tableSize={tableSize}
              label="Observation Rating"
              inputType="text"
              inputPlaceholder="Title"
              inputStyle={{
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
              inputValue={rating}
              onInputChange={(e) => {
                if (editMode) {
                  setObservationRating(e.target.value);
                }
              }}
              options={RatingOptions}
              editMode={editMode}
              tabIndex={7}
            />
            <div className="lbl_txa">
              <label>Short Description</label>
              <textarea
                type="text"
                placeholder="Short Description"
                value={short_description}
                style={{
                  // border: shortDescriptionError ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                readOnly={!editMode}
                onChange={(e) => setShortDescription(e.target.value)}
                tabIndex={8}
              />{" "}
            </div>{" "}
          </form>
        </div>

        {/* 2 cell */}
        <div className="grid_item">
          <MultiOptionWorkPaper
            apiUrl={`/api/audit_engagement/workpaper/?audit_id=${APIData?.audit?.id}`}
            setSelectorError={setWorkPIdError}
            selectorError={workPIdError}
            title="Select Workpaper"
            Name="objective"
            initialIds={workPId}
            initialNames={workPojcts}
            setTestIds={setTestIds}
            testIds={testIds}
            editMode={!editMode}
            item={item}
            setItem={setItem}
            tabIndex={9}
          />
        </div>

        {/* 3 cell */}
        <div className="grid_item">
          <div className="lbl_txa">
            <div className="des_ai_button">
              <label>Description</label>

              <div className="label_askai_button">
                <button>Ask AI</button>
              </div>
            </div>

            <textarea
              type="text"
              placeholder="Description"
              value={summary}
              readOnly={!editMode}
              onChange={(e) => setSummary(e.target.value)}
              style={{
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
              tabIndex={10}
            />
          </div>

          <div className="subSummaryDiv">
            <div className="subSummaryDiv_head">
              <h3>Sub Observation</h3>

              <div className="addObv">
                <button
                  type="button"
                  className="addSubObv"
                  disabled={!editMode}
                  tabIndex={11}
                  onClick={handleAdd}
                >
                  <img src={blue_add_button} />
                </button>
              </div>
            </div>

            {inputList.map((input, index) => (
              <div key={index}>
                {input.isEditing ? (
                  <>
                    <div className="addsubObvDiv" style={{ gap: "1vw" }}>
                      <textarea
                        type="text"
                        value={input.value}
                        onChange={(e) => handleChange(e, index)}
                      />
                      <div className="addsubBtnDiv">
                        <button
                          type="button"
                          className="saveSubObv"
                          onClick={() => handleSave(index)}
                          style={{
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <img src={savesubObv} />
                        </button>
                        <button
                          type="button"
                          className="cancelSubObv"
                          onClick={() => handleCancel(index)}
                        >
                          <img src={remove_button} />
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            ))}
            {subObservationsdata?.map((subobs, index) => (
              <div
                key={subobs.id}
                className="remove_sunobspara"
                style={{ backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6" }}
              >
                <p>{subobs.management_response}</p>
                <button
                  type="button"
                  disabled={!editMode}
                  onClick={() => handleRemove(index, subobs.id)}
                >
                  <img src={remove_button} />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* 4 cell */}
        <div className="grid_item">
          <h2
            style={{
              fontSize: "14px",
              fontFamily: "interbold",
              marginBottom: "24px",
            }}
          >
            Risk Assessment
          </h2>

          <form>
            <OptionDropDown2
              tableSize={tableSize}
              label="Exposure to Losses"
              inputType="text"
              inputPlaceholder="Title"
              inputStyle={{
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
              inputValue={exposure_to_losses}
              onInputChange={(e) => {
                if (editMode) {
                  setExposureLosses(e.target.value);
                }
              }}
              options={options}
              editMode={editMode}
              tabIndex={12}
            />

            <OptionDropDown2
              tableSize={tableSize}
              label="Potential Impact"
              inputType="text"
              inputPlaceholder="Title"
              inputStyle={{
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
              inputValue={parseInt(potential_impact)}
              onInputChange={(e) => {
                if (editMode) {
                  setPotentialImpact(e.target.value);
                }
              }}
              options={PotetntialImpactOptions}
              editMode={editMode}
              tabIndex={13}
            />

            <OptionDropDown2
              tableSize={tableSize}
              label="Likelihood"
              inputType="text"
              inputPlaceholder="Title"
              inputStyle={{
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
              inputValue={parseInt(likelyhood)}
              onInputChange={(e) => {
                if (editMode) {
                  setLikelihood(e.target.value);
                }
              }}
              options={LikeOptions}
              editMode={editMode}
              tabIndex={14}
            />

            <div className="lng_lbl_inpt">
              <label>Risk 01</label>
              <input
                type="text"
                placeholder="Risk 01"
                value={risk1}
                readOnly={!editMode}
                onChange={(e) => setRisk01(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                tabIndex={15}
              />
            </div>

            <div className="lng_lbl_inpt">
              <label>Risk 02</label>
              <input
                type="text"
                placeholder="Risk 02"
                value={risk2}
                readOnly={!editMode}
                onChange={(e) => setRisk02(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                tabIndex={16}
              />
            </div>

            <div className="lng_lbl_inpt">
              <label>Risk 03</label>
              <input
                type="text"
                placeholder="Risk 03"
                value={risk3}
                readOnly={!editMode}
                onChange={(e) => setRisk03(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                tabIndex={17}
              />
            </div>
          </form>
        </div>

        {/* 5 cell */}
        {!(observationUrl === "finalizeobservation" ||
          observationUrl === "generatereportlist") && (
          <div className="grid_item">
            <div className="obs_file_upload_header">
              <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>
                Image Upload
              </h2>

              <button
                style={{
                  backgroundColor:
                    APIData?.status === "Closed" ? "#7A7A7A" : "#5771DF",
                }}
                className="cmn_btn"
                tabIndex={18}
                onClick={() => handlepopup()}
                disabled={APIData?.status === "Closed"}
              >
                Select Images
              </button>
            </div>

            {fileData?.map((file, index) => (
              <div key={index} className="main-fileData">
                <div className="fileData">
                  <div className="fileData-name">
                    {file?.documents?.filename}
                  </div>
                  <div className="delete-image-file-btn">
                    <img
                      src={cutbutton}
                      onClick={() =>
                        handleDeleteModalOpen(
                          file?.documents?.associated_with_id
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {!(observationUrl === "finalizeobservation" ||
          observationUrl === "generatereportlist") && (
          <div className="comment_section fw_grid_item">
            <div className="heading_toggle_aq">
              <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>
                Comments & Activity
              </h2>

              <div className="togglecon">
                <div
                  className="toggle-option"
                  onClick={() => setIsComments(true)}
                  style={{
                    backgroundColor: isComments ? "#fff" : "",
                    borderRadius: "999px",
                  }}
                  tabIndex={19}
                >
                  Comments
                </div>

                <div
                  className="toggle-option"
                  onClick={() => setIsComments(false)}
                  style={{
                    backgroundColor: !isComments ? "#fff" : "",
                    borderRadius: "999px",
                  }}
                  tabIndex={20}
                >
                  Activity
                </div>
              </div>
            </div>

            {isComments ? (
              <>
                <div className="initial_comment">
                  <input
                    type="text"
                    placeholder="Enter a comment..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    style={{ width: "90%" }}
                    tabIndex={21}
                  />
                  <div className="sendComm">
                    <img
                      onClick={handleAddComment}
                      src={sendComment}
                      alt="Send Comment"
                    />
                  </div>
                </div>

                <div className="all_comments">
                  {commentData
                    ?.slice()
                    .reverse()
                    .map((comment, index) => {
                      const { text, date, time } = comment;
                      return (
                        <div className="comment_wrapper_" key={index}>
                          <div className="comment_head">
                            <div className="avatar_name_AQ">
                              <SuperAvatar
                                firstName={
                                  comment.user
                                    ? comment.user.name
                                    : `${avaUserName} ${lastName}`
                                }
                                classOfAvatar="Avtaraudit"
                              />
                              <h5>
                                {comment.user
                                  ? comment.user.name
                                  : `${avaUserName} ${lastName}`}
                              </h5>
                            </div>

                            <div className="time_date_AQ">
                              <span>
                                <sub>{convertDateTime(comment.created_at)}</sub>
                              </span>
                            </div>
                          </div>
                          <div className="comment_AQ_observation">
                            <p>{text}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : (
              <div className="all_comments">
                {activitylog
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((activity, index) => (
                    <div className="activity_wrapper_com">
                      <div className="activity_head">
                        <span className="status_complete">
                          {activity.action}
                        </span>
                        <div className="time_date_AQ">
                          <span>
                            <sub>{convertDate(activity.created_at)}</sub>
                          </span>
                        </div>
                      </div>

                      <div className="activity_AQ">
                        <div className="activity_transaction">
                          <div className="avatar_name_AQ">
                            <SuperAvatar
                              firstName={activity.action_by_id.name}
                              classOfAvatar="Avtaraudit"
                            />
                            <h5>{activity.action_by_id.name}</h5>
                          </div>

                          <h4 style={{ wordWrap: "break-word" }}>
                            {activity.log.length > 30
                              ? `${activity.log.substring(0, 30)}...`
                              : activity.log}
                          </h4>

                          {activity.action !== "CREATE" &&
                            activity.action !== "UPDATE" && (
                              <div className="avatar_name_AQ">
                                <SuperAvatar
                                  firstName={"Affan Shaikh"}
                                  classOfAvatar="Avtaraudit"
                                />
                                <h5> Affan Shaikh </h5>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}

        {/* 4 items that span 2 cells each */}
        <div className="grid_item grid_item_span_2">
          <div className="lbl_txa">
            <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>
              Recommendation
            </h2>
            <textarea
              type="text"
              placeholder="Recommendation"
              value={recommendation}
              readOnly={!editMode}
              onChange={(e) => setRecommendation(e.target.value)}
              style={{
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
              tabIndex={22}
            />
          </div>
        </div>

        <div className="grid_item grid_item_span_2">
          <h2
            style={{
              fontSize: "14px",
              fontFamily: "interbold",
              marginBottom: "24px",
            }}
          >
            Management Response
          </h2>

          <form>
            <div className="lbl_txa">
              <label>Action Plan</label>
              <textarea
                type="text"
                placeholder="Action Plan"
                value={action_plan}
                readOnly={!(businessOnlyRole && editMode)}
                onChange={(e) => {
                  setActionPlan(e.target.value)
                  setActionPlanError(false)
                  }}
                style={{
                  backgroundColor: !(businessOnlyRole && editMode)
                    ? "#e6e6e6"
                    : "#EEF1FC",
                  border: action_planError ? "1px solid #FF4646" : "",
                }}
                tabIndex={23}
              />
            </div>

            <SingleOptionSelectorAuditBU
              id={id}
              setSelectorId={setLevel1Owner}
              setSelectorError={setLevel1OwnerError}
              selectorError={level_1_ownerError}
              Name="name"
              title="Level 1 Owner"
              editMode={businessOnlyRole ? editMode : ""}
              initialId={APIData?.assign_to ? APIData?.assign_to.id : ""}
              initialName={APIData?.assign_to ? APIData?.assign_to.name : ""}
              setTrue={setTrue}
              maketrue={maketrue}
              tabIndex={24}
            />

            <SingleOptionSelectorAuditBU
              id={id}
              setSelectorId={setLevel2Owner}
              setSelectorError={setLevel2OwnerError}
              selectorError={level_2_ownerError}
              Name="name"
              title="Level 2 Owner"
              editMode={businessOnlyRole ? editMode : ""}
              initialId={
                APIData?.secondary_assign_to
                  ? APIData?.secondary_assign_to.id
                  : ""
              }
              initialName={
                APIData?.secondary_assign_to
                  ? APIData?.secondary_assign_to.name
                  : ""
              }
              setTrue={setTrue}
              maketrue={maketrue}
              tabIndex={25}
            />

            <div className="lbl_inpt">
              <label>Due Date</label>
              <input
                type="date"
                value={convertDate(management_response_due_date)}
                readOnly={!(businessOnlyRole && editMode)}
                onChange={(e) => setActionDueDate(e.target.value)}
                style={{
                  backgroundColor: !(businessOnlyRole && editMode)
                    ? "#e6e6e6"
                    : "#EEF1FC",
                  border: management_response_due_dateError
                    ? "1px solid #FF4646"
                    : "",
                }}
                tabIndex={26}
              />
            </div>
          </form>
        </div>

        <div className="grid_item grid_item_span_2">
          <h2
            style={{
              fontSize: "14px",
              fontFamily: "interbold",
              marginBottom: "24px",
            }}
          >
            Internal Audit Response
          </h2>
          <form>
            <div className="lbl_txa">
              <label>Audit Response</label>
              <textarea
                type="text"
                placeholder="Internal Audit Response"
                value={response_audit}
                readOnly={!(!businessOnlyRole && editMode)}
                onChange={(e) => setAuditResponse(e.target.value)}
                style={{
                  backgroundColor: !(!businessOnlyRole && editMode)
                    ? "#e6e6e6"
                    : "#EEF1FC",
                }}
                tabIndex={27}
              />
            </div>

            <div className="lbl_inpt">
              <label>Due Date</label>
              <input
                type="date"
                value={convertDate(response_audit_due_date)}
                readOnly={!(!businessOnlyRole && editMode)}
                onChange={(e) => setResponseActionDueDate(e.target.value)}
                style={{
                  backgroundColor: !(!businessOnlyRole && editMode)
                    ? "#e6e6e6"
                    : "#EEF1FC",
                }}
                tabIndex={28}
              />
            </div>
          </form>
        </div>

        <div className="grid_item grid_item_span_2">
          <h2
            style={{
              fontSize: "14px",
              fontFamily: "interbold",
              marginBottom: "24px",
            }}
          >
            Preliminary Close Observation
          </h2>

          <div className="lbl_txa">
            <label>Audit Response</label>
            <textarea
              type="text"
              placeholder="Preliminary Close Observation"
              value={preliminary_close_observation}
              readOnly={!editMode}
              onChange={(e) => setPreliminaryCloseObservation(e.target.value)}
              style={{
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                border: preliminary_close_observationError
                  ? "1px solid #FF4646"
                  : "",
              }}
              tabIndex={29}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ObservationForm;
