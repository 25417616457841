import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../css/top.css";
import notify from "../images/notify.svg";
import user from "../images/user.svg";
import department from "../images/department.svg";
import BU from "../images/BU.svg";
import cutbtn from "../images/cutbutton.svg";
import SidebarMenu from "./SidebarMenu";
import { auditRoutes, homeRoutes } from "./subroutes";
import SuperAvatar from "./SuperAvatar";
import AccountWindow from "../components/AccountWindow"; // Import the AccountWindow component
import notify_mobile_icon from "../images/notification_mobile_icon.svg";
import { FaCog, FaSignOutAlt } from "react-icons/fa";
import "../css/sidebar.css";
import { deleteDataWithFetchV2, fetchDataV2, updateDataV3 } from "./apiUtils";
let routes = [];

const Top = ({ isOpen }) => {
  const [showSide, setShowSide] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [notificationPopup, setNotificationPopup] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [Errors, setErrors] = useState("");
  const [successMessageDelete, setSuccessMessageDelete] = useState(false);

  const [showAccountWindow, setShowAccountWindow] = useState(false); // State to manage the display of AccountWindow
  const accountWindowRef = useRef(null); // Create a reference for the AccountWindow
  // const [isOpen, setIsOpen] = useState(true);
  // const toggle = () => {
  //   handleChange(!isOpen);
  //   setIsOpen(!isOpen)
  // };
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const heading = url.pathname.split("/")[1];

  const username = localStorage.getItem("userName");
  const userid = localStorage.getItem("user_id");
  const userLastName = localStorage.getItem("userLastName");
  const designation = localStorage.getItem("designation");
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
    const dropdownRef = useRef(null);
    const dropdownRef1 = useRef(null);
  

  useEffect(() => {
    const parameter = {
      url: `/api/system/notification/?action_by_user_id=${userid}`,
      setterFunction: setNotificationData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  const toggleSide = () => {
    navigate(-1);
  };
  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowDropdown(false);
        }
        if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
          setNotificationPopup(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

  const avaUserName = localStorage.getItem("userName");

  let titleHeading;
  switch (heading) {
    case "home":
      routes = homeRoutes;
      titleHeading = "Dashboard";
      break;
    case "Administration":
      routes = homeRoutes;
      titleHeading = "Administration";
      break;
    case "AuditUniverse":
      routes = auditRoutes;
      titleHeading = "Audit Universe";
      break;
    case "AuditExecution":
      routes = [];
      titleHeading = "Audit Execution";
      break;
    case "issuetracking":
      routes = [];
      titleHeading = "Issue Tracking";
      break;
    default:
      console.log("");
  }

  const clearStorage = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.clear();
    window.location.href = "/";
  };

  const handleAccountClick = () => {
    setShowAccountWindow(!showAccountWindow);
  };

  // Handle clicks outside of AccountWindow
  const handleClickOutside = (event) => {
    if (
      accountWindowRef.current &&
      !accountWindowRef.current.contains(event.target)
    ) {
      setShowAccountWindow(false);
    }
  };

  useEffect(() => {
    if (showAccountWindow) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener when component is unmounted or when AccountWindow is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAccountWindow]);

  const deleteNotification = (notid) => {
    const parameter = {
      url: `/api/system/notification/${notid}/`,
      navigate: navigate,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/system/notification/?action_by_user_id=${userid}`,
            dataset: setNotificationData,
          },
        ],
        setSuccessMessage: setSuccessMessageDelete,
        setErrors: setErrors,
      },
    };

    deleteDataWithFetchV2(parameter);
  };
  const markasread = (notid) => {
    const parameter = {
      url: `/api/system/notification/update/${notid}`,
      payload: {
        viewed: true,
      },
      navigate: navigate,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/system/notification/?action_by_user_id=${userid}`,
            dataset: setNotificationData,
          },
        ],
        setSuccessMessage: setSuccessMessageDelete,
        setErrors: setErrors,
      },
    };

    updateDataV3(parameter);
  };

  return (
    <>
      {successMessageDelete && (
        <p className="successmessage">Notification Deleted Successfully</p>
      )}
      <div className="top">
        <div className="top_left">
          <div className="hamburgercontainer" onClick={toggleSide}>
            <svg
              width="9"
              height="18"
              viewBox="0 0 9 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00009 16.92L1.48009 10.4C0.710088 9.62996 0.710088 8.36996 1.48009 7.59996L8.00009 1.07996"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"

              />
            </svg>
          </div>
        </div>

        <div className="top_right">
          <div
            className="notaacc"
            onClick={(e) => {
              e.stopPropagation();
              setShowDropdown(!showDropdown);
            }}
            style={{
              flexDirection: isOpen ? "row" : "column",
              background: isOpen ? "" : "transparent",
              border: isOpen ? "" : "none",
            }}
          >
            <div className="avatar_name_sidebar">
              <div
                className="acc"
                // onClick={handleAccountClick}
              >
                <SuperAvatar
                  firstName={avaUserName}
                  classOfAvatar={"top_avatar"}
                />
              </div>

              <div
                className="pers_name"
                style={{ display: isOpen ? "" : "none" }}
              >
                <h2>{username + " " + userLastName}</h2>
                <h2>{designation}</h2>
              </div>
            </div>

            <img className="notify_mobile_icon" src={notify_mobile_icon} />
     
            <svg  onClick={(e) => {
                e.stopPropagation();
                setNotificationPopup(!notificationPopup);
              }} width="23" height="23" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5324 2.26953C7.1995 2.26953 3.67822 5.79081 3.67822 10.1237V13.9068C3.67822 14.7053 3.33787 15.9227 2.93208 16.6033L1.4267 19.1036C0.497291 20.6482 1.13871 22.3631 2.84044 22.939C8.48234 24.824 14.5693 24.824 20.2112 22.939C21.7951 22.4154 22.4889 20.5435 21.6249 19.1036L20.1196 16.6033C19.7269 15.9227 19.3865 14.7053 19.3865 13.9068V10.1237C19.3865 5.8039 15.8521 2.26953 11.5324 2.26953Z" stroke="white" stroke-width="1.96354" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M13.9537 2.64937C13.5479 2.53156 13.1291 2.43993 12.6971 2.38757C11.4404 2.23048 10.2361 2.32211 9.11035 2.64937C9.48997 1.68069 10.4325 1 11.532 1C12.6316 1 13.5741 1.68069 13.9537 2.64937Z" stroke="white" stroke-width="1.96354" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.4596 23.4106C15.4596 25.5705 13.6924 27.3377 11.5325 27.3377C10.4591 27.3377 9.46428 26.8927 8.75741 26.1858C8.05054 25.4789 7.60547 24.484 7.60547 23.4106" stroke="white" stroke-width="1.96354" stroke-miterlimit="10"/>
{notificationData.length!==0&& <circle  cx="18.5" cy="5.60889" r="4.5" fill="#FF3C3C"/>}
</svg>


            {showDropdown && (
              <div
              ref={dropdownRef}
                className="dropdown1"
                style={{
                  position: "absolute",
                  left: isOpen ? "13%" : "10%",
                  border: isOpen ? "" : "none",
                  width:isOpen?"":'80px',
                  justifyContent:isOpen?'':'center'
                }}
              >
                {isOpen ? (
                  <>
                    <Link to="/user-settings" className="dropdown-item">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 9.11011V14.8801C3 17.0001 3 17.0001 5 18.3501L10.5 21.5301C11.33 22.0101 12.68 22.0101 13.5 21.5301L19 18.3501C21 17.0001 21 17.0001 21 14.8901V9.11011C21 7.00011 21 7.00011 19 5.65011L13.5 2.47011C12.68 1.99011 11.33 1.99011 10.5 2.47011L5 5.65011C3 7.00011 3 7.00011 3 9.11011Z"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Settings
                    </Link>

                    <button onClick={clearStorage} className="dropdown-item">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15 12H3.62"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <Link to="/user-settings" className="dropdown-item" >
                    <svg
                        width="20"
                        height="20"
                        style={{margin:'auto'}}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 9.11011V14.8801C3 17.0001 3 17.0001 5 18.3501L10.5 21.5301C11.33 22.0101 12.68 22.0101 13.5 21.5301L19 18.3501C21 17.0001 21 17.0001 21 14.8901V9.11011C21 7.00011 21 7.00011 19 5.65011L13.5 2.47011C12.68 1.99011 11.33 1.99011 10.5 2.47011L5 5.65011C3 7.00011 3 7.00011 3 9.11011Z"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Link>

                    <button onClick={clearStorage} className="dropdown-item">
                    <svg
                        width="20"
                        height="20"
                        style={{margin:'auto'}}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15 12H3.62"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </>
                )}

                {/* <Link to="/settings" className="dropdown-item1">
              Settings
            </Link>
            <button onClick={clearStorage} className="cmn_btn" style={{backgroundColor:'red'}}>
              Logout
            </button> */}
              </div>
            )}
          </div>
        </div>
      </div>

      {notificationPopup ? (
        <div className="notifybar" style={{left:isOpen? "210px":"120px"}} 
          ref={dropdownRef1}
          >
          <div
            className="notify_sub_div"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <h2
              style={{
                fontSize: "16px",
                fontFamily: "interbold",
              }}
            >
              Notifications
            </h2>
            <img
              src={cutbtn}
              alt=""
              title="Close Notification"
              onClick={() => setNotificationPopup(false)}
            />
          </div>
          {notificationData
            .sort((a, b) => a.viewed - b.viewed || a.id - b.id)
            .map((notification, index) => (
              <div
                className="activity_wrapper_com"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "10px",
                  backgroundColor: notification.viewed ? "#f0f0f0" : "#ffffff",
                }}
              >
                <div
                  className="notify_sub_div"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <span className="status_complete">{notification.action}</span>
                  <div style={{display:'flex',alignItems:'center'}}>
                 {notification.viewed===false &&<button
                  className="cmn_btn"
                  title="Mark as Read"
                  onClick={() => {
                    markasread(notification.id, index);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 7.42999V13.43C22 14.93 21.5 16.18 20.62 17.06C19.75 17.93 18.5 18.43 17 18.43V20.56C17 21.36 16.11 21.84 15.45 21.4L11 18.43H8.88C8.96 18.13 9 17.82 9 17.5C9 16.48 8.61 15.54 7.97 14.83C7.25 14.01 6.18 13.5 5 13.5C3.88 13.5 2.86 13.96 2.13 14.71C2.04 14.31 2 13.88 2 13.43V7.42999C2 4.42999 4 2.42999 7 2.42999H17C20 2.42999 22 4.42999 22 7.42999Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 17.5C9 18.25 8.79001 18.96 8.42001 19.56C8.21001 19.92 7.94 20.24 7.63 20.5C6.93 21.13 6.01 21.5 5 21.5C3.54 21.5 2.26999 20.72 1.57999 19.56C1.20999 18.96 1 18.25 1 17.5C1 16.24 1.58 15.11 2.5 14.38C3.19 13.83 4.06 13.5 5 13.5C7.21 13.5 9 15.29 9 17.5Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.43994 17.5L4.42993 18.49L6.55994 16.52"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.5 10.5H15.5"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>}
                <button style={{border:'none',background:'transparent'}}>
                <img
                    src={cutbtn}
                    title="Delete Notification"
                    alt=""
                    onClick={() => deleteNotification(notification.id, index)}
                  />
                </button>
                  
                  </div>
                </div>
                <h4 title={notification.message}>{notification.message.length > 50 ? `${notification.message.substring(0, 50)}...` : notification.message}</h4> 
              </div>
            ))}
        </div>
      ) : (
        ""
      )}

      {/* {showAccountWindow && (
        <div ref={accountWindowRef}>
          <AccountWindow />
        </div>
      )} */}
    </>
  );
};

export default Top;
