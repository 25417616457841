import React, { useState } from 'react';
import Breadcrumb from './Breadcrumb';
import Button from './user/Button';
import { useNavigate, Link } from 'react-router-dom';
import ProgressBar from "../components/auditExecution/progressbar/ProgressBar";
import createBtn from "../images/createbutton.svg";
import { auditRoutes } from "../components/subroutes";

const NavRow = ({
  name, Create, parentName, link, hide, idtrue, truePlan, trueback, RcmCreate, trueRisk, phaseTrue, fieldStatus, queStatus, backToExecution, trueReport, moreReport, saveBack, createIcon, trueStatus,
  observationUrl, obvStatus,
  auditData,
  id, rcapUpload,
  secondName,issuestatus
}) => {
  console.log("parentName",parentName)
  console.log('name',name)
  var displayName = name ? name : "";
  const [isCreateClicked, setIsCreateClicked] = useState(false);

    const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");

  const Roles = is_Admin && Object.keys(userRoles).some(key => key.includes("Audit Team"));
  const navigate = useNavigate();

  const backTo = () => {
    if (backToExecution) {
      navigate(backToExecution)
      return;
    }
    navigate(-1)
  }
  const { AuditExecution, departmentId } = rcapUpload || {};
  const { fieldstatus } = fieldStatus || {};
  const { questatus, showStatus, incomingStatus } = queStatus || {};
  const { APIData } = obvStatus || {};

  const navTo = () => {
    if (RcmCreate) {
      RcmCreate();
      return;
    }
    navigate(link)
  }

  
  const StatusMapping = (status) => {
      switch (status){
        case "Open":
          return "Not Started";
        case "Planning":
          return "Planning";
        case "Planning Complete":    
          return "Planning Complete";
        case "Fieldwork":
          return "Fieldwork"
        case "Fieldwork Complete":
          return "Fieldwork Complete";
        case "Reporting":
          return "Reporting";
        case "Reporting Complete":
          return "Reporting Complete";
        default:
          return "Not Started"          
      }
      
  };
 
  return (
    <div className="navrow">
      <div style={{ display: "flex", alignItems: "center", gap: "2vw" }}>
        <h2 className="page_title">{name}</h2>
        <Breadcrumb
          name={name}
          idtrue={idtrue}
          parentName={parentName}
          secondName={secondName}
        />
      </div>

      <div style={{ display: "flex", gap: "1vw", alignItems: "center" }}>
        {createIcon && (
          <div className="searchncbtn">
            <div className="createuser" onClick={createIcon?.handlePopup}>
              <button
                style={{
                  border: "none",
                  background: "transparent",
                }}
              >
                <img
                  src={createIcon?.createBtn}
                  alt="Create"
                  style={{ width: "24px" }}
                />
              </button>
            </div>
          </div>
        )}
        {trueRisk && (
          <>
            <div className="togglecon">
              <div
                className="toggle-option"
                onClick={trueRisk?.toggleListView}
                style={{
                  backgroundColor:
                    trueRisk?.activeView === "List View" ? "#fff" : "",
                  borderRadius: "999px",
                }}
              >
                List View
              </div>

              <div
                className="toggle-option"
                onClick={trueRisk?.toggleGraphView}
                style={{
                  backgroundColor:
                    trueRisk?.activeView === "Graph View" ? "#fff" : "",
                  borderRadius: "999px",
                }}
              >
                Graph View
              </div>
            </div>

            {trueRisk?.deleterisksassessmentdata && (
              <>
                <button
                  className="cmn_btn"
                  style={{ backgroundColor: "#e5e5e5", color: "#000" }}
                >
                  Export
                </button>
                <button
                  className="cmn_btn"
                  style={{ backgroundColor: "#17c247" }}
                  onClick={trueRisk?.createPlan}
                  disabled={trueRisk.APIData.status === "Open"}
                >
                  <svg
                    width="12"
                    height="10"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.72621L3.18182 6.90803L9 1.08984"
                      stroke="white"
                      stroke-width="1.45455"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Create Audit Plan
                </button>
              </>
            )}
          </>)
        }


        {console.log(phaseTrue?.phase,"phase")}

        {phaseTrue && <span className="status_complete">
          {StatusMapping(phaseTrue?.phase)}</span>
          }

        {trueReport && !moreReport && (
          <div className="togglecon">
            <div
              className="toggle-option"
              style={{ backgroundColor: "#fff", borderRadius: "999px" }}
            >
              List
            </div>
            <div className="toggle-option" onClick={trueReport}>
              Finalized Details
            </div>
          </div>
        )}

        {moreReport && (
          <div className="togglecon">
            <div className="toggle-option" onClick={trueReport}>
              List
            </div>
            <div
              className="toggle-option"
              style={{ backgroundColor: "#fff", borderRadius: "999px" }}
            >
              Finalized Details
            </div>
          </div>
        )}
        
        {saveBack && (
          <div style={{ display: "flex", gap: "1vw" }}>
            <Button
              buttonName="Close"
              buttonColor="#4CAF50"
              onClick={saveBack.close}
            />

            <Button
              buttonName="Save"
              buttonColor="#4CAF50"
              onClick={() => saveBack.CreateIssue()}
            />
          </div>
        )}

        {issuestatus && (
          <span className="status_todo">{issuestatus.APIData.status}</span>
        )}

        {truePlan && (
          <div
            className="button_container"
            style={{ display: Roles ? "" : "none", margin: "0" }}
          >
            {!isCreateClicked && (
              <button
                className="cmn_btn"
                onClick={truePlan?.handleCreateClick}
                style={{ backgroundColor: "#5571DF" }}
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Submit
              </button>
            )}

            {isCreateClicked && (
              <>
                <button
                  className="cmn_btn"
                  onClick={truePlan?.handleApproveClick}
                  style={{
                    backgroundColor: "#65CC5C",
                  }}
                >
                  <svg
                    width="12"
                    height="10"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.72621L3.18182 6.90803L9 1.08984"
                      stroke="white"
                      stroke-width="1.45455"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Approve
                </button>

                <button
                  className="cmn_btn"
                  onClick={truePlan?.handleRejectClick}
                  style={{
                    backgroundColor: "#FE2768",
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99967 14.6654C11.6663 14.6654 14.6663 11.6654 14.6663 7.9987C14.6663 4.33203 11.6663 1.33203 7.99967 1.33203C4.33301 1.33203 1.33301 4.33203 1.33301 7.9987C1.33301 11.6654 4.33301 14.6654 7.99967 14.6654Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.11328 9.88661L9.88661 6.11328"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.88661 9.88661L6.11328 6.11328"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Reject
                </button>
              </>
            )}
          </div>
        )}

        {trueStatus && (
          <div className="searchncbtn">
            <div className="indication-section">
              {observationUrl === "initialobservation" && (
                <div className="prog-bar-div">
                  <ProgressBar
                    percentage={
                      auditData.report_status?.CREATE_INITIAL_OBSERVATION
                    }
                  />
                </div>
              )}

              {observationUrl === "qaapprovalinitialobservation" && (
                <div className="prog-bar-div">
                  <ProgressBar
                    percentage={
                      auditData.report_status?.APPROVE_INITIAL_OBSERVATION
                    }
                  />
                </div>
              )}

              {observationUrl === "businessownerapproval" && (
                <div className="prog-bar-div">
                  <ProgressBar
                    percentage={auditData.report_status?.BUSINESS_APPROVAL}
                  />
                </div>
              )}

              {observationUrl === "finalizeobservation" && (
                <div className="prog-bar-div">
                  <ProgressBar
                    percentage={auditData.report_status?.FINALIZED_OBSERVATION}
                  />
                </div>
              )}

              {observationUrl === "qaapprovalfinalizeobservation" && (
                <div className="prog-bar-div">
                  <ProgressBar
                    percentage={
                      auditData.report_status?.APPROVE_QA_FINAL_OBSERVATION
                    }
                  />
                </div>
              )}

              {observationUrl === "auditdirectorapproval" && (
                <div className="prog-bar-div">
                  <ProgressBar
                    percentage={
                      auditData.report_status?.APPROVE_ADMIN_FINAL_OBSERVATION
                    }
                  />
                </div>
              )}
            </div>
            {/* <input type="text" placeholder="Search" className="searchbar" /> */}

            <span className="status_complete">
              <p style={{ whiteSpace: "nowrap", fontFamily: "interbold" }}>
                {auditData?.phase}
              </p>
            </span>
            {observationUrl === "initialobservation" && (
              <div className="top-con-obv" style={{ alignItems: "center" }}>
                <Link to={`/audit-execution/${id}/reporting/createobservation`}>
                  <img src={createBtn} className="createobv" />
                </Link>
              </div>
            )}
          </div>
        )}

        {departmentId && rcapUpload && (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div className="questionnaire_file_name">
              {rcapUpload.selectedFiles &&
              rcapUpload.selectedFiles.length > 0 ? (
                rcapUpload.selectedFiles?.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h6>{file.name}</h6>
                    <svg
                      onClick={() => {
                        rcapUpload.setSelectedFiles(
                          rcapUpload.selectedFiles.filter((_, i) => i !== index)
                        );
                      }}
                      width="10"
                      height="10"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L10.9989 10.9989"
                        stroke="#292D32"
                        strokeWidth="1.76758"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.00107 10.9989L11 1"
                        stroke="#292D32"
                        strokeWidth="1.76758"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                ))
              ) : (
                <h6 className="slct_plchldr">Select Files</h6>
              )}
            </div>

            {rcapUpload.showSelectFileButton ? (
              <button
                className="cmn_btn"
                style={{ backgroundColor: "#5771df  " }}
                onClick={() => document.getElementById("fileInput").click()}
              >
                Select File
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={rcapUpload.handleFileSelect}
                />
              </button>
            ) : (
              <button
                className="cmn_btn"
                style={{ backgroundColor: "#65CC5C" }}
                onClick={rcapUpload.handleFileUpload}
              >
                Upload File
              </button>
            )}
          </div>
        )}

        {fieldstatus && fieldstatus === "Completed" && (
          <span className="status_complete">
            {fieldstatus === "Completed" ? "Completed" : "Mark As Done"}
          </span>
        )}

        {questatus && showStatus && (
          <span className="status_complete" disabled>
            {incomingStatus}
          </span>
        )}

        {obvStatus && (
          <>
            {obvStatus.APIData?.task_initial_admin_approval?.status ===
              "done" &&
              obvStatus.APIData?.status === "Initial Draft Admin Approve" &&
              obvStatus.observationUrl === "initialobservation" && (
                <span className="status_complete">
                  {obvStatus.APIData.status === "Closed"
                    ? "Closed"
                    : obvStatus.APIData.status === "Rejected"
                    ? "Rejected"
                    : "Accepted"}
                </span>
              )}

            {obvStatus.APIData?.task_initial_qa_approval?.status === "done" &&
              obvStatus.APIData?.status === "Initial Draft Qa Approve" &&
              obvStatus.observationUrl === "qaapprovalinitialobservation" && (
                <span className="status_complete">
                  {obvStatus.APIData.status === "Closed"
                    ? "Closed"
                    : obvStatus.APIData.status === "Rejected"
                    ? "Rejected"
                    : "Accepted"}
                </span>
              )}

            {obvStatus.APIData?.task_final_admin_approval?.status === "done" &&
              obvStatus.APIData?.status === "Published" &&
              obvStatus.observationUrl === "auditdirectorapproval" && (
                <span className="status_complete">
                  {obvStatus.APIData.status === "Closed"
                    ? "Closed"
                    : obvStatus.APIData.status === "Rejected"
                    ? "Rejected"
                    : "Accepted"}
                </span>
              )}

            {obvStatus.APIData?.task_initial_business_approval?.status ===
              "done" &&
              obvStatus.APIData?.status === "Approved Draft" &&
              obvStatus.observationUrl === "businessownerapproval" && (
                <span className="status_complete">
                  {obvStatus.APIData.status === "Closed"
                    ? "Closed"
                    : obvStatus.APIData.status === "Rejected"
                    ? "Rejected"
                    : "Accepted"}
                </span>
              )}

            {obvStatus.APIData?.status === "Finalized Draft Qa Approve" &&
              obvStatus.observationUrl === "finalizeobservation" && (
                <span className="status_complete">
                  {obvStatus.APIData.status === "Closed"
                    ? "Closed"
                    : obvStatus.APIData.status === "Rejected"
                    ? "Rejected"
                    : "Finalized"}
                </span>
              )}

            {obvStatus.APIData?.task_final_qa_approval?.status === "done" &&
              obvStatus.APIData?.status === "Finalized Draft Admin Approve" &&
              obvStatus.observationUrl === "qaapprovalfinalizeobservation" && (
                <span className="status_complete">
                  {obvStatus.APIData.status === "Closed"
                    ? "Closed"
                    : obvStatus.APIData.status === "Rejected"
                    ? "Rejected"
                    : "Accepted"}
                </span>
              )}

            {obvStatus.APIData?.status === "Closed" && (
              <span className="status_complete">
                {obvStatus.APIData.status === "Closed"
                  ? "Closed"
                  : obvStatus.APIData.status === "Rejected"
                  ? "Rejected"
                  : "Accepted"}
              </span>
            )}
          </>
        )}

        {Create && !hide && link && (
          <Button buttonName="Create" buttonColor="#4CAF50" onClick={navTo} />
        )}

        {(!Create || trueback) && (
          <Button buttonName="Back" buttonColor="#4CAF50" onClick={backTo} />
        )}
      </div>
    </div>
  );
};

export default NavRow;
