import React from "react";
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchDataV2, postDataReturnId } from "../../apiUtils";
import SingleOptionSelector from "../../SingleOptionSelector";
import RenderIcons from "../../RenderIcons";
import { auditExecution } from "../../subroutes";
import NavRow from "../../NavRow";
import "../../auditUniverse/rcm/createRcm/creatercap.css";
import SingleOptionSelectorAuditBU from "../../SingleOptionSelectorAuditBU";
import SingleOptionSelectorAuditPR from "../../SingleOptionSelectorAuditPR";
const CreateWorkpaper = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [objective, setObjective] = useState("");
  const [entityId, setEntityId] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [title, setTitle] = useState("");
  const [objectiveError, setObjectiveError] = useState(false);
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_id, setAssign_to_id] = useState("");
  const [assign_to_idError, setAssign_to_idError] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { id, workId } = useParams();

  const segments = location.pathname.split("/");
  const auditUniverse = segments.find((segment) => segment === "auditUniverse");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    if (APIData.audit_entity) {
      if (!auditUniverse) {
        setEntityId(APIData.audit_entity.id);
      }
    }
  }, [APIData]);

  const sendToNext = () => {
    if (!objective || !preparer_id || !reviewer_id) {
      setObjectiveError(!objective ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setPreparerError(!preparer_id ? true : false);
      setAssign_to_idError(!assign_to_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const parameter = {
      url: `/api/audit_engagement/workpaper/`,
      payload: {
        audit_id: id,
        preparer_id,
        reviewer_id,
        assign_to_id,
        objective,
        title,
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/Fieldwork/${workId}`,
      setSuccessCallback: (data) => {
        const successMessageColor = "#4285F4";
        navigate(`/audit-execution/${id}/fieldwork/${data.id}/`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      },
    };

    postDataReturnId(parameter);
  };

  const [APIaudit, setAudit] = useState([]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  const [maketrue, setTrue] = useState(false);

  return (
    <>
      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />

        <NavRow
          name={"Create Fieldwork"}
          parentName={APIaudit?.name}
          idtrue={true}
        />

        <div
          className="RCM_template_con"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <form action="">
            <div className="lng_lbl_inpt">
              <label htmlFor="">Title</label>
              <input
                type="text"
                placeholder="Unauthorized Access to Financial Data"
                style={{
                  backgroundColor: "#EEF1FC",
                }}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                tabIndex={1}
              />
            </div>
            <div className="lng_lbl_inpt">
              <label htmlFor="">Objective</label>
              <input
                type="text"
                placeholder="Unauthorized Access to Financial Data"
                style={{
                  backgroundColor: "#EEF1FC",
                  border: objectiveError ? "1px solid #FF4646" : "",
                }}
                value={objective}
                onChange={(e) => {
                  setObjective(e.target.value);
                  setObjectiveError(false);
                }}
                tabIndex={2}
              />
            </div>

            <SingleOptionSelectorAuditPR
              id={id}
              setSelectorId={setPreparerId}
              setSelectorError={setPreparerError}
              selectorError={PreparerError}
              Name="name"
              title="Preparer"
              editMode={editMode}
              tabIndex={3}
              maketrue={maketrue}
              setTrue={setTrue}
            />

            <SingleOptionSelectorAuditPR
              id={id}
              setSelectorId={setReviewerId}
              setSelectorError={setReviewerError}
              selectorError={reviewerError}
              Name="name"
              title="Reviewer"
              editMode={editMode}
              tabIndex={4}
              maketrue={maketrue}
              setTrue={setTrue}
            />

            <SingleOptionSelectorAuditBU
              id={id}
              setSelectorId={setAssign_to_id}
              setSelectorError={setAssign_to_idError}
              selectorError={assign_to_idError}
              Name="name"
              title="Assign To"
              editMode={editMode}
              tabIndex={5}
              maketrue={maketrue}
              setTrue={setTrue}
            />
          </form>
          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={sendToNext}
              tabIndex={6}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create Fieldwork
            </button>
          </div>
        </div>

        <div className="cardscontainer"></div>
      </section>
    </>
  );
};

export default CreateWorkpaper;
