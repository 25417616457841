import React, { useState, useEffect } from "react";
import "../userHome/userhome.css";
import "../userHome/userhomenew.css";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  fetchDataV2,
  justFetchDataV2,
  updateDataV2,
  convertDate,
  fetchDataObjectV2,
} from "../../apiUtils";
import SuperAvatar from "../../SuperAvatar";
import BounceLoader from "react-spinners/BounceLoader";
import PriorityBarChart from "../../issueTracking/Charts/PriorityBarChart";
import AllIssuePieChart from "../../issueTracking/Charts/AllIssuePieChart";
import ReactPaginate from "react-paginate";
import { FaLeaf } from "react-icons/fa";

const Userhome = ({ tableSize, state }) => {
  const [APIData, setAPIData] = useState([]);
  const [APIDatatask, setAPIDataTask] = useState([]);
  const [APIAllData, setAPIAllData] = useState([]);
  const [auditNames, setAuditNames] = useState([]);
  const [issueNames, setIssueNames] = useState([]);
  const [activeTab, setActiveTab] = useState("owned");
  const [loading, setLoading] = useState(false);
  const [IssueAPIData, setIssueAPIData] = useState([]);
  const [IssueBuAPIData, setIssueBuAPIData] = useState([]);
  const [errors, setErrors] = useState("");
  const userId = localStorage.getItem("user_id");
  const [isAssigned, setIsAssigned] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [auditPwaPopup, setauditPwaPopup] = useState(false);
  const [issuesPwaPopup, setissuesPwaPopup] = useState(false);
  const [tasksList, setTasksList] = useState(false);
  const [todotask, setTodoTask] = useState([]);
  const [donetask, setDoneTask] = useState([]);
  const [commentsdata, setCommentData] = useState([]);
  const [reviewtask, setReviewTask] = useState([]);
  const [APIDataRating, setAPIDataRating] = useState([]);
  const [APIDataBuRating, setAPIDataBuRating] = useState([]);
  const avaUserName = localStorage.getItem("userName");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 11;
  const userRole = localStorage.getItem("userRoles");
  const hasbusinessRole = userRole.includes("Business");

  const [currentPageTaskOwned, setCurrentPageTaskOwned] = useState(0);
  const itemsPerPageTaskOwned = 10;

  const [currentPageTaskAssigned, setCurrentPageTaskAssigned] = useState(0);
  const itemsPerPageTaskAssigned = 10;

  const { id } = useParams();

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/user/?user_id=${userId}`,
      setterFunction: (data) => {
        setAPIData(data);
      },
      setErrors: (error) => {
        setErrors(error);
      },
      setLoading: setLoading,
    };

    justFetchDataV2(parameter);
  }, []);

  useEffect(() => {
    if (hasbusinessRole) {
      const parameter = {
        url: `/api/issues/user/chart/?user_id=${userId}`,
        setterFunction: (data) => {
          // Extract the ratings_summary for the chart
          setIssueBuAPIData(data.status_summary || {});
        },
        setErrors: setErrors,
        setLoading: setLoading,
      };
      fetchDataObjectV2(parameter);
    } else {
      const parameter = {
        url: `/api/issues/count/`,
        setterFunction: setIssueAPIData,
        setErrors: setErrors,
      };
      fetchDataV2(parameter);
    }
  }, []);
  console.log(hasbusinessRole);
  useEffect(() => {
    if (hasbusinessRole) {
      const parameter = {
        url: `/api/issues/user/chart/?user_id=${userId}`,
        setterFunction: (data) => {
          // Extract the ratings_summary for the chart
          setAPIDataBuRating(data.ratings_summary || {});
        },
        setErrors: setErrors,
        setLoading: setLoading,
      };
      fetchDataObjectV2(parameter);
    } else {
      const parameter = {
        url: `/api/issues/count/rating/`,
        setterFunction: setAPIDataRating,
        setErrors: setErrors,
        setLoading: setLoading,
      };
      fetchDataObjectV2(parameter);
    }
  }, [hasbusinessRole, userId]);

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const [filters, setFilters] = useState({
    status: "",
    id: "",
    title: "",
    preparer: "",
    reviewer: "",
    due: "",
    actual_start_date: "",
    actual_end_date: "",
  });

  const filteredData = APIData.filter((data) => {
    const {
      id,
      name,
      status,
      phase,
      business_users,
      actual_start_date,
      actual_end_date,
    } = data;

    const matchesId = filters.id ? id.toString().includes(filters.id) : true;

    const entityName = name ? name.toLowerCase() : "";
    const matchesEntity = filters.name
      ? entityName.includes(filters.name.toLowerCase())
      : true;

    const matchesKeyStakeholder = filters.business_users
      ? business_users.some((user) =>
          user.name.toLowerCase().includes(filters.business_users.toLowerCase())
        )
      : true;

    const matchesStatus = filters.phase
      ? phase.toLowerCase().includes(filters.phase.toLowerCase())
      : true;

    const matchesStartDate = filters.actual_start_date
      ? (actual_start_date || "").toString().includes(filters.actual_start_date)
      : true;

    const matchesEndDate = filters.actual_end_date
      ? new Date(actual_end_date || "")
          .toString()
          .includes(filters.actual_end_date)
      : true;

    return (
      matchesId &&
      matchesEntity &&
      matchesKeyStakeholder &&
      matchesStatus &&
      matchesStartDate &&
      matchesEndDate
    );
  });

  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/audit-execution/${id}/Detail`);
  };

  const StatusColor = (status) => {
    if (status === "Open") {
      return <span className="status_todo">{"Not Started"}</span>;
    } else if (status === "Planning") {
      return <span className="status_between">{status}</span>;
    } else if (status === "Planning Complete") {
      return <span className="status_inprogress">{status}</span>;
    } else if (status === "Fieldwork") {
      return <span className="status_between">{status}</span>;
    } else if (status === "Fieldwork Complete") {
      return <span className="status_inprogress">{status}</span>;
    } else if (status === "Reporting") {
      return <span className="status_between">{status}</span>;
    } else if (status === "Reporting Complete") {
      return <span className="status_inprogress">{status}</span>;
    } else if (status === "Wrapup Qa") {
      return <span className="status_inprogress">{status}</span>;
    } else if (status === "Wrapup Qa Complete") {
      return <span className="status_complete">{"Closed"}</span>;
    } else {
      return <span className="status_default">{status}</span>;
    }
  };

  useEffect(() => {
    if (location.state && location.state.data === "reload") {
      const reloadFlag = localStorage.getItem("reloadFlag");

      if (!reloadFlag) {
        localStorage.setItem("reloadFlag", "true");
        window.location.reload();
      } else {
        localStorage.removeItem("reloadFlag");
      }
    }
  }, [location.state]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/`,
      setterFunction: setAuditNames,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/issues/`,
      setterFunction: setIssueNames,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    if (userId) {
      const parameter = {
        url: `/api/system/task/?assigned_to=${userId}&owner=${userId}`,
        setterFunction: setAPIDataTask,
        setErrors: setErrors,
      };
      fetchDataV2(parameter);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const parameter = {
        url: `/api/system/task/count/status/?status=in progress&user_id=${userId}`,
        setterFunction: setTodoTask,
        setErrors: setErrors,
      };
      fetchDataV2(parameter);
      const parameter1 = {
        url: `/api/system/task/count/status/?status=done&user_id=${userId}`,
        setterFunction: setDoneTask,
        setErrors: setErrors,
      };
      fetchDataV2(parameter1);
      const parameter2 = {
        url: `/api/system/task/count/status/?status=review&user_id=${userId}`,
        setterFunction: setReviewTask,
        setErrors: setErrors,
      };
      fetchDataV2(parameter2);
      const parameter3 = {
        url: `/api/system/comments/count/?user_id=${userId}`,
        setterFunction: setCommentData,
        setErrors: setErrors,
      };
      fetchDataV2(parameter3);
    }
  }, [userId]);

  const storeQuesId = (questionnaire_id) => {
    navigate(`/audit-universe/risk-assessments/answer-questionnaire`, {
      state: { questionnaire_id },
    });
  };

  const navigateToAuditPage = (auditId) => {
    const id = auditId;
    navigate(`/audit-execution/${id}/`);
  };

  const handleToggle = () => {
    setIsAssigned((prevState) => !prevState);
  };

  const handleOpenAuditPopup = () => {
    setauditPwaPopup(true);
  };

  const handleCloseAuditPopup = () => {
    setauditPwaPopup(false);
  };

  const handleIssueOpen = () => {
    setissuesPwaPopup(true);
  };

  const handleIssueClose = () => {
    setissuesPwaPopup(false);
  };

  const handleTasksListsOpen = () => {
    setTasksList(true);
  };

  const handleTasksListClose = () => {
    setTasksList(false);
  };

  const handleAuditClick = (auditId) => {
    const id = auditId;
    navigate(`/audit-execution/${id}/`);
  };

  const handleIssueClick = (auditId) => {
    const id = auditId;
    navigate(`/issuetracking/issues/${id}`);
  };

  const renderStatus = (status) => {
    switch (status) {
      case "done":
        return <span className="status_complete">Done</span>;
      case "in progress":
        return <span className="status_inprogress">In Progress</span>;
      case "todo":
        return <span className="status_todo">Todo</span>;
      case "review":
        return <span className="status_between">Review</span>;
      case "rev":
        return <span className="status_between">Review</span>;

      default:
        return <span className="status_todo">todo</span>;
    }
  };

  // Pagination Logic
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const startOffset = currentPage * itemsPerPage;
  const paginatedData = filteredData.slice(
    startOffset,
    startOffset + itemsPerPage
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Pagination Logic
  const pageCountTaskOwned = Math.ceil(
    APIDatatask.sort((u,v)=>v.id-u.id).length / itemsPerPageTaskOwned
  );
  const startOffsetTaskOwned = currentPageTaskOwned * itemsPerPageTaskOwned;
  const paginatedDataTaskOwned = APIDatatask.slice(
    startOffsetTaskOwned,
    startOffsetTaskOwned + itemsPerPageTaskOwned
  );

  const handlePageClickTaskOwned = ({ selected }) => {
    setCurrentPageTaskOwned(selected);
  };

  // Pagination Logic
  const pageCountTaskAssigned = Math.ceil(
    APIDatatask.sort((u,v)=>v.id-u.id).length / itemsPerPageTaskAssigned
  );
  const startOffsetTaskAssigned =
    currentPageTaskAssigned * itemsPerPageTaskAssigned;
  const paginatedDataTaskAssigned = APIDatatask.slice(
    startOffsetTaskAssigned,
    startOffsetTaskAssigned + itemsPerPageTaskAssigned
  );

  const handlePageClickTaskAssigned = ({ selected }) => {
    setCurrentPageTaskAssigned(selected);
  };

  const keyMappingStatus = {
    closed: "Closed",
    status_open: "Open",
    overdue: "Overdue",
    extended_date: "Extended",
    closed_under_monitoring: "Monitoring",
  };

  const keyMappingRating = {
    rating_low: "Low",
    rating_medium: "Medium",
    rating_high: "High",
    rating_very_high: "Very High",
    rating_critical: "Critical",
  };

  const ratingtotal = Object.values(
    hasbusinessRole ? APIDataBuRating : APIDataRating || {}
  ).reduce((sum, value) => sum + value, 0);

  const totalissues = hasbusinessRole
    ? IssueBuAPIData.Open + IssueBuAPIData.Closed
    : IssueAPIData.status_open + IssueAPIData.closed;

  return (
    <>
      <div
        className="home_container"
        style={{ width: tableSize ? "100%" : "100%" }}
      >
        <div className="taskcard">
          <h2>Pending Tasks</h2>
          <h2 style={{ color: "orange" }}>{todotask.count}</h2>
        </div>

        <div className="taskcard">
          <h2 className="">Comments</h2>
          <h2 style={{ color: "#4d4dd0a1" }}>{commentsdata.comment_count}</h2>
        </div>

        <div className="taskcard">
          <h2 className="">Completed Tasks</h2>
          <h2 style={{ color: "#17c247" }}>{donetask.count}</h2>
        </div>

        <div className="taskcard">
          <h2 className="">Pending Approvals</h2>
          <h2 style={{ color: "#ff0000b3" }}>{reviewtask.count}</h2>
        </div>

        <div className="userhome_bar_graph">
          <h5> Status - Total Issues ({totalissues}) </h5>
          <PriorityBarChart
            apiResponse={hasbusinessRole ? IssueBuAPIData : IssueAPIData}
            keyMapping={keyMappingStatus}
          />
        </div>
        <div className="userhome_pie_chart">
          <h5> Ratings - Total Issues ({ratingtotal}) </h5>

          <PriorityBarChart
            apiResponse={hasbusinessRole ? APIDataBuRating : APIDataRating}
            keyMapping={keyMappingRating}
          />
        </div>

        <div className="userhome_audit_table">
          <table style={{ width: tableSize ? "100%" : "100%" }}>
            <thead className="">
              <tr
                className="tablehead"
                style={{ height: "10px", padding: "0px" }}
              >
                <th
                  colSpan={6}
                  style={{
                    textAlign: "center",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                >
                  <h2>Audits</h2>
                </th>
              </tr>
            </thead>
            <thead>
              <tr className="tablehead">
                <th
                  style={{
                    borderTopRightRadius: "0px",
                    borderTopLeftRadius: "0px",
                  }}
                >
                  <h4 className="thhead">UID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("id", e)}
                    value={filters.id}
                  />
                </th>
                <th>
                  <h4 className="thhead">Audit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("name", e)}
                    value={filters.name}
                  />
                </th>
                <th>
                  <h4 className="thhead" style={{ whiteSpace: "nowrap" }}>
                    Business Stakeholder
                  </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("business_users", e)}
                    value={filters.business_users}
                  />
                </th>
                <th>
                  <h4 className="thhead">Status</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("phase", e)}
                    value={filters.phase}
                  />
                </th>
                <th>
                  <h4 className="thhead" style={{ whiteSpace: "nowrap" }}>
                    Actual Start Date
                  </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("actual_start_date", e)}
                    value={filters.actual_start_date}
                  />
                </th>
                <th style={{ borderTopRightRadius: "0px" }}>
                  <h4 className="thhead" style={{ whiteSpace: "nowrap" }}>
                    Actual End Date
                  </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("actual_end_date", e)}
                    value={filters.actual_end_date}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>
              ) : paginatedData.length === 0 ? (
                <tr>
                  <td colSpan="6" className="data-error-text">
                    No data found
                  </td>
                </tr>
              ) : (
                paginatedData
                  .sort((a, b) => a.id - b.id)
                  .map((data) => (
                    <tr
                      className="tabledata"
                      onClick={() => Viewuser(data)}
                      key={data.id}
                    >
                      <td>
                        <span className="status_todo">{data.id}</span>
                      </td>
                      <td>{data.name}</td>
                      <td>
                        {data.business_users
                          ? data.business_users
                              .map((user) => user.name)
                              .slice(0, 2)
                              .join(", ") +
                            (data.business_users.length > 2 ? "..." : "")
                          : ""}
                      </td>
                      <td>{StatusColor(data.phase)}</td>
                      <td>
                        <span>{convertDate(data.actual_start_date)}</span>
                      </td>
                      <td>
                        <span>{convertDate(data.actual_end_date)}</span>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container"
            activeClassName={"active"}
          />
        </div>

        <div className="userhome_audit_table2">
          <table style={{ width: "100%" }}>
            <thead>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">Status</h4>
                </th>
                <th>
                  <h4 className="thhead">Task owned by me</h4>
                </th>
                <th>
                  <h4 className="thead">Type</h4>
                </th>
              </tr>
            </thead>

            <tbody>
              {paginatedDataTaskOwned.filter(
                (item) => item.owner?.id === JSON.parse(userId)
              ).length > 0 ? (
                paginatedDataTaskOwned
                  .sort((u, v) => v.id - u.id)
                  .filter((item) => item.owner?.id === JSON.parse(userId))
                  .map((item) => (
                    <tr
                      className="tabledata"
                      key={item.id}
                      onClick={() => storeQuesId(item?.data)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{renderStatus(item.status)}</td>
                      {/* <td>{item.status === "Done" ?
                      (
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M7.75 12L10.58 14.83L16.25 9.17004" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      ) : (
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M7.91998 12H15.92" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      )}</td> */}
                      <td>
                        {item.title.length > 25
                          ? `${item.title.slice(0, 25)}...`
                          : item.title}
                      </td>

                      <td>
                        <span className="status_todo">
                          {item?.data?.type ? item?.data?.type : "none"}
                        </span>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="2" style={{ textAlign: "center" }}>
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCountTaskOwned}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClickTaskOwned}
            containerClassName="pagination-container"
            activeClassName={"active"}
          />
        </div>

        <div className="userhome_audit_table2">
          <table style={{ width: "100%" }}>
            <thead>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">Status</h4>
                </th>
                <th>
                  <h4 className="thhead">Task assigned to me</h4>
                </th>
                <th>
                  <h4 className="thhead">Type</h4>
                </th>
              </tr>
            </thead>

            <tbody>
              {paginatedDataTaskAssigned.filter(
                (item) => item.assigned_to?.id === JSON.parse(userId)
              ).length > 0 ? (
                paginatedDataTaskAssigned
                  .filter((item) => item.assigned_to?.id === JSON.parse(userId))
                  .map((item) => (
                    <tr
                      className="tabledata"
                      key={item.id}
                      onClick={() => storeQuesId(item?.data?.id)}
                      style={{ cursor: "pointer" }}
                    >
                    <td>{renderStatus(item.status)}</td>
                      {/* <td>
                        {item.status === "Done" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                              stroke="#292D32"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.75 12L10.58 14.83L16.25 9.17004"
                              stroke="#292D32"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                              stroke="#292D32"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.91998 12H15.92"
                              stroke="#292D32"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                      </td> */}
                      <td>{item.title}</td>
                      <td><span className="status_todo">
                          {item?.data?.type ? item?.data?.type : "none"}
                        </span></td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="2" style={{ textAlign: "center" }}>
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCountTaskAssigned}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClickTaskAssigned}
            containerClassName="pagination-container"
            activeClassName={"active"}
          />
        </div>
      </div>

      <div className="cardscontainerhome">
        <div className="back_acc_bar span2_home_section">
          <svg
            onClick={() => {
              navigate(-1);
            }}
            width="44"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <SuperAvatar firstName={avaUserName} classOfAvatar={"top_avatar"} />
        </div>

        <div className="taskcard taskcard1">
          <h2>Pending Tasks</h2>
          <h2 style={{ color: "orange" }}>{todotask.count}</h2>
        </div>

        <div className="taskcard taskcard2">
          <h2 className="">Comments</h2>
          <h2 style={{ color: "#4d4dd0a1" }}>32</h2>
        </div>

        <div className="taskcard taskcard3">
          <h2 className="">Completed Tasks</h2>
          <h2 style={{ color: "#17c247" }}>{donetask.count}</h2>
        </div>

        <div className="taskcard taskcard4">
          <h2 className="">Pending Approvals</h2>
          <h2 style={{ color: "#ff0000b3" }}>{reviewtask.count}</h2>
        </div>

        <div
          className="task_click_con span2_home_section"
          onClick={handleTasksListsOpen}
        >
          <h5>View tasks assigned to me</h5>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.91 19.92L15.43 13.4C16.2 12.63 16.2 11.37 15.43 10.6L8.91 4.08002"
              stroke="#5771DF"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        {tasksList && (
          <div className="tasks_view_transp">
            <div className="back_acc_bar">
              <svg
                width="36"
                height="36"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  onClick={handleTasksListClose}
                  x="0.5"
                  width="32"
                  height="32"
                  rx="16"
                  fill="white"
                />
                <path
                  d="M13.67 18.83L19.33 13.17"
                  stroke="#292D32"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.33 18.83L13.67 13.17"
                  stroke="#292D32"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <SuperAvatar
                firstName={avaUserName}
                classOfAvatar={"top_avatar"}
              />
            </div>

            <div className="switchable_tab_PWA">
              <div
                className={` task_PWA ${
                  activeTab === "owned" ? "taskactive_PWA" : ""
                }`}
                onClick={() => setActiveTab("owned")}
              >
                <h1>Task Owned by Me</h1>
              </div>

              <div
                className={` task_PWA ${
                  activeTab === "assigned" ? "taskactive_PWA" : ""
                }`}
                onClick={() => setActiveTab("assigned")}
              >
                <h1>Task Assigned to Me</h1>
              </div>
            </div>

            {activeTab === "owned"
              ? APIDatatask.filter(
                  (item) => item.owner?.id === JSON.parse(userId)
                ).map((item) => (
                  <div className="taskDiv" style={{width:'100%'}} key={item.id} >
                    <div
                      className="task_ownme"
                      onClick={() => storeQuesId(item?.data?.id)}
                      style={{ cursor: "pointer" }}
                    >
                      {renderStatus(item.status)}
                      {/* {item.status === "Done" ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.75 12L10.58 14.83L16.25 9.17004"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.91998 12H15.92"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )} */}
                      &nbsp;
                      <h6>{item.title}</h6>
                      <span className="status_todo">{item?.data?.type?item?.data?.type:'none'}</span>
                    </div>
                  </div>
                ))
              : ""}

            {activeTab === "assigned"
              ? APIDatatask.filter(
                  (item) => item.assigned_to?.id === JSON.parse(userId)
                ).map((item) => (
                  <div className="taskDiv" style={{width:'100%'}} key={item.id}>
                    <div
                      className="task_ownme"
                      onClick={() => storeQuesId(item?.data?.id)}
                      style={{ cursor: "pointer" }}
                    >
                                          {renderStatus(item.status)}

                      {/* {item.status === "Done" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.75 12L10.58 14.83L16.25 9.17004"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                      {item.status !== "Done" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.91998 12H15.92"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )} */}
                      &nbsp; <h6>{item.title}</h6>
                      <span className="status_todo">{item?.data?.type?item?.data?.type:'none'}</span>

                    </div>
                  </div>
                ))
              : ""}
          </div>
        )}

        <div className="section_cards_pwa" onClick={handleOpenAuditPopup}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3334 7.99996H18.6667C21.3334 7.99996 21.3334 6.66663 21.3334 5.33329C21.3334 2.66663 20 2.66663 18.6667 2.66663H13.3334C12 2.66663 10.6667 2.66663 10.6667 5.33329C10.6667 7.99996 12 7.99996 13.3334 7.99996Z"
              stroke="#415FE1"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.3333 5.35999C25.7733 5.59999 28 7.23999 28 13.3333V21.3333C28 26.6667 26.6667 29.3333 20 29.3333H12C5.33333 29.3333 4 26.6667 4 21.3333V13.3333C4 7.25332 6.22667 5.59999 10.6667 5.35999"
              stroke="#415FE1"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.33331 13.3334H21.3333"
              stroke="#415FE1"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M9.33331 18.6666H16"
              stroke="#415FE1"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
          <h4>Audits</h4>
        </div>

        {auditPwaPopup && (
          <div className="audit_view_transp">
            <div className="audit_list_con">
              <h5>Audits you’re involved in</h5>

              <div className="audit_name_con">
                {auditNames.map((audit) => (
                  <div
                    className="auditname_status"
                    onClick={() => navigateToAuditPage(audit.id)}
                  >
                    <h4>{audit.name}</h4>
                    <div className="audit_status_pwa">
                      <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="3" cy="3" r="3" fill="#17C247" />
                      </svg>

                      <h6>{audit.status}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <svg
              onClick={handleCloseAuditPopup}
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="32" height="32" rx="16" fill="white" />
              <path
                d="M13.67 18.83L19.33 13.17"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.33 18.83L13.67 13.17"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}


        <div className="section_cards_pwa" onClick={handleIssueOpen}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 10.3334V17.3334"
              stroke="#5771DF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M28.1067 11.44V20.56C28.1067 22.0533 27.3067 23.44 26.0134 24.2L18.0934 28.7733C16.8 29.52 15.2 29.52 13.8933 28.7733L5.97332 24.2C4.67998 23.4533 3.87997 22.0666 3.87997 20.56V11.44C3.87997 9.94667 4.67998 8.55995 5.97332 7.79995L13.8933 3.22663C15.1867 2.47996 16.7867 2.47996 18.0934 3.22663L26.0134 7.79995C27.3067 8.55995 28.1067 9.93334 28.1067 11.44Z"
              stroke="#5771DF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 21.6V21.7333"
              stroke="#5771DF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <h4>Issues</h4>
        </div>

        {issuesPwaPopup && (
          <div className="audit_view_transp">
            <div className="audit_list_con">
              <h5>Issues assigned to you</h5>

              <div className="audit_name_con">
                {issueNames.map((audit) => (
                  <>
                    <div className="auditname_status">
                      <h5
                        key={audit.id}
                        onClick={() => handleIssueClick(audit.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {audit.objective
                          ? audit.objective
                          : "Name Not Available"}
                      </h5>
                    </div>
                  </>
                ))}
              </div>
            </div>

            <svg
              onClick={handleIssueClose}
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="32" height="32" rx="16" fill="white" />
              <path
                d="M13.67 18.83L19.33 13.17"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.33 18.83L13.67 13.17"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
};

export default Userhome;
