import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ErrorPage.css';
import not_found from "./images/404-error.png";

const ErrorPage = ({ errorCode }) => {
  const navigate = useNavigate();

  const getErrorMessage = () => {
    switch (errorCode) {
      case 404:
        return {
          title: "Page Not Found",
          message: "The page you are looking for doesn't exist.",
          image: "https://via.placeholder.com/400x200?text=404+Error",
        };
      case 500:
        return {
          title: "Server Error",
          message: "Something went wrong on our end. Please try again later.",
          image: "https://via.placeholder.com/400x200?text=500+Error",
        };
      default:
        return {
          title: "Oops!",
          message: "An unexpected error occurred. Please try again later.",
          image: "https://via.placeholder.com/400x200?text=Error",
        };
    }
  };

  const { title, message, image } = getErrorMessage();

  return (
    <div className="error-page">
      <img src={not_found} alt={`${title}`} style={{ width: "10vw" }} />
      <h2 style={{fontWeight : "bold", fontSize : "1.2rem", color: "red"}}>{title}</h2>
      <p style={{fontWeight : "bold"}}>{message}</p>
      <button className="cmn_btn" onClick={() => navigate('/')}>Back to Homepage</button>
    </div>
  );
};

export default ErrorPage;
