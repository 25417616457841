import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/Breadcrumb";
import SidebarMenu from "../../components/SidebarMenu";
import "../../css/viewuser.css";
import { fetchDataObjectV2, fetchDataV2, justFetchDataV2 } from "../apiUtils";
import { issueTracking } from "../subroutes";
import create from "../../images/createbutton.svg";
import openarrow from "../../images/openarrow.svg";
import pie_chart from "../../images/pie_chart.png";
import "./issuetracking.css";
import RenderIcons from "../RenderIcons";
import NavRow from "../NavRow";
import Piechart from "../../components/issueTracking/Charts/PieChart";
import AllIssuePieChart from "./Charts/AllIssuePieChart";
import PriorityBarChart from "./Charts/PriorityBarChart";
import IssueRatingPieChart from "./Charts/IssueRatingPieChart";
import BounceLoader from "react-spinners/BounceLoader";
import SeverityBarChart from "./Charts/StatusChart/SeverityBarChart";
import ReactPaginate from "react-paginate";

const Issuetracking = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const [auditData, setAuditData] = useState([]);
  const [IssueData, setIssueData] = useState([]);

  const [APIDataRating, setAPIDataRating] = useState([]);
  const [issueData, setIssueAuditData] = useState([]);
  const [loading, setLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(0);
      const itemsPerPage = 10;



 


  useEffect(() => {
    
    const parameter = {
      url: `/api/issues/audit/issue/count/`,
      setterFunction: setIssueAuditData,
      setErrors: setErrors,
      setLoading: setLoading,

    };
    justFetchDataV2(parameter);
  }, []);


  useEffect(() => {
    const parameter = {
      url: `/api/issues/count/rating/`,
      setterFunction: setAPIDataRating,
      setErrors: setErrors,
      setLoading: setLoading,

    };
    fetchDataObjectV2(parameter);
  }, []);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/issues/count/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
      setLoading: setLoading,

    };
    fetchDataV2(parameter);
  }, []);
  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/`,
      setterFunction: setAuditData,
      setErrors: setErrors,
      setLoading: setLoading,

    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/issues/`,
      setterFunction: setIssueData,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataV2(parameter);
  }, []);

  const viewanalytics = (data) => {
    const auid = data;
    navigate(`/issue-tracking/analytics/${auid}`);
  };

  const viewauditIssue = (data) => {
    const auid = data;
    navigate(`/issue-tracking/analytics/audit-issue/${auid}`);
  };

  const pageCount = Math.ceil(issueData.length / itemsPerPage);
  const startOffset = currentPage * itemsPerPage;
  const paginatedData = issueData.slice(
    startOffset,
    startOffset + itemsPerPage
  );
 
  const handlePageClick = ({ selected }) => {
   setCurrentPage(selected);
 };



  const keyMappingStatus = {
    "closed": "Closed",
    "status_open": "Open",
    "overdue": "Overdue",
    "extended_date": "Extended",
    "closed_under_monitoring": "Monitoring"
  };


  const keyMappingRating = {
    rating_critical: "Critical",
    rating_very_high: "Very High",
    rating_high: "High",
    rating_medium: "Medium",
    rating_low: "Low",
  };
  const ratingtotal=APIDataRating.rating_high+APIDataRating.rating_low+APIDataRating.rating_medium+APIDataRating.rating_very_high+APIDataRating.rating_critical
const totalissues=APIData.status_open+APIData.closed

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={issueTracking} />
        <NavRow name={"Analytics"} Create={"Create"} idtrue={true} />

        <div
          className="analytics_container"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
          <div className="analytics_grid_container">

            <div className="analytic_grid_item">
              <h5> Status - Total Issues ({totalissues})</h5>
              <PriorityBarChart apiResponse ={APIData} keyMapping ={keyMappingStatus} />
            </div>

            <div className="analytic_grid_item">
              <h5> Ratings - Total Issues ({ratingtotal}) </h5>
              <PriorityBarChart apiResponse ={APIDataRating} keyMapping ={keyMappingRating} />

            </div>

            {/* <div className="analytic_grid_item_span_2"></div> */}

            <div className="analytic_grid_table_span_2">

              <table>
                <thead>
                  <tr className="tablehead">
                    <th>
                      <h4 className="thhead">Audit Name</h4>
                    </th>
                    <th>
                      <h4 className="thhead">Audit Year</h4>
                    </th>
                    <th>
                      <h4 className="thhead">Total</h4>
                    </th>
                    <th>
                      <h4 className="thhead">Open</h4>
                    </th>
                    <th>
                      <h4 className="thhead">Closed</h4>
                    </th>
                    <th>
                      <h4 className="thhead">Overdue</h4>
                    </th>
                    <th>
                      <h4 className="thhead">Extended</h4>
                    </th>
                    <th>
                      <h4 className="thhead">Closed und...</h4>
                    </th>
                    <th>
                      <h4 className="thhead">Analytics</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <div className="spinner-bounce-loader">
                      <BounceLoader color="#a6fafa" />
                    </div>
                  ) : paginatedData.length === 0 ? (
                    <p className="data-error-text">No data found</p>
                  ) : (paginatedData.map((audit, index) => (
                    <tr key={index}>
                      <td>
                        <span
                          className="clickable"
                          onClick={() => viewauditIssue(audit?.audit_id)}
                        >
                          {audit.name}
                        </span>
                        <span
                          className="clickable"
                          onClick={() => viewauditIssue(audit?.audit_id)}
                        >
                          ({audit?.audit_id})
                        </span>
                      </td>
                      <td>
                        <span>{audit?.year_of_audit}</span>
                      </td>
                      <td>
                        <span>{audit?.total_issues}</span>
                      </td>
                      <td>
                        <span>{audit?.open_issues}</span>
                      </td>
                      <td>
                        <span>{audit?.closed_issues}</span>
                      </td>
                      <td>
                        <span>{audit?.overdue_issues}</span>
                      </td>
                      <td>
                        <span>{audit?.extended_issues}</span>
                      </td>
                      <td>
                        <span>{audit?.closed_under_monitoring}</span>
                      </td>
                      <td>
                        <button
                          onClick={() => viewanalytics(audit?.audit_id)}
                          className="cmn_btn"
                          style={{ backgroundColor: "#5771DF" }}
                        >

                          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 22H22" stroke="white" stroke-width="1" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.75 4V22H14.25V4C14.25 2.9 13.8 2 12.45 2H11.55C10.2 2 9.75 2.9 9.75 4Z" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M3 10V22H7V10C7 8.9 6.6 8 5.4 8H4.6C3.4 8 3 8.9 3 10Z" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17 15V22H21V15C21 13.9 20.6 13 19.4 13H18.6C17.4 13 17 13.9 17 15Z" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>


                          View Analytics
                        </button>
                      </td>
                    </tr>
                  )))}
                </tbody>
              </table>
              <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container"
            activeClassName={"active"}
          />
            </div>
          </div>
        </div>

        {/* <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard" onClick={() => Viewuser(data)}>
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div> */}
      </section>
    </>
  );
};

export default Issuetracking;
