import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../../css/viewuser.css";
import "../../rcm/listofrcap.css";
import OptionDropDown from "../../../OptionDropDown";
import {
    fetchDataV2,
    justFetchDataV2,
    postDataWithFetchV2,
    updatewithFetchDataV2,
    deleteDataV2,
} from "../../../apiUtils";
import { auditRoutes } from "../../../subroutes";
import axios from "axios";
import WindowComponent from "../../../WindowComponent";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../../RenderIcons";
import Button from "../../../user/Button";
import NavRow from "../../../NavRow";

const DetailRcapPwa = ({ tableSize }) => {
    const [errors, setErrors] = useState("");
    const [showDone, setShowDone] = useState(false);
    const [APIData, setAPIData] = useState([]);
    const [audit,setAudit]=useState([])
    const [successMessageQuestion, setSuccessMessage] = useState(false);
    const [DepartmetData, setDepartmentData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const successMessage = location.state?.successMessage;
    const [uploadStatus, setUploadStatus] = useState("idle");
    const [badRequest, setBadRequest] = useState("");
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { id, planid, tempId, departmentId,rid } = useParams();
    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [editedRowData, setEditedRowData] = useState({});
    const [entityId, setEntityId] = useState("");
    const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [control_existing, setExistingControl] = useState("");
    const [control_desired, setDesiredControl] = useState("");
    const [control_design, setControlDesign] = useState("");
    const [audit_procedure_data, setDataRequired] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [likelikhoodError, setLikelikhoodError] = useState(false);
    const [impactError, setImpactError] = useState(false);
    const [existingControlError, setExistingControlError] = useState(false);
    const [desiredControlError, setDesiredControlError] = useState(false);
    const [evaluationError, setEvaluationError] = useState(false);
    const [controlDesignError, setControlDesignError] = useState(false);
    const [controlTypeError, setcontrolTypeError] = useState(false);
    const [resLikelihoodError, setResLikelihoodError] = useState(false);
    const [resImpactError, setResImpactError] = useState(false);
    const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
    const [dataRequiredError, setDataRequiredError] = useState(false);
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [objectiveError, setObjectiveError] = useState(false);
    const [riskIdError, setRiskIdError] = useState(false);
    const [riskCategoryError, setRiskCategoryError] = useState(false);
    const [describeRiskError, setDescribeRiskError] = useState(false);
    const [titleRiskError, setTitleRiskError] = useState(false);
    const segments = location.pathname.split("/");
    const AuditExecution = segments.find(
        (segment) => segment === "AuditExecution"
    );
    const [showNewRow, setShowNewRow] = useState(false);
    const [deptObject, setDeptObject] = useState({});
    const [businessUnitsId, setBusinessUnitsId] = useState([]);
    const [departmentNames, setDepartmentNames] = useState([]);
    const [departmentIds, setDepartmentIds] = useState({});
    const [planData, setPlanData] = useState([]);
    const [showApproves, setShowApproves] = useState(false);
    const user_id = localStorage.getItem("user_id");



    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate(".", { replace: true });
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        if (APIData.length > 0) {
            setShowDone(true);
        }
    }, [APIData]);

    useEffect(()=>{
        const parameter={
            url:`/api/audit_universe/rcap/${rid}/`,
            setterFunction:setAPIData,
            setErrors:setErrors,
        }
        fetchDataV2(parameter)
    },[rid])

  

    function impactmapping(value) {
        switch (value) {
          case 1:
            return "Low (1)";
          case 2:
            return "Minor (2)";
          case 3:
            return "Moderate (3)";
          case 4:
            return "Major (4)";
          case 5:
            return "Severe (5)";
          default:
            return "";
        }
      }
      function likemapping(value) {
        switch (value) {
          case 1:
            return "Remote (1)";
          case 2:
            return "Unlikely (2)";
          case 3:
            return "Possible (3)";
          case 4:
            return "Likely (4)";
          case 5:
            return "Almost Certain (5)";
          default:
            return "";
        }
      };

    useEffect(() => {
        if (id) {
            const parameter = {
                url: `/api/audit_engagement/audit/${id}/`,
                setterFunction: setAudit,
                setErrors: setErrors,
            }
            fetchDataV2(parameter);
        }
    }, [id]);

    const [auditEntity, setAuditEntity] = useState([]);
    useEffect(() => {
        if (AuditExecution) {
            const parameter = {
                url: `/api/audit_engagement/workpaper/?audit_id=${id}`,
                setterFunction: setAPIData,
                setErrors: setErrors,
            }
            fetchDataV2(parameter);
        }
    }, [AuditExecution, id, setAPIData, setErrors]);


    return (
        <>
            {errors && <p className="errormessage">{errors}</p>}
            {successMessage && <p className="successmessage">{successMessage}</p>}
            {successMessageQuestion && (
                <p className="successmessage">Question Added Successfully</p>
            )}
            <section className="navroutes">
                <RenderIcons homeRoutes={auditRoutes} />
                <NavRow
                    name={`${APIData?.name ? APIData?.name : "ALL"}`}
                    parentName={audit?.name}
                    Create={"Create"}
                    link={`/administration/user/createUser`}
                    trueback={true}
                    // RcmCreate={() => handleCreateRow()}
                    // rcapUpload={rcapUpload}
                    secondName={"Planning"}
                />

                <div className="rcmt_table"
                    style={{ width: tableSize ? "86.5vw" : "92.5vw" }}
                >
                    <h1>This Page is only <br /> available on Mobile App</h1>
                </div>

                <div className="cardscontainer">

                    <div className="previous_rcm_data ">
                        <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>
                            Risk Control Matrix Audit Procedure
                        </h2>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Introductory
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Functions</label>
                                    <p>
                                        {APIData.rcap
                                            ? APIData.rcap.business_unit?.name
                                                ? APIData.rcap.business_unit?.name
                                                : "All Functions"
                                            : "All Functions"}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Process</label>
                                    <p>{APIData.process ? APIData.process : "--"}</p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Sub-Process</label>
                                    <p>{APIData.sub_process ? APIData.sub_process : "--"}</p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Objective Description</label>
                                    <p>{APIData.objective ? APIData.objective : "--"}</p>
                                </div>
                            </div>
                        </div>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Risk Description
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Risk ID</label>
                                    <p>{APIData.risk_id ? APIData.risk_id : ""}</p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Risk Category</label>
                                    <p>
                                        {APIData.risk_category ? APIData.risk_category : ""}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Risk Title</label>
                                    <p>{APIData.risk_title ? APIData.risk_title : "--"}</p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Risk Statement</label>
                                    <p>
                                        {APIData.risk_description
                                            ? APIData.risk_description
                                            : ""}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Inherent Assessment
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Inh. Likelihood</label>
                                    <p>
                                        {likemapping(
                                            APIData.inherent_assessment_likelihood
                                                ? APIData.inherent_assessment_likelihood
                                                : "--"
                                        )}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Inh. Impact</label>
                                    <p>
                                        {impactmapping(
                                            APIData.inherent_assessment_impact
                                                ? APIData.inherent_assessment_impact
                                                : "--"
                                        )}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Inherent Risk Evaluation Score</label>
                                    <p>
                                        {APIData.risk_inherant_evaluation_score
                                            ? APIData.risk_inherant_evaluation_score
                                            : "--"}
                                    </p>
                                </div>
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Inherent Risk Evaluation</label>
                                    <p>
                                        {APIData.risk_inherant_evaluation
                                            ? APIData.risk_inherant_evaluation
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Control
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Control Description</label>
                                    <pre>
                                        {APIData.control_description
                                            ? APIData.control_description
                                            : "--"}
                                    </pre>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Control Design</label>
                                    <p>
                                        {APIData.control_design ? APIData.control_design : "--"}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Control Type</label>
                                    <p>
                                        {APIData.control_type ? APIData.control_type : "--"}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Control Evaluation</label>
                                    <p>
                                        {APIData.control_evaluation
                                            ? APIData.control_evaluation
                                            : "--"}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Best Practices for Reference</label>
                                    <p>
                                        {APIData.control_desired
                                            ? APIData.control_desired
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Residual Assessment
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Res. Likelihood</label>
                                    <p>
                                        {likemapping(
                                            APIData.residual_assessment_likelihood
                                                ? APIData.residual_assessment_likelihood
                                                : "--"
                                        )}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Res. Impact</label>
                                    <p>
                                        {impactmapping(
                                            APIData.residual_assessment_impact
                                                ? APIData.residual_assessment_impact
                                                : "--"
                                        )}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Residual Risk Evaluation Score</label>
                                    <p>
                                        {APIData.risk_residual_evaluation_score
                                            ? APIData.risk_residual_evaluation_score
                                            : "--"}
                                    </p>
                                </div>
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Residual Risk Evaluation </label>
                                    <p>
                                        {APIData.risk_residual_evaluation
                                            ? APIData.risk_residual_evaluation
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Audit Procedure
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Audit Step Details</label>
                                    <pre>
                                        {APIData.audit_procedure_step
                                            ? APIData.audit_procedure_step
                                            : "--"}
                                    </pre>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Data Required</label>
                                    <p>
                                        {APIData.audit_procedure_data
                                            ? APIData.audit_procedure_data
                                            : "--"}
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>
        </>
    );
};

export default DetailRcapPwa;
