import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../css/viewuser.css";
import { fetchDataV2, postDataV2 } from "../../apiUtils";
import {
  auditExecution,
  auditExecutionInnerBU,
  auditExecutionInnerQA,
  auditBusiness,
} from "../../subroutes";
import openarrow from "../../../images/openarrow.svg";
import "../auditplan/auditplan.css";
import ProgressBar from "../progressbar/ProgressBar";
import "./auditwrapup.css";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";

const AuditWrapUp = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [checklistTaskData, setChecklistTaskData] = useState([]);
  const [answer1, setAnswer1] = useState([]);
  const [isAnswer1Loading, setIsAnswer1Loading] = useState(true);
const [isChecklistTaskDataLoading, setIsChecklistTaskDataLoading] = useState(true);
  const [surveyId, setQaSurveyId] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const { id } = useParams();
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const name = auditData.name;
  var routes = [];

  if (is_Admin) {
    routes = auditExecution;
  } else if (Object.keys(userRoles).some(key => key.includes("Business"))) {
    routes = auditExecutionInnerBU;
  } else if (Object.keys(userRoles).some(key => key.includes("Quality Assurance"))) {
  } else if (Object.keys(userRoles).some(key => key.includes("Audit Team"))) {
    routes = auditExecution;
  } else if (Object.keys(userRoles).some(key => key.includes("Quality Assurance"))) {
    routes = auditExecutionInnerQA;
  } else {
    console.log("No matching role found");
  }

  const wrapcarddata = [
    {
      id: "1",
      objective: "Audit Feedback",
      risk_title: "Audit Risk Title 1",
      unit_head: { name: "John Doe" },
      department: { name: "Quality Assurance" },
    },
    {
      id: "2",
      objective: "QA Checklist",
      risk_title: "Audit Risk Title 2",
      unit_head: { name: "Jane Smith" },
      department: { name: "Compliance" },
    },
  ];

  const Viewuser = (id) => {
    navigate(`/audit-execution/${id}/WrapupQA/${id}`);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/audit-entities/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
  }, []);


  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAuditData,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
  }, [id]);


  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/qa-survey/?audit_id=${id}`,
      setterFunction: setQaSurveyId,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/qa-survey/answers/?audit_id=${id}`,
      setterFunction: (data) => {
        setAnswer1(data);
        setIsAnswer1Loading(false);
      },
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/qa-checklist/tasks/?audit_id=${id}`,
      setterFunction: (data) => {
        setChecklistTaskData(data);
        setIsChecklistTaskDataLoading(false);
      },
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  const createTask = () => {
    const parameter = {
      url: `/api/audit_engagement/qa-checklist/tasks/`,
      payload: {
        audit_id: parseInt(id),
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/wrapup-qa/qachecklist/`,
    };

    postDataV2(parameter);
  };

  const createqasuvreytask = () => {
    const parameter = {
      url: `/api/audit_engagement/qa-survey/answers/`,
      payload: {
        audit_id: parseInt(id),
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/wrapup-qa/qasurvey/`,
    };
    postDataV2(parameter);
  };

  const navigateTOFeedback = () => {
    navigate(`/audit-execution/${id}/wrapup-qa/qasurvey`);
  };

  const navigateTOCheckList = () => {
    navigate(`/audit-execution/${id}/wrapup-qa/qachecklist/`);
  };

  const phaseTrue = {
    phase: auditData.phase,
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        
        <RenderIcons homeRoutes={routes} id={id} />
        <NavRow
          parentName={name}
          name={name}
          phaseTrue={phaseTrue}
          backToExecution={`/audit-execution`}
        />


        <div className="wraptable" style={{ width: tableSize ? "100%" : "100%" }}>
          <table>
            <thead>
              <tr className="tablehead">
                <th>Action</th>
                <th>Task</th>
                <th>Generate Task</th>
                <th>Progress</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <input type="checkbox" name="" id="" />
                </td>
                <td
                  onClick={() =>
                    answer1.length !== 0 ? navigateTOFeedback() : null
                  }
                  style={{
                    cursor: answer1.length !== 0 ? "pointer" : "not-allowed",
                  }}
                >
                  <div className="checkbox_detail">Audit Feedback</div>
                </td>
                <td>
                  {!isAnswer1Loading && answer1.length === 0 && (
                    <button
                      onClick={createqasuvreytask}
                      style={{ backgroundColor: "#65CC5C" }}
                      className="cmn_btn"
                    >
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 4.72621L3.18182 6.90803L9 1.08984"
                          stroke="white"
                          stroke-width="1.45455"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Generate Task
                    </button>
                  )}
                </td>

                <td>
                  <ProgressBar percentage={answer1[0]?.survey_answer} />
                  {console.log(answer1[0]?.survey_answer)}
                </td>
              </tr>

              <tr>
                <td>
                  <input type="checkbox" name="" id="" />
                </td>
                <td
                  onClick={() =>
                    checklistTaskData.length !== 0
                      ? navigateTOCheckList()
                      : null
                  }
                  style={{
                    cursor:
                      checklistTaskData.length !== 0
                        ? "pointer"
                        : "not-allowed",
                  }}
                >
                  <div className="checkbox_detail">QA Checklist</div>
                </td>

                <td>
                  {!isChecklistTaskDataLoading && checklistTaskData.length === 0 && (
                    <button
                      className="cmn_btn"
                      style={{ backgroundColor: "#65CC5C" }}
                      onClick={createTask}
                    >
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 4.72621L3.18182 6.90803L9 1.08984"
                          stroke="white"
                          stroke-width="1.45455"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Generate Task
                    </button>
                  )}
                </td>

                <td>
                  <ProgressBar
                    percentage={checklistTaskData[0]?.survey_checklist}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <div className="cardscontainer">

            <div className="deptcard"
              onClick={() => navigateTOFeedback()}
            >
              <div className="bunstkhlder">
                <h2>
                  <p className="buplacehldr">
                    Audit Feedback
                  </p>
                </h2>
              </div>

              <img src={openarrow} alt="Open Arrow" />
            </div>

            <div className="deptcard"
              onClick={() => navigateTOCheckList()}
            >
              <div className="bunstkhlder">
                <h2>
                  <p className="buplacehldr">
                    QA Checklist
                  </p>
                </h2>
              </div>

              <img src={openarrow} alt="Open Arrow" />
            </div>

        </div>

        {/* <div className="button_container" style={{ marginTop: "2vh" }}>
          <button className="cmn_btn" style={{ backgroundColor: "#5771DF" }}>
            View Audit Report
          </button>
        </div> */}

      </section>
    </>
  );
};

export default AuditWrapUp;
