import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  postDataV2,
  deleteDataV2,
  fetchDataObjectV2,
  justUpdateDataV2,
  updateDataV3,
  postDataWithFetchV2,
} from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import cancelbtn from "../../../../images/closeicon.svg";
import delbtn from "../../../../images/delete_wbg.svg";
import SingleOptionSelector from "../../../SingleOptionSelector";
import WindowComponent from "../../../WindowComponent";
import "../viewTask/viewtask.css";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";
import SingleOptionSelectorAuditBU from "../../../SingleOptionSelectorAuditBU";
import SingleOptionSelectorAuditPR from "../../../SingleOptionSelectorAuditPR";

const ViewInitialReqList = ({ tableSize, APIData, setAPIData }) => {
  const [errors, setErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [objective, setObjective] = useState(APIData.objective);
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_id, setAssign_To] = useState("");
  const [assignError, setAssignError] = useState(false);
  const [scheduled_start_date, setStartDate] = useState(
    APIData.scheduled_start_date
  );
  const [successMessageUpdate, setSuccessMessageUpdate] = useState(false);
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState(APIData.scheduled_end_date);
  const [end_dateError, setEnd_dateError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showRow, setShowRow] = useState(false);
  const { id, planid } = useParams();
  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (editMode) {
      if (!preparer_id) {
        setPreparerError(!preparer_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null);
        }, 5000);
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/plan/preparer/${planid}/`,
        payload: {
          preparer_id: preparer_id,
        },
        setErrors: setErrors,
      };

      justUpdateDataV2(parameter);
    }
  }, [preparer_id]);

  useEffect(() => {
    if (editMode) {
      if (!reviewer_id) {
        setReviewerError(!reviewer_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null);
        }, 5000);
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/plan/reviewer/${planid}/`,
        payload: {
          reviewer_id: reviewer_id,
        },
        setErrors: setErrors,
      };

      justUpdateDataV2(parameter);
    }
  }, [reviewer_id]);

  useEffect(() => {
    if (editMode) {
      if (!assign_to_id) {
        setAssignError(!assign_to_id ? true : false);
        setErrors("Please Fill the Field");
        setTimeout(() => {
          setErrors(null);
        }, 5000);
        return;
      }
      const parameter = {
        url: `/api/audit_engagement/plan/assign/${planid}/`,
        payload: {
          assign_to_id: assign_to_id,
        },
        setErrors: setErrors,
      };
      justUpdateDataV2(parameter);
    }
  }, [assign_to_id]);

  const updateAPIData = (e) => {
    e.preventDefault();
    const listData = allData.map((data) => data.name);
    if (
      !preparer_id ||
      !reviewer_id ||
      !assign_to_id ||
      !scheduled_start_date ||
      !scheduled_end_date ||
      !id
    ) {
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssignError(!assign_to_id ? true : false);
      setStart_dateError(!scheduled_start_date ? true : false);
      setEnd_dateError(!scheduled_end_date ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (new Date(scheduled_end_date) < new Date(scheduled_start_date)) {
      setErrors("End Date should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const Parameter = {
      url: `/api/audit_engagement/plan/${planid}`,
      payload: {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        scheduled_start_date,
        scheduled_end_date,
        plan_type: "Send Initial List Of Requirements",
        audit_id: id,
        data: {
          details: listData,
        },
      },
      tofetch: {
        setSuccessMessage: setSuccessMessageUpdate,
        items: [
          {
            fetchurl: `/api/audit_engagement/plan/${planid}/`,
            dataset: setAPIData,
          },
        ],
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/audit-execution/${id}/planning`,
    };

    updateDataV3(Parameter);
    setEditMode(false);
  };
  const handleEditClick = () => {
    if(APIData.task.status==="in progress"){

      setEditMode(true);
    }
  };
  const onDelete = (e) => {
    e.preventDefault();
    const parameter = {
      url: `/api/audit_engagement/plan/${planid}`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/planning`,
    };

    deleteDataV2(parameter);
  }

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  function convertDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function handleStartDateChange(e) {
    const inputValue = e.target.value;
    if (!inputValue) {
      setStartDate(null);
      setStart_dateError(true);
      return;
    }

    const localDate = new Date(inputValue);
    const utcDate = new Date(
      localDate.getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000
    );
    setStartDate(utcDate.toISOString());
    setStart_dateError(false);
  }

  function handleEndDateChange(e) {
    const inputValue = e.target.value;
    if (!inputValue) {
      setEndDate(null);
      setEnd_dateError(true);
      return;
    }

    const localDate = new Date(inputValue);
    const utcDate = new Date(
      localDate.getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000
    );
    setEndDate(utcDate.toISOString());
    setEnd_dateError(false);

    if (new Date(inputValue) < new Date(scheduled_start_date)) {
      setErrors(
        "End Date should be greater than or equal to Start Date."
      );
      setTimeout(() => {
        setErrors(null);
      }, 5000);
    }
  }

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);
  const createRow = (e) => {
    e.preventDefault();
    setInputList((prevList) => [...prevList, ""]);
    setShowRow(true);
  };

  const handleInputChange = (index, event) => {
    const { value } = event.target;
  
    setInputList((prevList) => {
      const updatedList = [...prevList];
      updatedList[index] = value;
      setAllData(updatedList); // Synchronize `allData` with the updated `inputList`
      return updatedList;
    });
  };

  const handleCancel = (index, e) => {
    e.preventDefault();
    setAllData((prevData) => prevData.filter((u, i) => i != index));
    setInputList((prevList) => prevList.filter((u, i) => i != index));
  };

  const handleSendRequest = (e) => {
    e.preventDefault();
    if(APIData.task.status==="in progress"){
    const parameter = {
      url: `/api/system/task/`,
      payload: {
        title: "Initial Task",
        audit_id: id,
        data_type: "audit_file_upload",
        file_list: allData,
      },
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/plan/${planid}/`,
            dataset: setAPIData,
          },
        ],
        setSuccessMessage: setSuccessMessage,
        setErrors: setErrors,
      },

    };
    postDataWithFetchV2(parameter);
    setInputList([""]);
    setShowRow(false);
    setIsRequestSent(true)
  }
  };

  const [maketrue, setTrue] = useState(false);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageUpdate && (
        <p className="successmessage">Updated SuccessFully</p>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />

        <NavRow
          name={
            editMode
              ? "Edit Initial List Of Requirements"
              : "Initial List Of Requirements"
          }
          parentName={APIaudit?.name}
        />

        <div
          className="createauditmemo"
          style={{
            width: tableSize ? "100%" : "100%",
          }}
        >
          <form onDoubleClick={handleEditClick}>
            <div className="lng_lbl_inpt">
              <label>Objective</label>
              <input
                type="text"
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                placeholder="Objective"
                value={objective}
                onChange={(e) => {
                  setObjective(e.target.value);
                }}
                readOnly={!editMode}
                tabIndex={1}
              />
            </div>

            <div className="lbl_inpt">
              <label> Start Date: </label>
              <input
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  border: start_dateError ? "1px solid #FF4646" : "",
                }}
                type="date"
                value={
                  scheduled_start_date ? convertDate(scheduled_start_date) : ""
                }
                onChange={handleStartDateChange}
                readOnly={!editMode}
                tabIndex={2}
              />
            </div>

            <div className="lbl_inpt">
              <label> End Date: </label>
              <input
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  border: end_dateError ? "1px solid #FF4646" : "",
                }}
                type="date"
                value={
                  scheduled_end_date ? convertDate(scheduled_end_date) : ""
                }
                onChange={handleEndDateChange}
                readOnly={!editMode}
                tabIndex={3}
              />
            </div>

            <SingleOptionSelectorAuditPR
              id={id}
              setSelectorId={setPreparerId}
              setSelectorError={setPreparerError}
              selectorError={PreparerError}
              Name="name"
              title="Preparer"
              initialId={APIData.preparer ? APIData.preparer.id : ""}
              initialName={APIData.preparer ? APIData.preparer.name : ""}
              editMode={editMode}
              tabIndex={4}
              maketrue={maketrue}
              setTrue={setTrue}
            />

            <SingleOptionSelectorAuditPR
              id={id}
              setSelectorId={setReviewerId}
              setSelectorError={setReviewerError}
              selectorError={reviewerError}
              Name="name"
              title="Reviewer"
              initialId={APIData.reviewer ? APIData.reviewer.id : ""}
              initialName={APIData.reviewer ? APIData.reviewer.name : ""}
              editMode={editMode}
              tabIndex={5}
              maketrue={maketrue}
              setTrue={setTrue}
            />

            <SingleOptionSelectorAuditBU
              id={id}
              setSelectorId={setAssign_To}
              setSelectorError={setAssignError}
              selectorError={assignError}
              Name="name"
              title="Assign To"
              initialId={APIData.assign_to ? APIData.assign_to.id : ""}
              initialName={APIData.assign_to ? APIData.assign_to.name : ""}
              editMode={editMode}
              tabIndex={6}
              maketrue={maketrue}
              setTrue={setTrue}
            />

            <div
              className="section"
              style={{ backgroundColor: "#EEF1FC", borderRadius: "4px" }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  backgroundColor: "#fff",
                  padding: "15px",
                  borderRadius: "4px",
                }}
              >
                <div
                  className="lng_lbl_inpt"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    boxShadow: "0 .4vw .6vw -.7vw rgba(0, 0, 0, 0.5)",
                    paddingBottom: "4px",
                    width: "100%",
                  }}
                >
                  <label>List down the initial requirements </label>
                  <button
                    className="cmn_btn"
                    onClick={createRow}
                    style={{ backgroundColor:APIData.task.status==="Done"||!editMode?'#7a7a7a': "#65cc5c" }}
                    tabIndex={7}
                    disabled={APIData.task.status==="Done"||!editMode}
                    // readOnly={!editMode}
                  >
                    Add File Request
                  </button>
                </div>

                {showRow &&
                  inputList.map((input, index) => (
                    <div
                      className="lng_lbl_inpt"
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <input
                        type="text"
                        value={input}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                      <div style={{ display: "flex", gap: "1.5vw" }}>
                        <button
                          onClick={(e) => handleCancel(index, e)}
                          style={{
                            background: "none",
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            display: "flex",
                            gap: "12px",
                          }}
                        >
                          <img
                            src={delbtn}
                            alt="Cancel"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </form>

          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
              disabled={!editMode}
              onClick={updateAPIData}
              tabIndex={8}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Save
            </button>

            {editMode && (
              <button
                onClick={handleCancelClick}
                className="cmn_btn"
                style={{ backgroundColor: "#d6d6d6", color: "#000" }}
                tabIndex={9}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L10.9989 10.9989"
                    stroke="#292D32"
                    stroke-width="1.76758"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.00107 10.9989L11 1"
                    stroke="#292D32"
                    stroke-width="1.76758"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>  
                Cancel Edit
              </button>
            )}

            <WindowComponent
              disabled={APIData.task.status==="Done"}
              onDelete={onDelete}
              text="Delete Initial Requirements Lists"
              tabIndex={10}
              style={{backgroundColor:APIData.task.status==="Done"?'#7a7a7a':'#fe2768'}}
            />
            <button
              className="cmn_btn"
              style={{ backgroundColor:APIData.task.status==="Done"?'#7a7a7a': "#577dff" }}
              onClick={handleSendRequest}
              disabled={showRow===false||APIData.task.status==="Done"}
              tabIndex={11}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Send Request
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewInitialReqList;
