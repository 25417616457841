import React, { useState, useEffect, useRef } from "react";
import { fetchDataV2 } from "./apiUtils";
import cut from "../images/cut.svg";
import "../css/department.css";

const MultiDepartmentBu = ({
  rolesData,
  apiUrl,
  setSelectorId,
  selectedIds,
  Name,
  title,
  initialIds,
  initialNames,
  editMode,
  tabIndex = 0,
  setSelectedDept,
  business_units_id,
  setBusinessUnit_id,
  setSelectedBUList,
  selectedBUList,
  selectedDept,
  initialDeptp
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);


 
  
  useEffect(() => {
    const updateSelectedWithNames = () => {
      const updatedSelections = selectedIds?.map((id) => {
        const matchingItem = data.find((item) => item.id === id);
        return { id, Name: matchingItem ? matchingItem[Name] : `Name for ID: ${id}` };
      });
  
      setSelected(updatedSelections);
    };
  
    updateSelectedWithNames();
  }, [selectedIds, data, Name]);
  
  




  useEffect(() => {
    if (initialIds) {
      const fetchMissingNames = async () => {
        const initialSelections = [];
        for (let i = 0; i < initialIds.length; i++) {
          const id = initialIds[i];
          const name = initialNames?.[i]; // Check if the name is provided
          if (name) {
            initialSelections.push({ id, Name: name });
          } else {
            // Fetch the name if it's missing
            try {
              const response = await fetch(`${apiUrl}?id=${id}`);
              const result = await response.json();
              initialSelections.push({ id, Name: result[Name] || `Name for ID: ${id}` });
            } catch {
              initialSelections.push({ id, Name: `Name for ID: ${id}` });
            }
          }
        }
        setSelected(initialSelections);
        setSelectorId(initialIds);
      };
  
      fetchMissingNames();
    }
  }, [initialIds, initialNames, apiUrl, Name, setSelectorId]);
  


  useEffect(() => {
    if (apiUrl) {
      const parameter = {
        url: apiUrl,
        setterFunction: setData,
      };
      fetchDataV2(parameter);
    }
    if (rolesData) {
      setData(rolesData);
    }
  }, [apiUrl]);


useEffect (()=>{
  if(initialDeptp)
  {
    setSelectedBUList(data
      .filter(department => initialDeptp.includes(department.id))
      .flatMap(department => department.business_units));
  }

},[initialDeptp, data])

  useEffect(() => {
    if (initialIds && initialNames) {
      const initialSelections = initialIds.map((id, index) => ({
        id,
        Name: initialNames[index],
      }));
      setSelected(initialSelections);
      setSelectorId(initialIds);
    }
  }, [initialIds, initialNames, setSelectorId]);

  useEffect(() => {
    if (expanded) {
      inputRef.current.focus();
    }
  }, [expanded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChangeDepts = async (unitId, unitName, BUList) => {
    setSelectedBUList(prevState => {
      const existingIds = new Set(prevState);
      const newIds = BUList;
      
      const uniqueIds = [...newIds].filter(id => !existingIds.has(id));
      
      return [...prevState, ...uniqueIds];
    });
    
    const newBU = BUList.map(obj => obj.id);
    const isSubset = newBU.every(element => business_units_id.includes(element));
    
    if (isSubset) {
      setBusinessUnit_id(prevState => 
        prevState.filter(id => !newBU.includes(id))
      );
    } else {
      setBusinessUnit_id(prevState => {
        const existingIds = new Set(prevState);
        const newIds = BUList.map(item => item.id);
        
        const uniqueIds = [...newIds].filter(id => !existingIds.has(id));
        
        return [...prevState, ...uniqueIds];
      });
    }
    






  
  
      const isChecked = selectedIds?.includes(unitId);
    const updatedSelectedIds = isChecked
      ? selectedIds.filter((id) => id !== unitId)
      : [...selectedIds, unitId];
  
    if (!isChecked && !unitName) {
      // Fetch the name if not provided
      try {
        const response = await fetch(`${apiUrl}?id=${unitId}`);
        const result = await response.json();
        unitName = result[Name] || `Name for ID: ${unitId}`;
      } catch {
        unitName = `Name for ID: ${unitId}`;
      }
    }
  
    const updatedSelected = isChecked
      ? selected.filter((dept) => dept.id !== unitId)
      : [...selected, { id: unitId, Name: unitName }];
  
    setSelected(updatedSelected);
    setSelectorId(updatedSelectedIds);

    const department = updatedSelected.map((dept) => dept.id);
    setSelected(department);
    setSelectorId(department); 



  };
  
  
  const handleSearchChangeDepts = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleExpandDept = () => {
    setExpanded(!expanded);
  };



  


  


  const handleRemoveSelectedDepts = (unitToRemove) => {
    console.log(selectedBUList)
    const toRemoveID = selectedBUList.filter(obj => obj.department === unitToRemove.id).map(obj => obj.id)

    setBusinessUnit_id(business_units_id.filter(element => !toRemoveID.includes(element)))

    const updatedSelected = selected.filter(
      (dept) => dept.id !== unitToRemove.id
    );
    const updatedSelectedIds = selectedIds.filter(
      (id) => id !== unitToRemove.id
    );
  
    setSelected(updatedSelected);
    setSelectorId(updatedSelectedIds);
   
  };
  


  return (
    <div className="lng_lbl_selector">
      <label>{title}</label>

      <div
        ref={dropdownRef}
        className="ref_div"
        style={{
          backgroundColor: editMode ? "#EEF1FC" : "#E6E6E6",
        }}
        tabIndex={tabIndex}
        onKeyDown={(e) => {
          if (e.key === "Enter" && editMode) {
            toggleExpandDept();
          } else if (e.key === "Tab") {
            setExpanded(false);
          }
        }}
      >
        <div
          className="sub_ref_div"
          onClick={() => {
            if (editMode) {
              toggleExpandDept();
            }
          }}
        >
          {selected?.length === 0 ? (
            <p className="slct_plchldr">{title}</p>
          ) : (
            selected?.map((dept) => (
              <span
                key={dept.id}
                className="slct_tag"
                style={{
                  backgroundColor: editMode ? "#5757df" : "#5f5f5f",
                }}
              >
                <span>{dept.Name}</span>

                <img
                  src={cut}
                  alt="Dashboard Icon"
                  onClick={() => {
                    if (editMode) {
                      handleRemoveSelectedDepts(dept);
                    }
                  }}
                />
              </span>
            ))
          )}
        </div>

        <div
          className="opt_drp_dwn"
          style={{
            visibility: expanded ? "visible" : "hidden",
            height: expanded ? "auto" : "0vh",
            backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
          }}
        >
          <input
            ref={inputRef}
            type="text"
            placeholder={`Search ${title}`}
            value={searchTerm}
            onChange={handleSearchChangeDepts}
          />

          <div>
            {data.filter((unit) =>
              unit[Name]
                ? unit[Name].toLowerCase().includes(searchTerm.toLowerCase())
                : true
            ).length === 0 ? (
              <div className="noDataFound">No data found</div>
            ) : (
              data
                .filter((unit) =>
                  unit[Name]
                    ? unit[Name].toLowerCase().includes(
                        searchTerm.toLowerCase()
                      )
                    : true
                )
                .map((unit) => (
                  <div
                    key={unit.id}
                    className="opt_drp_dwn_optns"
                    onClick={() =>
                      handleCheckboxChangeDepts(unit.id, unit[Name], unit.business_units)
                    }
                  >
                    <label> {unit[Name]} </label>

                    <input
                      type="checkbox"
                      id={unit.id}
                      value={unit.id}
                      checked={selectedIds?.includes(unit.id)}
                      readOnly
                    />
                  </div>
                ))
            )}
          </div>

          <div className="closebutton" onClick={toggleExpandDept}>
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiDepartmentBu;
