import React, { useState, useEffect } from "react";
import MultiOptionSelector from "../../MultiOptionSelector";
import LongMultiOptionSelector from "../../LongMultiOptionSelector";
import {
  postDataReturnId,
  updateDataV2,
  fetchDataV2,
  fetchDataObjectV2,
} from "../../apiUtils";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import IconButton from "../../iconbutton/IconButton";
import RenderIcons from "../../RenderIcons";
import Breadcrumb from "../../Breadcrumb";
import { auditExecution } from "../../subroutes";
import NavRow from "../../NavRow";

const ScheduleMeetingSendInitial = ({ tableSize }) => {
  const location = useLocation();

  const [errors, setErrors] = useState("");
  const [successMessageOther, setSuccessMessage] = useState("");
  const [objective, setObjective] = useState("");
  const [objectiveError, setObjectiveError] = useState("");
  const [start_date, setStartDate] = useState("");
  const [start_dateError, setStart_dateError] = useState(false);
  const [end_date, setEndDate] = useState("");
  const [end_dateError, setEnd_dateError] = useState(false);
  const [participantError, setParticipantError] = useState(false);
  const [participant_ids, setParticipants] = useState([]);
  const [scheduleMeetingId, setScheduleMeetingId] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [selectedOtherKey, setSelectedOtherKey] = useState([]);
  const [other_stakeholders_ids, setOther_stakeholders_ids] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [auditData, setAuditData] = useState([]);
  const [businessStakeholders, setBusinessStakeholders] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  const successMessage = location.state?.successMessage;

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAuditData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/business-stakeholders/`,
      setterFunction: setBusinessStakeholders,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    if (auditData?.closing_meeting) {
      setEditMode(false);
      setIsSaved(true);
      const parameter = {
        url: `/api/system/meeting/${auditData.closing_meeting?.id}/`,
        setterFunction: setMeetingData,
        setErrors: setErrors,
      };
      fetchDataV2(parameter);
    }
  }, [auditData]);

  const participants = businessStakeholders.map((participant) => ({
    id: participant.id,
    name: participant.name,
  }));

  console.log(participants);

  useEffect(() => {
    if (meetingData) {
      setStartDate(meetingData.start_date);
      setEndDate(meetingData.end_date);
      const participantIds =
        meetingData.participants?.map((participant) => participant.id) || [];
      setParticipants(participantIds);
      const otherIds =
        meetingData.participants?.map((participant) => participant.id) || [];
      const otherNames =
        meetingData.participants?.map((participant) => participant.name) || [];
      setOther_stakeholders_ids(otherIds);
      setSelectedOtherKey(otherNames);
    }
  }, [meetingData]);

  const postApi = (e) => {
    e.preventDefault();
    if (!start_date || !end_date || !participant_ids.length) {
      setParticipantError(!participant_ids.length);
      setStart_dateError(!start_date);
      setEnd_dateError(!end_date);
      setErrors("Please fill in all required fields.");
      setTimeout(() => setErrors(""), 5000);
      return;
    }

    const selectedParticipants = businessStakeholders
      .filter((participant) => participant_ids.includes(participant.id))
      .map((participant) => ({ id: participant.id, name: participant.name }));

    const parameter = {
      url: `/api/system/meeting/`,
      payload: {
        audit_id: id,
        start_date,
        end_date,
        data: { type: "Schedule_closing_meeting" },
        participants: selectedParticipants,
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/reporting`,
      setSuccessCallback: (data) => {
        setIsSaved(true);
        setEditMode(false);
        setScheduleMeetingId(data.id);
        const successMessageColor = "#4285F4";
        navigate(`/audit-execution/${id}/reporting/scheduleclosingmeeting/`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      },
    };

    postDataReturnId(parameter);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!auditData?.closing_meeting) {
      setErrors("No meeting scheduled to update.");
      setTimeout(() => setErrors(""), 5000);
      return;
    }

    const selectedParticipants = businessStakeholders
      .filter((participant) => participant_ids.includes(participant.id))
      .map((participant) => ({ id: participant.id, name: participant.name }));

    const Parameter = {
      url: `/api/system/meeting/${auditData?.closing_meeting?.id}`,
      payload: {
        audit_id: id,
        start_date,
        end_date,
        data: { type: "Schedule_closing_meeting" },
        participants: selectedParticipants,
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/audit-execution/${id}/reporting`,
    };

    updateDataV2(Parameter);
    //dont know why this code in updateData
    // () => {
    //   setIsSaved(true);
    //   setEditMode(false);
    //   setSuccessMessage('Meeting updated successfully.');
    //   setTimeout(() => setSuccessMessage(''), 5000);

    // },
  };

  function convertDate(inputDate) {
    const date = new Date(inputDate);

    // Subtract 5 hours and 30 minutes for display
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function handleStartDateChange(e) {
    // Convert the input value back to UTC if needed or store as is
    const localDate = new Date(e.target.value);
    // You might need to adjust to UTC if your API requires UTC dates
    const utcDate = new Date(
      localDate.getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000
    );
    setStartDate(utcDate.toISOString());
    setStart_dateError(false);
  }

  function handleEndDateChange(e) {
    // Convert the input value back to UTC if needed or store as is
    const localDate = new Date(e.target.value);
    // You might need to adjust to UTC if your API requires UTC dates
    const utcDate = new Date(
      localDate.getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000
    );
    setEndDate(utcDate.toISOString());
    setEnd_dateError(false);
    if (new Date(e.target.value) < new Date(start_date)) {
      setErrors("End Date should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
    }
  }
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageOther && (
        <p className="successmessage">{successMessageOther}</p>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow name={"Schedule Closing Meeting"} parentName={APIaudit?.name} />

        <div
          className="createmeeting"
          style={{
            width: tableSize ? "100%" : "100%"
          }}
        >

          <form>
            <div className="lbl_inpt">
              <label> Start Date: </label>
              <input
                style={{
                  border: start_dateError ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                type="datetime-local"
                value={convertDate(start_date)}
                onChange={handleStartDateChange}
                readOnly={!editMode} // Set readOnly based on editMode
              />
            </div>

            <div className="lbl_inpt">
              <label> End Date:</label>
              <input
                type="datetime-local"
                style={{
                  border: end_dateError ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                value={convertDate(end_date)}
                onChange={handleEndDateChange}
                readOnly={!editMode} // Set readOnly based on editMode
              />
            </div>

            <LongMultiOptionSelector
              apiUrl="/api/audit_universe/business-stakeholders/"
              setSelectorId={setParticipants}
              setSelectorError={setParticipantError}
              selectorError={participantError}
              Name="name"
              title="Participants"
              editMode={editMode} // Pass editMode to MultiOptionSelector
              tableSize={tableSize}
              initialIds={other_stakeholders_ids}
              initialNames={selectedOtherKey}
            />
          </form>

          <div className="button_container">
            {!isSaved && (
              <button
                className="cmn_btn"
                style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
                disabled={!editMode}
                onClick={postApi}
                tabIndex={10}
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Save
              </button>
            )}
            {!editMode && (
              <button
                className="cmn_btn"
                style={{ backgroundColor: "#5771DF" }}
                onClick={() => setEditMode(true)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
                    stroke="white"
                    stroke-width="1.2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Edit
              </button>
            )}
            {isSaved && (
              <button
                className="cmn_btn"
                style={{ backgroundColor: "#65CC5C" }}
                onClick={handleUpdate}
              >
                Update Notification to All
              </button>
            )}

            <button className="cmn_btn" style={{ backgroundColor: "#5771DF" }}>
              Send Initial Notification to All
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ScheduleMeetingSendInitial;
