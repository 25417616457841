import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RenderIcons from "../../../RenderIcons";
import "../../../../css/viewuser.css";
import { fetchDataV2, postDataReturnId } from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import SingleOptionSelectorAuditBU from "../../../SingleOptionSelectorAuditBU";
import "../createObservation/createobservation.css";
import MultiOptionWorkPaper from "../../../MultiOptionWorkPaper";
import NavRow from "../../../NavRow";
import SingleOptionSelectorAuditPR from "../../../SingleOptionSelectorAuditPR";
import CreateObsPR from "../../../CreateObsPR";

const CreateObservation = ({ tableSize }) => {
  const [errors, setErrors] =useState("");
  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [short_description, setShortDescription] = useState("");
  const [auditData, setAuditData] = useState([]);
  const [item, setItem] = useState({});
  const [objective, setTitle] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [assign_to_id, setAssign_to_id] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState("");
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [testIds, setTestIds] = useState([]);
  const [workPIdError, setWorkPIdError] = useState("");
  const [editMode, setEditMode] = useState(true);
  const successMessage = location.state?.successMessage;
  const { id, reportId } = useParams();
  const [inputList, setInputList] = useState([]);
  const [subObservations, setSubObservations] = useState([]);
  const [userData, setUserData] =useState('')

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter ={
      url :`/api/audit_engagement/audit/${id}/`, 
      setterFunction :setAuditData,
      setErrors :setErrors
     }
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    const parameter ={
      url :`/api/audit_universe/audit-entities/`, 
      setterFunction :setAPIData,
      setErrors :setErrors
     }
    fetchDataV2(parameter);
  }, []);

const findBusiness =localStorage.getItem('user_id');
  useEffect(() => {
    const parameter ={
      url :`/api/system/users/${findBusiness}/`, 
      setterFunction :setUserData,
      setErrors :setErrors
     }
    fetchDataV2(parameter);
  }, []);


  const assignId = auditData.assign_to ? auditData.assign_to.id : null;
  const assignName = auditData.assign_to ? auditData.assign_to.name : null;
  const reviewerName = auditData.reviewer ? auditData.reviewer.name : null;
  const reviewerId = auditData.reviewer ? auditData.reviewer.id : null;

  const createObservationTest = () => {
    const parameter = {
      url: `/api/audit_engagement/observation/`,
      payload: {
        audit_id: id,
        objective,
        short_description,
        preparer_id,
        reviewer_id,
        assign_to_id,
        workpapertestcase_id: Object.values(item).flat(),
      }, 
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/audit-execution/${id}/reporting/initialobservation/${reportId}/`,
      setSuccessCallback :   (data) => {
        const successMessageColor = "#4285F4";
        navigate(
          `/audit-execution/${id}/reporting/initialobservation/${data.id}/`,
          {
            state: {
              successMessage: "Successfully Created!",
              successMessageColor,
            },
          }
        );
      }

    };
    postDataReturnId(parameter);
  };



  const parentName = auditData.name;
  const [maketrue, setTrue] = useState(false);

  const preparerId = userData?.audit_team?.id;
  const preparerName = userData?.audit_team?.name;

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow name={`Create Observation`} parentName={parentName} />

        <div className="create_observation_con" style={{ width: tableSize ? "100%" : "100%" }}>

          <div className="backToReport">
            <button className="cmn_btn" style={{ backgroundColor: "#5571DF" }} onClick={createObservationTest}>
              Next
            </button>
          </div>

          <form action="">

            <div className="cr_wk_grid_container">

              <div className="cr_wk_grid_item">
                
                <h2 style={{ fontSize: "14px", fontFamily: "interbold", marginBottom: "16px" }}>Overview</h2>


                <div className="lng_lbl_inpt">
                  <label>Title</label>
                  <input
                    type="text"
                    placeholder="Title"
                    value={objective}
                    onChange={(e) => setTitle(e.target.value)}
                    tabIndex={1}
                  />
                </div>

                <CreateObsPR
                  id={id}
                  auditor={{id : userData?.audit_team?.id, name : userData?.audit_team?.name}}
                  setSelectorId={setPreparerId}
                  setSelectorError={setPreparerError}
                  selectorError={PreparerError}
                  Name="name"
                  title="Preparer"
                  editMode={editMode}
                  initialId={preparerId}
                  initialName={preparerName}
                  tabIndex={2}
                  maketrue={maketrue}
                  setTrue={setTrue}
                />

                <SingleOptionSelectorAuditPR 
                id ={id}
                  setSelectorId={setReviewerId}
                  setSelectorError={setReviewerError}
                  selectorError={reviewerError}
                  Name="name"
                  title="Reviewer"
                  editMode={editMode}
                  initialId={reviewerId}
                  initialName={reviewerName}
                  tabIndex={3}
                  maketrue={maketrue}
                  setTrue={setTrue}
                />

                <SingleOptionSelectorAuditBU 
                id={id}
                  setSelectorId={setAssign_to_id}
                  setSelectorError={setLevel1OwnerError}
                  selectorError={level_1_ownerError}
                  Name="name"
                  title="Owner"
                  editMode={editMode}
                  initialId={assignId}
                  initialName={assignName}
                  tabIndex={4}
                  maketrue={maketrue}
                  setTrue={setTrue}
                />

                <div className="lbl_txa">
                  <label>Short Description</label>
                  <textarea
                    type="text"
                    placeholder="Short Description"
                    value={short_description}
                    style={{
                      border: shortDescriptionError ? "1px solid #FF4646" : "",
                    }}
                    onChange={(e) => {
                      setShortDescription(e.target.value);
                      setShortDescriptionError(false);
                    }}
                    tabIndex={5}
                  />{" "}
                </div>
              </div>

              <div className="cr_wk_grid_item">

                <MultiOptionWorkPaper
                  apiUrl={id?`/api/audit_engagement/workpaper/?audit_id=${id}`:""}
                  setSelectorError={setWorkPIdError}
                  selectorError={workPIdError}
                  title="Select Workpaper"
                  Name="objective"
                  setTestIds={setTestIds}
                  editMode={!editMode}
                  item={item}
                  setItem={setItem}
                  tabIndex={6}
                />


              </div>

            </div>

          </form>


        </div>

      </section>
    </>
  );
};

export default CreateObservation;
