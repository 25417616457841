import React from "react";
import "../createQuestionnaire/createQuestionnaire.css";

import { NavLink, useLocation,useParams } from "react-router-dom";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../.././../css/viewuser.css";
import add_button from "../../../../images/add_button.svg";
import { auditRoutes } from "../../../subroutes";
import { useState } from "react";
import Question from "./Question";
import { useEffect } from "react";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";
import { fetchDataObjectV2 } from "../../../apiUtils";

const CreateQuestionnaire = ({ tableSize }) => {
  const location = useLocation();
  const [auditData , setAuditData] = useState();
  const [error , setErrors] =useState(false);
  const { id,quid } = useParams();
  console.log(location?.state?.data  )

  useEffect(() => {
    const parameter ={
      url : `/api/audit_universe/audit-entities/${id}/`,
      setterFunction : setAuditData,
      setErrors : setErrors,
     }
    fetchDataObjectV2(parameter);

    
  }, [id]);

  return (
    <>
     <section className="navroutes">
      <RenderIcons homeRoutes={auditRoutes} />
      <NavRow name={"Questionnaire"} trueback={true}  parentName ={auditData?.name} />

      <div className="questionniarecontainer" style={{ width: tableSize ? "100%" : "100%" }}>
        <Question tempID={quid} tableSize={tableSize} />
      </div>
      </section>
    </>
  );
};

export default CreateQuestionnaire;
